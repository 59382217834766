import React, { useState, useEffect } from "react";
import { getFundName } from "../APIs/ConfirmInvestmentAPI";

export default function GetFundName(time, fundLink, setLoading) {
  const [fundName, setFundName] = useState(null);

  useEffect(() => {
    async function getFundNameInformation() {
      setLoading(true);
      try {
        const response = await getFundName(fundLink);
        const fundNameData = response.data;
        setFundName(fundNameData.msg);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err);
        }
      }
      setLoading(false);
    }
    getFundNameInformation();
  }, [time, fundLink]);
  return { fundName };
}
