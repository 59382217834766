import React, { useState, useContext, useEffect } from "react";
import Header from "./../../Layouts/Advisor/ClientDetails/Header/Header";
import { UserContext } from "../../Contexts/UserContext";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";
import { InlineWidget } from "react-calendly";
import MuiButton from "../Buttons/MuiButton/MuiButton.js";
import "./Styles.css";

function ContactUs() {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  init(process.env.REACT_APP_EMAILJS_USER_ID);

  useEffect(() => {
    setSubjectError("");
  }, [subject]);

  useEffect(() => {
    setMessageError("");
  }, [message]);

  const submitMessage = async () => {
    setLoading(true);
    var errorsDetected = false;

    if (subject.length === 0) {
      setSubjectError("Subject is required.");
      errorsDetected = true;
      setLoading(false);
    }

    if (message.length === 0) {
      setMessageError("Message is required.");
      errorsDetected = true;
      setLoading(false);
    }

    if (errorsDetected) {
      return;
    } else {
      try {
        await emailjs.send("service_kyyw60a", "template_giibyso", {
          subject_line: subject,
          from_name: user
            ? user.investorSummaryData.first_name +
              " " +
              user.investorSummaryData.last_name
            : "",
          message: message,
          reply_to: user ? user.id : "admin@investwithaqua.com",
        });
        setSubject("");
        setMessage("");
        setLoading(false);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container pt-5 mt-5">
        <div className="row g-0 w-100">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4">
              <span className="title-text mt-4 pe-3">Contact Us</span>
            </div>
            <div className="row g-0 w-100 mb-5">
              <span className="trouble-text pe-3">Having trouble on Aqua?</span>
            </div>
            <div className="row g-0 w-100">
              <div className="col-12">
                <div className="row gx-4 w-100">
                  <div className="col-lg-12 mb-lg-0 mb-3">
                    <div className="row g-0 h-100 section-container">
                      <div className="col-12">
                        <div className="row g-0 w-100 section-header-box mb-3">
                          <span className="section-header-text pt-4 pb-4 ps-3">
                            Write to us
                          </span>
                        </div>
                        <div className="row g-0 w-100 mb-2">
                          <span className="sub-section-text ps-3">
                            Do you have any questions?
                          </span>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <span className="sub-section-text-gray ps-3">
                            Send us an email.
                          </span>
                        </div>
                        <div className="row g-0 w-100 pe-3">
                          <span className="input-name-text ps-3 mb-2">
                            Subject
                          </span>
                          <div className="col-md-6 col-12">
                            <div className="input-group">
                              <div className="row g-0 w-100 ps-3 mb-3">
                                <input
                                  type="text"
                                  class="form-control form-font"
                                  placeholder="Subject"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={subject}
                                  onChange={(e) => setSubject(e.target.value)}
                                />
                                {subjectError &&
                                  subjectError !==
                                    "This field is required." && (
                                    <div className="question-field-error-text mt-1">
                                      <span className="">{subjectError}</span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row g-0 w-100 ps-3 mb-3">
                              <span className="input-name-text mb-2">
                                Your Message
                              </span>
                              <div className="col-12">
                                <textarea
                                  class="form-control form-font"
                                  id="exampleFormControlTextarea1"
                                  placeholder="Your message goes here."
                                  rows="4"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                {messageError &&
                                  messageError !==
                                    "This field is required." && (
                                    <div className="question-field-error-text mt-1">
                                      <span className="">{messageError}</span>
                                    </div>
                                  )}
                                <div className="d-flex mt-3 mb-3">
                                  <MuiButton
                                    buttonText={"Submit"}
                                    onClick={() => {}}
                                    size={"large"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ContactUs;
