import React, { useState } from "react";
import "./Styles/Styles.scss";
import ClientIcon from "./ClientIcon/ClientIcon";
import Status from "./Status/Status";

const Header = ({
  clientName,
  clientEmail,
  firstName,
  lastName,
  email,
  status,
  invested,
  distributed,
}) => {
  return (
    <div className="row g-0 w-100">
      <div className="client-header-container">
        <div className="d-flex h-100 align-items-center">
          {/* <ClientIcon firstName={firstName} lastName={lastName} /> */}
          <div className="row g-0 w-100 ps-4">
            <div className="col-4 border-right">
              <div className="mb-1">
                <span className="client-name">{clientName}</span>
              </div>
              <div>
                <span className="client-email body-text-lg-medium">
                  {clientEmail}
                </span>
              </div>
            </div>
            {/* <div className="col-4 border-right ps-4">
              <div className="mb-1">
                <span className="section-title">Status</span>
              </div>
              <div>
                <Status status={status} />
              </div>
  </div> */}
            <div className="col-4 ps-4 align-items-center">
              <div className="row g-0 w-100 h-100 align-items-center">
                <div className="d-flex">
                  <div className="row g-0 w-100 mb-1">
                    <span className="invested">Total Invested:</span>
                  </div>
                  <div className="row g-0 w-100">
                    <span className="distributed">Total Distributed:</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="row g-0 w-100 mb-1">
                    <span className="invested">
                      {invested !== null
                        ? "$" + invested.toLocaleString()
                        : "$0"}
                    </span>
                  </div>
                  <div className="row g-0 w-100">
                    <span className="distributed">
                      {distributed !== null
                        ? "$" + distributed.toLocaleString()
                        : "$0"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
