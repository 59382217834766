import React from "react";
import "./SearchBar.scss";
function SearchBar({ setValue, placeholder }) {
  return (
    <div className="search-box">
      <div className="magnifier-container h-100 d-flex align-items-center">
        <img src="https://d1swyfveypj49y.cloudfront.net/Magnifier.svg" alt="Magnifier"></img>
      </div>
      <input
        type="text"
        className="search-input"
        placeholder={placeholder}
        onChange={(event) => setValue(event.target.value)}
      ></input>
    </div>
  );
}
export default SearchBar;
