import { Auth, Storage } from "aws-amplify";

/**
 *
 */
export const getRequestHeaders = async () => {
  const config = {
    headers: {},
  };

  const accessToken = await Auth.currentSession().then(
    (response) => response.accessToken.jwtToken
  );
  if (accessToken !== undefined && accessToken !== null) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return config;
};

/**
 *
 * @param {string} email
 * @returns {boolean} -> Email in valid format
 */
export const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 *
 * @param {string} password
 * @returns {boolean} -> Password follows
 */
export const isPasswordValid = (password) => {
  const re =
    /^(?=.{10,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]).*$/;
  return re.test(String(password));
};

/**
 * Returns whether the user has scrolled to the bottom of a container
 *
 * @param {event} e
 */
export const isBottom = (e) => {
  const bottom =
    e.target.scrollHeight - Math.round(e.target.scrollTop) <=
    e.target.clientHeight;
  if (process.env.DEBUG) console.log("bottom: ", bottom);
  return bottom;
};

export const isValidPhoneNumber = (number) => {
  const regEx =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  return regEx.test(number);
};

/**
 *
 * @param {string} level
 * @param {string} path
 * @returns {string} -> Signed URL to access S3 file from React
 */
export const getSignedURL = async (level, path) => {
  return path;
  // This function is obsoleted by a backend change.
  let tempPath = path;
  let levelSpecification = level + "/";
  let cleanedPath = tempPath.slice(
    path.indexOf(levelSpecification) + levelSpecification.length
  );
  var signedURL = await Storage.get(cleanedPath, {
    level: level,
  });
  return signedURL;
};

/**
 *
 * @param {string} num
 * @returns
 */
export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

export const thousandsSeparator = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 *
 * @param {string} value
 * @returns {string} formatted value (US locale with $ amount)
 */
export const formatInvestmentAmount = (value) => {
  if (!value) return value;
  if (value === "" || value === "$") {
    return "";
  }
  if (
    value.length === 1 &&
    value !== "0" &&
    value !== "1" &&
    value !== "2" &&
    value !== "3" &&
    value !== "4" &&
    value !== "5" &&
    value !== "6" &&
    value !== "7" &&
    value !== "8" &&
    value !== "9"
  ) {
    return "";
  }

  var investmentAmount = value.replace(/[^\d]/g, "");
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return "$" + parseInt(investmentAmount).toLocaleString("en-US");
};

/**
 *
 * @param {string} value
 * @returns {string} formattedSSN
 */
export const formatSSN = (value) => {
  if (!value) return value;

  const ssn = value.replace(/[^\d]/g, "");

  const ssnLength = ssn.length;

  if (ssnLength < 4) return ssn;

  if (ssnLength < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
};

export const isValidSSN = (ssn) => {
  const re = /^(?!000|666)[0-9]{3}([ -])(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/;
  return re.test(String(ssn).toLowerCase());
};

export const isDobValid = (dob) => {
  const re = /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/;
  return re.test(String(dob).toLocaleLowerCase());
};

export const stripOutDigits = (input) => {
  const re = /[^0-9]/g;
  const newString = input.replace(re, "");

  return newString;
};
