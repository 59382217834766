import { useState, useEffect } from "react";
import { getInvestorConfidentialityAgreement } from "../APIs/SignupAPI";

export default function GetInvestorConfidentialityAgreement(time) {
  const [
    investorConfidentialityAgreement,
    setInvestorConfidentialityAgreement,
  ] = useState(null);

  useEffect(() => {
    async function getInvestorConfidentialityAgreementObject() {
      try {
        const response = await getInvestorConfidentialityAgreement();
        const investorConfidentialityAgreementData = response.data[0];
        setInvestorConfidentialityAgreement({
          id: investorConfidentialityAgreementData.id,
          version: investorConfidentialityAgreementData.version,
          agreement: investorConfidentialityAgreementData.agreement,
          status: investorConfidentialityAgreementData.status,
          startDate: investorConfidentialityAgreementData.start_date,
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err);
        }
      }
    }
    getInvestorConfidentialityAgreementObject();
  }, [time]);
  return { investorConfidentialityAgreement };
}
