import React, { useState, useContext, useEffect } from "react";
import Header from "./../../Layouts/Advisor/ClientDetails/Header/Header";
import { UserContext } from "../../Contexts/UserContext";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";
import { InlineWidget } from "react-calendly";
import MuiButton from "../Buttons/MuiButton/MuiButton.js";
import TextInputField from "../InputFields/TextInputField/TextInputField";
import "./Styles.css";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { submitBYO } from "../../APIs/WealthManagementAPI";
import Modal from "react-bootstrap/Modal";
import InvestmentAmountField from "../InputFields/InvestmentAmountInputField/InvestmentAmountInputField";
import SnackBar from "../Alerts/SnackBar/SnackBar";

const formatNum = (value) => {
  if (!value) return value;

  return value.replace(/[^\d]/g, "");
};

function BYO() {
  const [modal, setModal] = useState(false);

  const [inv, setInv] = useState("");
  const [invError, setInvError] = useState("");

  const [error, setError] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [invType, setInvType] = useState("");
  const [invTypeError, setInvTypeError] = useState("");

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");

  const [dealSize, setDealSize] = useState("");
  const [dealSizeError, setDealSizeError] = useState("");

  const [loading, setLoading] = useState("");

  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  useEffect(() => {
    setNameError("");
  }, [name]);

  useEffect(() => {
    setInvTypeError("");
  }, [invType]);

  useEffect(() => {
    setAmountError("");
  }, [amount]);

  useEffect(() => {
    setInvError("");
  }, [inv]);

  const submitMessage = async () => {
    setLoading(true);

    var errorsDetected = false;

    if (invType === "") {
      setInvTypeError("This field is required.");
      errorsDetected = true;
      setLoading(false);
    }

    if (amount.length === 0) {
      setAmountError("This field is required.");
      errorsDetected = true;
      setLoading(false);
    } else if (amount.replace(/[^\d]/g, "") <= 0) {
      setAmountError("This value must be greater than 0.");
      errorsDetected = true;
      setLoading(false);
    }

    if (name.length === 0) {
      setNameError("This field is required.");
      errorsDetected = true;
      setLoading(false);
    }

    if (inv.length === 0) {
      setInvError("This field is required.");
      errorsDetected = true;
      setLoading(false);
    }

    if (errorsDetected) {
      return;
    } else {
      try {
        const payload = {
          deal_size: parseInt(formatNum(amount)),
          name: name,
          inv_type: invType,
          inv: parseInt(formatNum(inv)),
        };

        await submitBYO(payload);
        setModal(true);
        setLoading(false);
        setSubmissionSuccess(true);
        setAmount("");
        setInv("");
        setDealSize("");
        setName("");
      } catch (err) {
        setModal(true);
        setError(true);
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
        setLoading(false);
        setSubmissionError(true);
      }
    }
  };

  return (
    <React.Fragment>
      {modal && (
        <Modal
          show={modal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            setModal(false);
          }}
        >
          <Modal.Header closeButton>
            {" "}
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!error ? (
              <div>Submission successful.</div>
            ) : (
              <div>There was a problem, please try again.</div>
            )}
          </Modal.Body>
        </Modal>
      )}
      <div className="container pt-5 mt-5">
        <div className="row g-0 w-100">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4">
              <span className="title-text mt-4 pe-3">
                Bring Your Own Investment
              </span>
            </div>
            <div className="row g-0 w-100">
              <div className="col-12">
                <div className="row gx-4 w-100">
                  <div className="col-lg-12 mb-lg-0 mb-3">
                    <div className="row g-0 h-100 section-container">
                      <div className="col-12 mt-2">
                        <div className="row g-0 w-100 pe-3">
                          <span className="input-name-text ps-3 mb-2">
                            Investment Type
                          </span>
                          <div className="col-md-6 col-12">
                            <div className="input-group">
                              <div className="row g-0 w-100 ps-3 mb-3">
                                <div className="d-flex">
                                  <div className="col">
                                    <label
                                      for={"restrictionActionShownRadioId"}
                                      className={"radio"}
                                      onClick={() => {
                                        setInvType("DIRECT");
                                      }}
                                    >
                                      <input
                                        type={"radio"}
                                        className={"radio__input"}
                                        checked={invType === "DIRECT"}
                                        readOnly={true}
                                      />
                                      <div className="radio__radio"></div>
                                      <span className="qualification-question-text ms-2">
                                        Direct Deal
                                      </span>
                                    </label>
                                  </div>
                                  <div className="col">
                                    <label
                                      for={"restrictionActionShownRadioId"}
                                      className={"radio"}
                                      onClick={() => {
                                        setInvType("FUND");
                                      }}
                                    >
                                      <input
                                        type={"radio"}
                                        className={"radio__input"}
                                        checked={invType === "FUND"}
                                        readOnly={true}
                                      />
                                      <div className="radio__radio"></div>
                                      <span className="qualification-question-text ms-2">
                                        Fund Opportunity
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                {invTypeError && (
                                  <div className="question-field-error-text mt-1">
                                    <span className="">
                                      This field is required.
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 ps-3 pe-3 mb-3">
                          <div className="col ps-0 pe-3">
                            <TextInputField
                              value={name}
                              setValue={setName}
                              valueError={nameError}
                              setValueError={setNameError}
                              label={"Name of Deal/Fund Opportunity "}
                              required={true}
                              fullWidth={true}
                              size={"small"}
                              shrink={true}
                            />
                          </div>
                          <div className="col ps-3 pe-0">
                            <TextInputField
                              value={inv}
                              setValue={setInv}
                              valueError={invError}
                              setValueError={setInvError}
                              label={"Estimated Amount of Investors"}
                              required={true}
                              fullWidth={true}
                              size={"small"}
                              shrink={true}
                              mask={{
                                maskFormatter: formatNum,
                                maskPattern: "999999999",
                              }}
                            />
                          </div>
                        </div>
                        <div className="row g-0 w-100 ps-3 pe-3 mb-4">
                          <div className="col pe-0">
                            <InvestmentAmountField
                              placeholder={"Enter Allocation Amount"}
                              error={amountError}
                              fieldValueChange={setAmount}
                              initialValue={amount}
                              disabled={false}
                              maxInput={1000000000}
                            />
                          </div>
                        </div>
                        <div className="row g-0 w-100 ps-3 pe-3 mb-3">
                          <div className="d-flex">
                            <MuiButton
                              buttonText={"Submit"}
                              onClick={submitMessage}
                              size={"large"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SnackBar
          snackbarState={submissionSuccess}
          setSnackbarState={setSubmissionSuccess}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"success"}
          message={"Your request was submitted successfully."}
        />
        <SnackBar
          snackbarState={submissionError}
          setSnackbarState={setSubmissionError}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"error"}
          message={"There was an error submitting your request."}
        />
      </div>
    </React.Fragment>
  );
}
export default BYO;
