import React from "react";
import "./Styles/Styles.scss";
import InfiniteInputComponent from "../../../../../../../Components/InfiniteInputComponent/InfiniteInputComponent";
import InfiniteTabInput from "../../../../../../../Components/InfiniteInputComponent/InfiniteTabInput";

function TrusteeInformation({
  accountType,
  hasTrustees,
  setHasTrustees,
  relatedPartyInfo,
  setRelatedPartyInfo,
  activeTab,
  setActiveTab,
  setHideButton,
  hideAlterButtons,
  addNewTab,
  handleRemoveFields,
  disabled,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <div className="row g-0 w-100 mb-2">
          <span className="body-text-sm-semibold mb-1">
            {
              "Does this trust have any trustees other than the primary account holder that you would like to specify?"
            }
          </span>
        </div>
        <div className="row g-0 w-100 mb-2 fade-in-test">
          <div className="col-6">
            <label for={"hasTrusteesRadioId"} className={"radio"}>
              <input
                type={"radio"}
                name={"hasTrusteesRadio"}
                id={"hasTrusteesRadioId"}
                className={"radio__input"}
                checked={hasTrustees}
                onClick={() => {
                  setHasTrustees(true);
                }}
              />
              <div className="radio__radio"></div>
              <span className="qualification-question-text ms-2">Yes</span>
            </label>
          </div>
          <div className="col-6">
            <label for={"notHasTrusteesRadioId"} className={"radio"}>
              <input
                type={"radio"}
                name={"notHasTrusteesRadio"}
                id={"notHasTrusteesRadioId"}
                className={"radio__input"}
                checked={hasTrustees !== "" && !hasTrustees}
                onClick={() => {
                  setHasTrustees(false);
                }}
              />
              <div className="radio__radio"></div>
              <span className="qualification-question-text ms-2">No</span>
            </label>
          </div>
        </div>
      </div>
      {hasTrustees === true && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <h6>Related Party Information</h6>
          </div>
          <div className="row g-0 w-100">
            <InfiniteTabInput
              tabs={relatedPartyInfo.map(
                (elem, idx) => "Trustee #" + (idx + 1).toString()
              )}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              addNewTab={addNewTab}
            />
            <InfiniteInputComponent
              inputFields={relatedPartyInfo}
              setInputFields={setRelatedPartyInfo}
              index={activeTab.match(/\d+/) - 1}
              handleRemoveFields={handleRemoveFields}
              disabled={disabled}
              setActiveTab={setActiveTab}
              setHideButton={setHideButton}
              hideAlterButtons={hideAlterButtons}
              accountType={accountType}
              relatedPartyType={"TRT"}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default TrusteeInformation;
