import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import styles from "../../../utilities/scss/_variables.scss";

function SelectInputField({
  value,
  setValue,
  valueError,
  setValueError,
  selectOptions,
  label,
  size,
  required,
  fullWidth,
  id,
  disabled,
  shrink,
}) {
  //Styles variable assignment from exported sass variable values
  const primaryFontFamily = styles.primaryFontFamily;
  const primaryBlack2 = styles.primaryBlack2;
  const primaryBlue = styles.primaryBlue;
  const secondaryOffWhite = styles.secondaryOffWhite;
  const secondaryRed = styles.secondaryRed;
  const fontSize3 = styles.fontSize3;

  useEffect(() => {
    setValueError("");
  }, [value]);

  const visible = (label !=="State" && disabled === false) || value !== "N/A"; 

  return (
    visible ? 
    (!disabled ? 
    <Box sx={{ minWidth: 120 }}>
      <TextField
        error={valueError !== ""}
        id={id}
        label={label}
        helperText={valueError}
        value={value}
        disabled={disabled}
        onChange={(event) => setValue(event.target.value)}
        onBlur={() => {
          if (required && value === "") {
            setValueError("This field is required.");
          }
        }}
        size={size}
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: primaryFontFamily,
            color: primaryBlack2,
            fontSize: fontSize3,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: primaryBlue,
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: primaryBlue,
              color: primaryBlue,
            },
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: secondaryOffWhite,
            },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            "& > fieldset": {
              borderColor: secondaryOffWhite,
            },
          },
          "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
            "& > fieldset": {
              borderColor: valueError === "" ? secondaryOffWhite : secondaryRed,
            },
          },
        }}
        required={required}
        InputLabelProps={{ shrink: shrink }}
        fullWidth={fullWidth}
        select
      >
        {selectOptions.map((option) => {
          return <MenuItem value={option.value}>{option.label}</MenuItem>;
        })}
      </TextField>
    </Box> :
    <>
    <Box sx={{ minWidth: 120 }}>
      <TextField
        error={valueError !== ""}
        id={id}
        label={label}
        helperText={valueError}
        value={value}
        disabled={disabled}
        size={size}
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: primaryFontFamily,
            color: primaryBlack2,
            fontSize: fontSize3,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: primaryBlue,
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: primaryBlue,
              color: primaryBlue,
            },
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: secondaryOffWhite,
            },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            "& > fieldset": {
              borderColor: secondaryOffWhite,
            },
          },
          "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
            "& > fieldset": {
              borderColor: valueError === "" ? secondaryOffWhite : secondaryRed,
            },
          },
        }}
        required={required}
        InputLabelProps={{ shrink: shrink }}
        fullWidth={fullWidth}
      >
      </TextField>
    </Box>
    </>) :
    (<></>)
  );
}
export default SelectInputField;
