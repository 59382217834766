import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../Contexts/UserContext";
import {
  getFirmFundRestrictionAction,
  getFirmFundRestrictions,
} from "../APIs/FirmFundRestrictionsAPI";

export const GetFirmFundRestrictionAction = (setLoading, refresh) => {
  const [firmFundRestrictionAction, setFirmFundRestrictionAction] =
    useState("");

  useEffect(() => {
    async function getFirmFundRestrictionActionType() {
      setLoading(true);
      try {
        const response = await getFirmFundRestrictionAction();

        const firmFundRestrictionActionData = response.data;

        let restrictionActionMap = {
          I: "Include",
          E: "Exclude",
        };

        setFirmFundRestrictionAction(
          restrictionActionMap[firmFundRestrictionActionData.restriction_action]
        );
      } catch (err) {
        setFirmFundRestrictionAction("");
      }
      setLoading(false);
    }
    getFirmFundRestrictionActionType();
  }, [refresh]);

  return { firmFundRestrictionAction };
};

export const GetFirmFundRestrictions = (setLoading, refresh) => {
  const [firmFundRestrictions, setFirmFundRestrictions] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function getFirmFundRestrictionInformation() {
      try {
        setLoading(true);
        const response = await getFirmFundRestrictions(user.firmInfo.id);
        const firmFundRestrictionData = response.data;

        let strategyTypeMap = {
          STRUCTURED_CREDIT: "Structured Credit",
          CLO_MANAGEMENT: "CLO Management",
          DIRECT_LANDING: "Direct Landing",
          PRIVATE_EQUITY: "Private Equity",
          CO_INVESTMENT: "Co Investment",
          FUND_OF_FUNDS: "Fund of Funds",
          VENTURE_CAPITAL: "Venture Capital"
        };

        let restrictionActionMap = {
          I: "Include",
          E: "Exclude",
        };

        let processedArray = firmFundRestrictionData.map((item) => {
          return {
            id: item.id,
            firmName: item.firm_name,
            fundName: item.fund_name,
            strategy: strategyTypeMap[item.strategy_type],
            restrictionType: restrictionActionMap[item.restriction_type],
            dateAdded: item.dateAdded,
          };
        });

        setFirmFundRestrictions(processedArray);
      } catch (err) {
        setFirmFundRestrictions([]);
      }
      setLoading(false);
    }
    getFirmFundRestrictionInformation();
  }, [refresh]);

  return { firmFundRestrictions };
};
