import React from "react";
import "./Styles.scss";

function TargetPerformanceSection({
  targetIRR,
  strategy,
  targetReturnMultiple,
  minimum,
  stage,
}) {
  return (
    <div class="col-12">
      <div class="col-12">
        <div class="row ms-3 me-3">
          <div class="two-by-two-grid rounded horizontal-border">
            <div class="row border-between vertical-border">
              <div class="col-6 two-by-two-grid-item">
                {" "}
                <div class="fund-target-header-text">Target IRR</div>{" "}
                <span class="info-text">{targetIRR + "%"}</span>
              </div>
              <div class="col-6 two-by-two-grid-item">
                {" "}
                <div class="fund-target-header-text">Strategy</div>{" "}
                <span class="info-text">{strategy}</span>
              </div>
            </div>
            <div class="row border-between">
              <div class="col-6 two-by-two-grid-item">
                <div class="fund-target-header-text">Target Net Multiple</div>
                <span class="info-text">{targetReturnMultiple + "x"}</span>
              </div>
              <div class="col-6 two-by-two-grid-item">
                <div class="fund-target-header-text">Minimum</div>
                <span class="info-text">
                  {"$" + minimum.toLocaleString("en-US")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0 mb-3">
          <div class="row gx-3"></div>
        </div>
      </div>
    </div>
  );
}
export default TargetPerformanceSection;
