import React, { useState, useEffect, useRef, useContext } from "react";
import Header from "../Header/Header.js";
import "./Styles.scss";
import InvestPageHeader from "./InvestPageHeader/InvestPageHeader.js";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation.js";
import FilterRow from "./../FilterRow/FilterRow.js";
import { getSignedURL } from "./../../utilities/functions/functions.js";
import FundCard from "./../FundCard/FundCard.js";
import BYOCard from "./../FundCard/BYOCard.js";
import useFundSearch from "../../APIs/useFundSearch.js";
import { isFeederAPI } from "../../APIs/WealthManagementAPI.js";
//import useFundSearch from "../../../Api/GlobalFunctions/useFundSearch.js";
import NoFundsFoundCard from "./../NoFundsFoundCard/NoFundsFoundCard.js";
import { useLocation } from "react-router-dom";
import SnackBar from "../Alerts/SnackBar/SnackBar.js";
import { UserContext } from "../../Contexts/UserContext.js";

function InvestPage() {
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const [searchKeyWords, setSearchKeyWords] = useState("");
  const [fundMinimumAllocation, setFundMinimumAllocation] = useState("All");
  const [minInvestment, setMinInvestment] = useState("All");
  const [defaultMinInvestment, setDefaultMinInvestment] = useState([]);
  const [strategyType, setStrategyType] = useState("All");
  const [defaultStrategyType, setDefaultStrategyType] = useState("All");
  const [status, setStatus] = useState("All");
  const [defaultStatus, setDefaultStatus] = useState(["All"]);
  const [loading, setLoading] = useState(false);

  const [accountLinkSuccessful, setAccountLinkSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.accountLinked !== null &&
      location.state.accountLinked !== undefined
      ? location.state.accountLinked
      : false
  );

  const [feeder, setFeeder] = useState(false);

  useEffect(() => {
    async function isFeeder() {
      isFeeder = await isFeederAPI();
      isFeeder.data.msg === true && setFeeder(true);
    }
    isFeeder();
  }, [accountLinkSuccessful]);


  useEffect(() => {
    let minInvestmentFilterItems = "";
    let defaultSelected = [];

    if (minInvestment !== "") {
      defaultSelected.push(minInvestment);
    }

    if (minInvestment === "$10,000") {
      minInvestmentFilterItems = 10000;
    } else if (minInvestment === "$50,000") {
      minInvestmentFilterItems = 50000;
    } else if (minInvestment === "$100,000") {
      minInvestmentFilterItems = 100000;
    }

    setDefaultMinInvestment(defaultSelected);
    setFundMinimumAllocation(minInvestmentFilterItems);
  }, [minInvestment]);

  useEffect(() => {
    let defaultItems = [];
    let statusArray = [];
    if (status !== "All") {
      statusArray.push(status);
    }
    defaultItems.push(status);
    setDefaultStatus(defaultItems);
  }, [status]);

  useEffect(() => {
    setDefaultStrategyType(strategyType);
  }, [strategyType]);

  // const logo_link =
  //   "public/firm_documents/03c5c002-e7b0-44d8-b94a-94a37aad5b56/static_firm_logos/PermiraGeneratedLog.svg";

  // const [logoLink, setLogoLink] = useState(null);

  // async function getUsableURLs() {
  //   const firmLogoLinkUrl = await getSignedURL("public", logo_link);
  //   setLogoLink(firmLogoLinkUrl);
  // }

  // getUsableURLs();

  // const [allFunds, setAllFunds] = useState([
  //   {
  //     id: "test",
  //     link: "fund_iv",
  //     fundName: "Vintage IV",
  //     logoLink: logoLink,
  //     investorInterested: false,
  //     firm: "Thamesville Capital",
  //     strategy: "PRIVATE_EQUITY",
  //     fundRestrictions: "Nothing",
  //     fundTerms: 2,
  //     fundSize: 200,
  //     fundLength: 120,
  //     fundInvestmentStatus: "Fundraising",
  //     amountInvested: 0,
  //     minimumInvestment: 100000,
  //     targetIRR: 24,
  //     fundTerms: { target_return_multiple: 2.5 },
  //   },
  // ]);

  // const [funds, setFunds] = useState(allFunds);

  const { funds } = useFundSearch(
    searchKeyWords,
    fundMinimumAllocation,
    status,
    strategyType,
    setLoading
  );


  // useEffect(() => {
  //   setAllFunds([
  //     {
  //       id: "test",
  //       link: "fund_iv",
  //       fundName: "Vintage IV",
  //       logoLink: logoLink,
  //       investorInterested: false,
  //       firm: "Thamesville Capital",
  //       strategy: "PRIVATE_EQUITY",
  //       fundRestrictions: "Nothing",
  //       fundTerms: 2,
  //       fundSize: 200,
  //       fundLength: 120,
  //       fundInvestmentStatus: "Fundraising",
  //       amountInvested: 0,
  //       minimumInvestment: 100000,
  //       targetIRR: 24,
  //       fundTerms: { target_return_multiple: 2.5 },
  //     },
  //   ]);
  // }, [logoLink]);

  // useEffect(() => {
  //   console.log(strategyType, fundMinimumAllocation);
  //   setFunds(
  //     allFunds.filter(
  //       (fund) =>
  //         (fund.fundName.includes(searchKeyWords) ||
  //           fund.firm.includes(searchKeyWords)) &&
  //         (fundMinimumAllocation === "All" ||
  //           fund.minimumInvestment >= fundMinimumAllocation) &&
  //         (strategyType === "All" || fund.strategy == strategyType) &&
  //         (status === "All" || fund.fundInvestmentStatus == status)
  //     )
  //   );
  // }, [
  //   allFunds,
  //   strategyType,
  //   status,
  //   minInvestment,
  //   searchKeyWords,
  //   fundMinimumAllocation,
  // ]);

  return (
    <React.Fragment>
      <div className="pt-3 mt-3"></div>
      <div className="container justify-content-center">
        <div className="row g-0 justify-content-center">
          <div className="row g-0 w-100 justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 g-0">
              <InvestPageHeader />
            </div>
          </div>
          <div className="row g-0 w-100 justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row g-0 w-100">
                <div className="container overflow-hidden">
                  <FilterRow
                    setQuery={setSearchKeyWords}
                    minInvestment={minInvestment}
                    setMinInvestment={setMinInvestment}
                    defaultMinInvestment={defaultMinInvestment}
                    status={status}
                    setStatus={setStatus}
                    defaultStatus={defaultStatus}
                    strategyType={strategyType}
                    setStrategyType={setStrategyType}
                    defaultStrategyType={defaultStrategyType}
                  />
                  <LoadingAnimation loading={loading} />
                  {!loading && funds.length === 0 ? (
                    <NoFundsFoundCard />
                  ) : (
                    <div className="row gx-0 gy-4 mt-2 mb-5">
                      {funds.map((fund) => {
                        return <FundCard key={0} fund={fund} />;
                      })}
                      {!feeder && user.userRole !== 'Self-Serve' && <BYOCard />}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        snackbarState={accountLinkSuccessful}
        setSnackbarState={setAccountLinkSuccessful}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"success"}
        message={"You have successfully linked your account."}
      />
    </React.Fragment>
  );
}
export default InvestPage;
