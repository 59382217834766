import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "./../../../Components/GooglePlacesAutocomplete";
import { syonBankAccount } from "../../../APIs/WealthManagementAPI";

interface Props {
  investment: string;
  clientId: string;
  setSelectedTab: (e: string) => void;
}

type BankAccountData = {
  financialInstitution: string;
  telephone: string;
  abaNumber: string;
  accountRepresentative: string;
  forAccountOf: string;
  accountNumber: string;
  isFATFCountry: string | null;
};

const BankAccountForm: React.FC<Props> = ({ investment, clientId, setSelectedTab }) => {
  const [formData, setFormData] = useState<BankAccountData>({
    financialInstitution: "",
    telephone: "",
    abaNumber: "",
    accountRepresentative: "",
    forAccountOf: "",
    accountNumber: "",
    isFATFCountry: null,
  });

  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postal, setPostal] = useState('');
  const [country, setCountry] = useState('');

  const [addressError, setAddressError] = useState('');


  useEffect(() => {
    console.log(addressLine1);
  }, [addressLine1]);

  const [errors, setErrors] = useState<Partial<BankAccountData>>({});

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "isFATFCountry" ? value === "true" : value,
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<BankAccountData> = {};
    Object.keys(formData).forEach((key) => {
      if (
        !["telephone", "forAccountOf", "accountRepresentative"].includes(key) && 
       (formData[key as keyof BankAccountData] === "" ||
        formData[key as keyof BankAccountData] === null)
      ) {
        newErrors[key as keyof BankAccountData] = "This field is required.";
      }
      /*if ([addressLine1, city, state, postal, country].includes("")) {
        setAddressError("Required");
      }*/
    });
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      await syonBankAccount({ "investment": investment, "bank_account_data": { ...formData, "address1": `${addressLine1} ${addressLine2}`, "address2": `${city} ${state} ${postal} ${country}` }, "client_id": clientId });
      setSelectedTab("Document Collection");
    }
  };

  return (
    <form
      className="tw-max-w-lg tw-mx-auto tw-bg-white tw-shadow-md tw-rounded-lg tw-p-6"
      onSubmit={handleSubmit}
    >
      <div className="tw-space-y-4">
      Please specify the bank account information for the account that will be used to fund this subscription. Fields marked with an asterisk are required. Note: This will also be the bank account that distributions will be sent to.
        <div>
          <label
            htmlFor="financialInstitution"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Financial Institution*
          </label>
          <input
            type="text"
            name="financialInstitution"
            id="financialInstitution"
            value={formData.financialInstitution}
            onChange={handleInputChange}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-p-2"
          />
          {errors.financialInstitution && (
            <p className="tw-text-red-500 tw-text-sm">
              {errors.financialInstitution}
            </p>
          )}
        </div>


        {/* address */}
        <div>
          <label
            htmlFor="financialInstitution"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Bank Address*
          </label>
          <GooglePlacesAutocomplete
            hideHeader={true}
            disabled={false}
            object={"bank"}
            values={{
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              city: city,
              state: state,
              postalCode: postal,
              country: country,
            }}
            addressCallbacks={{
              setAddressLine1: setAddressLine1,
              setAddressLine2: setAddressLine2,
              setCity: setCity,
              setState: setState,
              setPostalCode: setPostal,
              setCountry: setCountry
            }}
            errorList={[addressError]}
            errorCallbacks={false}
          /></div>


        {/* Telephone */}
        <div>
          <label
            htmlFor="telephone"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Telephone
          </label>
          <input
            type="tel"
            name="telephone"
            id="telephone"
            value={formData.telephone}
            onChange={handleInputChange}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-p-2"
          />
          {errors.telephone && (
            <p className="tw-text-red-500 tw-text-sm">{errors.telephone}</p>
          )}
        </div>

        {/* ABA Number */}
        <div>
          <label
            htmlFor="abaNumber"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            ABA Number*
          </label>
          <input
            type="text"
            name="abaNumber"
            id="abaNumber"
            value={formData.abaNumber}
            onChange={handleInputChange}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-p-2"
          />
          {errors.abaNumber && (
            <p className="tw-text-red-500 tw-text-sm">{errors.abaNumber}</p>
          )}
        </div>

        {/* Account Representative */}
        <div>
          <label
            htmlFor="accountRepresentative"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Account Representative
          </label>
          <input
            type="text"
            name="accountRepresentative"
            id="accountRepresentative"
            value={formData.accountRepresentative}
            onChange={handleInputChange}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-p-2"
          />
          {errors.accountRepresentative && (
            <p className="tw-text-red-500 tw-text-sm">
              {errors.accountRepresentative}
            </p>
          )}
        </div>

        {/* For Account Of */}
        <div>
          <label
            htmlFor="forAccountOf"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            For Account Of
          </label>
          <input
            type="text"
            name="forAccountOf"
            id="forAccountOf"
            value={formData.forAccountOf}
            onChange={handleInputChange}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-p-2"
          />
          {errors.forAccountOf && (
            <p className="tw-text-red-500 tw-text-sm">{errors.forAccountOf}</p>
          )}
        </div>

        {/* Account Number */}
        <div>
          <label
            htmlFor="accountNumber"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Account Number*
          </label>
          <input
            type="text"
            name="accountNumber"
            id="accountNumber"
            value={formData.accountNumber}
            onChange={handleInputChange}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-p-2"
          />
          {errors.accountNumber && (
            <p className="tw-text-red-500 tw-text-sm">{errors.accountNumber}</p>
          )}
        </div>

        {/* Is FATF Country */}
        <div>
          <label
            htmlFor="isFATFCountry"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Is FATF Country?*
          </label>
          <select
            name="isFATFCountry"
            id="isFATFCountry"
            value={formData.isFATFCountry === null ? "" : formData.isFATFCountry}
            onChange={handleInputChange}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-p-2"
          >
            <option value="">Select an option</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          {errors.isFATFCountry && (
            <p className="tw-text-red-500 tw-text-sm">
              {errors.isFATFCountry}
            </p>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="tw-bg-blue-500 tw-text-white tw-font-medium tw-rounded-md tw-px-4 tw-py-2 tw-mt-4 hover:tw-bg-blue-600"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default BankAccountForm;
