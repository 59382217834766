import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import Header from "./../../../Components/Header/Header";
import { addFirmUser, getAdvisors } from "../../../APIs/FundActions";
import PhoneInputField from "./../../../Components/InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "./../../../Components/InputFields/SelectInputField/SelectInputField";
import { countryList } from "./../../../utilities/lists/countryList";
import SnackBar from "./../../../Components/Alerts/SnackBar/SnackBar";
import { isValidPhoneNumber } from "./../../../utilities/functions/functions";

import MuiButton from "./../../../Components/Buttons/MuiButton/MuiButton";

import TextInputField from "./../../../Components/InputFields//TextInputField/TextInputField";
import { WarningModal } from "../../../Components/Modals/WarningModal";

const AddFirmUser = () => {
  const navigate = useNavigate();

  const [formVals, setFormVals] = useState({
    firstName: "",
    lastName: "",
    role: "",
    phoneNumber: "",
    country: "",
    email: "",
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [advisors, setAdvisors] = useState([]);

  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        const response = await getAdvisors();
        setAdvisors(response.data || []);
      } catch (error) {
        console.error("Error fetching advisors:", error);
      }
    };

    fetchAdvisors();
  }, []);

  const submit = async () => {
    // If it's a co-advisor without associations, show warning modal
    if (formVals.role === "Co-Advisor" && selectedAdvisors.length === 0) {
      setShowWarningModal(true);
      return;
    }

    var errors = false;

    for (const key in formVals) {
      if (!key.endsWith("Error") && formVals[key] === "") {
        setForm(key + "Error", "This field is required.");
        errors = true;
      }
    }

    if (!isValidPhoneNumber(formVals.phoneNumber)) {
      errors = true;
      setForm("phoneNumberError", "Enter a valid phone number.");
    }

    if (errors) {
      return;
    }

    try {
      const params = {
        first_name: formVals.firstName,
        last_name: formVals.lastName,
        role: formVals.role,
        email: formVals.email,
        phone_number: formVals.phoneNumber,
        country: formVals.country,
      };

      // Only add selectedAdvisors if role is Co-Advisor and there are selections
      if (formVals.role === "Co-Advisor" && selectedAdvisors.length > 0) {
        params.selectedAdvisors = selectedAdvisors;
      }

      const resp = await addFirmUser(params);

      setFormVals({
        firstName: "",
        lastName: "",
        role: "",
        phoneNumber: "",
        country: "",
        email: "",
      });
      setSelectedAdvisors([]);
      setSuccess(true);
    } catch (e) {
      setError(true);
    }
  };

  const setForm = (key, newVal) => {
    setFormVals((prevState) => ({ ...prevState, [key]: newVal }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Add validation for co-advisor without associations
  //   if (formVals.role === "Co-Advisor" && selectedAdvisors.length === 0) {
  //     setShowWarningModal(true);
  //     return;
  //   }

  //   try {
  //     // Existing submission logic...
  //     const userData = {
  //       ...formVals,
  //       selectedAdvisors: selectedAdvisors,
  //     };

  //     await submitForm(userData);
  //     navigate("/manage-users");
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  const handleSelectAllAdvisors = (e) => {
    if (e.target.checked) {
      setSelectedAdvisors(advisors.map((advisor) => advisor.id));
    } else {
      setSelectedAdvisors([]);
    }
  };

  const handleAdvisorSelection = (advisorId) => {
    setSelectedAdvisors((prev) => {
      if (prev.includes(advisorId)) {
        return prev.filter((id) => id !== advisorId);
      }
      return [...prev, advisorId];
    });
  };

  return (
    <>
      <SnackBar
        snackbarState={error}
        setSnackbarState={setError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Something went wrong."}
      />
      <SnackBar
        snackbarState={success}
        setSnackbarState={setSuccess}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"success"}
        message={"User successfully added."}
      />
      <div className="container-xxl" style={{ maxWidth: "628px" }}>
        <div className="container-xxl pt-5 mb-5" style={{ maxWidth: "628px" }}>
          <div className="row g-0 w-100 mt-5"></div>
          <div className="row g-0 w-100 mt-5" style={{ marginBottom: "28px" }}>
            <div className="d-flex align-items-center">
              <img
                src="https://d1swyfveypj49y.cloudfront.net/NewBackArrow.svg"
                style={{
                  width: "16px",
                  height: "14px",
                  cursor: "pointer",
                  marginRight: "12px",
                }}
                onClick={() => navigate("/manage-users")}
              />
              <span class="title-text">Add Firm User</span>
            </div>
          </div>{" "}
        </div>
        <div class="row g-0 w-100 add-account-wizard-container">
          <div class="col-12 add-account-outer-column-padded">
            <React.Fragment>
              <React.Fragment>
                <div className="row g-0 w-100 mb-4">
                  <span className="add-account-step-subheader">
                    User Information
                  </span>
                </div>
                <div className="row g-0 w-100 mb-4">
                  <div className="col pe-2">
                    <TextInputField
                      value={formVals.firstName}
                      setValue={(newVal) => setForm("firstName", newVal)}
                      valueError={formVals.firstNameError}
                      setValueError={(newVal) =>
                        setForm("firstNameError", newVal)
                      }
                      label={"First Name"}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"individual-account-address-line-1-input"}
                    />
                  </div>
                  <div className="col pe-0 ps-2">
                    <TextInputField
                      value={formVals.lastName}
                      setValue={(newVal) => setForm("lastName", newVal)}
                      valueError={formVals.lastNameError}
                      setValueError={(newVal) =>
                        setForm("lastNameError", newVal)
                      }
                      label={"Last Name"}
                      required={false}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"individual-account-address-line-2-input"}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100 mb-4">
                  <div className="col pe-2">
                    <SelectInputField
                      setValue={(newVal) => setForm("role", newVal)}
                      value={formVals.role}
                      valueError={formVals.roleError}
                      setValueError={(newVal) => setForm("roleError", newVal)}
                      label={"Role"}
                      selectOptions={[
                        { value: "Ops", label: "Ops" },
                        { value: "Advisor", label: "Advisor" },
                        { value: "Co-Advisor", label: "Co-Advisor" },
                        {
                          value: "IOS",
                          label: "Investment Operations Specialist",
                        },
                      ]}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"domicile-country-input"}
                    />
                  </div>
                  <div className="col pe-0 ps-2">
                    <PhoneInputField
                      shrink={true}
                      label={"Phone Number"}
                      size={"small"}
                      fullWidth={true}
                      required={true}
                      value={formVals.phoneNumber}
                      setValue={(newVal) => {
                        setForm("phoneNumber", newVal);
                        setForm("phoneNumberError", null);
                      }}
                      valueError={formVals.phoneNumberError}
                      setValueError={(newVal) =>
                        setForm("phoneNumberError", newVal)
                      }
                      disabled={false}
                    />
                    {formVals.phoneNumberError && (
                      <div className="SignupForm-field-error-text mt-1">
                        <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          {formVals.phoneNumberError}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row g-0 w-100 mb-4">
                  <div className="col pe-2">
                    <SelectInputField
                      setValue={(newVal) => setForm("country", newVal)}
                      value={formVals.country}
                      valueError={formVals.countryError}
                      setValueError={(newVal) =>
                        setForm("countryError", newVal)
                      }
                      label={"Country"}
                      selectOptions={countryList}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"domicile-country-input"}
                    />
                  </div>
                  <div className="col pe-0 ps-2">
                    <TextInputField
                      value={formVals.email}
                      setValue={(newVal) => setForm("email", newVal)}
                      valueError={formVals.emailError}
                      setValueError={(newVal) => setForm("emailError", newVal)}
                      label={"Email"}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"individual-account-address-line-1-input"}
                    />
                  </div>
                </div>

                {formVals.role === "Co-Advisor" && (
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-12">
                      <div className="mb-3">
                        <span className="add-account-step-subheader">
                          Associate with Advisors
                        </span>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" className="px-6 py-3 text-left">
                                <input
                                  type="checkbox"
                                  onChange={handleSelectAllAdvisors}
                                  className="form-check-input"
                                />
                              </th>
                              <th scope="col" className="px-6 py-3 text-left">
                                Advisor Name
                              </th>
                              <th scope="col" className="px-6 py-3 text-left">
                                Email
                              </th>
                              <th scope="col" className="px-6 py-3 text-left">
                                Current Associations
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {advisors.map((advisor) => (
                              <tr key={advisor.id}>
                                <td className="px-6 py-4">
                                  <input
                                    type="checkbox"
                                    checked={selectedAdvisors.includes(
                                      advisor.id
                                    )}
                                    onChange={() =>
                                      handleAdvisorSelection(advisor.id)
                                    }
                                    className="form-check-input"
                                  />
                                </td>
                                <td className="px-6 py-4">
                                  {advisor.firstName} {advisor.lastName}
                                </td>
                                <td className="px-6 py-4">{advisor.email}</td>
                                <td className="px-6 py-4">
                                  {advisor.currentAssociations || 0}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            </React.Fragment>

            <MuiButton buttonText={"Submit"} onClick={submit} />
          </div>
        </div>
      </div>

      {/* Warning Modal */}
      <WarningModal
        isOpen={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        onProceed={submit}
        title="No Advisor Associations"
        message="Adding a co-advisor without associating an advisor will mean the co-advisor won't be able to perform any client related actions on the portal until an advisor is associated to their profile. Note, this can be done later by managing their associations from the manage users tab of the manage firm page. Would you like to proceed?"
      />
    </>
  );
};

export default AddFirmUser;
