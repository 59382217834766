import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import HorizontalTableContainer from "../../../../Components/HorizontalTable/HorizontalTableContainer/HorizontalTableContainer";
import HorizontalTableRow from "../../../../Components/HorizontalTable/HorizontalTableRow/HorizontalTableRow";
import { GetWMFirmInfo } from "../../../../Adapters/FirmInformation";
import LoadingAnimation from "../../../../Components/LoadingAnimation/LoadingAnimation";

function FirmInformation() {
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);

  const { wmFirmInfo } = GetWMFirmInfo(setLoading, refresh);

  return (
    <div className="container-xxl p-4">
      <div className="row g-0 w-100 mb-2">
        <h5>Firm Information</h5>
      </div>
      {loading === false ? (
        <HorizontalTableContainer>
          <HorizontalTableRow
            rowName={"Firm Name"}
            rowValue={wmFirmInfo.name}
          />
          <HorizontalTableRow
            rowName={"Firm Type"}
            rowValue={wmFirmInfo.type}
          />
          <HorizontalTableRow
            rowName={"Subdomain"}
            rowValue={wmFirmInfo.subdomain}
          />
          <HorizontalTableRow
            rowName={"Logo Status"}
            rowValue={wmFirmInfo.logo}
          />
          <HorizontalTableRow
            rowName={"Master User"}
            rowValue={wmFirmInfo.master_user_email}
          />
        </HorizontalTableContainer>
      ) : (
        <LoadingAnimation />
      )}
    </div>
  );
}
export default FirmInformation;
