import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { UserContext } from '../Contexts/UserContext';

interface User {
    id: string;
  }

const ResetPwd: React.FC = () => {
    const { user } = useContext(UserContext) as { user: User };
    console.log(user);
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [step, setStep] = useState<'requestCode' | 'resetPassword'>('requestCode');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  // Request the reset code
  const requestResetCode = async () => {
    try {
      await Auth.forgotPassword(user.id);
      setMessage('Code sent to your email');
      setStep('resetPassword');
      setError(null);
    } catch (err) {
        console.log(err)
      setError( 'Error requesting reset code');
    }
  };

  // Submit the new password along with the code
  const resetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(user.id, code, newPassword);
      setMessage('Password successfully reset. You can now log in.');
      setStep('requestCode'); // Reset to initial step after success
      setError(null);
    } catch (err: any) {
      setError(err.message ||  'Error resetting password');
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}

      {step === 'requestCode' && (
              <button
              className="tw-px-4 tw-py-2 tw-bg-gray-500 tw-text-white tw-font-medium tw-rounded-md"
              onClick={requestResetCode}
            >
              Request Reset Code
            </button>
      )}

      {step === 'resetPassword' && (
        <div>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter the code"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"

          /><br /><br />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter your new password"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"

          /><br /><br />
              <button
              className="tw-px-4 tw-py-2 tw-bg-gray-500 tw-text-white tw-font-medium tw-rounded-md"
              onClick={resetPassword}
            >
              Request Password
            </button>
        </div>
      )}
    </div>
  );
};

export default ResetPwd;
