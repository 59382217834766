import React from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../InputFields/TextInputField/TextInputField";
import SelectInputField from "../../InputFields/SelectInputField/SelectInputField";
import { formatSSN } from "../../../utilities/functions/functions";
import { stateList } from "../../stateList";
import PhoneInputField from "../../InputFields/PhoneInputField/PhoneInputField";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import StyledDateInputField from "../../StyledDateInput/StyledDateInput";
import UploadButton from "../../Buttons/UploadButton/UploadButton";
import IrrevocableTrustRelatedPartyInputInformation from "./IrrevocableTrustRelatedPartyInputInformation/IrrevocableTrustRelatedPartyInputInformation";
import GooglePlacesAutocomplete from "./../../GooglePlacesAutocomplete";

function EntityInputInformation({
  accountType,
  inputField,
  handleChangeInput,
  index,
  disabled = false,
  relatedPartyType,
}) {
  const entityOptions = [
    { value: "LT", label: "Revocable Trust" },
    { value: "TRUST", label: "Irrevocable Trust" },
    { value: "SM_LLC", label: "Single-member LLC" },
    { value: "LLC", label: "LLC" },
    { value: "GP", label: "General Partnership" },
    { value: "LP", label: "Limited Partnership" },
    { value: "C-CORP", label: "C-Corp" },
    { value: "S-CORP", label: "S-Corp" },
  ];

  const trusteeOptions = [
    // { value: "LT", label: "Revocable Trust" },
    // { value: "TRUST", label: "Irrevocable Trust" },
    { value: "SM_LLC", label: "Single-member LLC" },
    { value: "LLC", label: "LLC" },
    { value: "GP", label: "General Partnership" },
    { value: "LP", label: "Limited Partnership" },
    { value: "C-CORP", label: "C-Corp" },
    { value: "S-CORP", label: "S-Corp" },
  ];

  const yesNoOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const Trust_Company_Copy = `
  
  <strong>
   Trust Company Definiton
  </strong>
  <br />
  A trust company is a legal entity that acts as a fiduciary, agent, or trustee on behalf of a person or business for the purpose of administration, management, and the eventual transfer of assets to a beneficial party. The trust company acts as a custodian for trusts, estates, custodial arrangements, asset management, stock transfer, beneficial ownership registration, and other related arrangements.
`;

  const renderTooltip = (copy, props) => (
    <Tooltip id="button-tooltip" style={{ width: "600px" }} {...props}>
      <div dangerouslySetInnerHTML={{ __html: copy }} />
    </Tooltip>
  );

  const handleFileUpload = (event, inputName) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const b64 = reader.result.split(",")[1];
      handleChangeInput(
        index,
        { category: inputName, name: inputName, b64_string: b64 },
        inputName
      );
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <div className="align-self-end mt-1">
          {relatedPartyType === "TRT" ? (
            <h6>Trustee Entity Legal Information</h6>
          ) : (
            <h6>Beneficial Owner Entity Legal Information</h6>
          )}
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <div className="align-self-end mt-1">
            <SelectInputField
              value={inputField.entityType}
              setValue={(value) => {
                handleChangeInput(index, value, "entityType");
                inputField.entityTypeError = "";
              }}
              valueError={inputField.entityTypeError}
              setValueError={(value) =>
                handleChangeInput(index, value, "entityTypeError")
              }
              selectOptions={
                relatedPartyType === "BO" ? entityOptions : trusteeOptions
              }
              required={true}
              fullWidth={true}
              size={"small"}
              id={"entity-type-select"}
              label={"Entity Type"}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col ps-2 pe-0">
          <div className="align-self-end mt-1">
            <TextInputField
              value={inputField.entityName}
              setValue={(event) => {
                handleChangeInput(index, event, "entityName");
                inputField.entityNameError = "";
              }}
              setValueError={(e) => {
                inputField.entityNameError = e;
              }}
              valueError={inputField.entityNameError}
              label={"Entity Name"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={disabled}
              shrink={true}
              id={"entity-name-input"}
            />
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <div className="align-self-end mt-1">
            <TextInputField
              value={inputField.entityPercentOwned}
              setValue={(event) => {
                handleChangeInput(index, event, "entityPercentOwned");
                inputField.entityPercentOwnedError = "";
              }}
              setValueError={(e) => {
                inputField.entityPercentOwnedError = e;
              }}
              mask={{
                maskPattern: "999",
                maskFormatter: (e) => (parseInt(e) <= 100 ? e : e.slice(0, 2)),
              }}
              valueError={inputField.entityPercentOwnedError}
              label={"Percent Ownership"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={disabled}
              shrink={true}
              id={"entity-percent-owned-input"}
            />
          </div>
        </div>
        <div className="col ps-2 pe-0">
          { ["United States", ""].includes(inputField.entityCountry) && <div className="align-self-end mt-1">
            <SelectInputField
              value={inputField.entityStateOfIncorporation}
              setValue={(event) => {
                handleChangeInput(index, event, "entityStateOfIncorporation");
                inputField.entityStateOfIncorporationError = "";
              }}
              valueError={inputField.entityStateOfIncorporationError}
              setValueError={(e) => {
                inputField.entityStateOfIncorporationError = e;
              }}
              selectOptions={stateList}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={disabled}
              shrink={true}
              id={"entity-state-of-incorporation-select"}
              label={"State of Incorporation"}
            />
          </div> }
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        {inputField.entityType !== "LT" &&
          inputField.entityType !== "SM_LLC" && (
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityTaxIDNumber}
                  setValue={(event) => {
                    handleChangeInput(index, event, "entityTaxIDNumber");
                    inputField.entityTaxIDNumberError = "";
                  }}
                  valueError={inputField.entityTaxIDNumberError}
                  setValueError={(event) =>
                    handleChangeInput(index, event, "entityTaxIDNumberError")
                  }
                  label={`Entity ${inputField.entityCountry === 'United States' ? `EIN` : 'Tax ID'}`}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-tax-id-number-input"}
                />
              </div>
            </div>
          )}
        <div
          className={
            inputField.entityType !== "LT" && inputField.entityType !== "SM_LLC"
              ? "col ps-2 pe-0"
              : "col pe-0"
          }
        >
          <div className="align-self-end mt-1">
            <PhoneInputField
              shrink={true}
              label={"Entity Phone Number"}
              size={"small"}
              fullWidth={true}
              required={true}
              value={inputField.entityPhoneNumber}
              setValue={(event) => {
                handleChangeInput(index, event, "entityPhoneNumber");
                inputField.entityPhoneNumberError = "";
              }}
              valueError={inputField.entityPhoneNumberError}
              setValueError={(event) =>
                handleChangeInput(index, event, "entityPhoneNumberError")
              }
              disabled={disabled}
              id={"entity-phone-number-input"}
            />
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-0">
          <div className="align-self-end mt-1">
            {relatedPartyType === "TRT" ? (
              <h6>Trustee Entity Address Information</h6>
            ) : (
              <h6>Beneficial Owner Entity Address Information</h6>
            )}
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <GooglePlacesAutocomplete
          disabled={disabled}
          object={"entity"}
          values={{
            addressLine1: inputField.entityAddressLine1,
            addressLine2: inputField.entityAddressLine2,
            city: inputField.entityCity,
            state: inputField.entityState,
            postalCode: inputField.entityPostalCode,
            country: inputField.entityCountry,
          }}
          addressCallbacks={{
            setAddressLine1: (event) => {
              handleChangeInput(index, event, "entityAddressLine1");
              inputField.entityAddressLine1Error = "";
            },
            setAddressLine2: (event) => {
              handleChangeInput(index, event, "entityAddressLine2");
              inputField.entityAddressLine2Error = "";
            },
            setCity: (event) => {
              handleChangeInput(index, event, "entityCity");
              inputField.entityCityError = "";
            },
            setState: (event) => {
              handleChangeInput(index, event, "entityState");
              inputField.entityStateError = "";
            },
            setPostalCode: (event) => {
              handleChangeInput(index, event, "entityPostalCode");
              inputField.entityPostalCodeError = "";
            },
            setCountry: (event) => {
              handleChangeInput(index, event, "entityCountry");
              inputField.entityCountryError = "";
            },
          }}
          errorList={[inputField.entityAddressLine1Error, inputField.entityCityError, inputField.entityStateError, inputField.entityPostalCodeError, inputField.entityCountryError]}
        /></div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-0">
          <div className="align-self-end mt-1">
            <SelectInputField
              value={inputField.entityMailingAddressDifferent}
              setValue={(event) => {
                handleChangeInput(
                  index,
                  event,
                  "entityMailingAddressDifferent"
                );
                inputField.entityMailingAddressDifferentError = "";
              }}
              valueError={inputField.entityMailingAddressDifferentError}
              setValueError={(e) => {
                inputField.entityMailingAddressDifferentError = e;
              }}
              selectOptions={yesNoOptions}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={disabled}
              shrink={true}
              id={"entity-mailing-address-different-select"}
              label={"Is the mailing address different?"}
            />
          </div>
        </div>
      </div>
      {inputField.entityMailingAddressDifferent === "Yes" && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <div className="align-self-end mt-1">
                {relatedPartyType === "TRT" ? (
                  <h6>Trustee Entity Mailing Address Information</h6>
                ) : (
                  <h6>Beneficial Owner Entity Mailing Address Information</h6>
                )}
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <GooglePlacesAutocomplete
              disabled={disabled}
              object={"entity mailing"}
              values={{
                addressLine1: inputField.entityMailingAddressLine1,
                addressLine2: inputField.entityMailingAddressLine2,
                city: inputField.entityMailingCity,
                state: inputField.entityMailingState,
                postalCode: inputField.entityMailingPostalCode,
                country: inputField.entityMailingCountry,
              }}
              addressCallbacks={{
                setAddressLine1: (event) => {
                  handleChangeInput(index, event, "entityMailingAddressLine1");
                  inputField.entityMailingAddressLine1Error = "";
                },
                setAddressLine2: (event) => {
                  handleChangeInput(index, event, "entityMailingAddressLine2");
                  inputField.entityMalingAddressLine2Error = "";
                },
                setCity: (event) => {
                  handleChangeInput(index, event, "entityMailingCity");
                  inputField.entityMailingCityError = "";
                },
                setState: (event) => {
                  handleChangeInput(index, event, "entityMailingState");
                  inputField.entityMailingStateError = "";
                },
                setPostalCode: (event) => {
                  handleChangeInput(index, event, "entityMailingPostalCode");
                  inputField.entityMailingPostalCodeError = "";
                },
                setCountry: (event) => {
                  handleChangeInput(index, event, "entityMailingCountry");
                  inputField.entityMailingCountryError = "";
                },
              }}
              errorList={[inputField.entityMailingAddressLine1Error, inputField.entityMailingCityError, inputField.entityMailingStateError, inputField.entityMailingPostalCodeError, inputField.entityMailingCountryError]}
            /></div>
        </React.Fragment>
      )}
      {(accountType === "TRUST" || accountType === "LT") &&
        relatedPartyType === "TRT" &&
        !disabled && (
          <React.Fragment>
            <div className="row g-0 w-100 mb-3">
              <div className="col pe-0">
                <div className="align-self-end mt-1">
                  <div className="d-flex">
                    <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                      <span className="body-text-sm-semibold">
                        Is the entity considered a trust management company?
                      </span>
                    </div>
                    <div className="align-self-center d-flex align-items-center pt-auto pb-auto">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 400 }}
                        overlay={(props) => {
                          return renderTooltip(Trust_Company_Copy, props);
                        }}
                      >
                        <img
                          src="https://d1swyfveypj49y.cloudfront.net/i.svg"
                          className="h-100"
                          style={{ paddingLeft: "5px" }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3 fade-in-test">
              <div className="col-6">
                <label
                  for={
                    "entityIsTrustManagementCompanyId" +
                    index +
                    relatedPartyType
                  }
                  className={"radio"}
                >
                  <input
                    type={"radio"}
                    name={"entityIsTrustManagementCompanyRadio"}
                    id={
                      "entityIsTrustManagementCompanyId" +
                      index +
                      relatedPartyType
                    }
                    className={"radio__input"}
                    checked={
                      inputField.entityIsTrustManagementCompany === "Yes"
                    }
                    onClick={() => {
                      handleChangeInput(
                        index,
                        "Yes",
                        "entityIsTrustManagementCompany"
                      );
                      inputField.entityIsTrustManagementCompanyError = "";
                    }}
                  />
                  <div className="radio__radio"></div>
                  <span className="qualification-question-text ms-2">Yes</span>
                </label>
              </div>
              <div className="col-6">
                <label
                  for={
                    "entityIsNotTrustManagementCompanyId" +
                    index +
                    relatedPartyType
                  }
                  className={"radio"}
                >
                  <input
                    type={"radio"}
                    name={"entityIsNotTrustManagementCompanyRadio"}
                    id={
                      "entityIsNotTrustManagementCompanyId" +
                      index +
                      relatedPartyType
                    }
                    className={"radio__input"}
                    checked={inputField.entityIsTrustManagementCompany === "No"}
                    onClick={() => {
                      handleChangeInput(
                        index,
                        "No",
                        "entityIsTrustManagementCompany"
                      );
                      inputField.entityIsTrustManagementCompanyError = "";
                    }}
                  />
                  <div className="radio__radio"></div>
                  <span className="qualification-question-text ms-2">No</span>
                </label>
              </div>
            </div>
            {inputField.entityIsTrustManagementCompanyError !== "" && (
              <div className="row g-0 w-100 mb-3">
                <div className="SignupForm-field-error-text mt-1">
                  <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                    {inputField.entityIsTrustManagementCompanyError}
                  </span>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      {inputField.entityType !== "TRUST" ? (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <div className="align-self-end mt-1">
                {relatedPartyType === "TRT" ? (
                  <h6>Trustee Entity Control Person Information</h6>
                ) : (
                  <h6>Beneficial Owner Entity Control Person Information</h6>
                )}
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityControlPersonFirstName}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityControlPersonFirstName"
                    );
                    inputField.entityControlPersonFirstNameError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonFirstNameError = e;
                  }}
                  valueError={inputField.entityControlPersonFirstNameError}
                  label={"First Name"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-first-name-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityControlPersonLastName}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityControlPersonLastName"
                    );
                    inputField.entityControlPersonLastNameError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonLastNameError = e;
                  }}
                  valueError={inputField.entityControlPersonLastNameError}
                  label={"Last Name"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-last-name-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityControlPersonEmail}
                  setValue={(event) => {
                    handleChangeInput(index, event, "entityControlPersonEmail");
                    inputField.entityControlPersonEmailError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonEmailError = e;
                  }}
                  valueError={inputField.entityControlPersonEmailError}
                  label={"Email"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-email-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <PhoneInputField
                  value={inputField.entityControlPersonPhoneNumber}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityControlPersonPhoneNumber"
                    );
                    inputField.entityControlPersonPhoneNumberError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonPhoneNumberError = e;
                  }}
                  valueError={inputField.entityControlPersonPhoneNumberError}
                  label={"Phone Number"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-phone-number-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={inputField.entityControlPersonIsUSBased}
                  setValue={(value) => {
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSBased"
                    );
                    inputField.entityControlPersonIsUSBasedError = "";
                  }}
                  valueError={inputField.entityControlPersonIsUSBasedError}
                  setValueError={(value) =>
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSBasedError"
                    )
                  }
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-control-person-is-us-based-select"}
                  label={"Is US Based?"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={inputField.entityControlPersonIsUSCitizen}
                  setValue={(value) => {
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSCitizen"
                    );
                    inputField.entityControlPersonIsUSCitizenError = "";
                  }}
                  valueError={inputField.entityControlPersonIsUSCitizenError}
                  setValueError={(value) =>
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSCitizenError"
                    )
                  }
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-control-person-is-us-citizen-select"}
                  label={"Is US Citizen?"}
                />
              </div>
            </div>
          </div>
          {!disabled && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <span className="body-text-sm-semibold">
                    Do you have access to the personally identifiable
                    information for a control person of this entity? (i.e. SSN,
                    DOB, etc.)
                  </span>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col-6">
                  <label
                    for={
                      "entityControlPersonPIIAvailableId" +
                      index +
                      relatedPartyType
                    }
                    className={"radio"}
                  >
                    <input
                      type={"radio"}
                      name={"entityControlPersonPIIAvailableRadio"}
                      id={
                        "entityControlPersonPIIAvailableId" +
                        index +
                        relatedPartyType
                      }
                      className={"radio__input"}
                      checked={
                        inputField.entityControlPersonPIIAvailable === "Yes"
                      }
                      onClick={() => {
                        if (!disabled) {
                          handleChangeInput(
                            index,
                            "Yes",
                            "entityControlPersonPIIAvailable"
                          );
                          inputField.entityControlPersonPIIAvailableError = "";
                        }
                      }}
                    />
                    <div className="radio__radio"></div>
                    <span className="qualification-question-text ms-2">
                      Yes
                    </span>
                  </label>
                </div>
                <div className="col-6">
                  <label
                    for={
                      "entityControlPersonPIINotAvailableId" +
                      index +
                      relatedPartyType
                    }
                    className={"radio"}
                  >
                    <input
                      type={"radio"}
                      name={"entityControlPersonPIINotAvailableRadio"}
                      id={
                        "entityControlPersonPIINotAvailableId" +
                        index +
                        relatedPartyType
                      }
                      className={"radio__input"}
                      checked={
                        inputField.entityControlPersonPIIAvailable === "No"
                      }
                      onClick={() => {
                        if (!disabled) {
                          handleChangeInput(
                            index,
                            "No",
                            "entityControlPersonPIIAvailable"
                          );
                          inputField.entityControlPersonPIIAvailableError = "";
                        }
                      }}
                    />
                    <div className="radio__radio"></div>
                    <span className="qualification-question-text ms-2">No</span>
                  </label>
                </div>
              </div>
            </React.Fragment>
          )}
          {inputField.entityControlPersonPIIAvailableError !== "" && (
            <div className="row g-0 w-100 mb-3">
              <div className="SignupForm-field-error-text mt-1">
                <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                  {inputField.entityControlPersonPIIAvailableError}
                </span>
              </div>
            </div>
          )}
          {inputField.entityControlPersonPIIAvailable === "Yes" && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <GooglePlacesAutocomplete
                  disabled={disabled}
                  object={"entity control person"}
                  values={{
                    addressLine1: inputField.entityControlPersonAddressLine1,
                    addressLine2: inputField.entityControlPersonAddressLine2,
                    city: inputField.entityControlPersonCity,
                    state: inputField.entityControlPersonState,
                    postalCode: inputField.entityControlPersonPostalCode,
                    country: inputField.entityControlPersonCountry,
                  }}
                  addressCallbacks={{
                    setAddressLine1: (event) => {
                      handleChangeInput(index, event, "entityControlPersonAddressLine1");
                      inputField.entityControlPersonAddressLine1Error = "";
                    },
                    setAddressLine2: (event) => {
                      handleChangeInput(index, event, "entityControlPersonAddressLine2");
                      inputField.entityControlPersonAddressLine2Error = "";
                    },
                    setCity: (event) => {
                      handleChangeInput(index, event, "entityControlPersonCity");
                      inputField.entityControlPersonCityError = "";
                    },
                    setState: (event) => {
                      handleChangeInput(index, event, "entityControlPersonState");
                      inputField.entityControlPersonStateError = "";
                    },
                    setPostalCode: (event) => {
                      handleChangeInput(index, event, "entityControlPersonPostalCode");
                      inputField.entityControlPersonPostalCodeError = "";
                    },
                    setCountry: (event) => {
                      handleChangeInput(index, event, "entityControlPersonCountry");
                      inputField.entityControlPersonCountryError = "";
                    },
                  }}
                  errorList={[inputField.entityControlPersonAddressLine1Error, inputField.entityControlPersonCityError, inputField.entityControlPersonStateError, inputField.entityControlPersonPostalCodeError, inputField.entityControlPersonCountryError]}
                /></div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-2">
                  <div className="align-self-end mt-1">
                    <StyledDateInputField
                      value={inputField.entityControlPersonDOB}
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityControlPersonDOB"
                        );
                        inputField.entityControlPersonDOBError = "";
                      }}
                      valueError={inputField.entityControlPersonDOBError}
                      setValueError={(e) => {
                        inputField.entityControlPersonDOBError = e;
                      }}
                      label={"Date Of Birth"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      shrink={true}
                      id={"entity-control-person-dob-input"}
                    />
                  </div>
                </div>
                <div className="col ps-2 pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityControlPersonCountry === "United States" ? <TextInputField
                      value={inputField.entityControlPersonTaxIDNumber}
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityControlPersonTaxIDNumber"
                        );
                        inputField.entityControlPersonTaxIDNumberError = "";
                      }}
                      setValueError={(e) => {
                        inputField.entityControlPersonTaxIDNumberError = e;
                      }}
                      mask={{
                        maskFormatter: formatSSN,
                        maskPattern: "999-99-9999",
                      }}
                      valueError={
                        inputField.entityControlPersonTaxIDNumberError
                      }
                      label={"SSN"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      shrink={true}
                      id={"entity-control-person-tax-id-number-input"}
                    /> :
                      <TextInputField
                        value={inputField.entityControlPersonTaxIDNumbe}
                        setValue={(event) => {
                          handleChangeInput(
                            index,
                            event,
                            "entityControlPersonTaxIDNumber"
                          );
                          inputField.entityControlPersonTaxIDNumberError = "";
                        }}
                        valueError={
                          inputField.entityControlPersonTaxIDNumberError
                        } setValueError={(e) => {
                          inputField.entityControlPersonTaxIDNumberError = e;
                        }}
                        label={"Passport Number"}
                        required={true}
                        fullWidth={true}
                        size={"small"}
                        mask={null}
                        id={"grantor-ssn-input"}
                      />
                    }
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {!disabled && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {relatedPartyType === "TRT" ? (
                      <h6>Additional Required Trustee Entity Documentation</h6>
                    ) : (
                      <h6>
                        Additional Required Beneficial Owner Entity
                        Documentation
                      </h6>
                    )}
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <span className="body-text-sm-semibold">
                      {relatedPartyType === "TRT"
                        ? "Please upload the following documents for this trustee entity (Certificate of Formation, Proof of Address):"
                        : "Please upload the following documents for this beneficial owner entity (Certificate of Formation, Proof of Address):"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-1">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityCertificateOfFormation === "" ? (
                      <UploadButton
                        text={"Upload Certificate of Formation"}
                        onChange={handleFileUpload}
                        inputField={"entityCertificateOfFormation"}
                        label={index + relatedPartyType}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Certificate of Formation Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Certificate of Formation"}
                            onChange={handleFileUpload}
                            inputField={"entityCertificateOfFormation"}
                            label={index + relatedPartyType}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityCertificateOfFormationError !== "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {inputField.entityCertificateOfFormationError}
                    </span>
                  </div>
                </div>
              )}
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityProofOfAddress === "" ? (
                      <UploadButton
                        text={"Upload Proof of Address"}
                        onChange={handleFileUpload}
                        inputField={"entityProofOfAddress"}
                        label={index + relatedPartyType}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Proof of Address Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Proof of Address"}
                            onChange={handleFileUpload}
                            inputField={"entityProofOfAddress"}
                            label={index + relatedPartyType}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityProofOfAddressError !== "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {inputField.entityProofOfAddressError}
                    </span>
                  </div>
                </div>
              )}
              {inputField.entityControlPersonPIIAvailable === "Yes" && (
                <React.Fragment>
                  <div className="row g-0 w-100 mb-2">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        {relatedPartyType === "TRT" ? (
                          <h6>
                            Additional Required Trustee Entity Control Person
                            Documentation
                          </h6>
                        ) : (
                          <h6>
                            Additional Required Entity Control Person
                            Documentation
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        <span className="body-text-sm-semibold">
                          {relatedPartyType === "TRT"
                            ? "Please upload the following documents for the control person of this trustee entity (Passport, Proof of Address):"
                            : "Please upload the following documents for the control person of this beneficial owner entity (Passport, Proof of Address):"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        {inputField.entityControlPersonPassportDocument ===
                          "" ? (
                          <UploadButton
                            text={"Upload Passport"}
                            onChange={handleFileUpload}
                            inputField={"entityControlPersonPassportDocument"}
                            label={index + relatedPartyType}
                          />
                        ) : (
                          <div className="d-flex">
                            <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                              <span className="body-text-sm-semibold">
                                Passport Uploaded
                              </span>
                            </div>
                            <div className="align-self-center align-items-center pt-auto pb-auto">
                              <UploadButton
                                text={"Change Passport"}
                                onChange={handleFileUpload}
                                inputField={
                                  "entityControlPersonPassportDocument"
                                }
                                label={index + relatedPartyType}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {inputField.entityControlPersonPassportDocumentError !==
                    "" && (
                      <div className="row g-0 w-100 mb-3">
                        <div className="SignupForm-field-error-text mt-1">
                          <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                            {inputField.entityControlPersonPassportDocumentError}
                          </span>
                        </div>
                      </div>
                    )}
                  <div className="row g-0 w-100 mb-3">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        {inputField.entityControlPersonProofOfAddress === "" ? (
                          <UploadButton
                            text={"Upload Proof of Address"}
                            onChange={handleFileUpload}
                            inputField={"entityControlPersonProofOfAddress"}
                            label={index + relatedPartyType}
                          />
                        ) : (
                          <div className="d-flex">
                            <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                              <span className="body-text-sm-semibold">
                                Proof of Address Uploaded
                              </span>
                            </div>
                            <div className="align-self-center align-items-center pt-auto pb-auto">
                              <UploadButton
                                text={"Update Proof of Address"}
                                onChange={handleFileUpload}
                                inputField={"entityControlPersonProofOfAddress"}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {inputField.entityControlPersonProofOfAddressError !== "" && (
                    <div className="row g-0 w-100 mb-3">
                      <div className="SignupForm-field-error-text mt-1">
                        <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          {inputField.entityControlPersonProofOfAddressError}
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <IrrevocableTrustRelatedPartyInputInformation
          index={index}
          inputField={inputField}
          relatedPartyType={relatedPartyType}
          handleChangeInput={handleChangeInput}
          handleFileUpload={handleFileUpload}
          disabled={disabled}
          yesNoOptions={yesNoOptions}
        />
      )}
    </React.Fragment>
  );
}
export default EntityInputInformation;
