import React, { useState, useEffect } from "react";

import TextInputField from "../InputFields/TextInputField/TextInputField";
import SelectInputField from "../InputFields/SelectInputField/SelectInputField";
import MuiButton from "./../Buttons/MuiButton/MuiButton";
import { countryList } from "./../../utilities/lists/countryList.js";
import { stateList } from "./../stateList";

function LegalInformationTaxSection({
  isEnt,
  formValues,
  setFormValues,
  setActiveSlide,
}) {
  const [countryOfFormation, setCountryOfFormation] = useState("");
  const [idType, setIDType] = useState("");
  const [idTypeError, setIDTypeError] = useState("");

  const idTypeOptions = [
    { value: "EIN", label: "EIN" },
    { value: "FTID", label: "Foreign Tax ID" },
    { value: "ITIN", label: "ITIN" },
  ];

  const [idNumber, setIDNumber] = useState("");
  const [idNumberError, setIDNumberError] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const processedStateList = [];
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const processedCountryList = [];
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const submit = () => {
    var errors = false;

    if (idType === "" && countryOfFormation !== "United States") {
      errors = true;
      setIDTypeError("Please select an id type.");
    }

    if (idNumber === "") {
      errors = true;
      setIDNumberError("Please enter an id number");
    }

    if (addressLine1 === "") {
      errors = true;
      setAddressLine1Error("This field is required.");
    }

    if (city === "") {
      errors = true;
      setCityError("Please enter a city.");
    }

    if (country === "United States" && state === "") {
      errors = true;
      setStateError("Please select a state.");
    }

    if (country === "") {
      errors = true;
      setCountryError("Please select a country.");
    }

    if (postalCode === "") {
      errors = true;
      setPostalCodeError("Please enter a postal code.");
    }

    if (!errors) {

      if (isEnt === false) {
        setActiveSlide( (prevState) => (prevState+1));
      }
      else {
        setActiveSlide( (prevState) => (prevState+3));
      }
    }
  };

  return (
    <div className="row g-0 w-100 account-legal-information-container">
      <div className="col-12 account-legal-information-outer-column-padded">
        <React.Fragment>
          <div className="row g-0 w-100 mb-4">
            <span className="add-account-step-subheader">
              Tax Information
            </span>
          </div>
          <div className="row g-0 w-100 mb-4">
            {countryOfFormation !== "United States" && (
              <div className="col pe-2">
                <SelectInputField
                  value={idType}
                  setValue={setIDType}
                  valueError={idTypeError}
                  setValueError={setIDTypeError}
                  label={"Tax ID Type"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  id={"entity-account-id-type-select"}
                  selectOptions={idTypeOptions}
                />
              </div>
            )}
            <div
              className={
                countryOfFormation === "United States"
                  ? "col pe-0"
                  : "col pe-0 ps-2"
              }
            >
              {countryOfFormation === "United States" && (
                <TextInputField
                  value={idNumber}
                  setValue={setIDNumber}
                  valueError={idNumberError}
                  setValueError={setIDNumberError}
                  label={"EIN"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  id={"entity-account-ein-input"}
                />
              )}
              {countryOfFormation !== "United States" && (
                <TextInputField
                  value={idNumber}
                  setValue={setIDNumber}
                  valueError={idNumberError}
                  setValueError={setIDNumberError}
                  label={"Tax ID Number"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  id={"entity-account-tax-id-input"}
                />
              )}
            </div>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className="row g-0 w-100 mb-4">
            <span className="add-account-step-subheader">
              Mailing Address
            </span>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col pe-2">
              <TextInputField
                value={addressLine1}
                setValue={setAddressLine1}
                valueError={addressLine1Error}
                setValueError={setAddressLine1Error}
                label={"Address Line 1"}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"trust-account-address-line-1-input"}
              />
            </div>
            <div className="col pe-0 ps-2">
              <TextInputField
                value={addressLine2}
                setValue={setAddressLine2}
                valueError={addressLine2Error}
                setValueError={setAddressLine2Error}
                label={"Address Line 2"}
                required={false}
                fullWidth={true}
                size={"small"}
                id={"trust-account-address-line-2-input"}
              />
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div
              className={country === "United States" ? "col pe-2" : "col pe-0"}
            >
              <TextInputField
                value={city}
                setValue={setCity}
                valueError={cityError}
                setValueError={setCityError}
                label={"City"}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"trust-account-address-city-input"}
              />
            </div>
            {country === "United States" && (
              <div className="col pe-0 ps-2">
                <SelectInputField
                  value={state}
                  setValue={setState}
                  valueError={stateError}
                  setValueError={setStateError}
                  selectOptions={stateList}
                  label={"State"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  id={"trust-account-state-input"}
                />
              </div>
            )}
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col pe-2">
              <SelectInputField
                value={country}
                setValue={setCountry}
                valueError={countryError}
                setValueError={setCountryError}
                label={"Country"}
                selectOptions={countryList}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"trust-account-address-country-input"}
              />
            </div>
            <div className="col pe-0 ps-2">
              <TextInputField
                value={postalCode}
                setValue={setPostalCode}
                valueError={postalCodeError}
                setValueError={setPostalCodeError}
                label={"Postal Code"}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"trust-account-postal-code-input"}
              />
            </div>
          </div>
        </React.Fragment>
        <div className="d-flex">
          <MuiButton buttonText={"Next Step"} onClick={submit} size={"large"} />
        </div>
      </div>
    </div>
  );
}
export default LegalInformationTaxSection;
