import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import { HOST } from "../utilities/host-config/host-config.js";
import { v4 as uuidv4 } from "uuid";

export const getClientDocuments = async (clientId, docType) => {
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      client_id: clientId,
      document_type: docType,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  let documentList = [
    {
      id: "ebf53baf-1ebc-430e-b970-0be238a6159a",
      document_name: "1000000000 - MBC II",
      document_type: "Closing",
      upload_date: "2022-10-21T17:20:30Z",
      year: "2022",
      month: "10",
      document_link:
        "private/us-east-1:3b84d10e-a662-4382-be0f-fa94b7ccd636/1000000006/statement_documents/2022/1/stock_image.png",
    },
    {
      id: "5f4e8bd9-c3bf-4d9b-8e77-ea536fa9857d",
      document_name: "1000000001 - MBC III",
      document_type: "Closing",
      upload_date: "2022-10-21T17:20:30Z",
      year: "2022",
      month: "10",
      document_link:
        "private/us-east-1:3b84d10e-a662-4382-be0f-fa94b7ccd636/1000000006/statement_documents/2022/1/stock_image.png",
    },
    {
      id: "82ae7437-f1a2-457c-8188-c7ab9bc0287f",
      document_name: "1000000010 - HarbourVest",
      document_type: "Closing",
      upload_date: "2022-10-21T17:20:30Z",
      year: "2022",
      month: "10",
      document_link:
        "private/us-east-1:3b84d10e-a662-4382-be0f-fa94b7ccd636/1000000006/statement_documents/2022/1/stock_image.png",
    },
    {
      id: "3be22d94-6c47-402c-9af0-1d602910d4b6",
      document_name: "2021 Aqua",
      document_type: "Statement",
      upload_date: "2022-10-21T17:20:30Z",
      year: "2022",
      month: "10",
      document_link:
        "private/us-east-1:3b84d10e-a662-4382-be0f-fa94b7ccd636/1000000006/statement_documents/2022/1/stock_image.png",
    },
    {
      id: "fcfc7b2a-6f47-4130-95a2-852f007d6e7c",
      document_name: "2020 Aqua",
      document_type: "Statement",
      upload_date: "2022-10-21T17:20:30Z",
      year: "2022",
      month: "10",
      document_link:
        "private/us-east-1:3b84d10e-a662-4382-be0f-fa94b7ccd636/1000000006/statement_documents/2022/1/stock_image.png",
    },
    {
      id: "3e339eba-e945-41d7-bb5c-cb48b77776d8",
      document_name: "1000000001 - MBC II K1",
      document_type: "Tax",
      upload_date: "2022-10-21T17:20:30Z",
      year: "2022",
      month: "10",
      document_link:
        "private/us-east-1:3b84d10e-a662-4382-be0f-fa94b7ccd636/1000000006/statement_documents/2022/1/stock_image.png",
    },
    {
      id: "1fd54269-81ef-4058-ba08-71c47493d931",
      document_name: "1000000010 - MBC III K1",
      document_type: "Closing",
      upload_date: "2022-10-21T17:20:30Z",
      year: "2022",
      month: "10",
      document_link:
        "private/us-east-1:3b84d10e-a662-4382-be0f-fa94b7ccd636/1000000006/statement_documents/2022/1/stock_image.png",
    },
  ];
  return {
    data: documentList.filter((elem) => {
      if (docType === "ALL" || elem.document_type === docType) {
        return elem;
      }
    }),
  };
};

export const getTransactions = async (clientId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      client_id: clientId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return {
    data: [
      {
        id: uuidv4(),
        firm_name: "Whittman Capital",
        fund_name: "Vintage VI",
        amount: 100000,
        type: "DEPOSIT",
        status: "COMPLETED",
        method: "WIRE",
        date: "2022-10-21T17:20:30Z",
      },
      {
        id: uuidv4(),
        firm_name: "Whittman Capital",
        fund_name: "Vintage VI",
        amount: 50000,
        type: "DISBURSEMENT",
        status: "COMPLETED",
        method: "WIRE",
        date: "2022-10-21T17:20:30Z",
      },
      {
        id: uuidv4(),
        firm_name: "Whittman Capital",
        fund_name: "Vintage VI",
        amount: 50000,
        type: "DISBURSEMENT",
        status: "COMPLETED",
        method: "WIRE",
        date: "2022-10-21T17:20:30Z",
      },
      {
        id: uuidv4(),
        firm_name: "Whittman Capital",
        fund_name: "Vintage VI",
        amount: 50000,
        type: "DISBURSEMENT",
        status: "COMPLETED",
        method: "WIRE",
        date: "2022-10-21T17:20:30Z",
      },
      {
        id: uuidv4(),
        firm_name: "Whittman Capital",
        fund_name: "Vintage VI",
        amount: 50000,
        type: "DISBURSEMENT",
        status: "COMPLETED",
        method: "WIRE",
        date: "2022-10-21T17:20:30Z",
      },
    ],
  };
};

export const getInvestments = async (clientId, date) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      client_id: clientId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return {
    data: [
      {
        id: uuidv4(),
        investment_date: "2022-10-21T17:20:30Z",
        investment_amount: 100000,
        status: "F",
        approved_by: "SYSTEM",
        approval_date: "2022-10-21T17:20:30Z",
        account_name: "Dev Patel",
        fund: {
          id: uuidv4(),
          link: "vintage_iv",
          name: "Vintage IV",
          investment_status: "Closed",
          fund_closing_date: "2022-10-21T17:20:30Z",
          originator_firm: {
            id: uuidv4(),
            name: "Thamesville Capital",
            firm_aum: 10000000000,
          },
          strategy_type: {
            id: uuidv4(),
            type: "PRIVATE_EQUITY",
          },
        },
      },
    ],
  };
};

export const getDistributions = async (clientId, date) => {
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      client_id: clientId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return {
    data: [
      {
        id: uuidv4(),
        investor_profile: uuidv4(),
        distribution_date: "2022-10-23T17:20:30Z",
        distribution_amount: 400000,
        status: "F",
        approved_by: "SYSTEM",
        approved_date: "2022-10-23T17:20:30Z",
        account_name: "Dev Patel",
        fund: {
          id: uuidv4(),
          link: "vintage_iv",
          name: "Vintage IV",
          investment_status: "Closed",
          fund_closing_date: "2022-10-21T17:20:30Z",
          originator_firm: {
            id: uuidv4(),
            name: "Thamesville Capital",
            firm_aum: 10000000000,
          },
          strategy_type: {
            id: uuidv4(),
            type: "PRIVATE_EQUITY",
          },
        },
      },
    ],
  };
};

export const getClients = async (advisorId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      advisor_id: advisorId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return {
    data: [
      {
        id: "ebf53baf-1ebc-430e-b970-0be238a6159a",
        firstName: "John",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "5f4e8bd9-c3bf-4d9b-8e77-ea536fa9857d",
        firstName: "Michael",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "82ae7437-f1a2-457c-8188-c7ab9bc0287f",
        firstName: "Frank",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "3be22d94-6c47-402c-9af0-1d602910d4b6",
        firstName: "Jim",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "fcfc7b2a-6f47-4130-95a2-852f007d6e7c",
        firstName: "Carter",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "3e339eba-e945-41d7-bb5c-cb48b77776d8",
        firstName: "Martin",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "1fd54269-81ef-4058-ba08-71c47493d931",
        firstName: "Lawrence",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "b917ed20-5482-42ba-9e59-b1483291238b",
        firstName: "Dan",
        lastName: "Friedlinghaus",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "91f9d13d-d274-40ab-afd6-d62058f1f827",
        firstName: "Max",
        lastName: "Johnson",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "19917030-4b23-475c-a852-63c4fd754b07",
        firstName: "Tim",
        lastName: "White",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "ca132209-dc47-4015-9804-7947015a2811",
        firstName: "Patrick",
        lastName: "Ewing",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "84c01bdc-80ab-42e4-b611-00326fcf3b5e",
        firstName: "Dustin",
        lastName: "Black",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "1309fa67-2979-4c57-8f2f-d57f1fdb4de8",
        firstName: "George",
        lastName: "Russel",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "8c4416c0-d555-4beb-9305-afdb24882ccf",
        firstName: "Daniel",
        lastName: "Ricciardo",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "47eba459-6a4c-4290-b578-bf956cdac2ec",
        firstName: "Michael",
        lastName: "Charleston",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "4c40fe7b-a053-46ba-939b-ec718586e34e",
        firstName: "Samuel",
        lastName: "Jackson",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "13c5dd1f-4be5-47a4-87aa-5aed6cc4ea71",
        firstName: "Robert",
        lastName: "Ewing",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "1bd95676-12b0-4c8c-b67e-3ecc3b6f67a6",
        firstName: "Marshall",
        lastName: "White",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "1191a994-35c6-4780-8bd7-b2a34d533e2f",
        firstName: "Phil",
        lastName: "Mikaelson",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "dde8a0d5-c96f-415e-a605-626e75533445",
        firstName: "Rory",
        lastName: "Johnson",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "b9da49f5-d600-46bc-973f-f9f5b219fd46",
        firstName: "Ryan",
        lastName: "Reynolds",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "1e8a5352-fe2c-4cbb-a856-bcc3192e351b",
        firstName: "Ashton",
        lastName: "Kutcher",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "6d4f98b2-a8af-4a5c-be85-5a07de4b145b",
        firstName: "Nina",
        lastName: "Doberman",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "f1d6812f-e693-4adb-8ebc-b7c1351cb79a",
        firstName: "Lisa",
        lastName: "Kudrow",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
      {
        id: "fdc14666-44ad-4413-95c3-b2d4d02a3662",
        firstName: "Michelle",
        lastName: "Smith",
        status: "Profile created",
        email: "john@aqua.com",
        authLevel: "Make investments",
        selected: false,
      },
    ],
  };
};

export const getClientInfo = async (clientId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      client_id: clientId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  let list = [
    {
      id: "ebf53baf-1ebc-430e-b970-0be238a6159a",
      firstName: "John",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "5f4e8bd9-c3bf-4d9b-8e77-ea536fa9857d",
      firstName: "Michael",
      lastName: "Smith",
      status: "Profile created",
      email: "michael@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "82ae7437-f1a2-457c-8188-c7ab9bc0287f",
      firstName: "Frank",
      lastName: "Smith",
      status: "Profile created",
      email: "frank@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "3be22d94-6c47-402c-9af0-1d602910d4b6",
      firstName: "Jim",
      lastName: "Smith",
      status: "Profile created",
      email: "jim@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "fcfc7b2a-6f47-4130-95a2-852f007d6e7c",
      firstName: "Carter",
      lastName: "Smith",
      status: "Profile created",
      email: "carter@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "3e339eba-e945-41d7-bb5c-cb48b77776d8",
      firstName: "Martin",
      lastName: "Smith",
      status: "Profile created",
      email: "martin@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1fd54269-81ef-4058-ba08-71c47493d931",
      firstName: "Lawrence",
      lastName: "Smith",
      status: "Profile created",
      email: "lawrence@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "b917ed20-5482-42ba-9e59-b1483291238b",
      firstName: "Dan",
      lastName: "Friedlinghaus",
      status: "Profile created",
      email: "dan@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "91f9d13d-d274-40ab-afd6-d62058f1f827",
      firstName: "Max",
      lastName: "Johnson",
      status: "Profile created",
      email: "max@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "19917030-4b23-475c-a852-63c4fd754b07",
      firstName: "Tim",
      lastName: "White",
      status: "Profile created",
      email: "tim@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "ca132209-dc47-4015-9804-7947015a2811",
      firstName: "Patrick",
      lastName: "Ewing",
      status: "Profile created",
      email: "patrick@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "84c01bdc-80ab-42e4-b611-00326fcf3b5e",
      firstName: "Dustin",
      lastName: "Black",
      status: "Profile created",
      email: "dustin@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1309fa67-2979-4c57-8f2f-d57f1fdb4de8",
      firstName: "George",
      lastName: "Russel",
      status: "Profile created",
      email: "george@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "8c4416c0-d555-4beb-9305-afdb24882ccf",
      firstName: "Daniel",
      lastName: "Ricciardo",
      status: "Profile created",
      email: "daniel@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "47eba459-6a4c-4290-b578-bf956cdac2ec",
      firstName: "Michael",
      lastName: "Charleston",
      status: "Profile created",
      email: "michael@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "4c40fe7b-a053-46ba-939b-ec718586e34e",
      firstName: "Samuel",
      lastName: "Jackson",
      status: "Profile created",
      email: "samuel@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "13c5dd1f-4be5-47a4-87aa-5aed6cc4ea71",
      firstName: "Robert",
      lastName: "Ewing",
      status: "Profile created",
      email: "robert@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1bd95676-12b0-4c8c-b67e-3ecc3b6f67a6",
      firstName: "Marshall",
      lastName: "White",
      status: "Profile created",
      email: "marshall@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1191a994-35c6-4780-8bd7-b2a34d533e2f",
      firstName: "Phil",
      lastName: "Mikaelson",
      status: "Profile created",
      email: "phil@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "dde8a0d5-c96f-415e-a605-626e75533445",
      firstName: "Rory",
      lastName: "Johnson",
      status: "Profile created",
      email: "rory@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "b9da49f5-d600-46bc-973f-f9f5b219fd46",
      firstName: "Ryan",
      lastName: "Reynolds",
      status: "Profile created",
      email: "ryan@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1e8a5352-fe2c-4cbb-a856-bcc3192e351b",
      firstName: "Ashton",
      lastName: "Kutcher",
      status: "Profile created",
      email: "ashton@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "6d4f98b2-a8af-4a5c-be85-5a07de4b145b",
      firstName: "Nina",
      lastName: "Doberman",
      status: "Profile created",
      email: "nina@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "f1d6812f-e693-4adb-8ebc-b7c1351cb79a",
      firstName: "Lisa",
      lastName: "Kudrow",
      status: "Profile created",
      email: "lisa@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "fdc14666-44ad-4413-95c3-b2d4d02a3662",
      firstName: "Michelle",
      lastName: "Smith",
      status: "Profile created",
      email: "michalle@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
  ];

  let selectedClient = list.filter((elem) => {
    if (elem.id === clientId) {
      return elem;
    }
  });

  return {
    data: selectedClient,
  };
};

export const getAccountVerificationDocuments = async (payload) => {
  const config = await getRequestHeaders();

  return axios.get(
    HOST + "/get-account-verification-documents?client_id=" + payload,
    config
  );
};

export const uploadAccountVerificationDocuments = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(
    HOST + "/upload-account-verification-document",
    payload,
    config
  );
};
