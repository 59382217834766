import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import useDebounce from "../Hooks/useDebounce.js";
import { HOST } from "../utilities/host-config/host-config.js";
import { UserContext } from "../Contexts/UserContext.js";

const CancelToken = axios.CancelToken;

//Dev environment function
export default function useMasterOpsClientSearch(
  searchKeywords,
  setLoading,
  refreshClients
) {
  const [clients, setClients] = useState([]);
  const cancelSource = useRef(null);
  const debouncedSearchTerm = useDebounce(searchKeywords, 200);
  const { user } = useContext(UserContext);

  let accountTypeMapping = {
    IND: "Individual",
    TRUST: "Irrevocable Trust",
    LT: "Living Trust",
    SM_LLC: "Single Member LLC",
    LLC: "Limited Liability Company",
    C_Corp: "C Corporation",
    S_Corp: "S Corporation",
    LP: "Limited Partnership",
    GP: "General Partnership",
  };

  useEffect(() => {
    setClients([]);
  }, []);

  useEffect(() => {
    setClients([]);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    cancelSource.current = CancelToken.source();

    setClients([]);
    setLoading(true);

    var queryParam = "";
    queryParam = debouncedSearchTerm;

    async function getClients(endpoint, queryString, config) {
      setLoading(true);
      return axios
        .get(endpoint + "?query=" + queryString.replace("+", "%2B"), config)
        .then((response) => {
          const tempObject = response.data;

          if (
            response.data === null ||
            response.data === undefined ||
            response.data.length === 0
          ) {
            setClients([]);
            setLoading(false);
          } else {
            setClients([]);
            tempObject.map((client) => {
              async function mapClientInfo() {
                const clientInfo = {
                  id: client.id,
                  clientName: client.client_name,
                  clientEmail: client.client_email,
                  accountType: accountTypeMapping[client.account_type],
                  advisorEmail: client.advisor_email,
                  firstName: client.first_name,
                  lastName: client.last_name,
                  email: client.email,
                  status: client.account_verification_document_status,
                };

                setClients((prevClients) => [...prevClients, clientInfo]);
                setLoading(false);
              }
              mapClientInfo();
            });
          }
        })
        .catch((error) => {
          setClients([]);
          setLoading(false);
          if (process.env.REACT_APP_DEBUG === "1") console.log(error);
        });
    }

    getRequestHeaders().then((config) => {
      config.cancelToken = cancelSource.current.token;
      getClients(
        HOST + "/master-ops-search-firm-clients",
        queryParam,
        config
      ).then();
    });

    return () => {
      cancelSource.current.cancel();
    };
  }, [debouncedSearchTerm, refreshClients]);

  return { clients };
}
