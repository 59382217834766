import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import axios from "axios";
import { getRequestHeaders } from "../../../../../utilities/functions/functions.js";
import { HOST } from "../../../../../utilities/host-config/host-config.js";
import {
  fetchAvailableUsers,
  saveAssociations,
} from "../../../../../APIs/mockAssociationsAPI";

const LoadingSkeleton = () => (
  <div className="border rounded p-3 d-flex align-items-center animate-pulse">
    <div className="d-flex align-items-center w-100">
      <div className="me-3">
        <div className="skeleton-checkbox" />
      </div>
      <div
        className="rounded-circle skeleton-avatar me-3"
        style={{
          width: "32px",
          height: "32px",
          minWidth: "32px",
        }}
      />
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="skeleton-text" style={{ width: "150px" }} />
        <div className="skeleton-text" style={{ width: "200px" }} />
      </div>
    </div>
  </div>
);

const ManageAssociations = ({ user, onClose, onSave }) => {
  const [selectedAssociations, setSelectedAssociations] = useState([]);
  const [unassociatedUsers, setUnassociatedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newAssociationsAdded, setNewAssociationsAdded] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState(new Set());
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  // Fetch both current associations and unassociated users
  useEffect(() => {
    // Start loading immediately
    setIsLoading(true);

    // Show modal with animation
    requestAnimationFrame(() => {
      setIsVisible(true);
    });

    const fetchCurrentAssociations = async () => {
      try {
        const config = await getRequestHeaders();
        const response = await axios.get(
          `${HOST}/get-associations-for-firm-user?id=${user.id}`,
          config
        );

        if (response.data) {
          setSelectedAssociations(response.data.associations);
          setUnassociatedUsers(response.data.unassociated);
        }
      } catch (error) {
        setError(true);
        setErrorMessage(
          error.response?.data?.detail ||
            "Failed to load current associations. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentAssociations();
  }, [user.id]);

  const updateAssociations = async () => {
    try {
      const config = await getRequestHeaders();
      let requestBody = {};
      if (user.role === "Advisor") {
        const currentIds = selectedAssociations.map((assoc) => assoc.id);
        const newIds = Array.from(selectedUserIds);
        requestBody = {
          relations: {
            [user.id]: [...currentIds, ...newIds],
          },
        };
      } else if (user.role === "Co-Advisor") {
        const currentIds = selectedAssociations.map((assoc) => assoc.id);
        const newIds = Array.from(selectedUserIds);
        const fullAdvisorList = [...currentIds, ...newIds];
        let relations = {};

        fullAdvisorList.forEach((advisorID) => {
          relations[advisorID] = [user.id];
        });
        requestBody = {
          relations: relations,
        };
      }

      const response = await axios.post(
        `${HOST}/advisor_relations`,
        requestBody,
        config
      );
    } catch (error) {
      setError(true);
      setErrorMessage(
        error.response?.data?.detail ||
          "Failed to update associations. Please try again."
      );
    }
  };

  const handleToggleUser = (userId) => {
    setSelectedUserIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
      } else {
        newSet.add(userId);
      }
      setNewAssociationsAdded(newSet.size > 0);
      return newSet;
    });
  };

  const handleSelectAll = () => {
    if (selectedUserIds.size === unassociatedUsers.length) {
      setSelectedUserIds(new Set());
      setNewAssociationsAdded(false);
    } else {
      setSelectedUserIds(new Set(unassociatedUsers.map((user) => user.id)));
      setNewAssociationsAdded(true);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(false);
      setErrorMessage("");

      await updateAssociations();
      await onSave(Array.from(selectedUserIds));
    } catch (error) {
      console.error("Error saving associations:", error);
      setError(true);
      setErrorMessage(
        error.response?.data?.message ||
          "Failed to save associations. Please try again."
      );
      return;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="modal show d-block"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <SnackBar
        snackbarState={error}
        setSnackbarState={setError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={errorMessage}
      />
      <div
        className={`modal-dialog-ma modal-dialog-centered modal-dialog ${
          isVisible ? "show" : ""
        }`}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <div>
              <h5 className="modal-title mb-2">
                Manage Associations for {user.name}
              </h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            />
          </div>

          <div className="modal-body">
            {/* Current Associations Section */}
            <div
              className={`mb-4 associations-section ${
                isLoading ? "loading" : ""
              }`}
            >
              <h6 className="mb-3 fw-bold">
                {`Current ${
                  user.role === "Advisor" ? "Co-Advisor" : "Advisor"
                } Associations`}
              </h6>
              <div className="d-flex flex-column gap-2">
                {selectedAssociations.length > 0 ? (
                  selectedAssociations.map((associate) => (
                    <div
                      key={associate.id}
                      className="border rounded p-3 bg-light d-flex align-items-center"
                    >
                      <div className="d-flex align-items-center w-100">
                        <div
                          className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center me-3"
                          style={{
                            width: "32px",
                            height: "32px",
                            minWidth: "32px",
                          }}
                        >
                          {associate.name
                            .split(" ")
                            .map((n) => n[0])
                            .join("")}
                        </div>
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          <div className="fw-medium">{associate.name}</div>
                          <div className="text-muted">{associate.email}</div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-muted p-3 border rounded text-center bg-light">
                    {`No ${
                      user.role === "Advisor" ? "co-advisor" : "advisor"
                    } associations`}
                  </div>
                )}
              </div>
            </div>

            {/* Available Users Section */}
            <div
              className={`associations-section ${isLoading ? "loading" : ""}`}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h6 className="fw-bold mb-1">
                    {`Available ${
                      user.role === "Advisor" ? "Co-Advisors" : "Advisors"
                    }`}
                  </h6>
                  <div className="text-gray-600 text-sm">
                    {`Select ${
                      user.role === "Advisor" ? "co-advisors" : "advisors"
                    } to associate with this ${
                      user.role === "Advisor" ? "advisor" : "co-advisor"
                    }.`}
                  </div>
                </div>
                {unassociatedUsers.length > 0 && !isLoading && (
                  <button
                    className="btn btn-link text-decoration-none p-0"
                    onClick={handleSelectAll}
                  >
                    {selectedUserIds.size === unassociatedUsers.length
                      ? "Deselect All"
                      : "Select All"}
                  </button>
                )}
              </div>
              <div className="d-flex flex-column gap-2">
                {isLoading ? (
                  <>
                    <LoadingSkeleton />
                    <LoadingSkeleton />
                    <LoadingSkeleton />
                  </>
                ) : unassociatedUsers.length > 0 ? (
                  unassociatedUsers.map((otherUser) => (
                    <div
                      key={otherUser.id}
                      className="border rounded p-3 d-flex align-items-center"
                      onClick={() =>
                        !isLoading && handleToggleUser(otherUser.id)
                      }
                      style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                    >
                      <div className="d-flex align-items-center w-100">
                        <div
                          className="me-3"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedUserIds.has(otherUser.id)}
                            onChange={() =>
                              !isLoading && handleToggleUser(otherUser.id)
                            }
                            className="form-check-input cursor-pointer"
                            disabled={isLoading}
                          />
                        </div>
                        <div className="d-flex align-items-center flex-grow-1">
                          <div
                            className="rounded-circle d-flex align-items-center justify-content-center me-3 fs-6"
                            style={{
                              width: "32px",
                              height: "32px",
                              minWidth: "32px",
                              backgroundColor: "#0d6efd",
                              color: "white",
                            }}
                          >
                            {otherUser.name
                              .split(" ")
                              .map((n) => n[0])
                              .join("")}
                          </div>
                          <div className="d-flex justify-content-between w-100 align-items-center">
                            <div className="fw-medium">{otherUser.name}</div>
                            <div className="text-muted">{otherUser.email}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-muted p-3 border rounded text-center bg-light">
                    {`No ${
                      user.role === "Advisor" ? "co-advisors" : "advisors"
                    } available`}
                  </div>
                )}
              </div>
            </div>

            {/* Help Text */}
            <div
              className={`mt-4 p-3 border rounded bg-light associations-section ${
                isLoading ? "loading" : ""
              }`}
            >
              <div className="text-muted small">
                <strong>Note:</strong> If an association has been incorrectly
                made, please reach out to{" "}
                <a href="mailto:dev@investwithaqua.com">
                  dev@investwithaqua.com
                </a>{" "}
                to resolve the associations.
              </div>
            </div>
          </div>

          <div className="modal-footer border-top-0">
            <button
              type="button"
              className="btn btn-link text-decoration-none"
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            {newAssociationsAdded && (
              <MuiButton
                buttonText={
                  isLoading
                    ? "Saving..."
                    : `Add ${selectedUserIds.size} Association${
                        selectedUserIds.size > 1 ? "s" : ""
                      }`
                }
                onClick={handleSave}
                disabled={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAssociations;
