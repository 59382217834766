import React, { useContext } from "react";
import { UserContext } from "../../Contexts/UserContext";
import "./Styles/Styles.scss";
import MasterOpsPortfolio from "./MasterOps/MasterOpsPortfolio";

function Portfolio() {
  const { user } = useContext(UserContext);

  return  (
    <MasterOpsPortfolio />
  )
}
export default Portfolio;
