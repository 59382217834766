import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Button, Space } from "antd";
import "./FilterStyles.scss";
import "antd/dist/antd.css";

function Filter({ text, setText, defaultKey, options, label }) {
  const menu = (
    <Menu
      style={{ width: "100%", paddingTop: 0, paddingBottom: 0 }}
      selectable
      onClick={(e) => setText(e.key)}
      defaultSelectedKeys={defaultKey}
    >
     {
      options.map((e) => 
      <Menu.Item key={e} className="h-100">
        <span className="dropdown-text-style">{e}</span>
      </Menu.Item> ) }
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <Button
        style={{
          backgroundColor: "#ffffff !important",
          paddingLeft: 0,
          paddingRight: 0,
        }}
        className="d-flex ant-btn-style"
      >
        <div className="row g-0 h-100 w-100 align-items-center">
          <div className="col-10 d-flex h-100 align-items-center justify-content-center right-border-style text-break">
            <span className="align-text-center justify-text-center ms-2 my-custom-input-text ms-1 me-1 text-wrap">
              {label}: {text}
            </span>
          </div>
          <div className="col-2 h-100 d-flex justify-self-end align-items-center justify-content-center">
            <img src="https://d1swyfveypj49y.cloudfront.net/PortfolioArrow.svg" alt="portfolio-arrow"></img>
          </div>
        </div>
      </Button>
    </Dropdown>
  );
}
export default Filter;

