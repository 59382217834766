import React, { useState } from "react";
import TextInputField from "./../InputFields/TextInputField/TextInputField";
import SelectInputField from "./../InputFields/SelectInputField/SelectInputField";
import MuiButton from "./../Buttons/MuiButton/MuiButton";
import StyledDateInputField from "../StyledDateInput/StyledDateInput.js";
import { formatSSN } from "./../../utilities/functions/functions.js";
import { countryList } from "./../../utilities/lists/countryList.js";
import { stateList } from "./../stateList";

function ClientBasicInfo({ setActiveSlide }) {
  const [idNumber, setIDNumber] = useState("");
  const [idNumberError, setIDNumberError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [domicileCountryError, setDomicileCountryError] = useState("");


  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [error, setError] = useState("");
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState("");

  const [domicileCountry, setDomicileCountry] = useState("");


  const submit = () => {
    var errors = false;

    if (firstName === "") {
      errors = true;
      setFirstNameError("This field is required.");
    } 

    if (lastName === "") {
      errors = true;
      setLastNameError("This field is required.");
    } 

    if (dob === "") {
      errors = true;
      setDobError("This field is required.");
    } 

    if (idNumber === "") {
      errors = true;
      setIDNumberError("This field is required.");
    } 

    if (addressLine1 === "") {
      errors = true;
      setAddressLine1Error("Please enter an address line 1.");
    }

    if (city === "") {
      errors = true;
      setCityError("Please enter a city.");
    }

    if (country === "United States" && state === "") {
      errors = true;
      setStateError("Please enter a state.");
    }

    if (country === "") {
      errors = true;
      setCountryError("Please enter a country.");
    }

    if (domicileCountry === "") {
      errors = true; 
      setDomicileCountryError("Please enter a country.");
    }

    if (postalCode === "") {
      errors = true;
      setPostalCodeError("Please enter a postal code.");
    }
    if (errors === false) {
      setActiveSlide((prevState) => prevState + 1);
    }

  };

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="row g-0 w-100 mb-4">
          <span className="add-account-step-subheader">
            Personal Information
          </span>
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="col pe-2">
            <TextInputField
              value={firstName}
              setValue={setFirstName}
              valueError={firstNameError}
              setValueError={setFirstNameError}
              label={"First Name"}
              required={true}
              fullWidth={true}
              shrink={true}
              size={"small"}
              id={"individual-account-address-line-1-input"}
            />
          </div>
          <div className="col pe-0 ps-2">
            <TextInputField
              value={lastName}
              setValue={setLastName}
              valueError={lastNameError}
              setValueError={setLastNameError}
              label={"Last Name"}
              required={false}
              fullWidth={true}
              shrink={true}
              size={"small"}
              id={"individual-account-address-line-2-input"}
            />
          </div>
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="col pe-2">
            <StyledDateInputField
              value={dob}
              setValue={setDob}
              valueError={dobError}
              setValueError={setDobError}
              label={"Date Of Birth"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"individual-account-dob-input"}
            />
          </div>
          <div className="col pe-0 ps-2">
          <SelectInputField
            value={domicileCountry}
            setValue={setDomicileCountry}
            valueError={domicileCountryError}
            setValueError={setDomicileCountryError}
            label={"Domiciled Country"}
            selectOptions={countryList}
            required={true}
            fullWidth={true}
            shrink={true}
            size={"small"}
          />
          </div>
        </div>

        <div className="row g-0 w-50 mb-4">
          <div className="col pe-2">
          {domicileCountry === "United States" && (
              <TextInputField
                value={idNumber}
                setValue={setIDNumber}
                valueError={idNumberError}
                setValueError={setIDNumberError}
                label={"SSN"}
                required={true}
                fullWidth={true}
                size={"small"}
                mask={{ maskFormatter: formatSSN, maskPattern: "999-99-9999" }}
                id={"individual-account-ssn-input"}
              />
            )}
            {domicileCountry !== "United States" && (
              <TextInputField
                value={idNumber}
                setValue={setIDNumber}
                valueError={idNumberError}
                setValueError={setIDNumberError}
                label={"Passport Number"}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"individual-account-passport-input"}
              />
            )}
        </div></div>
      </React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">
          Mailing Address
        </span>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <TextInputField
            value={addressLine1}
            setValue={setAddressLine1}
            valueError={addressLine1Error}
            setValueError={setAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            shrink={true}
            size={"small"}
            id={"individual-account-address-line-1-input"}
          />
        </div>
        <div className="col pe-0 ps-2">
          <TextInputField
            value={addressLine2}
            setValue={setAddressLine2}
            valueError={addressLine2Error}
            setValueError={setAddressLine2Error}
            label={"Address Line 2"}
            required={false}
            fullWidth={true}
            shrink={true}
            size={"small"}
            id={"individual-account-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <TextInputField
            value={city}
            setValue={setCity}
            valueError={cityError}
            setValueError={setCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            shrink={true}
            size={"small"}
            id={"individual-account-address-city-input"}
          />
        </div>
        {country === "United States" && (
          <div className="col pe-0 ps-2">
            <SelectInputField
              value={state}
              setValue={setState}
              valueError={stateError}
              setValueError={setStateError}
              selectOptions={stateList}
              label={"State"}
              required={true}
              fullWidth={true}
              shrink={true}
              size={"small"}
              id={"individual-account-state-input"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectInputField
            value={country}
            setValue={setCountry}
            valueError={countryError}
            setValueError={setCountryError}
            label={"Country"}
            selectOptions={countryList}
            required={true}
            fullWidth={true}
            shrink={true}
            size={"small"}
            id={"individual-account-address-country-input"}
          />
        </div>
        <div className="col pe-0 ps-2">
          <TextInputField
            value={postalCode}
            setValue={setPostalCode}
            valueError={postalCodeError}
            setValueError={setPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            shrink={true}
            size={"small"}
            id={"individual-account-postal-code-input"}
          />
        </div>
      </div>
      <div className="d-flex">
        <MuiButton buttonText={"Next Step"} onClick={submit} size={"large"} />
      </div>
      {error ? (
        <div className="row g-0 w-100 mb-4">
          <div className="col-10">
            <div className="row g-0 w-100">
              <div className="col">
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                  alt="error"
                  width="15"
                  height="15"
                />
                <span className="ms-3 trust-qualification-error-text">
                  {error}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}
export default ClientBasicInfo;
