import React, { useState, useEffect } from "react";

export const GetDropdownList = (fn, setLoading, refresh) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    async function getDropdownItems() {
      setLoading(true);
      try {
        const response = await fn();
        const dropdownItemData = response.data;

        let itemArray = dropdownItemData.map((item) => {
          return {
            value: item.value,
            label: item.label,
          };
        });

        setItems(itemArray);
      } catch (err) {
        setItems([]);
      }
      setLoading(false);
    }
    getDropdownItems();
  }, [refresh]);

  return { items };
};
