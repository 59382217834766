import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import PasswordInputField from "../../../../../Components/InputFields/PasswordInputField/PasswordInputField";
import PhoneInputField from "../../../../../Components/InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import { countryList } from "../../../../../utilities/lists/countryList";
import { firmList } from "../../../../../utilities/lists/firmList";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import { isPasswordValid } from "../../../../../utilities/functions/functions";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import { Auth } from "aws-amplify";
import { stripOutDigits } from "../../../../../utilities/functions/functions";
import { wmUserSignup } from "../../../../../APIs/WealthManagementAPI";
const SignupForm = ({
  feeder,
  code,
  navigation,
  confidentialityAgreementId,
  confidentialityAgreementDateSigned,
  confidentialitySignatureName,
  termsOfUseId,
  termsOfUseDateSigned,
  firmName,
  setFirmName,
  advisorFirstName,
  setAdvisorFirstName,
  advisorLastName,
  setAdvisorLastName,
  domicileCountry,
  setDomicileCountry,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const [firmNameError, setFirmNameError] = useState("");
  const [advisorFirstNameError, setAdvisorFirstNameError] = useState("");
  const [advisorLastNameError, setAdvisorLastNameError] = useState("");
  const [domicileCountryError, setDomicileCountryError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [cognitoSignupError, setCognitoSignupError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userId, setUserId] = useState("");
  const [directToLogin, setDirectToLogin] = useState(null);

  const signUp = async () => {
    setLoading(true);

    var errors = false;

    if (!feeder) {
      if (firmName === "") {
        errors = true;
        setFirmNameError("This field is required.");
      } else if (firmList.filter((e) => e.value === firmName).length === 0) {
        errors = true;
        setFirmNameError("Please select a valid firm from the list.");
      }
    }

    const fullName = (
      advisorFirstName.toLowerCase() +
      " " +
      advisorLastName.toLowerCase()
    ).trim();

    if (
      confidentialitySignatureName.replace(/  +/g, " ").trim().toLowerCase() !==
      fullName.replace(/  +/g, " ")
    ) {
      errors = true;
      setAdvisorFirstNameError(
        "Name entered does not match the name signed on the confidentiality agreement."
      );
      setAdvisorLastNameError(
        "Name entered does not match the name signed on the confidentiality agreement."
      );
    } else if (
      confidentialitySignatureName.toLowerCase() ===
      fullName.replace(/  +/g, " ")
    ) {
      setAdvisorFirstNameError("");
      setAdvisorLastNameError("");
    }

    if (advisorFirstName === "") {
      errors = true;
      setAdvisorFirstNameError("This field is required.");
    }

    if (advisorLastName === "") {
      errors = true;
      setAdvisorLastNameError("This field is required.");
    }

    if (email === "") {
      errors = true;
      setEmailError("This field is required.");
    }

    if (phoneNumber === "") {
      errors = true;
      setPhoneNumberError("Please enter a phone number.");
    }

    if (password === "") {
      errors = true;
      setPasswordError("Please enter a password.");
    } else if (!isPasswordValid(password)) {
      errors = true;
      setPasswordError(
        "Your password must be longer than 10 characters and contain at least one number, an uppercase, a lowercase, and a special character. Valid special characters include (@,#,$,%,^,&,+,=,!,_)/"
      );
    }

    if (confirmPassword === "") {
      errors = true;
      setConfirmPasswordError("Please confirm your password.");
    } else if (confirmPassword !== password) {
      errors = true;
      setConfirmPasswordError("Your passwords do not match.");
    }

    if (domicileCountry === "") {
      errors = true;
      setDomicileCountryError("This field is required.");
    } else if (
      countryList.filter((e) => e.value === domicileCountry).length === 0
    ) {
      errors = true;
      setDomicileCountryError("Please select a valid country from the list.");
    }

    if (!errors) {
      try {
        const cognitoUser = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
            //'custom:firm': firmName.replace(/ /g,"_").toLowerCase(),
            "custom:first_name": advisorFirstName.replace(/  +/g, " ").trim(),
            "custom:last_name": advisorLastName.replace(/  +/g, " ").trim(),
          },
        });

        setUserId(cognitoUser.userSub);
        const newUser = {
          username: cognitoUser.userSub,
          email: email.toLowerCase(),
          first_name: advisorFirstName.replace(/  +/g, " ").trim(),
          last_name: advisorLastName.replace(/  +/g, " ").trim(),
          phone_number: stripOutDigits(phoneNumber),
          country: domicileCountry,
          signer_name: confidentialitySignatureName.replace(/  +/g, " ").trim(),
          confidentiality_agreement_id: confidentialityAgreementId,
          confidentiality_agreement_date_signed: confidentialityAgreementDateSigned,
          terms_of_agreement_id: termsOfUseId,
          terms_of_agreement_date_signed: termsOfUseDateSigned,
          ...(feeder && { magic_code: code }),
        };

         await wmUserSignup(newUser);

        setLoading(false);
        navigation.next();
      } catch (err) {
        if (
          feeder &&
          err.message === "An account with the given email already exists."
        );
        {
          setDirectToLogin(
            "Account already exists, please log in before accessing the feeder signup."
          );
        }

        setCognitoSignupError(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        {!feeder && (
          <div className="col-12">
            <div className="row g-0 w-100 mb-3">
              <h5>Firm Details</h5>
            </div>
            <div className="row g-0 w-100">
              <div className="col">
                <SelectInputField
                  value={firmName}
                  setValue={setFirmName}
                  valueError={firmNameError}
                  setValueError={setFirmNameError}
                  label={"Firm Name"}
                  selectOptions={firmList}
                  required={true}
                  fullWidth={true}
                  shrink={true}
                  size={"small"}
                  id={"firm-name-input"}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-3">
            <h5>Advisor Details</h5>
          </div>

          <div className="row g-0 w-100 mb-4">
            <div className="col pe-3">
              <TextInputField
                value={advisorFirstName}
                setValue={setAdvisorFirstName}
                valueError={advisorFirstNameError}
                setValueError={setAdvisorFirstNameError}
                label={"First Name"}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"first-name-input"}
              />
            </div>
            <div className="col">
              <TextInputField
                value={advisorLastName}
                setValue={setAdvisorLastName}
                valueError={advisorLastNameError}
                setValueError={setAdvisorLastNameError}
                label={"Last Name"}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"last-name-input"}
              />
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            {!feeder && (
              <div className="col pe-3">
                <TextInputField
                  value={email}
                  setValue={setEmail}
                  valueError={emailError}
                  setValueError={setEmailError}
                  label={"Email"}
                  required={true}
                  fullWidth={true}
                  shrink={true}
                  size={"small"}
                  id={"email-input"}
                />
              </div>
            )}
            <div className="col">
              <PhoneInputField
                shrink={true}
                label={"Phone Number"}
                size={"small"}
                fullWidth={true}
                required={true}
                value={phoneNumber}
                setValue={setPhoneNumber}
                valueError={phoneNumberError}
                setValueError={setPhoneNumberError}
                disabled={false}
              />
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col pe-3">
              <PasswordInputField
                value={password}
                setValue={setPassword}
                valueError={passwordError}
                setValueError={setPasswordError}
                label={"Password"}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"password-input"}
              />
            </div>
            <div className="col">
              <PasswordInputField
                value={confirmPassword}
                setValue={setConfirmPassword}
                valueError={confirmPasswordError}
                setValueError={setConfirmPasswordError}
                label={"Confirm Password"}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"confirm-password-input"}
              />
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col">
              <SelectInputField
                value={domicileCountry}
                setValue={setDomicileCountry}
                valueError={domicileCountryError}
                setValueError={setDomicileCountryError}
                label={"Domicile Country"}
                selectOptions={countryList}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"domicile-country-input"}
              />
            </div>
          </div>
          <div className="row g-0 w-100">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
              <div className="row g-0 w-100">
                <div className="col ms-0 me-auto">
                  <div className="d-flex">
                    <MuiButton
                      buttonText={"Sign Up"}
                      onClick={() => signUp()}
                      size={"large"}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SnackBar
            snackbarState={cognitoSignupError}
            setSnackbarState={setCognitoSignupError}
            vertical={"top"}
            horizontal={"right"}
            duration={5000}
            sxProps={{ paddingLeft: "3px" }}
            severity={"error"}
            message={directToLogin || "Sorry, there was an error signing up."}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default SignupForm;
