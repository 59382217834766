import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as dayjs from "dayjs";
import { UserContext } from "../../Contexts/UserContext.js";
import { thousandsSeparator } from "../../utilities/functions/functions";
import {
  aggViewsGraph,
  getFirmUser,
  advisorAggViewsGraph,
} from "../../APIs/WealthManagementAPI.js";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { numFormatter } from "../../utilities/functions/functions";

const AggViews = ({ isAdvisor }) => {
  const { user } = useContext(UserContext);
  const isMasterOpsOrRM =
    user.userRole === "Master" ||
    user.userRole === "Ops" ||
    user.userRole === "Relationship Manager";

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [value, setValue] = React.useState([null, null]);

  const [accountCreated, setAccountCreated] = useState(new Date());

  const [graphData, setGraphData] = useState(null);

  const fetchGraph = async () => {
    const resp = isMasterOpsOrRM
      ? await aggViewsGraph(
          formatDate(startDate),
          formatDate(endDate),
          user.firmInfo.id
        )
      : await advisorAggViewsGraph(formatDate(startDate), formatDate(endDate));
    if (resp.data.msg === "No investments") {
      setGraphData(null);
    } else {
      setGraphData(
        [
          {
            index: "2022-11-22",
            fulfilled_on: 0,
            amount: 0,
            investment_amount: 0,
            marked_value: 0,
            distribution_amount: 0,
          },
        ].concat(
          resp.data.data.map((e) => ({
            ...e,
            marked_value: e.marked_value.toFixed(2),
          }))
        )
      );
    }
  };

  const onLoad = async () => {
    const firmId = await getFirmUser();
    setAccountCreated(new Date(firmId.data.firm.created_at));
    setStartDate(new Date(firmId.data.firm.created_at));
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    fetchGraph(startDate, endDate);
  }, [startDate, endDate]);

  // const AxisFormatter = (number) => {
  //   return "$ " + thousandsSeparator(number);
  // };

  const AxisFormatter = (number) => {
    return "$ " + numFormatter(number);
  };

  const TooltipAxisFormatter = (number) => {
    return "$ " + thousandsSeparator(number);
  };

  return (
    <>
      <div className="container p-4">
        <div className="row g-0 w-100 mb-4">
          <div>
            <>
              <ResponsiveContainer height={250}>
                <LineChart
                  data={
                    graphData !== null && graphData !== undefined
                      ? graphData.map((e) => ({
                          ...e,
                          date: dayjs(e.index).unix(),
                        }))
                      : [
                          {
                            date: dayjs().unix(),
                            investment_amount: 0,
                            distribution_amount: 0,
                            capital_calls: 0,
                            marked_value: 0,
                          },
                          {
                            date: dayjs().subtract(5, "day").unix(),
                            investment_amount: 0,
                            distribution_amount: 0,
                            capital_calls: 0,
                            marked_value: 0,
                          },
                        ]
                  }
                  height={250}
                  margin={{
                    top: 10,
                    right: 10,
                    left: 0,
                    bottom: 11,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="0"
                    stroke="#E2E6ED"
                    strokeWidth={1}
                    hidden={true}
                  />
                  <XAxis
                    type={"number"}
                    domain={["dataMin", "dataMax"]}
                    dataKey="date"
                    interval="preserveEnd"
                    tickLine={false}
                    hide={true}
                    allowDataOverflow={false}
                  />
                  <YAxis
                    tickFormatter={AxisFormatter}
                    type={"number"}
                    width={65}
                    domain={["auto", "auto"]}
                    style={{
                      color: "#262A3D",
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "11px",
                    }}
                  />
                  <Tooltip
                    formatter={TooltipAxisFormatter}
                    labelFormatter={(date) =>
                      dayjs.unix(date).format("MMM D, YYYY")
                    }
                    labelStyle={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "13px",
                    }}
                    contentStyle={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "13px",
                    }}
                    wrapperStyle={{
                      border: "none !important",
                      borderRadius: "20px",
                      background: "#0adddf",
                    }}
                  />
                  <Line
                    type="stepAfter"
                    name="Investments"
                    dataKey="investment_amount"
                    stroke="#0091EA"
                    strokeWidth={3}
                    dot={false}
                  />
                  <Line
                    type="stepAfter"
                    name="Distributions"
                    dataKey="distribution_amount"
                    stroke="#0D47A1"
                    strokeWidth={3}
                    dot={false}
                  />
                  <Line
                    type="stepAfter"
                    name="Capital Calls"
                    dataKey="capital_calls"
                    stroke="#6CB4EE"
                    strokeWidth={3}
                    dot={false}
                  />
                  <Line
                    type="stepAfter"
                    name="Marked Value"
                    dataKey="marked_value"
                    stroke="#008E97"
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </>
          </div>
        </div>
        <div class="pb-4" style={{ float: "right" }}>
          <Stack spacing={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "Start date", end: "End date" }}
            >
              <DesktopDateRangePicker
                fontSize="10"
                value={[startDate, endDate]}
                onChange={(newValue) => {
                  setStartDate(formatDate(newValue[0].$d));
                  setEndDate(formatDate(newValue[1].$d));
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Stack>
        </div>
      </div>
    </>
  );
};
export default AggViews;
