import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles.scss";
import StageSection from "./StageSection/StageSection.js";
import FundNameSection from "./FundNameSection/FundNameSection.js";
import TargetPerformanceSection from "./TargetPerformanceSection/TargetPerformanceSection.js";
import AllocationGraphSection from "./AllocationGraphSection/AllocationGraphSection.js";
//import InactiveFundCard from "./InactiveFundCard/InactiveFundCard";

function BYOCard({ fund }) {
  let navigate = useNavigate();

  const fundRef = useRef(null);

  return (
    <div className="col-xl-4 col-md-6 col-12">
      <div className="row g-0 ps-3 pe-3 h-100">
        <div
          ref={fundRef}
          className="fund-card active-fund-card"
          onClick={() => {
            navigate("/byo");
            // if (fund.fundInvestmentStatus !== "Upcoming") {
            //   navigate("/fund/" + fund.link);
            // }
          }}
        >
          <div class="">
            <div class="col-12">
              <FundNameSection
                fundName={""}
                firmName={"Bring Your Own Investment"}
                logoLink={"https://d1swyfveypj49y.cloudfront.net/byo.svg"}
              />
              <div class="col-12">
                <div class="col-12">
                  <div class="row ms-3 me-3 mb-3">
                    <div class="two-by-two-grid rounded pb-3 ps-3 pt-3 pe-3">
                      <div class="row">
                        <div>
                          {" "}
                          <span class="">
                            {" "}
                            Source high quality private companies or private
                            funds for your clients to invest in, we take care of
                            the rest.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="row gx-3"></div>
                  </div>
                </div>
                <div class="row ms-3 me-3">
                  <div class="two-by-two-grid rounded"></div>
                </div>
                <button></button>
              </div>
              <div class="row g-0" style={{ paddingBottom: "39px" }}>
                <div class="container me-3">
                  <div class="row g-0">
                    <div class="col-12 pt-0 pb-0 " style={{ display: "none" }}>
                      <div class="row g-0">
                        <div class="col-12 ms-3">
                          <div class="allocation-left-text">
                            Allocation Filled:{" "}
                          </div>
                        </div>
                      </div>
                      <div class="row g-0 mt-3 ms-3 me-3 mb-3">
                        <div class="col-12">
                          <div class="progressBar-height">
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "rgba(0, 145, 234, 0.1)",
                                borderRadius: "10px",
                                margin: "0px",
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "8.625e-05%",
                                  backgroundColor: "rgb(0, 145, 234)",
                                  borderRadius: "inherit",
                                  zIndex: "2",
                                  textAlign: "right",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ bottom: "0px", width: "100%", position: "absolute" }}
              >
                <div
                  class="col-12 pt-0 pb-0 rounded-bottom new-fundraising-stage"
                  style={{ backgroundColor: "purple" }}
                >
                  <div class="row g-0 mt-3 ms-3 me-3">
                    <div
                      class="col-12 stage-text"
                      style={{ textAlign: "center" }}
                    >
                      <b>Contact Us</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BYOCard;
