import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions";
import { HOST } from "../utilities/host-config/host-config";
import { v4 as uuidv4 } from "uuid";
// const backendApi = axios.create({
//   baseUrl: HOST,
// });

//Dev environment endpoint
export const getFundSubscriptionStats = async (fundLink) => {
  const link = fundLink.toString();
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  //   return axios.get(
  //     HOST + "/fund/" + link + "/subscription-info/",
  //     requestParams
  //   );

  return {
    data: {
      id: uuidv4(),
      name: "Vintage IV",
      link: "vintage_iv",
      investment_status: "Fundraising",
      allow_ira_investment: false,
      subscription_stats: [
        {
          fund: uuidv4(),
          fund_size: "10000000.00",
          committed_allocation_amount: "0.00",
          remaining_allocation_amount: "1000000.00",
        },
      ],
      fund_terms: [
        {
          fund: uuidv4(),
          fund_size: "1000000.00",
          target_length_months: "120",
          fund_currency: "USD",
          minimum_allocation_amount: "100000.00",
          maximum_allocation_amount: "300000.00",
          capital_calls: false,
          initial_funding_percent: "0",
          target_return_lower_percent: "25",
          target_return_upper_percent: "36",
          target_return_multiple: "3.1",
          upfront_management_fee_percent: "0.25",
          ongoing_distribution_fee_percent: "1.0",
          legacy_pricing_structure: false,
        },
      ],
    },
  };
};

export const getInvestorFundInvestment = async (fundId, accountId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      fund_id: fundId,
      account_id: accountId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/investor-fund-investment/", requestParams);
};

export const createInvestment = async (
  fundId,
  investmentAmount,
  accountId,
  iraPartnerName = null
) => {
  const config = await getRequestHeaders();

  const params = {
    ...(iraPartnerName && { ira_partner_name: iraPartnerName }),
    ...{ fund_id: fundId, amount: investmentAmount, account_id: accountId },
  };

  if (process.env.REACT_APP_DEBUG === "1") {
    console.log(
      "Create investment request made with parameters: " +
        JSON.stringify(params)
    );
  }
  return true;
  //   return axios.post(
  //     HOST + "/create-update-investor-fund-investment/",
  //     params,
  //     config
  //   );
};

export const addFirmUser = async (params) => {
  const config = await getRequestHeaders();

  if (process.env.REACT_APP_DEBUG === "1") {
    console.log(
      "Create investment request made with parameters: " +
        JSON.stringify(params)
    );
  }
  return axios.post(HOST + "/retool/create-wm-firm-user", params, config);
};

export const getAdvisors = async () => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  try {
    const response = await axios.get(
      HOST + "/get-firm-advisors",
      requestParams
    ); // Adjust the endpoint as needed
    return response.data;
  } catch (error) {
    throw error;
  }
};
