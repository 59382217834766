import React from "react";
import "./Styles/Styles.scss";
import Reference from "./Reference/Reference";
import DropdownContent from "../../../../../Components/DropdownContent/DropdownContent";

const Overview = ({ fund }) => {
  let fundOverviewContent = fund ? (
    <React.Fragment>
      <div className="row g-0 ms-4 me-4 mt-4 mb-2">
        <div className="col-12 heading-4">
          {fund && fund.tagline ? (
            fund.tagline
          ) : (
            <span className="badge badge-light">Fund Not Found</span>
          )}
        </div>
        {fund && fund.fundOverview ? (
          <div
            className="col-12 mt-2"
            dangerouslySetInnerHTML={{
              __html: fund ? fund.fundOverview : <p>Loading...</p>,
            }}
          ></div>
        ) : null}
        {fund.referenceTerms.length > 0 ? (
          <Reference referenceTerms={fund.referenceTerms} />
        ) : null}
      </div>
      {fund.fundDueDiligenceQuestions !== null &&
        fund.fundDueDiligenceQuestions !== undefined &&
        fund.fundDueDiligenceQuestions.length !== 0 && (
          <React.Fragment>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12 ps-5 pe-4">
                <span className="heading-4">Due Diligence Questions</span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12 ps-5 pe-5">
                {fund.fundDueDiligenceQuestions.map((element, index) => {
                  return index === fund.fundDueDiligenceQuestions.length - 1 ? (
                    <div className="row g-0 ps-4 w-100">
                      <DropdownContent
                        key={index}
                        dropdownPreview={element.question}
                        dropdownDetailedContent={element.answer}
                      />
                    </div>
                  ) : (
                    <div className="row g-0 ps-4 w-100 mb-1">
                      <DropdownContent
                        key={index}
                        dropdownPreview={element.question}
                        dropdownDetailedContent={element.answer}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        )}
    </React.Fragment>
  ) : (
    <span className="badge badge-light text-dark">
      Overview Not Found. Please check again later.
    </span>
  );

  let fundOverviewCard = fund ? (
    fundOverviewContent
  ) : (
    <span className="badge badge-light text-dark">
      Fund overview not found. Please check again later.
    </span>
  );
  return fundOverviewCard;
};
export default Overview;
