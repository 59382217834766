import React, { useState, useEffect } from "react";
import { formatInvestmentAmount } from "../../../utilities/functions/functions";
import "./Styles/Styles.scss";

const InvestmentAmountField = ({
  placeholder,
  error,
  fieldValueChange,
  initialValue,
  disabled,
  maxInput,
}) => {
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [focus, setFocus] = useState(false);
  const [divClass, setDivClass] = useState("input-box-container");
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (!error) {
      setDivClass(focus ? "input-box-containerFocus" : "input-box-container");
    } else {
      setDivClass("input-box-containerError");
    }
  }, [focus, error]);

  useEffect(() => {
    setInputPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    fieldValueChange(fieldValue);
  }, [fieldValue, fieldValueChange]);

  return (
    <div
      className={divClass}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div className="input-box">
        <input
          className={
            error === "This field is required."
              ? "internal-input-error-empty"
              : "internal-input"
          }
          placeholder={
            error === "This field is required." ? "Required" : inputPlaceholder
          }
          value={initialValue}
          onChange={(event) => {
            if (event.target.value.replace(/[^\d]/g, "") <= maxInput)
              setFieldValue(formatInvestmentAmount(event.target.value));
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
export default InvestmentAmountField;
