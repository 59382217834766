import React, { useState, useEffect } from "react";
import MultiSelectTable from "./MultiSelectTable";
import ConfirmInvestments from "./ConfirmInvestments/ConfirmInvestments";
import ErrorComponent from "./ErrorComponent/ErrorComponent";
import GetUninvestedClients from "../../Adapters/GetUninvestedClients";

function ParentComponent({
  fundInvestmentInformation,
  setSuccessfulInvestmentSubmission,
}) {
  const [errorInfo, setErrorInfo] = useState("");
  const [showConfirmInvestments, setShowConfirmInvestments] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);

  const { clients } = GetUninvestedClients(
    fundInvestmentInformation,
    setLoading
  );

  const rows = [
    {
      id: 1,
      full_name: "Dev Patel",
      email: "dpatel5853@gmail.com",
      account_type: "Individual",
      proposed_investment_amount: "1000",
      disabled_input: false,
    },
    {
      id: 2,
      full_name: "Stetson Bennett",
      email: "stetsonbennett@gmail.com",
      account_type: "Joint",
      proposed_investment_amount: "34500",
      disabled_input: false,
    },
    {
      id: 3,
      full_name: "Michael Williams",
      email: "michaelwilliams@gmail.com",
      account_type: "Individual",
      proposed_investment_amount: "129038",
      disabled_input: false,
    },
  ];

  return (
    <React.Fragment>
      {showConfirmInvestments === false ? (
        <MultiSelectTable
          fundInvestmentInformation={fundInvestmentInformation}
          loading={loading}
          rows={clients}
          setErrorInfo={setErrorInfo}
          investments={investments}
          setInvestments={setInvestments}
          setShowConfirmInvestments={setShowConfirmInvestments}
        />
      ) : (
        <ConfirmInvestments
          setSuccessfulInvestmentSubmission={setSuccessfulInvestmentSubmission}
          fundInvestmentInformation={fundInvestmentInformation}
          investments={investments}
          setShowConfirmInvestments={setShowConfirmInvestments}
        />
      )}
    </React.Fragment>
  );
}
export default React.memo(ParentComponent);
