import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../Contexts/UserContext";
import { PersonOutline, InfoOutlined, ErrorOutline } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { HOST } from "../../utilities/host-config/host-config";
import { getRequestHeaders } from "../../utilities/functions/functions";

interface TeamMember {
  id: string;
  name: string;
  email: string;
  phone_number: string;
  role: string;
}

interface User {
  userRole: string;
  userProfileData: {
    id: string;
    firstName: string;
    lastName: string;
  };
  associations?: {
    specialists?: TeamMember[];
    advisors?: TeamMember[];
  };
}

interface UserContextType {
  user: User | null;
}

interface ApiError {
  message: string;
  status?: number;
}

const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return "";

  // Remove all non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Handle US/Canada numbers (11 digits starting with 1)
  if (cleaned.length === 11 && cleaned.startsWith("1")) {
    return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(
      7
    )}`;
  }

  // Handle other international numbers
  if (cleaned.length > 10) {
    // Get country code (assume it's the first 1-3 digits)
    const countryCodeLength =
      cleaned.length > 13 ? 3 : cleaned.length > 12 ? 2 : 1;
    const countryCode = cleaned.slice(0, countryCodeLength);
    const remaining = cleaned.slice(countryCodeLength);

    // Format remaining numbers in groups of 3-4
    if (remaining.length >= 10) {
      return `+${countryCode} (${remaining.slice(0, 3)}) ${remaining.slice(
        3,
        6
      )}-${remaining.slice(6, 10)}`;
    }

    // If less than 10 digits remaining, group in threes
    return `+${countryCode} ${remaining.replace(/(\d{3})/g, "$1 ").trim()}`;
  }

  // Handle standard 10-digit numbers (assume US/Canada)
  if (cleaned.length === 10) {
    return `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
      6
    )}`;
  }

  // For any other length, just add spaces every 3 digits
  return cleaned.replace(/(\d{3})/g, "$1 ").trim();
};

const TableSkeleton: React.FC = () => (
  <div className="mb-4">
    <div className="mb-3">
      <Skeleton
        variant="rectangular"
        height={48}
        className="rounded"
        style={{ backgroundColor: "#F1F5F9" }}
      />
    </div>
    {[1, 2, 3].map((i) => (
      <div key={i} className="mb-2">
        <Skeleton
          variant="rectangular"
          height={64}
          className="rounded"
          style={{ backgroundColor: "#F8FAFC" }}
        />
      </div>
    ))}
  </div>
);

const Team: React.FC = () => {
  const { user } = useContext(UserContext) as UserContextType;
  const isAdvisor = user?.userRole === "Advisor";
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ApiError | null>(null);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const config = await getRequestHeaders();

        const response = await axios.get(
          `${HOST}/get-associations-for-firm-user?id=${user?.userProfileData?.id}`,
          config
        );

        if (response.data) {
          setTeamMembers(response.data?.associations || []);
        } else {
          throw new Error("Failed to fetch team members");
        }
      } catch (err: any) {
        console.error("Error fetching team members:", err);
        setError({
          message:
            err.message ||
            "Unable to load team members. Please try again later.",
          status: err.response?.status,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.userProfileData?.id) {
      fetchTeamMembers();
    }
  }, [user?.userProfileData?.id]);

  // Add error state UI
  const ErrorMessage: React.FC<{ error: ApiError }> = ({ error }) => (
    <div className="text-center py-5">
      <div className="d-flex justify-content-center mb-3">
        <ErrorOutline style={{ fontSize: 48, color: "#EF4444" }} />
      </div>
      <h6 className="mb-2">Unable to Load Team Members</h6>
      <p className="text-secondary mb-3">{error.message}</p>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => window.location.reload()}
      >
        Try Again
      </button>
    </div>
  );

  return (
    <div className="container pt-5 mt-5">
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-4">
            <h5 className="m-0">Team</h5>
          </div>

          <div className="bg-white rounded p-4">
            <h6 className="mb-3">
              {isAdvisor ? "Your Co-Advisor Team" : "Your Advisor Associations"}
            </h6>
            <p className="text-secondary mb-4">
              {isAdvisor
                ? "The following co-advisors are associated to your account. Co-advisors can help manage client onboarding, subscription document and client verification processes for your clients."
                : "For any advisors that your account is associated to, you will have the ability to help manage client onboarding, subscription document and client verification processes for their clients."}
            </p>

            {isLoading ? (
              <TableSkeleton />
            ) : error ? (
              <ErrorMessage error={error} />
            ) : teamMembers.length === 0 ? (
              <div className="text-center py-5">
                <div className="d-flex justify-content-center mb-3">
                  <PersonOutline style={{ fontSize: 48, opacity: 0.2 }} />
                </div>
                <h6 className="mb-2">No Team Members Yet</h6>
                <p className="text-secondary">
                  {isAdvisor
                    ? "You currently have no co-advisors associated with your account."
                    : "You are currently not associated with any advisors."}
                </p>
              </div>
            ) : (
              <div className="mb-4 table-responsive">
                <table
                  className="table table-hover align-middle"
                  style={{ borderCollapse: "separate", borderSpacing: "0 4px" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="border-0 pb-2 text-uppercase"
                        style={{
                          fontSize: "0.6875rem",
                          color: "#64748B",
                          fontWeight: 600,
                          letterSpacing: "0.05em",
                        }}
                      >
                        Name
                      </th>
                      <th
                        className="border-0 pb-2 text-uppercase"
                        style={{
                          fontSize: "0.6875rem",
                          color: "#64748B",
                          fontWeight: 600,
                          letterSpacing: "0.05em",
                        }}
                      >
                        Email
                      </th>
                      <th
                        className="border-0 pb-2 text-uppercase"
                        style={{
                          fontSize: "0.6875rem",
                          color: "#64748B",
                          fontWeight: 600,
                          letterSpacing: "0.05em",
                        }}
                      >
                        Phone Number
                      </th>
                      <th
                        className="border-0 pb-2 text-uppercase"
                        style={{
                          fontSize: "0.6875rem",
                          color: "#64748B",
                          fontWeight: 600,
                          letterSpacing: "0.05em",
                        }}
                      >
                        Role
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamMembers.map((member) => (
                      <tr
                        key={member.id}
                        style={{
                          backgroundColor: "#FFFFFF",
                          boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.03)",
                          transition:
                            "transform 0.2s ease, box-shadow 0.2s ease",
                        }}
                        className="hover-row"
                      >
                        <td
                          className="py-3 px-3 border-0"
                          style={{
                            fontWeight: 500,
                            borderTopLeftRadius: "6px",
                            borderBottomLeftRadius: "6px",
                          }}
                        >
                          {member.name}
                        </td>
                        <td
                          className="py-3 px-3 border-0"
                          style={{ color: "#64748B" }}
                        >
                          {member.email}
                        </td>
                        <td
                          className="py-3 px-3 border-0"
                          style={{ color: "#64748B" }}
                        >
                          {formatPhoneNumber(member.phone_number)}
                        </td>
                        <td
                          className="py-3 px-3 border-0"
                          style={{
                            borderTopRightRadius: "6px",
                            borderBottomRightRadius: "6px",
                          }}
                        >
                          <span
                            className="badge rounded-pill"
                            style={{
                              backgroundColor: "rgba(59, 130, 246, 0.1)",
                              color: "#2563EB",
                              fontSize: "0.75rem",
                              padding: "0.35rem 0.75rem",
                              fontWeight: 500,
                            }}
                          >
                            {member.role}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div
              className="border rounded-3 p-4"
              style={{
                background: "linear-gradient(to right, #F8FAFC, #F1F5F9)",
                borderColor: "#E2E8F0",
              }}
            >
              <div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <InfoOutlined
                    style={{
                      fontSize: 18,
                      color: "#3B82F6",
                    }}
                  />
                  <h6 className="m-0" style={{ color: "#1E293B" }}>
                    Need to make changes?
                  </h6>
                </div>
                <p className="mb-0" style={{ color: "#475569" }}>
                  {isAdvisor
                    ? "To modify your co-advisor team, please reach out to your system administrator."
                    : "To modify your advisor associations, please reach out to your system administrator."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this CSS to your styles
const styles = `
  .hover-row:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }
`;

export default Team;
