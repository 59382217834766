import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import DocBox from "./DocBox/DocBox";
import GetDocs from "../../../../../Adapters/FundDocs";
require("dayjs/plugin/utc");

const Docs = ({ fund, deckOnly }) => {
  const { fundDocs } = GetDocs(fund, deckOnly);
  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    setAllDocs(fundDocs);
  }, [fundDocs]);

  return (
    <div className="row g-0 ms-4 me-4 mt-4 mb-3">
      {!deckOnly && <div className="col-12 heading-4">Docs</div>}
      <div className="container">
        <div className="row mt-2 gx-3 gy-2">
          {fundDocs.map((document, index) => {
            return (
              <DocBox
                key={index}
                documentName={document.documentName}
                signedLink={document.documentLink}
                displayDocument={document.display}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Docs;
