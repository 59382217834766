import React, { useState, useEffect, useContext } from "react";
//import "./ProfileBasicInfoStyles.css";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import TextInputField from "../InputFields/TextInputField/TextInputField";

import "react-phone-input-2/lib/bootstrap.css";

/* import {
  isValidPhoneNumber,
  isEmailValid,
} from "../../../Functions/functions.js"; */ 
//import { UserContext } from "../../../GlobalContexts/UserContext.js";

function ProfileBasicInfo() {
  //const { user, setUser } = useContext(UserContext);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [firstName, setFirstName] = useState("Dev");
  const [lastName, setLastName] = useState("Patel");
  const [email, setEmail] = useState("dpatel@investwithaqua.com");
  const [phoneNumber, setPhoneNumber] = useState("212-123-3456");

  /*
  useEffect(() => {
    setFirstName(user ? user.investorSummaryData.first_name : "");
    setLastName(user ? user.investorSummaryData.last_name : "");
    setEmail(user ? user.investorSummaryData.email : "");
    setPhoneNumber(user ? user.investorSummaryData.phone_number : "");
  }, [user]);

  useEffect(() => {
    setFirstNameError("");
  }, [firstName]);

  useEffect(() => {
    setLastNameError("");
  }, [lastName]);

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPhoneNumberError("");
  }, [phoneNumber]);

  const submit = async () => {
    var basicProfileInfo = {};
    var errorPresent = false;
    var changesDetected = false;
    var requireVerification = false;

    if (firstName.length === 0) {
      setFirstNameError("This field is required.");
      errorPresent = true;
    } else if (firstName !== user.investorSummaryData.first_name) {
      basicProfileInfo.first_name = firstName;
      requireVerification = true;
      changesDetected = true;
    }
    if (lastName.length === 0) {
      setLastNameError("This field is required.");
      errorPresent = true;
    } else if (lastName !== user.investorSummaryData.last_name) {
      basicProfileInfo.last_name = lastName;
      requireVerification = true;
      changesDetected = true;
    }
    if (email.length === 0) {
      setEmailError("This field is required.");
      errorPresent = true;
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email.");
      errorPresent = true;
    } else if (email !== user.investorSummaryData.email) {
      basicProfileInfo.email = email;
      changesDetected = true;
    }
    if (phoneNumber.length === 0) {
      setPhoneNumberError("This field is required.");
      errorPresent = true;
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError("Please enter a valid phone number.");
      errorPresent = true;
    } else if (phoneNumber !== user.investorSummaryData.phone_number) {
      basicProfileInfo.phone_number = phoneNumber;
      changesDetected = true;
    }

    if (errorPresent || !changesDetected) {
      return;
    } else {
      try {
        await updateBasicProfileInfo(basicProfileInfo);
        var verifyAgain = false;
        if (requireVerification) {
          try {
            await verifyUserProfile(false, "");
            verifyAgain = true;
          } catch (err) {
            if (process.env.REACT_APP_DEBUG === "1") console.error(err);
          }
        }
        const updatedUser = user;
        updatedUser.investorSummaryData.first_name = firstName;
        updatedUser.investorSummaryData.last_name = lastName;
        updatedUser.investorSummaryData.phone_number = phoneNumber;
        updatedUser.investorSummaryData.email = email;
        if (verifyAgain) {
          updatedUser.userProfileData.verified_profile = false;
        }
        setUser(updatedUser);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.log(err);
      }
    } */

  return (
    <React.Fragment>
      <div className="container-fluid w-100 ps-sm-5 ps-3 pe-sm-3 pe-2">
        <div className="row g-0 w-100">
          <div className="col-xxl-11 col-lg-11 col-12">
            <div className="row g-0 w-100 mt-3 mb-3">
              <div className="col-12">
                <h4>
                  Personal Details
                </h4>
              </div>
            </div>
            <div className="row g-0 w-100 mt-4 mb-4">
              <div className="col-12">
                <div className="row g-0 w-100">
                  <div className="col ps-0 pe-2">
                    <TextInputField
                      value={firstName}
                      setValue={setFirstName}
                      setValueError={setFirstNameError}
                      valueError={firstNameError}
                      label={"First Name"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={firstName !== ""}
                      id={"investor-profile-first-name-input"}
                    />
                  </div>
                  <div className="col ps-2 pe-0">
                    <TextInputField
                      value={lastName}
                      setValue={setLastName}
                      setValueError={setLastNameError}
                      valueError={lastNameError}
                      label={"Last Name"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={lastName !== ""}
                      id={"investor-profile-last-name-input"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="col-12">
                <div className="row g-0 w-100 mb-1">
                  <div className="col ps-0 pe-2">
                    <TextInputField
                      value={email}
                      setValue={setEmail}
                      setValueError={setEmailError}
                      valueError={emailError}
                      label={"Email"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={email !== ""}
                      id={"investor-profile-email-input"}
                    />
                  </div>
                  <div className="col ps-2 pe-0">
                    <TextInputField
                      value={phoneNumber}
                      setValue={setPhoneNumber}
                      setValueError={setPhoneNumberError}
                      valueError={phoneNumberError}
                      label={"Phone Number"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={phoneNumber !== ""}
                      id={"investor-profile-phone-number-input"}
                    />
                  </div>
                  <div className="row g-0 w-100 mt-4 contact-aqua-admin-container">
                    <div className="d-flex align-items-center">
                      <img
                        src="https://d1swyfveypj49y.cloudfront.net/newInfo.svg"
                        style={{ marginRight: "14px" }}
                      />
                      <span className="contact-aqua-admin-text">
                        If any of the above information is incorrect, please
                        contact admin@investwithaqua.com to update your account
                        information.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProfileBasicInfo;
