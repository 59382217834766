import React, { useState, useEffect } from "react";
import { getFirmUserFundAllocationRequest } from "../APIs/ConfirmInvestmentAPI";

export default function GetFirmUserFundAllocationRequest(
  time,
  fundLink,
  setLoading
) {
  const [firmUserFundAllocationRequest, setFirmUserFundAllocationRequest] =
    useState(null);

  useEffect(() => {
    async function getFirmUserFundAllocationRequestInformation() {
      setLoading(true);
      try {
        const response = await getFirmUserFundAllocationRequest(fundLink);
        const firmUserFundAllocationRequestData = response.data;
        setFirmUserFundAllocationRequest(firmUserFundAllocationRequestData);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err);
        }
      }
      setLoading(false);
    }
    getFirmUserFundAllocationRequestInformation();
  }, [time, fundLink]);
  return { firmUserFundAllocationRequest };
}
