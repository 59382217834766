import React, { useEffect } from "react";

export const WarningModal = ({
  isOpen,
  onClose,
  onProceed,
  title,
  message,
  isSubmitting = false,
}) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "unset";
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="warning-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className="warning-modal">
        <div className="warning-modal-header">
          <h3 className="title">{title}</h3>
        </div>
        <div className="warning-modal-content">{message}</div>
        <div className="warning-modal-footer">
          <button
            className="cancel"
            onClick={onClose}
            type="button"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="proceed"
            onClick={onProceed}
            type="button"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="button-content">
                <span className="spinner"></span>
                Processing...
              </span>
            ) : (
              "Proceed"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
