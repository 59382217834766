import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../../../Contexts/UserContext";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import PhoneInputField from "../../../../../Components/InputFields/PhoneInputField/PhoneInputField";
import { stateList } from "../../../../../Components/stateList";
import { countryList } from "../../../../../Components/countryList";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import { isValidPhoneNumber } from "../../../../../utilities/functions/functions";
import { isEmailValid } from "../../../../../utilities/functions/functions";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import { stripOutDigits } from "../../../../../utilities/functions/functions";
import { addFirmUser, getAdvisors } from "../../../../../APIs/FundActions";
import { GetWMFirmInfo } from "../../../../../Adapters/FirmInformation";
import { WarningModal } from "../../../../../Components/Modals/WarningModal";
import HorizontalTableContainer from "../../../../../Components/HorizontalTable/HorizontalTableContainer/HorizontalTableContainer";
import InfoIcon from "@mui/icons-material/Info";

function AddFirmUser({ setShowAddFirmUser }) {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState();

  const { wmFirmInfo } = GetWMFirmInfo(setLoading, 1);

  const shareClients = user.firmInfo.shareClients;
  const firmType = user.firmInfo.firmType;
  const [formVals, setFormVals] = useState({
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    role: "",
    roleError: "",
    phoneNumber: "",
    phoneNumberError: "",
    country: "",
    countryError: "",
    email: "",
    emailError: "",
  });

  const [isCustomFee, setIsCustomFee] = useState(false);
  const [managementFee, setManagementFee] = useState("");
  const [managementInvestedFee, setManagementInvestedFee] = useState("");
  const [distributionsFee, setDistributionsFee] = useState("");
  const [selectedDeal, setSelectedDeal] = useState("");
  const incompleteFees = [
    managementFee,
    managementInvestedFee,
    distributionsFee,
  ].includes("");

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [advisors, setAdvisors] = useState([]);

  const [bypassWarning, setBypassWarning] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        // Temporarily use sample data until API is ready
        const response = await getAdvisors();

        console.log(JSON.stringify(response));
        const sampleData = {
          data: [
            {
              id: "1",
              firstName: "John",
              lastName: "Smith",
              email: "john.smith@example.com",
              currentAssociations: 3,
            },
            {
              id: "2",
              firstName: "Sarah",
              lastName: "Johnson",
              email: "sarah.j@example.com",
              currentAssociations: 1,
            },
            {
              id: "3",
              firstName: "Michael",
              lastName: "Brown",
              email: "michael.brown@example.com",
              currentAssociations: 0,
            },
            {
              id: "4",
              firstName: "Emily",
              lastName: "Davis",
              email: "emily.davis@example.com",
              currentAssociations: 2,
            },
            {
              id: "5",
              firstName: "Robert",
              lastName: "Wilson",
              email: "robert.w@example.com",
              currentAssociations: 4,
            },
          ],
        };

        // Later, replace with actual API call
        // const response = await getAdvisors();
        setAdvisors(response.advisors || []);
      } catch (error) {
        console.error("Error fetching advisors:", error);
      }
    };

    fetchAdvisors();
  }, []);

  useEffect(() => {
    console.log("Selected Advisors: " + JSON.stringify(selectedAdvisors));
  }, [selectedAdvisors]);

  const handleCustomFeeToggle = (event) => {
    setIsCustomFee(event.target.checked);
  };

  const handleSelectAllAdvisors = (e) => {
    if (e.target.checked) {
      setSelectedAdvisors(advisors.map((advisor) => advisor.id));
    } else {
      setSelectedAdvisors([]);
    }
  };

  const handleAdvisorSelection = (advisorId) => {
    setSelectedAdvisors((prev) => {
      if (prev.includes(advisorId)) {
        return prev.filter((id) => id !== advisorId);
      }
      return [...prev, advisorId];
    });
  };

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const dropdownOptions = shareClients
    ? [
        { value: "Ops", label: "Ops" },
        { value: "Relationship Manager", label: "Relationship Manager" },
      ]
    : [
        { value: "Ops", label: "Ops" },
        { value: "Advisor", label: "Advisor" },
        ...(user.userRole === "Master"
          ? [{ value: "Co-Advisor", label: "Co-Advisor" }]
          : []),
        ...(firmType === "SS"
          ? [{ value: "Self-Serve", label: "Self-Serve" }]
          : []),
      ];

  const setForm = (key, newVal) => {
    setFormVals((prevState) => ({ ...prevState, [key]: newVal }));
  };

  const submit = async (bypassWarningCheck = false) => {
    if (isSubmitting) return;

    var errors = false;

    if (
      formVals.userRole === "Self-Serve" &&
      (incompleteFees || !isCustomFee || selectedDeal === "")
    ) {
      errors = true;
    }

    if (formVals["firstName"] === "") {
      errors = true;
      setForm("firstNameError", "This field is required.");
    }

    if (formVals["lastName"] === "") {
      errors = true;
      setForm("lastNameError", "This field is required.");
    }

    if (formVals["role"] === "") {
      errors = true;
      setForm("roleError", "This field is required.");
    }

    if (formVals["email"] === "") {
      errors = true;
      setForm("emailError", "This field is required.");
    } else if (!isEmailValid(formVals["email"])) {
      errors = true;
      setForm("emailError", "Please enter a valid email.");
    }

    if (formVals["country"] === "") {
      errors = true;
      setForm("countryError", "This field is required.");
    }

    if (formVals["phoneNumber"] === "") {
      errors = true;
      setForm("phoneNumberError", "This field is required.");
    } else if (!isValidPhoneNumber(formVals["phoneNumber"])) {
      errors = true;
      setForm("phoneNumberError", "Please enter a valid phone number.");
    }

    // If there are any validation errors, return early
    if (errors) {
      return;
    }

    // Only check for no associations if we haven't bypassed the warning
    if (
      !bypassWarningCheck &&
      formVals.role === "Co-Advisor" &&
      selectedAdvisors.length === 0
    ) {
      setShowWarningModal(true);
      return;
    }

    try {
      setIsSubmitting(true);

      const params = {
        firm_id: user.firmInfo.id,
        first_name: formVals.firstName.trim(),
        last_name: formVals.lastName.trim(),
        role: formVals.role.trim(),
        email: formVals.email.trim(),
        phone_number: stripOutDigits(formVals.phoneNumber),
        country: formVals.country.trim(),
        invite: {
          custom_fee: isCustomFee
            ? {
                mgmt: managementFee,
                mgmtInvested: managementInvestedFee,
                carry: distributionsFee,
              }
            : false,
          deal: selectedDeal,
        },
      };

      // Note: We don't add selectedAdvisors to params if there aren't any
      if (formVals.role === "Co-Advisor" && selectedAdvisors.length > 0) {
        params.selectedAdvisors = selectedAdvisors;
      }

      const resp = await addFirmUser(params);
      setSuccess(true);
      setShowAddFirmUser(false);
    } catch (e) {
      setError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="d-flex align-items-center">
          <img
            src="https://d1swyfveypj49y.cloudfront.net/NewBackArrow.svg"
            style={{
              width: "14px",
              height: "14px",
              cursor: "pointer",
              marginRight: "12px",
            }}
            onClick={() => setShowAddFirmUser(false)}
          />
          <h5 className="mt-auto mb-auto">Add User</h5>
        </div>
      </div>
      <div class="row g-0 w-100 add-account-wizard-container">
        <div class="col-12">
          <React.Fragment>
            <React.Fragment>
              <div className="row g-0 w-100 mb-4">
                <div className="col pe-2">
                  <TextInputField
                    value={formVals.firstName}
                    setValue={(newVal) => setForm("firstName", newVal)}
                    valueError={formVals.firstNameError}
                    setValueError={(newVal) =>
                      setForm("firstNameError", newVal)
                    }
                    label={"First Name"}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"individual-account-address-line-1-input"}
                  />
                </div>
                <div className="col pe-0 ps-2">
                  <TextInputField
                    value={formVals.lastName}
                    setValue={(newVal) => setForm("lastName", newVal)}
                    valueError={formVals.lastNameError}
                    setValueError={(newVal) => setForm("lastNameError", newVal)}
                    label={"Last Name"}
                    required={false}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"individual-account-address-line-2-input"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100 mb-4">
                <div className="col pe-2">
                  <SelectInputField
                    setValue={(newVal) => setForm("role", newVal)}
                    value={formVals.role}
                    valueError={formVals.roleError}
                    setValueError={(newVal) => setForm("roleError", newVal)}
                    label={"Role"}
                    selectOptions={dropdownOptions}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"domicile-country-input"}
                  />
                </div>
                <div className="col pe-0 ps-2">
                  <TextInputField
                    value={formVals.email}
                    setValue={(newVal) => setForm("email", newVal)}
                    valueError={formVals.emailError}
                    setValueError={(newVal) => setForm("emailError", newVal)}
                    label={"Email"}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"individual-account-address-line-1-input"}
                  />
                </div>
              </div>

              <div className="row g-0 w-100 mb-4">
                <div className="col pe-2">
                  <SelectInputField
                    setValue={(newVal) => setForm("country", newVal)}
                    value={formVals.country}
                    valueError={formVals.countryError}
                    setValueError={(newVal) => setForm("countryError", newVal)}
                    label={"Country"}
                    selectOptions={countryList}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"domicile-country-input"}
                  />
                </div>
                <div className="col pe-0 ps-2">
                  <PhoneInputField
                    shrink={true}
                    label={"Phone Number"}
                    size={"small"}
                    fullWidth={true}
                    required={true}
                    value={formVals.phoneNumber}
                    setValue={(newVal) => {
                      setForm("phoneNumber", newVal);
                      setForm("phoneNumberError", null);
                    }}
                    valueError={formVals.phoneNumberError}
                    setValueError={(newVal) =>
                      setForm("phoneNumberError", newVal)
                    }
                    disabled={false}
                  />
                  {formVals.phoneNumberError && (
                    <div className="SignupForm-field-error-text mt-1">
                      <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                        {formVals.phoneNumberError}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {formVals && formVals.role && formVals.role === "Self-Serve" && (
                <>
                  <form
                    onSubmit={() => {}}
                    className="tw-space-y-6 pe-2"
                    style={{ paddingBottom: "20px" }}
                  >
                    <h2 className="tw-text-2xl tw-font-semibold tw-text-gray-800">
                      Invite Details
                    </h2>

                    {/* Question 1: Custom Fee Structure */}
                    {/* <div className="tw-flex tw-items-center">
                    <input
                      type="checkbox"
                      checked={isCustomFee}
                      onChange={handleCustomFeeToggle}
                      className="tw-w-20 tw-h-4 tw-mr-2 tw-text-blue-600 tw-border-gray-300 tw-rounded"
                    />
                    <label className="tw-text-gray-700">Is there a custom fee structure?</label>
                  </div> */}

                    {/* Fee Fields */}
                    {isCustomFee && (
                      <div className="tw-space-y-4">
                        {incompleteFees && <>These are all required.</>}
                        <div>
                          <label className="tw-block tw-text-gray-700">
                            Management Fee (%)
                          </label>
                          <input
                            type="number"
                            value={managementFee}
                            onChange={(e) => setManagementFee(e.target.value)}
                            required
                            className="tw-w-20 tw-p-2 tw-border tw-rounded tw-border-gray-300 focus:tw-outline-none focus:tw-border-blue-500"
                          />
                        </div>
                        <div>
                          <label className="tw-block tw-text-gray-700">
                            Management Invested Fee (%)
                          </label>
                          <input
                            type="number"
                            value={managementInvestedFee}
                            onChange={(e) =>
                              setManagementInvestedFee(e.target.value)
                            }
                            required
                            className="tw-w-20 tw-p-2 tw-border tw-rounded tw-border-gray-300 focus:tw-outline-none focus:tw-border-blue-500"
                          />
                        </div>
                        <div>
                          <label className="tw-block tw-text-gray-700">
                            Distributions Fee (%)
                          </label>
                          <input
                            type="number"
                            value={distributionsFee}
                            onChange={(e) =>
                              setDistributionsFee(e.target.value)
                            }
                            required
                            className="tw-w-20 tw-p-2 tw-border tw-rounded tw-border-gray-300 focus:tw-outline-none focus:tw-border-blue-500"
                          />
                        </div>
                      </div>
                    )}

                    {/* Question 2: Deal Selection */}
                    <div>
                      <label className="tw-block tw-text-gray-700">
                        Select a Deal
                      </label>
                      <select
                        value={selectedDeal}
                        onChange={(e) => setSelectedDeal(e.target.value)}
                        required
                        className="tw-w-full tw-p-2 tw-border tw-rounded tw-border-gray-300 focus:tw-outline-none focus:tw-border-blue-500"
                      >
                        <option value="" disabled>
                          -- Choose a Deal --
                        </option>
                        {wmFirmInfo &&
                          wmFirmInfo.deals &&
                          wmFirmInfo.deals.map((deal, index) => (
                            <option value={deal.value}>{deal.label}</option>
                          ))}
                      </select>
                    </div>
                  </form>
                </>
              )}
            </React.Fragment>
          </React.Fragment>

          {formVals.role === "Co-Advisor" && (
            <div className="row g-0 w-100 mb-4">
              <div className="col-12">
                <div className="mb-4">
                  <span className="add-account-step-subheader">
                    Associate with Advisors
                  </span>
                  <p className="text-sm text-gray-500 mt-1">
                    Select the advisors you want to associate with this
                    co-advisor
                  </p>
                </div>

                {advisors.length === 0 ? (
                  <div className="d-flex align-items-center justify-content-center py-8 bg-light rounded">
                    <div className="text-center">
                      <p className="text-muted mb-0">
                        No advisors available for association
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="custom-table-container">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th className="checkbox-column">
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                id="selectAll"
                                onChange={handleSelectAllAdvisors}
                                className="custom-checkbox-input"
                              />
                              <label
                                htmlFor="selectAll"
                                className="custom-checkbox-label"
                              />
                            </div>
                          </th>
                          <th>Advisor Name</th>
                          <th>Email</th>
                          <th>Current Co-Advisors</th>
                        </tr>
                      </thead>
                      <tbody>
                        {advisors.map((advisor) => (
                          <tr
                            key={advisor.id}
                            className={
                              selectedAdvisors.includes(advisor.id)
                                ? "selected"
                                : ""
                            }
                            onClick={() => handleAdvisorSelection(advisor.id)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                e.preventDefault();
                                handleAdvisorSelection(advisor.id);
                              }
                            }}
                          >
                            <td className="checkbox-column">
                              <div className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  id={`advisor-${advisor.id}`}
                                  checked={selectedAdvisors.includes(
                                    advisor.id
                                  )}
                                  onChange={() =>
                                    handleAdvisorSelection(advisor.id)
                                  }
                                  className="custom-checkbox-input"
                                />
                                <label
                                  htmlFor={`advisor-${advisor.id}`}
                                  className="custom-checkbox-label"
                                />
                              </div>
                            </td>
                            <td className="name-column">
                              <div className="advisor-name">
                                <div className="avatar">
                                  {advisor.name
                                    .split(" ")
                                    .map((n) => n[0])
                                    .join("")}
                                </div>
                                <div className="name-details">
                                  <span className="full-name">
                                    {advisor.name}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="email-column">{advisor.email}</td>
                            <td className="associations-column">
                              <div className="association-badge">
                                {advisor.co_advisor_count || 0}{" "}
                                {advisor.co_advisor_count === 1
                                  ? "Co-Advisor"
                                  : "Co-Advisors"}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}

          <MuiButton
            buttonText={isSubmitting ? "Submitting..." : "Submit"}
            onClick={() => submit(false)}
            disabled={isSubmitting}
          />
        </div>
      </div>
      <WarningModal
        isOpen={showWarningModal}
        onClose={() => !isSubmitting && setShowWarningModal(false)}
        onProceed={() => {
          setShowWarningModal(false);
          submit(true);
        }}
        title="No Advisor Associations"
        message={
          <div className="warning-message-container">
            <div className="warning-primary">
              Adding a co-advisor without associating an advisor will mean the
              co-advisor won't be able to perform any client related actions on
              the portal until an advisor is associated to their profile.
            </div>
            <div className="warning-note">
              <InfoIcon className="info-icon" />
              <span>
                This can be done later by managing their associations from the
                manage users tab of the manage firm page.
              </span>
            </div>
          </div>
        }
        isSubmitting={isSubmitting}
      />
      <SnackBar
        snackbarState={error}
        setSnackbarState={setError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Something went wrong."}
      />
      <SnackBar
        snackbarState={success}
        setSnackbarState={setSuccess}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"success"}
        message={"User successfully added."}
      />
    </React.Fragment>
  );
}
export default AddFirmUser;
