import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import { HOST } from "../utilities/host-config/host-config.js";

export const getGlobalDocs = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(HOST + "/global_doc_view_firm", payload, config);
};

export const firmUserDocumentApproval = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(HOST + "/firm-doc-approval", payload, config);
};



export const resendDocumentAPI = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(HOST + "/firm-doc-resend", payload, config);
};
