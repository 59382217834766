import React, { useState, useEffect, useContext } from "react";
// import { useLocation } from "react-router-dom";
//import Header from "../../../Components/GlobalComponents/Header.js";
//import {getAccountSummary } from "./../../../Api/ProfileApi.js";
//import ProfileHeader from "./ProfileHeader.js";
import ProfileBasicInfo from "./ProfileBasicInfo.js";
import InvestorInformation from "./InvestorInformation.js";
//import InvestorInformation from "./InvestorInformation.js";
import ProfilePassword from "./ProfilePassword.js";
import "./Profile.css";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import VerticalTabs from "../VerticalTab/VerticalTabs.js";
import VerticalTab from "../VerticalTab/VerticalTab.js";
//import { UserContext } from "../../../GlobalContexts/UserContext.js";
/*import {
  getInvestorProfile,
} from "../../../Api/ProfileApi.js"; */
//import LoadingAnimation from "../../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation.js";

//import "./IndividualProfileSection/IndividualProfileSectionStyles/IndividualProfileSectionStyles.css";


function Profile(props) {

  const [basicInfoSelected, setBasicInfoSelected] = useState(true);
  const [investorInformationSelected, setInvestorInformationSelected] =
    useState(false);
  const [identityVerificationSelected, setIdentityVerificationSelected] =
    useState(false);
  const [qualifiedPurchaserSelected, setQualifiedPurchaserSelected] =
    useState(false);
  const [trustInformationSelected, setTrustInformationSelected] =
    useState(false);
  const [entityInformationSelected, setEntityInformationSelected] =
    useState(false);
  const [initialState, setInitialState] = useState(0);
  const [passwordSelected, setPasswordSelected] = useState(false);
  //const location = useLocation();
  /*(const [errorMessage, setErrorMessage] = useState(
    location ? (location.state ? location.state.message : "") : ""
  );*/
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [accountSummaryData, setAccountSummaryData] = useState({});
  /*

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
  
    async function setProfileData() {
      const userProfile = await getInvestorProfile(); // profile-pii

      var investorPrimaryAddressLine1 = "";
      var investorPrimaryAddressLine2 = "";
      var investorPrimaryCity = "";
      var investorPrimaryState = "";
      var investorPrimaryCountry = "";
      var investorPrimaryPostalCode = "";
      var investorPrimaryAddressType = "";

      var investorSecondaryAddressLine1 = "";
      var investorSecondaryAddressLine2 = "";
      var investorSecondaryCity = "";
      var investorSecondaryState = "";
      var investorSecondaryCountry = "";
      var investorSecondaryPostalCode = "";
      var investorSecondaryAddressType = "";

      if (userProfile.data[0].investor_addresses.length === 1) {
        investorPrimaryAddressLine1 =
          userProfile.data[0].investor_addresses[0].address_line_1;
        investorPrimaryAddressLine2 =
          userProfile.data[0].investor_addresses[0].address_line_2;
        investorPrimaryCity =
          userProfile.data[0].investor_addresses[0].city;
        investorPrimaryState =
          userProfile.data[0].investor_addresses[0].state;
        investorPrimaryCountry =
          userProfile.data[0].investor_addresses[0].country;
        investorPrimaryPostalCode =
          userProfile.data[0].investor_addresses[0].postal_code;
        investorPrimaryAddressType =
          userProfile.data[0].investor_addresses[0].address_type;
      } else if (userProfile.data[0].investor_addresses.length > 1) {
        userProfile.data[0].investor_addresses.forEach(
          (investor_address) => {
            if (investor_address.address_type === "M") {
              investorSecondaryAddressLine1 =
                investor_address.address_line_1;
              investorSecondaryAddressLine2 =
                investor_address.address_line_2;
              investorSecondaryCity = investor_address.city;
              investorSecondaryState = investor_address.state;
              investorSecondaryCountry = investor_address.country;
              investorSecondaryPostalCode = investor_address.postal_code;
              investorSecondaryAddressType = investor_address.address_type;
            } else if (
              investor_address.address_type === "R" ||
              investor_address === "RM"
            ) {
              investorPrimaryAddressLine1 = investor_address.address_line_1;
              investorPrimaryAddressLine2 = investor_address.address_line_2;
              investorPrimaryCity = investor_address.city;
              investorPrimaryState = investor_address.state;
              investorPrimaryCountry = investor_address.country;
              investorPrimaryPostalCode = investor_address.postal_code;
              investorPrimaryAddressType = investor_address.address_type;
            }
          }
        );
      }
      setUser((user) => ({ 
        ...user,
        userProfileData: userProfile.data[0],
        investorPrimaryAddressLine1: investorPrimaryAddressLine1,
        investorPrimaryAddressLine2: investorPrimaryAddressLine2,
        investorPrimaryCity: investorPrimaryCity,
        investorPrimaryState: investorPrimaryState,
        investorPrimaryCountry: investorPrimaryCountry,
        investorPrimaryPostalCode: investorPrimaryPostalCode,
        investorPrimaryAddressType: investorPrimaryAddressType,
        investorSecondaryAddressLine1: investorSecondaryAddressLine1,
        investorSecondaryAddressLine2: investorSecondaryAddressLine2,
        investorSecondaryCity: investorSecondaryCity,
        investorSecondaryState: investorSecondaryState,
        investorSecondaryCountry: investorSecondaryCountry,
        investorSecondaryPostalCode: investorSecondaryPostalCode,
        investorSecondaryAddressType: investorSecondaryAddressType,
      }));
     setLoading(false);
    }

    setProfileData(); }, []);

  useEffect(() => {
  

    async function fetchAccountSummary() {
      const accountSummaryDataRespUnformatted = await getAccountSummary(user.accountListData[0].id);
      const accountSummaryDataResp = accountSummaryDataRespUnformatted.data[0];
      setAccountSummaryData(accountSummaryDataResp);

      if (
        user.userProfileData.address_complete &&
        user.userProfileData.pii_complete &&
        accountSummaryData && 
        !accountSummaryData.investor_profile_complete
      ) {
        setAccountSummaryData({...accountSummaryData, ...{investor_profile_complete: true}});
      }
      if (
        accountSummaryDataResp.entities.length !== 0 &&
        (accountSummaryDataResp.entities[0].type === "LLC" ||
          accountSummaryDataResp.entities[0].type === "C-CORP" ||
          accountSummaryDataResp.entities[0].type === "S-CORP" ||
          accountSummaryDataResp.entities[0].type === "LP" ||
          accountSummaryDataResp.entities[0].type === "GP" ||
          accountSummaryDataResp.entities[0].type === "FE")
      ) {
        if (
          accountSummaryDataResp.entities[0].address_complete &&
          accountSummaryDataResp.entities[0].tax_info_complete &&
          !accountSummaryDataResp.entity_complete
        ) {
          setAccountSummaryData({...accountSummaryData, ...{entity_complete: true}});
        } else if (
          accountSummaryDataResp.entities[0].address_complete &&
          accountSummaryDataResp.entities[0].tax_info_complete &&
          accountSummaryDataResp.entities[0].related_party_complete &&
          !accountSummaryDataResp.entity_complete
        ) {
          setAccountSummaryData({...accountSummaryData, ...{entity_complete: true}});
        } } };
        fetchAccountSummary();

  }, 
  [user]);


  const { completeProfile, verifyProfile } = location
    ? location.state
      ? location.state
      : {
          completeProfile: "",
          verifyProfile: "",
        }
    : {
        completeProfile: "",
        verifyProfile: "",
      };

  useEffect(() => {
    setCompleted(completeProfile === "yes");
    setVerified(verifyProfile === "yes");
    // console.log("Location state is: " + location ? location.state : "");
  }, []);

  useEffect(() => {
    if (completed) {
      setInitialState(1);
    } else if (verified) {
      setInitialState(3);
    }
  }, [completed, verified]);
  useEffect(() => {
    if (basicInfoSelected) {
      setInvestorInformationSelected(false);
      setIdentityVerificationSelected(false);
      setQualifiedPurchaserSelected(false);
      setTrustInformationSelected(false);
      setPasswordSelected(false);
    }
  }, [basicInfoSelected]);

  useEffect(() => {
    if (investorInformationSelected) {
      setBasicInfoSelected(false);
      setIdentityVerificationSelected(false);
      setQualifiedPurchaserSelected(false);
      setTrustInformationSelected(false);
      setPasswordSelected(false);
    }
  }, [investorInformationSelected]);

  useEffect(() => {
    if (identityVerificationSelected) {
      setInvestorInformationSelected(false);
      setBasicInfoSelected(false);
      setQualifiedPurchaserSelected(false);
      setTrustInformationSelected(false);
      setPasswordSelected(false);
    }
  }, [identityVerificationSelected]);

  useEffect(() => {
    if (qualifiedPurchaserSelected) {
      setInvestorInformationSelected(false);
      setIdentityVerificationSelected(false);
      setBasicInfoSelected(false);
      setTrustInformationSelected(false);
      setPasswordSelected(false);
    }
  }, [qualifiedPurchaserSelected]);

  useEffect(() => {
    if (passwordSelected) {
      setInvestorInformationSelected(false);
      setIdentityVerificationSelected(false);
      setQualifiedPurchaserSelected(false);
      setTrustInformationSelected(false);
      setBasicInfoSelected(false);
    }
  }, [passwordSelected]);

  useEffect(() => {
    if (trustInformationSelected) {
      setInvestorInformationSelected(false);
      setIdentityVerificationSelected(false);
      setQualifiedPurchaserSelected(false);
      setBasicInfoSelected(false);
    }
  }, [trustInformationSelected]);

  */

  return (
    <React.Fragment>
      {/* <Header activePage={""}></Header> */ } 
      <div className="container-xxl container-fluid w-sm-75 w-100 mt-3 pt-5 justify-content-center">
        <div className="row g-0 w-100 justify-content-center mb-5">
          <div className="col-md-10 col-sm-11 col-12">
           { <div className="col-12 mt-5 title-text mb-4">My Profile</div>} 
  </div> 

          <VerticalTabs initial={initialState}>
      <VerticalTab
        title={"Basic Info"}
        selected={basicInfoSelected}
        setSelected={setBasicInfoSelected}
      >
        <ProfileBasicInfo /> 
      </VerticalTab>
      <VerticalTab
        title={"Advisor Information"}
        selected={investorInformationSelected}
        setSelected={setInvestorInformationSelected}
      >
        <InvestorInformation />
       { /* !loading ?  <InvestorInformation
          completed={completed}
          setCompleted={setCompleted}
          verified={verified}
          setVerified={setVerified}
          accountSummaryData={accountSummaryData}
          setAccountSummaryData={setAccountSummaryData}
  /> : <LoadingAnimation loading={true} /> */ } 
      </VerticalTab>
      <VerticalTab
        title={"Password"}
        selected={passwordSelected}
        setSelected={setPasswordSelected}
      >
         <ProfilePassword /> 
      </VerticalTab>
    </VerticalTabs>


        </div>
      </div>
    </React.Fragment>
  );
}

export default Profile;
