import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import "./Styles/Styles.scss";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import UserTable from "../UserTable/UserTable";
import useMasterOpsFirmUserSearch from "../../../../../APIs/useMasterOpsFirmUserSearch";
import { DataGridPro } from "@mui/x-data-grid-pro";
import ManageAssociations from "../ManageAssociations/ManageAssociations.js";
import { UserContext } from "../../../../../Contexts/UserContext";

function ViewUsers({ setShowAddFirmUser }) {
  const [firmUserQuery, setFirmUserQuery] = useState("");
  const [firmUserData, setFirmUserData] = useState([]);
  const [firmUserLoading, setFirmUserLoading] = useState(true);
  const { firmUsers } = useMasterOpsFirmUserSearch(
    firmUserQuery,
    setFirmUserLoading
  );
  const navigate = useNavigate();
  const [showAssociationsModal, setShowAssociationsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { user: currentUser } = useContext(UserContext);
  const isCurrentUserMaster = currentUser?.userRole === "Master";

  const columns = () => [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "email",
      text: "Email",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      headerStyle: { width: "33%" },
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "role",
      text: "Role",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
  ];

  const newCols = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    ...(isCurrentUserMaster
      ? [
          {
            field: "associations",
            headerName: "Associations",
            headerClassName: "stylish-header",
            flex: 1,
            filterable: false,
            hideSortIcons: true,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: (params) => {
              const user = params.row;
              if (!user) return null;

              if (user.role !== "Advisor" && user.role !== "Co-Advisor") {
                return "";
              }

              if (
                user.role === "Co-Advisor" &&
                user.associations?.advisors?.length > 0
              ) {
                return (
                  <div className="flex items-center gap-2">
                    <span className="text-sm bg-blue-50 px-2 py-1 rounded">
                      {user.associations.advisors.length} Advisors
                    </span>
                  </div>
                );
              }

              if (
                user.role === "Advisor" &&
                user.associations?.specialists?.length > 0
              ) {
                return (
                  <div className="flex items-center gap-2">
                    <span className="text-sm bg-gray-50 px-2 py-1 rounded">
                      {user.associations.specialists.length} Co-Advisors
                    </span>
                  </div>
                );
              }

              return "";
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            headerClassName: "stylish-header",
            flex: 1,
            renderCell: (params) => {
              const user = params.row;
              if (user.role === "Co-Advisor" || user.role === "Advisor") {
                return (
                  <MuiButton
                    buttonText="Manage Associations"
                    onClick={() => handleManageAssociations(user)}
                    size="small"
                  />
                );
              }
              return null;
            },
          },
        ]
      : []),
  ];

  const refreshUserData = () => {
    setFirmUserLoading(true);
    setFirmUserQuery((prevQuery) => prevQuery + " ");
  };

  const handleManageAssociations = (user) => {
    setSelectedUser(user);
    setShowAssociationsModal(true);
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <h5>Manage Users</h5>
      </div>
      <div className="row g-0 w-100 mb-4 align-items-center">
        <div style={{ width: "85%" }}>
          <SearchBar
            setValue={setFirmUserQuery}
            placeholder={"Search by full name, etc"}
          />
        </div>
        <div
          className="ms-auto me-0 justify-self-end justify-content-end d-flex"
          style={{ maxWidth: "120px" }}
        >
          <MuiButton
            buttonText={"Add User"}
            onClick={() => setShowAddFirmUser(true)}
            size={"large"}
          />
        </div>
      </div>
      <div
        className="row g-0 w-100"
        style={{
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        {/* <UserTable data={firmUsers} columns={columns} /> */}
        <DataGridPro
          sx={{
            height: "400px",
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              borderRadius: "6px",
            },
          }}
          rows={firmUsers}
          columns={newCols}
          loading={firmUserLoading}
          rowHeight={54}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
        />
      </div>

      {showAssociationsModal && selectedUser && (
        <ManageAssociations
          user={selectedUser}
          onClose={() => {
            setShowAssociationsModal(false);
            setSelectedUser(null);
          }}
          onSave={async (associations) => {
            // await updateAssociations(associations);
            refreshUserData();
            setShowAssociationsModal(false);
            setSelectedUser(null);
          }}
        />
      )}
    </React.Fragment>
  );
}
export default ViewUsers;
