import React, { useState, useEffect } from "react";
//import "./Styles/Styles.scss";
import HelloSign from "hellosign-embedded";
import {
  getSubdocLinkFromInv
} from "../../APIs/WealthManagementAPI";
import SnackBar from "./../../Components/Alerts/SnackBar/SnackBar";
import MuiButton from "./../../Components/Buttons/MuiButton/MuiButton";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../Components/LoadingAnimation/LoadingAnimation";
import Spinner1 from "../../Components/Spinners/Spinner1";
import TextInputField from "../../Components/InputFields/TextInputField/TextInputField";
import { TurnLeft } from "@mui/icons-material";

function SubDocs({accountId, fundId, setSigComplete, isPrefill}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const clientId =
    process.env.REACT_APP_API_ENDPOINT ===
    "https://api.staging.investwithaqua.com/"
      ? "9c4a35463ca1aaffef6e10f29104b2b4"
      : process.env.REACT_APP_API_ENDPOINT === "https://api.investwithaqua.com/"
      ? "78ef255f844acfc6eb47c48e71e28bb2"
      : process.env.REACT_APP_API_ENDPOINT ===
          "https://api.wm-demo.investwithaqua.com/" ||
        process.env.REACT_APP_API_ENDPOINT ===
          "https://api.wm-demo.investwithaqua.com"
      ? "1eb021ef3831c851cffe3ad1f80e94ee"
      : "ee92b78dc5a4c9124758eb38095d1e82";

  const client = new HelloSign({
    clientId: clientId,
    element: document.getElementById("dropbox-sign-client"),
    skipDomainVerification:
      process.env.REACT_APP_API_ENDPOINT !== "https://api.investwithaqua.com"
        ? true
        : false,
  });

  const callSubDocsEndpoint = async () => {
    try {
      setError(false);

      const resp = await getSubdocLinkFromInv(accountId, fundId, (isPrefill ? 'prefill' : 'sig'));
      if (
        resp.data.doc !== null &&
        resp.data.doc !== undefined &&
        resp.data.doc === "Signed"
      ) {
      } else {
        if (resp.data.msg === "Link is invalid.") {
          setError(true);
        } 
        else if (!["Enqueued", "Waiting"].includes(resp.data.msg)) {
          if (!resp.data.msg.startsWith("https://aqua-api")) {
            client.open(resp.data.msg, {
              clientId: clientId,
              container: document.getElementById("dropbox-sign-client"),
            });

            client.on("finish", () => {
              window.close();
              setSigComplete(true);
              // Make API call to transition to PF
            });
          }
          return true; 
        }
      }
    } catch (e) {
      setError(true);
      return true; 
    }
  };



  useEffect(() => {

    const interval = setInterval(async () => {
      const shouldStop = await callSubDocsEndpoint(interval); // Pass the interval ID to the polling function
      if (shouldStop) {
        clearInterval(interval); // Stop polling if the function indicates so
        setLoading(false);
      }
    }, 2000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);
  

  return (
    <>
    This may take up to 30 seconds to load. 
      {!loading ? 
                  <div id="dropbox-sign-client" /> : <LoadingAnimation loading={true}/> } 
                  
        </>
    );
}
export default SubDocs;
