import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import * as dayjs from "dayjs";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton";

var utc = require("dayjs/plugin/utc");

const InvestmentDetails = ({
  investorFundInvestmentInfo,
  navigate,
  setSelectedTab,
  id,
}) => {
  const [investmentStatus, setInvestmentStatus] = useState("");

  useEffect(() => {
    if (
      investorFundInvestmentInfo &&
      Object.keys(investorFundInvestmentInfo).length !== 0
    ) {
      let statuses = {
        P: "Pending Approval",
        PD: "Pending Sub-Docs",
        PF: "Pending Funding",
        F: "Funded",
      };
      setInvestmentStatus(statuses[investorFundInvestmentInfo.status]);
    }
  }, [investorFundInvestmentInfo]);

  return (
    <div className="container p-3">
      <div className="row g-0 w-100 mb-3">
        <div className="col-12">
          <div className="row g-0 w-100">
            <h4>Investment Details</h4>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-lg-7 col-12 h-100 pt-0">
              <div className="row g-0 w-100 h-100 border-right">
                <div className="col-12">
                  <div className="row g-0 w-100">
                    <div className="col-xxl-9 col-11 ps-0">
                      <div className="row gx-0 w-100 mb-2">
                        <span className="pt-2 pb-3 body-text-lg-semibold">
                          Congratulations, we received your investment request.
                        </span>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <span className="body-text-md-regular">
                          Your account may be pending a KYC/AML check. Once we
                          have successfully confirmed your KYC/AML status,
                          someone from Aqua will reach out to you with the
                          subscription documents for this opportunity, which you
                          should fill out promptly.
                        </span>
                      </div>
                      <div className="row g-0 w-100 mb-4">
                        <span className="body-text-md-regular">
                          You will not be guaranteed an allocation in the
                          opportunity until you have completed the subscription
                          documents.
                        </span>
                      </div>
                      <div className="row g-0 w-100">
                        <span className="body-text-md-semibold">
                          If you don't hear from us within 48 hours, contact us
                          at{" "}
                          <a
                            href="mailto:admin@investwithaqua.com"
                            className="body-text-md-medium"
                          >
                            admin@investwithaqua.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="row g-0 w-100 justify-content-lg-center pb-lg-0 pb-3">
                <div className="col-lg-10 col-12 pt-2 pe-lg-0 pe-4">
                  <div className="row g-0 w-100 mb-2">
                    <span className="body-text-lg-medium pb-3">
                      Investment Details
                    </span>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <span className="body-text-md-medium">Selected:</span>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-12 rounded-edges">
                      <div className="row g-0 w-100 align-items-center">
                        <div className="col-xl-2 col-lg-3 col-sm-2 col-3">
                          <img
                            src="https://d1swyfveypj49y.cloudfront.net/ThamesvilleLogo.svg"
                            className="ms-2 mt-2 mb-2"
                            height="40"
                            alt="logo"
                          ></img>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-sm-8 col-7">
                          <div className="row g-0 w-100">
                            <span className="body-text-sm-medium">
                              {investorFundInvestmentInfo &&
                              Object.keys(investorFundInvestmentInfo).length !==
                                0
                                ? investorFundInvestmentInfo.fundName
                                : "Loading..."}
                            </span>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex justify-content-lg-center justify-content-end me-lg-0 me-2">
                            <img
                              src="https://d1swyfveypj49y.cloudfront.net/SideArrowButton.svg"
                              height="36"
                              alt="logo"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigate("/fund/" + id)}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col-12">
                      <div className="d-flex">
                        <span className="body-text-md-medium justify-self-start">
                          Date:
                        </span>
                        <span className="body-text-md-semibold justify-self-end ms-auto me-0 text-end">
                          {investorFundInvestmentInfo &&
                          Object.keys(investorFundInvestmentInfo).length !== 0
                            ? dayjs(
                                investorFundInvestmentInfo.investmentDate
                              ).format("MMM D, YYYY")
                            : "...loading"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col-12">
                      <div className="d-flex">
                        <span className="body-text-md-medium justify-self-start">
                          Amount:
                        </span>
                        <span className="body-text-md-semibold justify-self-end ms-auto me-0 text-end">
                          {investorFundInvestmentInfo &&
                          Object.keys(investorFundInvestmentInfo).length !== 0
                            ? "$" +
                              investorFundInvestmentInfo.investmentAmount.toLocaleString(
                                "en-us"
                              )
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col-12">
                      <div className="d-flex">
                        <span className="body-text-md-medium justify-self-start">
                          Status:
                        </span>
                        <span className="body-text-md-semibold justify-self-end ms-auto me-0 text-end">
                          {investorFundInvestmentInfo &&
                          Object.keys(investorFundInvestmentInfo).length !== 0
                            ? investmentStatus
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100">
            <div className="d-flex">
              <MuiButton
                buttonText={"Invest for another client"}
                onClick={() => setSelectedTab(0)}
                size={"large"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InvestmentDetails;
