import React, { useState, useEffect, useContext } from "react";
import "./ProfilePasswordStyles.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import ProfilePasswordField from "../InputFields/ProfilePasswordField/ProfilePasswordField.js";
//import { isPasswordValid } from "../../../GlobalFunctions/globalFunctions.js";
import { Auth } from "aws-amplify";
import { UserContext } from "../../Contexts/UserContext.js";
import MuiButton  from "../Buttons/MuiButton/MuiButton"
import PasswordInputField from "../InputFields/PasswordInputField/PasswordInputField"


const isPasswordValid = () => { }
function ProfilePassword() {
  const [resetPassword, setResetPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const { user } = useContext(UserContext);

  useEffect(() => {
    setNewPasswordError("");
  }, [newPassword]);

  useEffect(() => {
    setOldPasswordError("");
  }, [oldPassword]);

  useEffect(() => {
    setConfirmNewPasswordError("");
  }, [confirmNewPassword]);

  const submit = async () => {
    var errorsDetected = false;
    var changesDetected = false;

    if (oldPassword.length === 0) {
      setOldPasswordError("This field is required.");
      errorsDetected = true;
    }
    if (newPassword.length === 0) {
      setNewPasswordError("This field is required.");
      errorsDetected = true;
    } else if (!isPasswordValid(newPassword)) {
      setNewPasswordError(
        "Your password must be longer than 10 characters and contain at least one number, an uppercase, a lowercase, and a special character."
      );
      errorsDetected = true;
    }
    if (confirmNewPassword.length === 0) {
      setConfirmNewPasswordError("This field is required.");
      errorsDetected = true;
    } else if (confirmNewPassword !== newPassword) {
      setConfirmNewPasswordError("Your confirm password does not match.");
      errorsDetected = true;
    }

    if (errorsDetected) {
      return;
    } else {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(currentUser, oldPassword, newPassword);
        setResetPassword(false);
      } catch (err) {
        setResetPasswordError("Password change failed. Try Again.");
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid w-100 ps-sm-5 ps-3 pe-sm-3 pe-2">
        <div className="row g-0 w-100">
          <div className="col-lg-11 col-12">
            <div className="row g-0 w-100 mt-3 mb-3">
              <div className="col-12">
              <div class="col-12">
                <h4>Change password</h4>
                {resetPassword ? <span class="subtitle-text">Please enter a new password</span> : <></>}
                </div>
           </div>
            </div>

            {resetPassword ? (
              <div className="row g-0 w-100">
                <div className="col-12">
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-12">
                      <div className="align-self-end mt-1">
              <PasswordInputField
                value={oldPassword}
                label={"Old Password"}
                setValue={setOldPassword}
                valueError={oldPasswordError}
                setValueError={setOldPasswordError}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"confirm-password-input"}
              />
                        {oldPasswordError &&
                          oldPasswordError !== "This field is required." && (
                            <div className="SignupForm-field-error-text mt-1">
                              <span className="">{oldPasswordError}</span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-12">
                      <div className="align-self-end mt-1">
                      <PasswordInputField
                value={newPassword}
                label={"New Password"}
                setValue={setNewPassword}
                valueError={newPasswordError}
                setValueError={setNewPasswordError}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"confirm-password-input"}
              />

                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-12">
                      <div className="align-self-end mt-1">
                      <PasswordInputField
                value={confirmNewPassword}
                label={"Confirm Password"}
                setValue={setConfirmNewPassword}
                valueError={confirmNewPasswordError}
                setValueError={setConfirmNewPasswordError}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"confirm-password-input"}
              />
                        {confirmNewPasswordError &&
                          confirmNewPasswordError !==
                            "This field is required." && (
                            <div className="SignupForm-field-error-text mt-1">
                              <span className="">
                                {confirmNewPasswordError}
                              </span>
                            </div>
                          )}
                        {resetPasswordError &&
                          resetPasswordError !== "This field is required." && (
                            <div className="SignupForm-field-error-text mt-1 align-self-end">
                              <span className="">{resetPasswordError}</span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="d-flex">
                      <div className="justify-self-start align-self-center">
                        <MuiButton
                          type="button"
                          buttonText={"Submit"}
                          class="btn btn-lg btn-class"
                          onClick={async () => submit()}
                        />
                      </div>
                      <span
                        className="cancel-text ps-5 justify-self-end align-self-center"
                        onClick={() => {
                          setResetPassword(false);
                        }}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row g-0 w-100">
                <div className="d-flex">
                  <MuiButton
                    buttonText={"Change password"}
                    size={"large"}
                    loading={false}
                    onClick={() => setResetPassword(true)} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProfilePassword;
