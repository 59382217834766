import React, { useEffect, useState, useContext } from "react";
import { aggViews } from "./../../APIs/WealthManagementAPI";
import BootstrapTable from "react-bootstrap-table-next";
import { UserContext } from "../../Contexts/UserContext.js";
import "./Styles/Styles.scss";

import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { DataGridPro } from "@mui/x-data-grid-pro";

const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  // ....
  return page === "<" ? (
    <img class="arrow" src="/Left-Arrow.svg" onClick={handleClick} />
  ) : page === ">" ? (
    <img class="arrow" src="/Right-Arrow.svg" onClick={handleClick} />
  ) : (
    <li className="page-item" onClick={handleClick}>
      <span href="#" class={active ? "active-page" : "inactive-page"}>
        {" "}
        {page}{" "}
      </span>
    </li>
  );
};

function moneyFormatter(cell) {
  if (cell.value === undefined || cell.value === null) {
    return "$ 0";
  }
  var integer = (Math.round(parseFloat(cell.value)*100)/100).toFixed(2);;
  return "$ " + integer.toLocaleString("en-US");
}

const options = {
  pageButtonRenderer,
  sizePerPage: 10,
};

const AdvisorByAdvisor = () => {
  const { user } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(true);
  const query = "";
  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "advisor",
      headerName: "Advisor Email",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "investment_amount",
      headerName: "Invested",
      headerClassName: "stylish-header",
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "distributions",
      headerName: "Distributed",
      headerClassName: "stylish-header",
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "capital_calls",
      headerName: "Capital Called",
      headerClassName: "stylish-header",
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "marked_value",
      headerName: "Marked value",
      headerClassName: "stylish-header",
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
  ];
  const noResultsMessage = "None";
  const showButton = false;

  const [data, setData] = useState(null);
  const [newRows, setNewRows] = useState([]);

  const fetchData = async () => {
    setResultsLoading(true);
    const resp = await aggViews("advisor", user.firmInfo.id);
    if (resp.data.msg === "No investments") {
      setData(null);
      setRows([]);
      setResultsLoading(false);
    } else {
      setData(resp.data.advisorData);
      let uniqueRows = [];

      if (
        resp.data !== null &&
        resp.data !== undefined &&
        resp.data.advisorData !== null &&
        resp.data.advisorData !== undefined
      ) {
        uniqueRows = resp.data.advisorData.map((item, idx) => {
          return {
            id: idx,
            ...item,
          };
        });
      }
      setRows(uniqueRows);
      setResultsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <div className="w-98"> */}
      <DataGridPro
        sx={{
          height: "400px",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            borderRadius: "6px",
          },
        }}
        rows={rows}
        columns={columns}
        loading={resultsLoading}
        rowHeight={54}
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
      />
      {/* <BootstrapTable
          pagination={paginationFactory(options)}
          keyField="id"
          data={rows}
          columns={columns(selectRow)}
          bordered={false}
          rowClasses={"table-row-style"}
        />

        {rows.length === 0 && (
          <div className=" w-100 pt-5 pb-5 text-center">
            <span className="no-transactions-text">{noResultsMessage}</span>
          </div>
        )} */}
      {/* </div> */}
    </>
  );
};
export default AdvisorByAdvisor;
