import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import Card from "@mui/material/Card";
import { Box } from "@mui/material";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton";
import {
  DataGridPro,
  useGridSelector,
  useGridApiRef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import { GetAccountVerificationDocuments } from "../../../../Adapters/ClientDetails";
import Spinner1 from "../../../../Components/Spinners/Spinner1";
import TextInputField from "../../../../Components/InputFields/TextInputField/TextInputField";
import UploadButton from "../../../../Components/Buttons/UploadButton/UploadButton";
import { Modal } from "react-bootstrap";
import { uploadAccountVerificationDocuments } from "../../../../APIs/ClientDetailsAPI";

const VerificationDocs = ({ id }) => {
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(true);
  //   const [docList, setDocList] = useState([
  //     { category: "Proof of Address" },
  //     { category: "Certificate of Incorporation" },
  //     { category: "Original List of Authorized Signatories" },
  //     { category: "Corporate Profile" },
  //   ]);

  //   const [message, setMessage] = useState(
  //     "https://docs.google.com/document/d/15l9FUCpPqccpsPVX_Rz49bb48aj0M8iS/edit?usp=drive_link&ouid=108056703584761450473&rtpof=true&sd=true"
  //   );
  const [documentData, setDocumentData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [docName, setDocName] = useState("");
  const [docNameError, setDocNameError] = useState("");

  const [docCategory, setDocCategory] = useState("");
  const [docCategoryError, setDocCategoryError] = useState("");

  const [docFile, setDocFile] = useState("");

  const {
    message,
    docList,
    accountVerificationDocuments,
    accountVerificationDocumentRequestStatus,
  } = GetAccountVerificationDocuments(id, setLoading, refresh);

  var columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "uploadDate",
      headerClassName: "stylish-header",
      headerName: "Upload Date",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "status",
      headerClassName: "stylish-header",
      headerName: "Status",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerClassName: "stylish-header",
      headerName: "View Document",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      field: "viewDocument",
      renderCell: (params) => {
        return (
          params.row.docLink !== null &&
          params.row.docLink !== undefined &&
          params.row.docLink !== "" && (
            <MuiButton
              size="small"
              buttonText={"View Document"}
              onClick={() => window.open(params.row.docLink, "_blank")}
            />
          )
        );
      },
    },
  ];

  const handleFileUpload = (event, inputName) => {
    // console.log("Event is: " + JSON.stringify(event.target.files[0]));
    const reader = new FileReader();
    reader.onloadend = () => {
      const b64 = reader.result.split(",")[1];

      setDocFile(b64);
    };
    reader.readAsDataURL(event.target.files[0]);
    // console.log("Event later is: " + JSON.stringify(event.target.files[0]));
  };

  const submitDocumentUpload = async () => {
    var errors = false;

    if (docName === "") {
      setDocNameError("Required");
      errors = true;
    }

    if (docCategory === "") {
      setDocCategoryError("Required");
      errors = true;
    }

    if (docFile === "") {
      errors = true;
    }

    if (errors === false) {
      try {
        await uploadAccountVerificationDocuments({
          client_id: id,
          name: docName,
          category: docCategory,
          b64_string: docFile,
        });
        setDocName("");
        setDocCategory("");
        setDocFile("");
        setRefresh((refresh) => refresh + 1);
        setModalOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <Spinner1 />
      ) : (
        <div className="container ps-3 pe-3">
          <div className="row g-0 w-100 mb-2">
            <h5>Verification Docs</h5>
          </div>
          {accountVerificationDocumentRequestStatus !== "" &&
          accountVerificationDocumentRequestStatus !== "F" ? (
            <div className="row g-0 w-100 justify-content-center mb-2">
              <div className="d-flex">
                <Box>
                  <Card variant="outlined" sx={{ backgroundColor: "#f3f3f3" }}>
                    <div className="p-4">
                      <div className={"row g-0"}>
                        <h6>
                          The Aqua team requests the following verification
                          documents:
                        </h6>
                      </div>
                      {docList !== null &&
                        docList !== undefined &&
                        docList.length !== 0 && (
                          <ul>
                            {docList.map((doc, index) => {
                              return (
                                <li className="mt-1 mb-1" key={index}>
                                  <span>{doc.category}</span>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      {message !== null &&
                        message !== undefined &&
                        message !== "" && (
                          <div className="row g-0 w-100 mt-2">
                            <div className="d-flex">
                              <MuiButton
                                buttonText={"View Full KYC Requirements"}
                                onClick={() => window.open(message, "_blank")}
                                size={"small"}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </Card>
                </Box>
              </div>
            </div>
          ) : (
            <div className="row g-0 w-100 justify-content-center mb-2">
              <div className="d-flex">
                <Box>
                  <Card variant="outlined" sx={{ backgroundColor: "#f3f3f3" }}>
                    <div className="p-4">
                      <div className="row g-0">
                        <div className="d-flex align-items-center">
                          <div className="me-2">
                            <img
                              src="https://d1swyfveypj49y.cloudfront.net/correct.svg"
                              width={25}
                              alt="Magnifier"
                            />
                          </div>
                          <div>
                            <span className="text-md-regular">
                              The Aqua team doesn't require any additional
                              verification documents at this time.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Box>
              </div>
            </div>
          )}
          <div className="row g-0 w-100 mt-5 mb-3">
            <h6>Uploaded Documents</h6>
          </div>
          <div className="row g-0 w-100 mb-3">
            <DataGridPro
              apiRef={apiRef}
              sx={{
                height: "400px",
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "&.MuiDataGrid-root": {
                  borderRadius: "6px",
                },
              }}
              columns={columns}
              rows={accountVerificationDocuments}
              loading={loading}
              rowHeight={54}
              disableColumnMenu={true}
              disableRowSelectionOnClick={true}
              // getDetailPanelContent={(params) => <WireCard props={params.row} />}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
                    id: false,
                  },
                },
              }}
            />
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="d-flex">
              <MuiButton
                size="large"
                buttonText={"Upload Document"}
                onClick={() => setModalOpen(true)}
                disabled={false}
              />
            </div>
          </div>
          <div className="row g-0 w-100">
            <Modal
              show={modalOpen}
              onHide={() => {
                setDocName("");
                setDocCategory("");
                setDocFile("");
                setModalOpen(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                {/* <div className="container p-4"> */}
                <React.Fragment>
                  <div className="row g-0 w-100 mt-3 mb-3">
                    <div className="col pe-2">
                      <TextInputField
                        value={docName}
                        setValue={setDocName}
                        setValueError={setDocNameError}
                        valueError={docNameError}
                        label={"Document Name"}
                        required={true}
                        fullWidth={true}
                        size={"small"}
                        disabled={false}
                        shrink={true}
                        id={"document-name-input"}
                      />
                    </div>
                    <div className="col ps-2 pe-0">
                      <TextInputField
                        value={docCategory}
                        setValue={setDocCategory}
                        setValueError={setDocCategoryError}
                        valueError={docCategoryError}
                        label={"Document Category"}
                        required={true}
                        fullWidth={true}
                        size={"small"}
                        disabled={false}
                        shrink={true}
                        id={"document-category-input"}
                      />
                    </div>
                  </div>
                  <div className="row g-0 w-100">
                    {docFile === "" ? (
                      <UploadButton
                        onChange={handleFileUpload}
                        inputField={"accountVerificationDocument"}
                        label={"acct" + id}
                      />
                    ) : (
                      <span>File Uploaded</span>
                    )}
                  </div>
                </React.Fragment>

                {/* </div> */}
              </Modal.Body>

              <Modal.Footer>
                <div className="d-flex">
                  <div className="me-3">
                    <MuiButton
                      size="small"
                      buttonText={"Submit"}
                      onClick={async () => {
                        await submitDocumentUpload();
                        //   await approveDocument(selected.id);
                      }}
                      disabled={false}
                    />
                  </div>
                  <div>
                    <MuiButton
                      size="small"
                      buttonText={"Close"}
                      onClick={() => {
                        setDocName("");
                        setDocCategory("");
                        setDocFile("");
                        setModalOpen(false);
                        //   setSelectedDocLink(null);
                      }}
                    />
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default VerificationDocs;
