import React from "react";
import "./Styles/Styles.scss";
import AggViewsMain from "../../AggViews/AggViewsMain";

function MasterOpsPortfolio() {
  return (
    <div className="container-xxl pt-5 mt-5">
      <AggViewsMain />
    </div>
  );
}
export default MasterOpsPortfolio;
