export const firmList = [
  {
    value: "Morgan Stanley Wealth Management",
    label: "Morgan Stanley Wealth Management",
  },
  {
    value: "Jefferies Wealth Management",
    label: "Jefferies Wealth Management",
  },
  {
    value: "Thamesville Capital Wealth Management",
    label: "Thamesville Capital Wealth Management",
  },
];
