import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import styles from "../../../utilities/scss/_variables.scss";

const PhoneInputField = ({
  setValue,
  valueError,
  value,
  disabled,
  shrink,
  label,
  size,
  required,
  fullWidth,
  ...props
}) => {
  //Styles variable assignment from exported sass variable values
  const primaryFontFamily = styles.primaryFontFamily;
  const primaryBlack2 = styles.primaryBlack2;
  const primaryBlue = styles.primaryBlue;
  const secondaryOffWhite = styles.secondaryOffWhite;
  const secondaryRed = styles.secondaryRed;
  const fontSize3 = styles.fontSize3;

  function handleChange(updatedValue) {
    setValue(updatedValue);
  }

  return (
    <MuiPhoneNumber
      defaultCountry={"us"}
      onChange={handleChange}
      disabled={disabled}
      size={size}
      value={value}
      label={label}
      required={required}
      fullWidth={fullWidth}
      InputLabelProps={{ shrink: shrink }}
      variant={"outlined"}
      sx={{
        "& .MuiInputLabel-root": {
          fontFamily: primaryFontFamily,
          color: primaryBlack2,
          fontSize: fontSize3,
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: primaryBlue,
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          "& > fieldset": {
            borderColor: primaryBlue,
            color: primaryBlue,
          },
        },
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            borderColor: secondaryOffWhite,
          },
        },
        "& .MuiOutlinedInput-root.Mui-disabled": {
          "& > fieldset": {
            borderColor: secondaryOffWhite,
          },
        },
        "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
          "& > fieldset": {
            borderColor: valueError === "" ? secondaryOffWhite : secondaryRed,
          },
        },
      }}
    />
  );
};

export default PhoneInputField;
