import React from "react";
import "./Styles/Styles.scss";
import HorizontalTableRowDivider from "../HorizontalTableRowDivider/HorizontalTableRowDivider";
import HorizontalTableRow from "../HorizontalTableRow/HorizontalTableRow";

function HorizontalTableContainer(props) {
  return (
    <div className="row g-0 w-100">
      <div className="col-12 table-container">
        {props.children.map(
          (item, index) =>
            item &&
            (index === props.children.length - 1 ? (
              <HorizontalTableRow
                rowName={item.props.rowName}
                rowValue={item.props.rowValue}
              />
            ) : (
              <React.Fragment>
                <HorizontalTableRow
                  rowName={item.props.rowName}
                  rowValue={item.props.rowValue}
                />
                <HorizontalTableRowDivider />
              </React.Fragment>
            ))
        )}
      </div>
    </div>
  );
}
export default HorizontalTableContainer;
