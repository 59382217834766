import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../../Contexts/UserContext";
import "./Styles/Styles.scss";
import { useNavigate } from "react-router-dom";
import ClientInformation from "./ClientInformation/ClientInformation";
import AccountInformation from "./AccountInformation/AccountInformation";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton";
import { isEmailValid } from "../../../../utilities/functions/functions";
import GooglePlacesAutocomplete from "../../../../Components/GooglePlacesAutocomplete";

import { isValidSSN } from "../../../../utilities/functions/functions";
import { isDobValid } from "../../../../utilities/functions/functions";
import { getAdvisorList } from "../../../../APIs/AddFirmClientAPI";
import { stripOutDigits } from "../../../../utilities/functions/functions";
import DynamicDropdown from "../../../../Components/DynamicDropdown/DynamicDropdown";
import SnackBar from "../../../../Components/Alerts/SnackBar/SnackBar";
import { createFirmClient } from "../../../../APIs/AddFirmClientAPI";
import QPModal from "../../../../Components/Qualification/QPModal.js"


function AccreditedInvestorQualification({ questions, setQuestions, acceptableQuestions }) {

  return (
    <>
      {(questions.length !== 0) && <><br /> <h6>Qualification Questionnaire</h6></>}
      {(questions).map((question) => {
        return (
          <div className="row g-0 w-100 mb-3">
            <div className="row g-0 w-100 mb-2">
              <span className="body-text-sm-semibold mb-1">
                {
                  question.text
                }
              </span>
            </div>
            <div className="row g-0 w-100 mb-2 fade-in-test">
              <div className="col-6">
                <label for={question.q_id + 'yes'} className={"radio"}>
                  <input
                    type={"radio"}
                    name={question.q_id}
                    id={question.q_id + 'yes'}
                    className={"radio__input"}
                    checked={question.answer === true}
                    onClick={() => {
                      setQuestions((state) => state.map(
                        function (q) {
                          if (question.q_id !== q.q_id) return q;
                          else return ({ q_id: question.q_id, name: question.name, text: question.text, answer: true })
                        }));
                    }}
                  />
                  <div className="radio__radio"></div>
                  <span className="qualification-question-text ms-2">Yes</span>
                </label>
              </div>
              <div className="col-6">
                <label for={question.q_id + 'no'} className={"radio"}>
                  <input
                    type={"radio"}
                    name={question.q_id}
                    id={question.q_id + 'no'}
                    className={"radio__input"}
                    checked={question.answer === false}
                    onClick={() => {
                      setQuestions((state) => state.map(
                        function (q) {
                          if (question.q_id !== q.q_id) return q;
                          else return ({ q_id: question.q_id, text: question.text, name: question.name, answer: false })
                        }));
                    }}
                  />
                  <div className="radio__radio"></div>
                  <span className="qualification-question-text ms-2">No</span>
                </label>
              </div>
            </div>
          </div>)
      })}
      <QPModal />
    </>);
};



function AddFirmClient() {
  const navigate = useNavigate();

  const ind = [
    ['individual_net_worth_1m', 'Do you have a net worth over $1M excluding your primary residence?'],
    ['individual_income_accredited', 'Was your income over $200,000 individually (if investing individually) or $300,000 household (if investing jointly) in each of the past 2 years with a reasonable expectation for the same to hold true in the coming year?'],
    ['individual_investment_professional', 'Are you an investment professional in good standing that currently holds a Series 7, Series 65 or Series 82 license?'],
    ['individual_q1_qp_5m_investments', 'Are you a natural person who alone (or together with his/her spouse, if investing jointly) owns not less than $5,000,000 in investments?']
  ]

  const sm_llc = [
    ['individual_q1_qp_5m_investments', 'Are you a natural person who alone (or together with his/her spouse, if investing jointly) owns not less than $5,000,000 in investments?'],
    ['sm_llc_assets_5m_not_specific_purpose_invest_fund', 'Are you as the owner of the Single-member LLC an accredited investor? *Full definition below'],
    ['sm_llc_owners_accredited', 'Does the entity have more than $5M in assets and wasn’t created for the sole purpose of investing in opportunities offered on our platform?'],
  ]

  const ent = [
    ['entity_owners_accredited', 'Are all equity owners of the entity accredited investors?'],
    ['entity_q1_qp_specific_purpose_invest_fund', 'Was the entity formed for the specific purpose of investing in a particular fund?'],
    ['entity_q2_qp_5m_investments', 'Does the entity have more than $5M in investments?'],
    ['entity_q3_qp_owned_by_family', 'Is the entity owned directly or indirectly by 2 or more natural persons who are related as siblings or spouse (including former spouses), or direct lineal descendants by birth or adoption, or spouses of such persons, the estates of such persons, or foundations, charitable organizations, or trusts established by or for the benefit of such persons?'],
    ['entity_q4_qp_25m_investments', 'Does the entity have more than $25M in investments that are invested on its own account or on behalf of other individuals?'],
    ['entity_q5_qp_all_owners_qp', 'Are all beneficial owners of the entity qualified purchasers (*Definition below)?'],
  ]

  const trt = [
    ['trust_sophisticated_investor', 'Do you have a net worth over $1M excluding your primary residence?'],
    ['trust_grantors_accredited', 'Is each grantor of the trust an accredited investor and can the trust may be amended or revoked at any time by the grantors?'],
    ['trust_q1_qp_5m_investments', 'Does the trust have more than $5M in assets?'],
    ['trust_q2_qp_owned_by_family', "Is the trust owned by two or more close family members (spouses, siblings, descendants, and/or their respective spouses)?"],
    ['trust_q3_qp_specific_purpose_invest_fund', 'Was the trust formed for the specific purpose of investing in a particular fund?'],
    ['trust_q4_qp_trustee_grantor_qp', 'Are the trustee(s) and persons granting assets to the trust qualified purchasers (*Definition below)?']
  ]

  const questionMap = {
    'IND': ind,
    'SM_LLC': sm_llc,

    'LT': trt,
    'TRUST': trt,

    'GP': ent,
    'LP': ent,
    'C-CORP': ent,
    'S-CORP': ent,
    'LLC': ent,

    null: []
  }

  const [questions, setQuestions] = useState([]);

  useEffect(
    () => {
      setAccountQualificationError(false);
    }, [questions]
  );

  const { user } = useContext(UserContext);
  const shareClients = user.firmInfo.shareClients;

  const [advisorId, setAdvisorId] = useState("");
  const [advisorIdError, setAdvisorIdError] = useState("");


  const [clientFirstName, setClientFirstName] = useState("");
  const [clientFirstNameError, setClientFirstNameError] = useState("");

  const [clientLastName, setClientLastName] = useState("");
  const [clientLastNameError, setClientLastNameError] = useState("");

  const [clientEmail, setClientEmail] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");

  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [clientPhoneNumberError, setClientPhoneNumberError] = useState("");

  const [clientAddressLine1, setClientAddressLine1] = useState("");
  const [clientAddressLine1Error, setClientAddressLine1Error] = useState("");


  const [clientAddressLine2, setClientAddressLine2] = useState("");
  const [clientAddressLine2Error, setClientAddressLine2Error] = useState("");

  const [clientCity, setClientCity] = useState("");
  const [clientCityError, setClientCityError] = useState("");

  const [clientState, setClientState] = useState("");
  const [clientStateError, setClientStateError] = useState("");

  const [clientPostalCode, setClientPostalCode] = useState("");
  const [clientPostalCodeError, setClientPostalCodeError] = useState("");

  const [clientTaxIdNumber, setClientTaxIdNumber] = useState("");
  const [clientTaxIdNumberError, setClientTaxIdNumberError] = useState("");

  const [clientDOB, setClientDOB] = useState("");
  const [clientDOBError, setClientDOBError] = useState("");

  const [clientIsUSCitizen, setClientIsUSCitizen] = useState("");
  const [clientIsUSCitizenError, setClientIsUSCitizenError] = useState("");

  const [accountType, setAccountType] = useState(null);
  const [accountTypeError, setAccountTypeError] = useState("");

  useEffect(() => {
    if (user && user.userRole === 'Self-Serve') {
      // These values don't matter
      setClientFirstName("Test");
      setClientLastName("Test");
      setClientEmail("donald+test@investwithaqua.com");
    }
  }, [user]);


  useEffect(
    () => {
      accountType && questionMap[accountType] && setQuestions(questionMap[accountType].map((q, i) => ({ 'q_id': i, 'name': q[0], 'text': q[1], 'answer': null })));
    }, [accountType]
  );

  const [percentOwned, setPercentOwned] = useState("");
  const [percentOwnedError, setPercentOwnedError] = useState("");

  const [accountCustodianInstitution, setAccountCustodianInstitution] =
    useState("");
  const [
    accountCustodianInstitutionError,
    setAccountCustodianInstitutionError,
  ] = useState("");

  const [accountCustodialNumber, setAccountCustodialNumber] = useState("");
  const [accountCustodialNumberError, setAccountCustodialNumberError] =
    useState("");

  const [accountQualification, setAccountQualification] = useState("");
  const [accountQualificationError, setAccountQualificationError] =
    useState(false);
  const [accountNotQualifiedError, setAccountNotQualifiedError] =
    useState(false);

  const [jointAccountType, setJointAccountType] = useState("");
  const [jointAccountTypeError, setJointAccountTypeError] = useState("");

  const [entityName, setEntityName] = useState("");
  const [entityCountry, setEntityCountry] = useState("");
  const [entityCountryError, setEntityCountryError] = useState("");

  const [entityNameError, setEntityNameError] = useState("");

  const [entityAddressLine1, setEntityAddressLine1] = useState("");
  const [entityAddressLine1Error, setEntityAddressLine1Error] = useState("");

  const [entityAddressLine2, setEntityAddressLine2] = useState("");
  const [entityAddressLine2Error, setEntityAddressLine2Error] = useState("");

  const [entityCity, setEntityCity] = useState("");
  const [entityCityError, setEntityCityError] = useState("");

  const [entityState, setEntityState] = useState("");
  const [entityStateError, setEntityStateError] = useState("");

  const [entityPostalCode, setEntityPostalCode] = useState("");
  const [entityPostalCodeError, setEntityPostalCodeError] = useState("");

  const [entityTaxIDNumber, setEntityTaxIDNumber] = useState("");
  const [entityTaxIDNumberError, setEntityTaxIDNumberError] = useState("");

  const [entityPhoneNumber, setEntityPhoneNumber] = useState("");
  const [entityPhoneNumberError, setEntityPhoneNumberError] = useState("");

  const [entityStateOfIncorporation, setEntityStateOfIncorporation] =
    useState("");
  const [entityStateOfIncorporationError, setEntityStateOfIncorporationError] =
    useState("");

  const [jointHolderFirstName, setJointHolderFirstName] = useState("");
  const [jointHolderFirstNameError, setJointHolderFirstNameError] =
    useState("");

  const [jointHolderLastName, setJointHolderLastName] = useState("");
  const [jointHolderLastNameError, setJointHolderLastNameError] = useState("");

  const [jointHolderEmail, setJointHolderEmail] = useState("");
  const [jointHolderEmailError, setJointHolderEmailError] = useState("");

  const [jointHolderPhoneNumber, setJointHolderPhoneNumber] = useState("");
  const [jointHolderPhoneNumberError, setJointHolderPhoneNumberError] =
    useState("");

  const [jointHolderAddressLine1, setJointHolderAddressLine1] = useState("");
  const [jointHolderAddressLine1Error, setJointHolderAddressLine1Error] =
    useState("");

  const [jointHolderAddressLine2, setJointHolderAddressLine2] = useState("");
  const [jointHolderAddressLine2Error, setJointHolderAddressLine2Error] =
    useState("");

  const [jointHolderCity, setJointHolderCity] = useState("");
  const [jointHolderCityError, setJointHolderCityError] = useState("");

  const [jointHolderState, setJointHolderState] = useState("");
  const [jointHolderStateError, setJointHolderStateError] = useState("");

  const [jointHolderPostalCode, setJointHolderPostalCode] = useState("");
  const [jointHolderPostalCodeError, setJointHolderPostalCodeError] =
    useState("");

  const [jointHolderTaxIDNumber, setJointHolderTaxIDNumber] = useState("");
  const [jointHolderTaxIDNumberError, setJointHolderTaxIDNumberError] =
    useState("");

  const [jointHolderDOB, setJointHolderDOB] = useState("");
  const [jointHolderDOBError, setJointHolderDOBError] = useState("");

  const [jointHolderIsUSCitizen, setJointHolderIsUSCitizen] = useState("");
  const [jointHolderIsUSCitizenError, setJointHolderIsUSCitizenError] =
    useState("");

  const [hasTrustees, setHasTrustees] = useState("");
  const [hasBeneficialOwners, setHasBeneficialOwners] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [citizenship, setCitizenship] = useState("");
  const [citizenshipError, setCitizenshipError] = useState("");

  const [hasTrustManagementCompany, setHasTrustManagementCompany] =
    useState("");

  const [hasAlternateSignatory, setHasAlternateSignatory] = useState("");
  const [hasAlternateSignatoryError, setHasAlternateSignatoryError] =
    useState("");
  const [alternateSignatoryFullName, setAlternateSignatoryFullName] =
    useState("");
  const [alternateSignatoryFullNameError, setAlternateSignatoryFullNameError] =
    useState("");
  const [alternateSignatoryEmail, setAlternateSignatoryEmail] = useState("");
  const [alternateSignatoryEmailError, setAlternateSignatoryEmailError] =
    useState("");
  const [alternateSignatoryTitle, setAlternateSignatoryTitle] = useState("");
  const [alternateSignatoryTitleError, setAlternateSignatoryTitleError] =
    useState("");



  const [relatedPartyInfo, setRelatedPartyInfo] = useState([
    {
      relatedPartyType: "",
      relatedPartyTypeError: "",
      entityPercentOwned: "",
      entityPercentOwnedError: "",
      entityName: "",
      entityNameError: "",
      entityAddressLine1: "",
      entityAddressLine1Error: "",
      entityAddressLine2: "",
      entityAddressLine2Error: "",
      entityCity: "",
      entityCityError: "",
      entityState: "",
      entityStateError: "",
      entityPostalCode: "",
      entityPostalCodeError: "",
      entityTaxIDNumber: "",
      entityTaxIDNumberError: "",
      entityPhoneNumber: "",
      entityPhoneNumberError: "",
      entityStateOfIncorporation: "",
      entityStateOfIncorporationError: "",
      entityMailingAddressDifferent: "",
      entityMailingAddressDifferentError: "",
      entityMailingAddressLine1: "",
      entityMailingAddressLine1Error: "",
      entityMailingAddressLine2: "",
      entityMailingAddressLine2Error: "",
      entityMailingCity: "",
      entityMailingCityError: "",
      entityMailingState: "",
      entityMailingStateError: "",
      entityMailingPostalCode: "",
      entityMailingPostalCodeError: "",
      entityType: "",
      entityTypeError: "",
      entityControlPersonFirstName: "",
      entityControlPersonFirstNameError: "",
      entityControlPersonLastName: "",
      entityControlPersonLastNameError: "",
      entityControlPersonEmail: "",
      entityControlPersonEmailError: "",
      entityControlPersonIsUSBased: "",
      entityControlPersonIsUSBasedError: "",
      entityControlPersonIsUSCitizen: "",
      entityControlPersonIsUSCitizenError: "",
      entityControlPersonPIIAvailable: "",
      entityControlPersonPIIAvailableError: "",
      entityControlPersonAddressLine1: "",
      entityControlPersonAddressLine1Error: "",
      entityControlPersonAddressLine2: "",
      entityControlPersonAddressLine2Error: "",
      entityControlPersonCity: "",
      entityControlPersonCityError: "",
      entityControlPersonState: "",
      entityControlPersonStateError: "",
      entityControlPersonPostalCode: "",
      entityControlPersonPostalCodeError: "",
      entityControlPersonPhoneNumber: "",
      entityControlPersonPhoneNumberError: "",
      entityControlPersonTaxIDNumber: "",
      entityControlPersonTaxIDNumberError: "",
      entityControlPersonDOB: "",
      entityControlPersonDOBError: "",
      entityCertificateOfFormation: "",
      entityCertificateOfFormationError: "",
      entityProofOfAddress: "",
      entityProofOfAddressError: "",
      entityControlPersonPassportDocument: "",
      entityControlPersonPassportDocumentError: "",
      entityControlPersonProofOfAddress: "",
      entityControlPersonProofOfAddressError: "",
      entityIsTrustManagementCompany: "",
      entityIsTrustManagementCompanyError: "",
      entityHasAlternateSignatory: "",
      entityHasAlternateSignatoryError: "",
      entityAlternateSignatoryFullName: "",
      entityAlternateSignatoryFullNameError: "",
      entityAlternateSignatoryEmail: "",
      entityAlternateSignatoryEmailError: "",
      entityAlternateSignatoryTitle: "",
      entityAlternateSignatoryTitleError: "",
      entityControlPersonRole: "",
      entityControlPersonRoleError: "",
      entityNestedControlEntityTrusteeType: "",
      entityNestedControlEntityTrusteeTypeError: "",
      entityNestedControlEntityName: "",
      entityNestedControlEntityNameError: "",
      entityNestedControlEntityType: "",
      entityNestedControlEntityTypeError: "",
      entityNestedControlEntityPercentOwned: "",
      entityNestedControlEntityPercentOwnedError: "",
      entityNestedControlEntityStateOfIncorporation: "",
      entityNestedControlEntityStateOfIncorporationError: "",
      entityNestedControlEntityTaxIDNumber: "",
      entityNestedControlEntityTaxIDNumberError: "",
      entityNestedControlEntityPhoneNumber: "",
      entityNestedControlEntityPhoneNumberError: "",
      entityNestedControlEntityAddressLine1: "",
      entityNestedControlEntityAddressLine1Error: "",
      entityNestedControlEntityAddressLine2: "",
      entityNestedControlEntityAddressLine2Error: "",
      entityNestedControlEntityCity: "",
      entityNestedControlEntityCityError: "",
      entityNestedControlEntityState: "",
      entityNestedControlEntityStateError: "",
      entityNestedControlEntityPostalCode: "",
      entityNestedControlEntityPostalCodeError: "",
      entityNestedControlEntityIsTrustManagementCompany: "",
      entityNestedControlEntityIsTrustManagementCompanyError: "",
      entityNestedControlEntityAddressType: "",
      entityNestedControlEntityAddressTypeError: "",
      entityNestedControlEntityCertificateOfFormation: "",
      entityNestedControlEntityCertificateOfFormationError: "",
      entityNestedControlEntityProofOfAddress: "",
      entityNestedControlEntityProofOfAddressError: "",
      entityNestedControlEntityControlPersonFirstName: "",
      entityNestedControlEntityControlPersonFirstNameError: "",
      entityNestedControlEntityControlPersonLastName: "",
      entityNestedControlEntityControlPersonLastNameError: "",
      entityNestedControlEntityControlPersonEmail: "",
      entityNestedControlEntityControlPersonEmailError: "",
      entityNestedControlEntityControlPersonIsUSBased: "",
      entityNestedControlEntityControlPersonIsUSBasedError: "",
      entityNestedControlEntityControlPersonIsUSCitizen: "",
      entityNestedControlEntityControlPersonIsUSCitizenError: "",
      entityNestedControlEntityControlPersonPhoneNumber: "",
      entityNestedControlEntityControlPersonPhoneNumberError: "",
      entityNestedControlEntityControlPersonPIIAvailable: "",
      entityNestedControlEntityControlPersonPIIAvailableError: "",
      entityNestedControlEntityControlPersonAddressLine1: "",
      entityNestedControlEntityControlPersonAddressLine1Error: "",
      entityNestedControlEntityControlPersonAddressLine2: "",
      entityNestedControlEntityControlPersonAddressLine2Error: "",
      entityNestedControlEntityControlPersonCity: "",
      entityNestedControlEntityControlPersonCityError: "",
      entityNestedControlEntityControlPersonState: "",
      entityNestedControlEntityControlPersonStateError: "",
      entityNestedControlEntityControlPersonPostalCode: "",
      entityNestedControlEntityControlPersonPostalCodeError: "",
      entityNestedControlEntityControlPersonTaxIDNumber: "",
      entityNestedControlEntityControlPersonTaxIDNumberError: "",
      entityNestedControlEntityControlPersonDOB: "",
      entityNestedControlEntityControlPersonDOBError: "",
      entityNestedControlEntityControlPersonPassportDocument: "",
      entityNestedControlEntityControlPersonPassportDocumentError: "",
      entityNestedControlEntityControlPersonProofOfAddress: "",
      entityNestedControlEntityControlPersonProofOfAddressError: "",
      entityNestedControlEntityControlPersonAddressType: "",
      entityNestedControlEntityControlPersonAddressTypeError: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      idNum: "",
      idNumError: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      addressLine1Error: "",
      addressLine2Error: "",
      cityError: "",
      stateError: "",
      countryError: "",
      postalCodeError: "",
      isUSCitizen: "",
      isUSCitizenError: "",
      citizenship: "",
      country: "",
      citizenshipError: "",
      countryError: "",
      percentOwnership: "",
      percentOwnershipError: "",
    },
  ]);

  const [beneficialOwnerInfo, setBeneficialOwnerInfo] = useState([
    {
      relatedPartyType: "",
      relatedPartyTypeError: "",
      entityPercentOwned: "",
      entityPercentOwnedError: "",
      entityName: "",
      entityNameError: "",
      entityAddressLine1: "",
      entityAddressLine1Error: "",
      entityAddressLine2: "",
      entityAddressLine2Error: "",
      entityCity: "",
      entityCityError: "",
      entityState: "",
      entityStateError: "",
      entityPostalCode: "",
      entityPostalCodeError: "",
      entityTaxIDNumber: "",
      entityTaxIDNumberError: "",
      entityPhoneNumber: "",
      entityPhoneNumberError: "",
      entityStateOfIncorporation: "",
      entityStateOfIncorporationError: "",
      entityMailingAddressDifferent: "",
      entityMailingAddressDifferentError: "",
      entityMailingAddressLine1: "",
      entityMailingAddressLine1Error: "",
      entityMailingAddressLine2: "",
      entityMailingAddressLine2Error: "",
      entityMailingCity: "",
      entityMailingCityError: "",
      entityMailingState: "",
      entityMailingStateError: "",
      entityMailingPostalCode: "",
      entityMailingPostalCodeError: "",
      entityType: "",
      entityTypeError: "",
      entityControlPersonFirstName: "",
      entityControlPersonFirstNameError: "",
      entityControlPersonLastName: "",
      entityControlPersonLastNameError: "",
      entityControlPersonEmail: "",
      entityControlPersonEmailError: "",
      entityControlPersonIsUSBased: "",
      entityControlPersonIsUSBasedError: "",
      entityControlPersonIsUSCitizen: "",
      entityControlPersonIsUSCitizenError: "",
      entityControlPersonPIIAvailable: "",
      entityControlPersonPIIAvailableError: "",
      entityControlPersonAddressLine1: "",
      entityControlPersonAddressLine1Error: "",
      entityControlPersonAddressLine2: "",
      entityControlPersonAddressLine2Error: "",
      entityControlPersonCity: "",
      entityControlPersonCityError: "",
      entityControlPersonState: "",
      entityControlPersonStateError: "",
      entityControlPersonPostalCode: "",
      entityControlPersonPostalCodeError: "",
      entityControlPersonPhoneNumber: "",
      entityControlPersonPhoneNumberError: "",
      entityControlPersonTaxIDNumber: "",
      entityControlPersonTaxIDNumberError: "",
      entityControlPersonDOB: "",
      entityControlPersonDOBError: "",
      entityCertificateOfFormation: "",
      entityCertificateOfFormationError: "",
      entityProofOfAddress: "",
      entityProofOfAddressError: "",
      entityControlPersonPassportDocument: "",
      entityControlPersonPassportDocumentError: "",
      entityControlPersonProofOfAddress: "",
      entityControlPersonProofOfAddressError: "",
      entityIsTrustManagementCompany: "",
      entityIsTrustManagementCompanyError: "",
      entityHasAlternateSignatory: "",
      entityHasAlternateSignatoryError: "",
      entityAlternateSignatoryFullName: "",
      entityAlternateSignatoryFullNameError: "",
      entityAlternateSignatoryEmail: "",
      entityAlternateSignatoryEmailError: "",
      entityAlternateSignatoryTitle: "",
      entityAlternateSignatoryTitleError: "",
      entityControlPersonRole: "",
      entityControlPersonRoleError: "",
      entityNestedControlEntityTrusteeType: "",
      entityNestedControlEntityTrusteeTypeError: "",
      entityNestedControlEntityName: "",
      entityNestedControlEntityNameError: "",
      entityNestedControlEntityType: "",
      entityNestedControlEntityTypeError: "",
      entityNestedControlEntityPercentOwned: "",
      entityNestedControlEntityPercentOwnedError: "",
      entityNestedControlEntityStateOfIncorporation: "",
      entityNestedControlEntityStateOfIncorporationError: "",
      entityNestedControlEntityTaxIDNumber: "",
      entityNestedControlEntityTaxIDNumberError: "",
      entityNestedControlEntityPhoneNumber: "",
      entityNestedControlEntityPhoneNumberError: "",
      entityNestedControlEntityAddressLine1: "",
      entityNestedControlEntityAddressLine1Error: "",
      entityNestedControlEntityAddressLine2: "",
      entityNestedControlEntityAddressLine2Error: "",
      entityNestedControlEntityCity: "",
      entityNestedControlEntityCityError: "",
      entityNestedControlEntityState: "",
      entityNestedControlEntityStateError: "",
      entityNestedControlEntityPostalCode: "",
      entityNestedControlEntityPostalCodeError: "",
      entityNestedControlEntityIsTrustManagementCompany: "",
      entityNestedControlEntityIsTrustManagementCompanyError: "",
      entityNestedControlEntityAddressType: "",
      entityNestedControlEntityAddressTypeError: "",
      entityNestedControlEntityCertificateOfFormation: "",
      entityNestedControlEntityCertificateOfFormationError: "",
      entityNestedControlEntityProofOfAddress: "",
      entityNestedControlEntityProofOfAddressError: "",
      entityNestedControlEntityControlPersonFirstName: "",
      entityNestedControlEntityControlPersonFirstNameError: "",
      entityNestedControlEntityControlPersonLastName: "",
      entityNestedControlEntityControlPersonLastNameError: "",
      entityNestedControlEntityControlPersonEmail: "",
      entityNestedControlEntityControlPersonEmailError: "",
      entityNestedControlEntityControlPersonIsUSBased: "",
      entityNestedControlEntityControlPersonIsUSBasedError: "",
      entityNestedControlEntityControlPersonIsUSCitizen: "",
      entityNestedControlEntityControlPersonIsUSCitizenError: "",
      entityNestedControlEntityControlPersonPhoneNumber: "",
      entityNestedControlEntityControlPersonPhoneNumberError: "",
      entityNestedControlEntityControlPersonPIIAvailable: "",
      entityNestedControlEntityControlPersonPIIAvailableError: "",
      entityNestedControlEntityControlPersonAddressLine1: "",
      entityNestedControlEntityControlPersonAddressLine1Error: "",
      entityNestedControlEntityControlPersonAddressLine2: "",
      entityNestedControlEntityControlPersonAddressLine2Error: "",
      entityNestedControlEntityControlPersonCity: "",
      entityNestedControlEntityControlPersonCityError: "",
      entityNestedControlEntityControlPersonState: "",
      entityNestedControlEntityControlPersonStateError: "",
      entityNestedControlEntityControlPersonPostalCode: "",
      entityNestedControlEntityControlPersonPostalCodeError: "",
      entityNestedControlEntityControlPersonTaxIDNumber: "",
      entityNestedControlEntityControlPersonTaxIDNumberError: "",
      entityNestedControlEntityControlPersonDOB: "",
      entityNestedControlEntityControlPersonDOBError: "",
      entityNestedControlEntityControlPersonPassportDocument: "",
      entityNestedControlEntityControlPersonPassportDocumentError: "",
      entityNestedControlEntityControlPersonProofOfAddress: "",
      entityNestedControlEntityControlPersonProofOfAddressError: "",
      entityNestedControlEntityControlPersonAddressType: "",
      entityNestedControlEntityControlPersonAddressTypeError: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      idNum: "",
      idNumError: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      addressLine1Error: "",
      addressLine2Error: "",
      cityError: "",
      stateError: "",
      countryError: "",
      postalCodeError: "",
      isUSCitizen: "",
      isUSCitizenError: "",
      citizenship: "",
      country: "",
      citizenshipError: "",
      countryError: "",
      percentOwnership: "",
      percentOwnershipError: "",
    },
  ]);

  const [hideButton, setHideButton] = useState(false);
  const [hideBeneficialOwnerButton, setHideBeneficialOwnerButton] =
    useState(false);
  const [hideAlterButtons, setHideAlterButtons] = useState(false);
  const [hideAlterBeneficialOwnerButtons, setHideAlterBeneficialOwnerButtons] =
    useState(false);

  const [activeTab, setActiveTab] = useState("Trustee #1");
  const [beneficialOwnerActiveTab, setBeneficialOwnerActiveTab] = useState(
    "Beneficial Owner #1"
  );

  const accountTypeOptions = [
    { value: "IND", label: "Individual" },
    //{ value: "JOINT", label: "Joint-Account" },
    { value: "LT", label: "Revocable Trust" },
    { value: "TRUST", label: "Irrevocable Trust" },
    { value: "SM_LLC", label: "Single-member LLC" },
    { value: "LLC", label: "LLC" },
    { value: "GP", label: "General Partnership" },
    { value: "LP", label: "Limited Partnership" },
    { value: "C-CORP", label: "C-Corp" },
    { value: "S-CORP", label: "S-Corp" },
  ];

  const jointAccountTypeOptions = [
    { value: "TWROS", label: "Tenants with Rights of Survivorship" },
    { value: "TIC", label: "Tenants in Common" },
    { value: "CP", label: "Community Property" },
  ];

  const addNewTab = () => {
    setRelatedPartyInfo([
      ...relatedPartyInfo,
      {
        relatedPartyType: "",
        relatedPartyTypeError: "",
        entityPercentOwned: "",
        entityPercentOwnedError: "",
        entityName: "",
        entityNameError: "",
        entityAddressLine1: "",
        entityAddressLine1Error: "",
        entityAddressLine2: "",
        entityAddressLine2Error: "",
        entityCity: "",
        entityCityError: "",
        entityState: "",
        entityStateError: "",
        entityPostalCode: "",
        entityPostalCodeError: "",
        entityTaxIDNumber: "",
        entityTaxIDNumberError: "",
        entityPhoneNumber: "",
        entityPhoneNumberError: "",
        entityStateOfIncorporation: "",
        entityStateOfIncorporationError: "",
        entityMailingAddressDifferent: "",
        entityMailingAddressDifferentError: "",
        entityMailingAddressLine1: "",
        entityMailingAddressLine1Error: "",
        entityMailingAddressLine2: "",
        entityMailingAddressLine2Error: "",
        entityMailingCity: "",
        entityMailingCityError: "",
        entityMailingState: "",
        entityMailingStateError: "",
        entityMailingPostalCode: "",
        entityMailingPostalCodeError: "",
        entityType: "",
        entityTypeError: "",
        entityControlPersonFirstName: "",
        entityControlPersonFirstNameError: "",
        entityControlPersonLastName: "",
        entityControlPersonLastNameError: "",
        entityControlPersonEmail: "",
        entityControlPersonEmailError: "",
        entityControlPersonIsUSBased: "",
        entityControlPersonIsUSBasedError: "",
        entityControlPersonIsUSCitizen: "",
        entityControlPersonIsUSCitizenError: "",
        entityControlPersonPIIAvailable: "",
        entityControlPersonPIIAvailableError: "",
        entityControlPersonAddressLine1: "",
        entityControlPersonAddressLine1Error: "",
        entityControlPersonAddressLine2: "",
        entityControlPersonAddressLine2Error: "",
        entityControlPersonCity: "",
        entityControlPersonCityError: "",
        entityControlPersonState: "",
        entityControlPersonStateError: "",
        entityControlPersonPostalCode: "",
        entityControlPersonPostalCodeError: "",
        entityControlPersonPhoneNumber: "",
        entityControlPersonPhoneNumberError: "",
        entityControlPersonTaxIDNumber: "",
        entityControlPersonTaxIDNumberError: "",
        entityControlPersonDOB: "",
        entityControlPersonDOBError: "",
        entityCertificateOfFormation: "",
        entityCertificateOfFormationError: "",
        entityProofOfAddress: "",
        entityProofOfAddressError: "",
        entityControlPersonPassportDocument: "",
        entityControlPersonPassportDocumentError: "",
        entityControlPersonProofOfAddress: "",
        entityControlPersonProofOfAddressError: "",
        entityIsTrustManagementCompany: "",
        entityIsTrustManagementCompanyError: "",
        entityHasAlternateSignatory: "",
        entityHasAlternateSignatoryError: "",
        entityAlternateSignatoryFullName: "",
        entityAlternateSignatoryFullNameError: "",
        entityAlternateSignatoryEmail: "",
        entityAlternateSignatoryEmailError: "",
        entityAlternateSignatoryTitle: "",
        entityAlternateSignatoryTitleError: "",
        entityControlPersonRole: "",
        entityControlPersonRoleError: "",
        entityNestedControlEntityTrusteeType: "",
        entityNestedControlEntityTrusteeTypeError: "",
        entityNestedControlEntityName: "",
        entityNestedControlEntityNameError: "",
        entityNestedControlEntityType: "",
        entityNestedControlEntityTypeError: "",
        entityNestedControlEntityPercentOwned: "",
        entityNestedControlEntityPercentOwnedError: "",
        entityNestedControlEntityStateOfIncorporation: "",
        entityNestedControlEntityStateOfIncorporationError: "",
        entityNestedControlEntityTaxIDNumber: "",
        entityNestedControlEntityTaxIDNumberError: "",
        entityNestedControlEntityPhoneNumber: "",
        entityNestedControlEntityPhoneNumberError: "",
        entityNestedControlEntityAddressLine1: "",
        entityNestedControlEntityAddressLine1Error: "",
        entityNestedControlEntityAddressLine2: "",
        entityNestedControlEntityAddressLine2Error: "",
        entityNestedControlEntityCity: "",
        entityNestedControlEntityCityError: "",
        entityNestedControlEntityState: "",
        entityNestedControlEntityStateError: "",
        entityNestedControlEntityPostalCode: "",
        entityNestedControlEntityPostalCodeError: "",
        entityNestedControlEntityIsTrustManagementCompany: "",
        entityNestedControlEntityIsTrustManagementCompanyError: "",
        entityNestedControlEntityAddressType: "",
        entityNestedControlEntityAddressTypeError: "",
        entityNestedControlEntityCertificateOfFormation: "",
        entityNestedControlEntityCertificateOfFormationError: "",
        entityNestedControlEntityProofOfAddress: "",
        entityNestedControlEntityProofOfAddressError: "",
        entityNestedControlEntityControlPersonFirstName: "",
        entityNestedControlEntityControlPersonFirstNameError: "",
        entityNestedControlEntityControlPersonLastName: "",
        entityNestedControlEntityControlPersonLastNameError: "",
        entityNestedControlEntityControlPersonEmail: "",
        entityNestedControlEntityControlPersonEmailError: "",
        entityNestedControlEntityControlPersonIsUSBased: "",
        entityNestedControlEntityControlPersonIsUSBasedError: "",
        entityNestedControlEntityControlPersonIsUSCitizen: "",
        entityNestedControlEntityControlPersonIsUSCitizenError: "",
        entityNestedControlEntityControlPersonPhoneNumber: "",
        entityNestedControlEntityControlPersonPhoneNumberError: "",
        entityNestedControlEntityControlPersonPIIAvailable: "",
        entityNestedControlEntityControlPersonPIIAvailableError: "",
        entityNestedControlEntityControlPersonAddressLine1: "",
        entityNestedControlEntityControlPersonAddressLine1Error: "",
        entityNestedControlEntityControlPersonAddressLine2: "",
        entityNestedControlEntityControlPersonAddressLine2Error: "",
        entityNestedControlEntityControlPersonCity: "",
        entityNestedControlEntityControlPersonCityError: "",
        entityNestedControlEntityControlPersonState: "",
        entityNestedControlEntityControlPersonStateError: "",
        entityNestedControlEntityControlPersonPostalCode: "",
        entityNestedControlEntityControlPersonPostalCodeError: "",
        entityNestedControlEntityControlPersonTaxIDNumber: "",
        entityNestedControlEntityControlPersonTaxIDNumberError: "",
        entityNestedControlEntityControlPersonDOB: "",
        entityNestedControlEntityControlPersonDOBError: "",
        entityNestedControlEntityControlPersonPassportDocument: "",
        entityNestedControlEntityControlPersonPassportDocumentError: "",
        entityNestedControlEntityControlPersonProofOfAddress: "",
        entityNestedControlEntityControlPersonProofOfAddressError: "",
        entityNestedControlEntityControlPersonAddressType: "",
        entityNestedControlEntityControlPersonAddressTypeError: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idNum: "",
        idNumError: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        isUSCitizen: "",
        isUSCitizenError: "",
        citizenship: "",
        country: "",
        citizenshipError: "",
        countryError: "",
        percentOwnership: "",
        percentOwnershipError: "",
      },
    ]);
    setActiveTab("Trustee #" + (relatedPartyInfo.length + 1).toString());
  };

  const addNewBeneficialOwnerTab = () => {
    setBeneficialOwnerInfo([
      ...beneficialOwnerInfo,
      {
        relatedPartyType: "",
        relatedPartyTypeError: "",
        entityPercentOwned: "",
        entityPercentOwnedError: "",
        entityName: "",
        entityNameError: "",
        entityAddressLine1: "",
        entityAddressLine1Error: "",
        entityAddressLine2: "",
        entityAddressLine2Error: "",
        entityCity: "",
        entityCityError: "",
        entityState: "",
        entityStateError: "",
        entityPostalCode: "",
        entityPostalCodeError: "",
        entityTaxIDNumber: "",
        entityTaxIDNumberError: "",
        entityPhoneNumber: "",
        entityPhoneNumberError: "",
        entityStateOfIncorporation: "",
        entityStateOfIncorporationError: "",
        entityMailingAddressDifferent: "",
        entityMailingAddressDifferentError: "",
        entityMailingAddressLine1: "",
        entityMailingAddressLine1Error: "",
        entityMailingAddressLine2: "",
        entityMailingAddressLine2Error: "",
        entityMailingCity: "",
        entityMailingCityError: "",
        entityMailingState: "",
        entityMailingStateError: "",
        entityMailingPostalCode: "",
        entityMailingPostalCodeError: "",
        entityType: "",
        entityTypeError: "",
        entityControlPersonFirstName: "",
        entityControlPersonFirstNameError: "",
        entityControlPersonLastName: "",
        entityControlPersonLastNameError: "",
        entityControlPersonEmail: "",
        entityControlPersonEmailError: "",
        entityControlPersonIsUSBased: "",
        entityControlPersonIsUSBasedError: "",
        entityControlPersonIsUSCitizen: "",
        entityControlPersonIsUSCitizenError: "",
        entityControlPersonPIIAvailable: "",
        entityControlPersonPIIAvailableError: "",
        entityControlPersonAddressLine1: "",
        entityControlPersonAddressLine1Error: "",
        entityControlPersonAddressLine2: "",
        entityControlPersonAddressLine2Error: "",
        entityControlPersonCity: "",
        entityControlPersonCityError: "",
        entityControlPersonState: "",
        entityControlPersonStateError: "",
        entityControlPersonPostalCode: "",
        entityControlPersonPostalCodeError: "",
        entityControlPersonPhoneNumber: "",
        entityControlPersonPhoneNumberError: "",
        entityControlPersonTaxIDNumber: "",
        entityControlPersonTaxIDNumberError: "",
        entityControlPersonDOB: "",
        entityControlPersonDOBError: "",
        entityCertificateOfFormation: "",
        entityCertificateOfFormationError: "",
        entityProofOfAddress: "",
        entityProofOfAddressError: "",
        entityControlPersonPassportDocument: "",
        entityControlPersonPassportDocumentError: "",
        entityControlPersonProofOfAddress: "",
        entityControlPersonProofOfAddressError: "",
        entityIsTrustManagementCompany: "",
        entityIsTrustManagementCompanyError: "",
        entityHasAlternateSignatory: "",
        entityHasAlternateSignatoryError: "",
        entityAlternateSignatoryFullName: "",
        entityAlternateSignatoryFullNameError: "",
        entityAlternateSignatoryEmail: "",
        entityAlternateSignatoryEmailError: "",
        entityAlternateSignatoryTitle: "",
        entityAlternateSignatoryTitleError: "",
        entityControlPersonRole: "",
        entityControlPersonRoleError: "",
        entityNestedControlEntityTrusteeType: "",
        entityNestedControlEntityTrusteeTypeError: "",
        entityNestedControlEntityName: "",
        entityNestedControlEntityNameError: "",
        entityNestedControlEntityType: "",
        entityNestedControlEntityTypeError: "",
        entityNestedControlEntityPercentOwned: "",
        entityNestedControlEntityPercentOwnedError: "",
        entityNestedControlEntityStateOfIncorporation: "",
        entityNestedControlEntityStateOfIncorporationError: "",
        entityNestedControlEntityTaxIDNumber: "",
        entityNestedControlEntityTaxIDNumberError: "",
        entityNestedControlEntityPhoneNumber: "",
        entityNestedControlEntityPhoneNumberError: "",
        entityNestedControlEntityAddressLine1: "",
        entityNestedControlEntityAddressLine1Error: "",
        entityNestedControlEntityAddressLine2: "",
        entityNestedControlEntityAddressLine2Error: "",
        entityNestedControlEntityCity: "",
        entityNestedControlEntityCityError: "",
        entityNestedControlEntityState: "",
        entityNestedControlEntityStateError: "",
        entityNestedControlEntityPostalCode: "",
        entityNestedControlEntityPostalCodeError: "",
        entityNestedControlEntityIsTrustManagementCompany: "",
        entityNestedControlEntityIsTrustManagementCompanyError: "",
        entityNestedControlEntityAddressType: "",
        entityNestedControlEntityAddressTypeError: "",
        entityNestedControlEntityCertificateOfFormation: "",
        entityNestedControlEntityCertificateOfFormationError: "",
        entityNestedControlEntityProofOfAddress: "",
        entityNestedControlEntityProofOfAddressError: "",
        entityNestedControlEntityControlPersonFirstName: "",
        entityNestedControlEntityControlPersonFirstNameError: "",
        entityNestedControlEntityControlPersonLastName: "",
        entityNestedControlEntityControlPersonLastNameError: "",
        entityNestedControlEntityControlPersonEmail: "",
        entityNestedControlEntityControlPersonEmailError: "",
        entityNestedControlEntityControlPersonIsUSBased: "",
        entityNestedControlEntityControlPersonIsUSBasedError: "",
        entityNestedControlEntityControlPersonIsUSCitizen: "",
        entityNestedControlEntityControlPersonIsUSCitizenError: "",
        entityNestedControlEntityControlPersonPhoneNumber: "",
        entityNestedControlEntityControlPersonPhoneNumberError: "",
        entityNestedControlEntityControlPersonPIIAvailable: "",
        entityNestedControlEntityControlPersonPIIAvailableError: "",
        entityNestedControlEntityControlPersonAddressLine1: "",
        entityNestedControlEntityControlPersonAddressLine1Error: "",
        entityNestedControlEntityControlPersonAddressLine2: "",
        entityNestedControlEntityControlPersonAddressLine2Error: "",
        entityNestedControlEntityControlPersonCity: "",
        entityNestedControlEntityControlPersonCityError: "",
        entityNestedControlEntityControlPersonState: "",
        entityNestedControlEntityControlPersonStateError: "",
        entityNestedControlEntityControlPersonPostalCode: "",
        entityNestedControlEntityControlPersonPostalCodeError: "",
        entityNestedControlEntityControlPersonTaxIDNumber: "",
        entityNestedControlEntityControlPersonTaxIDNumberError: "",
        entityNestedControlEntityControlPersonDOB: "",
        entityNestedControlEntityControlPersonDOBError: "",
        entityNestedControlEntityControlPersonPassportDocument: "",
        entityNestedControlEntityControlPersonPassportDocumentError: "",
        entityNestedControlEntityControlPersonProofOfAddress: "",
        entityNestedControlEntityControlPersonProofOfAddressError: "",
        entityNestedControlEntityControlPersonAddressType: "",
        entityNestedControlEntityControlPersonAddressTypeError: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idNum: "",
        idNumError: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        isUSCitizen: "",
        isUSCitizenError: "",
        citizenship: "",
        country: "",
        citizenshipError: "",
        countryError: "",
        percentOwnership: "",
        percentOwnershipError: "",
      },
    ]);
    setBeneficialOwnerActiveTab(
      "Beneficial Owner #" + (beneficialOwnerInfo.length + 1).toString()
    );
  };

  const handleRemoveFields = (index) => {
    const nextIdx = index === 0 ? 0 : index - 1;
    setActiveTab("Trustee #" + (nextIdx + 1).toString());
    setRelatedPartyInfo((relatedPartyInfo) =>
      relatedPartyInfo.filter((elem, idx) => idx !== index)
    );
  };

  const handleBeneficialOwnerRemoveField = (index) => {
    const nextIdx = index === 0 ? 0 : index - 1;
    setBeneficialOwnerActiveTab(
      "Beneficial Owner #" + (nextIdx + 1).toString()
    );
    setBeneficialOwnerInfo((beneficialOwnerInfo) =>
      beneficialOwnerInfo.filter((elem, idx) => idx !== index)
    );
  };

  useEffect(() => {
    if (
      relatedPartyInfo !== null &&
      relatedPartyInfo !== undefined &&
      relatedPartyInfo.length !== 0
    ) {
      var trustManagementCompanyExists = "";
      relatedPartyInfo.forEach((elem, idx) => {
        if (
          elem.relatedPartyType === "Entity" &&
          elem.entityIsTrustManagementCompany === "Yes"
        ) {
          trustManagementCompanyExists = true;
        } else if (
          elem.relatedPartyType === "Entity" &&
          elem.entityIsTrustManagementCompany === "No"
        ) {
          trustManagementCompanyExists = false;
        }
      });
      setHasTrustManagementCompany(trustManagementCompanyExists);
    } else {
      setHasTrustManagementCompany("");
    }
  }, [relatedPartyInfo]);

  // useEffect(() => {
  //   console.log("Account Type is: " + accountType);
  // }, [accountType]);

  // useEffect(() => {
  //   console.log("Has trustees is: " + hasTrustees);
  // }, [hasTrustees]);

  // useEffect(() => {
  //   console.log("Has beneficial owners is: " + hasBeneficialOwners);
  // }, [hasBeneficialOwners]);



  const [notAllQuestionsAnsweredError, setNotAllQuestionsAnsweredError] =
    useState(false);

  const [notAccreditedError, setNotAccreditedError] = useState(false);


  const getAnswer = (question) => questions.filter((q) => q.name == question)[0].answer

  const validateAccreditedStatus = () => {
    var errors = false;
    var isAccredited = true;

    if (["GP", "LP", "S-CORP", "C-CORP", "LLC"].includes(accountType)) {
      errors = false;

      if (questions.map((q) => (q.answer === null)).includes(true)) {
        errors = true;
      } else if (
        (!getAnswer('entity_q2_qp_5m_investments') || getAnswer('entity_q1_qp_specific_purpose_invest_fund')) &&
        !getAnswer('entity_owners_accredited')
      ) {
        isAccredited = false;
      }

      if (errors === true) {
        setNotAllQuestionsAnsweredError(true);
      } else if (isAccredited === false) {
        setNotAccreditedError(true);
      }
      else setAccountQualification(true);
    }
    else if (accountType === 'SM_LLC') {

      if (questions.map((q) => (q.answer === null)).includes(true)) {
        errors = true;
      } else if (!getAnswer('sm_llc_assets_5m_not_specific_purpose_invest_fund') && !getAnswer('sm_llc_owners_accredited')) {
        isAccredited = false;
      }

      if (errors === true) {
        setNotAllQuestionsAnsweredError(true);
      } else if (isAccredited === false) {
        setNotAccreditedError(true);
      } else setAccountQualification(true);

    }

    else if (["LT", "TRUST"].includes(accountType)) {
      errors = false;
      isAccredited = true;

      if (questions.map((q) => (q.answer === null)).includes(true)) {
        errors = true;
      }

      else if (
        !(
          getAnswer('trust_q1_qp_5m_investments') === true &&
          getAnswer('trust_sophisticated_investor') === true &&
          getAnswer('trust_q3_qp_specific_purpose_invest_fund') !== true
        ) &&
        !(getAnswer('trust_grantors_accredited') === true)
      ) {
        isAccredited = false;
      }

      if (errors === true) {
        setNotAllQuestionsAnsweredError(true);
      } else if (isAccredited === false) {
        setNotAccreditedError(true);
      } else setAccountQualification(true);

    }
    else if (accountType === "IND") {
      console.log(questions);
      if (questions.map((q) => (q.answer === null)).includes(true)) {
        errors = true;
      } else if (!getAnswer('individual_net_worth_1m') && !getAnswer('individual_income_accredited') && !getAnswer('individual_investment_professional')) {
        isAccredited = false;
      }

      if (errors === true) {
        setNotAllQuestionsAnsweredError(true);
      } else if (isAccredited === false) {
        setNotAccreditedError(true);
      } else setAccountQualification(true);

    }
    return errors || (isAccredited === false);
  };



  const submit = async (addAnother) => {
    var errors = false;
    if (validateAccreditedStatus())
      errors = true;
    var requestData = [];
    var beneficialOwnerRequestData = [];

    if (clientFirstName === "") {
      errors = true;
      setClientFirstNameError("This field is required.");
    }

    if (clientLastName === "") {
      errors = true;
      setClientLastNameError("This field is required.");
    }

    if (clientEmail === "") {
      errors = true;
      setClientEmailError("This field is required.");
    } else if (!isEmailValid(clientEmail)) {
      errors = true;
      setClientEmailError("Please enter a valid email.");
    }

    if (clientPhoneNumber === "") {
      errors = true;
      setClientPhoneNumberError("This field is required.");
    } else if (stripOutDigits(clientPhoneNumber).length < 11) {
      errors = true;
      setClientPhoneNumberError("Please enter a valid phone number.");
    }

    if (clientAddressLine1 === "") {
      errors = true;
      setClientAddressLine1Error("This field is required.");
    }
    if (clientCity === "") {
      errors = true;
      setClientCityError("This field is required.");
    }

    if (clientState === "" && country === "United States") {
      errors = true;
      setClientStateError("This field is required.");
    }
    if (clientPostalCode === "") {
      errors = true;
      setClientPostalCodeError("This field is required.");
    }

    if (clientTaxIdNumber === "") {
      errors = true;
      setClientTaxIdNumberError("This field is required.");
    } else if (!isValidSSN(clientTaxIdNumber) && country === "United States") {
      errors = true;
      setClientTaxIdNumberError("Please enter a valid ssn.");
    }

    if (
      (accountType === "LT" && (percentOwned === "0" || percentOwned === "")) ||
      (accountType === "TRUST" && percentOwned === "")
    ) {
      errors = true;
      setPercentOwnedError("Percent owned must be a value greater than 0.");
    }
    if (clientDOB === "") {
      errors = true;
      setClientDOBError("This field is required.");
    } else if (!isDobValid(clientDOB)) {
      errors = true;
      setClientDOBError("Please enter a valid dob.");
    }

    if (clientIsUSCitizen === "") {
      errors = true;
      setClientIsUSCitizenError("This field is required.");
    }

    if (accountType === "") {
      errors = true;
      setAccountTypeError("This field is required.");
    }

    if (accountCustodianInstitution === "" && user && user.userRole !== "Self-Serve") {
      errors = true;
      setAccountCustodianInstitutionError("This field is required.");
    }

    if (accountCustodialNumber === "" && user && user.userRole !== "Self-Serve") {
      errors = true;
      setAccountCustodialNumberError("This field is required.");
    }

    /*if (accountQualification === "") {
      errors = true;
      setAccountQualificationError(true);
    } else */

    if (accountType === "JOINT") {
      if (jointAccountType === "") {
        errors = true;
        setJointAccountTypeError("This field is required.");
      }

      if (jointHolderFirstName === "") {
        errors = true;
        setJointHolderFirstNameError("This field is required.");
      }

      if (jointHolderLastName === "") {
        errors = true;
        setJointHolderLastNameError("This field is required.");
      }

      if (jointHolderEmail === "") {
        errors = true;
        setJointHolderEmailError("This field is required.");
      } else if (!isEmailValid(jointHolderEmail)) {
        errors = true;
        setJointHolderEmailError("Please enter a valid email.");
      }

      if (jointHolderPhoneNumber === "") {
        errors = true;
        setJointHolderPhoneNumberError("This field is required.");
      } else if (stripOutDigits(jointHolderPhoneNumber).length < 11) {
        errors = true;
        setJointHolderPhoneNumberError("Please enter a valid phone number.");
      }

      if (jointHolderIsUSCitizen === "") {
        errors = true;
        setJointHolderIsUSCitizenError("This field is required.");
      }

      if (jointHolderAddressLine1 === "") {
        errors = true;
        setJointHolderAddressLine1Error("This field is required.");
      }

      if (jointHolderCity === "") {
        errors = true;
        setJointHolderCityError("This field is required.");
      }

      if (jointHolderState === "") {
        errors = true;
        setJointHolderStateError("This field is required.");
      }

      if (jointHolderPostalCode === "") {
        errors = true;
        setJointHolderPostalCodeError("This field is required.");
      }

      if (jointHolderTaxIDNumber === "") {
        errors = true;
        setJointHolderTaxIDNumberError("This field is required.");
      } else if (!isValidSSN(jointHolderTaxIDNumber)) {
        errors = true;
        setJointHolderTaxIDNumberError("Please enter a valid ssn.");
      }

      if (jointHolderDOB === "") {
        errors = true;
        setJointHolderDOBError("This field is required.");
      } else if (!isDobValid(jointHolderDOB)) {
        errors = true;
        setJointHolderDOBError("Please enter a valid dob.");
      }
    } else if (accountType === "LT" || accountType === "TRUST") {
      if (entityName === "") {
        errors = true;
        setEntityNameError("This field is required.");
      }

      if (entityAddressLine1 === "") {
        errors = true;
        setEntityAddressLine1Error("This field is required.");
      }

      if (entityCity === "") {
        errors = true;
        setEntityCityError("This field is required.");
      }

      if (entityState === "") {
        errors = true;
        setEntityStateError("This field is required.");
      }

      if (entityPostalCode === "") {
        errors = true;
        setEntityPostalCodeError("This field is required.");
      }

      if (entityPhoneNumber === "") {
        errors = true;
        setEntityPhoneNumberError("This field is required.");
      } else if (stripOutDigits(entityPhoneNumber).length < 11) {
        errors = true;
        setEntityPhoneNumberError("Please enter a valid phone number.");
      }

      if (entityStateOfIncorporation === "" && entityCountry === "United States") {
        errors = true;
        setEntityStateOfIncorporationError("This field is required.");
      }

      let values = [...relatedPartyInfo];

      if (clientIsUSCitizen === "No" && citizenship === "") {
        errors = true;
        setClientIsUSCitizenError("This field is required.");
      }

      if (hasTrustees === true) {
        requestData = relatedPartyInfo.map((inputField, index) => {
          if (inputField.relatedPartyType === "Entity") {
            if (inputField.entityType === "") {
              errors = true;
              values[index]["entityTypeError"] = "This field is required.";
            }

            if (inputField.entityName === "") {
              errors = true;
              values[index]["entityNameError"] = "This field is required.";
            }

            if (inputField.entityPercentOwned === "") {
              errors = true;
              values[index]["entityPercentOwnedError"] =
                "This field is required.";
            }

            if (inputField.entityStateOfIncorporation === "" && inputField.entityCountry === "United States") {
              errors = true;
              values[index]["entityStateOfIncorporationError"] =
                "This field is required.";
            }

            if (
              inputField.entityType !== "LT" &&
              inputField.entityType !== "SM_LLC" &&
              inputField.entityTaxIDNumber === ""
            ) {
              errors = true;
              values[index]["entityTaxIDNumberError"] =
                "This field is required.";
            }

            if (inputField.entityPhoneNumber === "") {
              errors = true;
              values[index]["entityPhoneNumberError"] =
                "This field is required.";
            }

            if (inputField.entityAddressLine1 === "") {
              errors = true;
              values[index]["entityAddressLine1Error"] =
                "This field is required.";
            }

            if (inputField.entityCity === "") {
              errors = true;
              values[index]["entityCityError"] = "This field is required.";
            }

            if (inputField.entityState === "") {
              errors = true;
              values[index]["entityStateError"] = "This field is required.";
            }

            if (inputField.entityPostalCode === "") {
              errors = true;
              values[index]["entityPostalCodeError"] =
                "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "") {
              errors = true;
              values[index]["entityMailingAddressDifferentError"] =
                "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "Yes") {
              if (inputField.entityMailingAddressLine1 === "") {
                errors = true;
                values[index]["entityMailingAddressLine1Error"] =
                  "This field is required.";
              }

              if (inputField.entityMailingCity === "") {
                errors = true;
                values[index]["entityMailingCityError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingState === "") {
                errors = true;
                values[index]["entityMailingStateError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingPostalCode === "") {
                errors = true;
                values[index]["entityMailingPostalCodeError"] =
                  "This field is required.";
              }
            }

            if (inputField.entityIsTrustManagementCompany === "") {
              errors = true;
              values[index]["entityIsTrustManagementCompanyError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonFirstName === "") {
              errors = true;
              values[index]["entityControlPersonFirstNameError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonLastName === "") {
              errors = true;
              values[index]["entityControlPersonLastNameError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonEmail === "") {
              errors = true;
              values[index]["entityControlPersonEmailError"] =
                "This field is required.";
            } else if (!isEmailValid(inputField.entityControlPersonEmail)) {
              errors = true;
              values[index]["entityControlPersonEmailError"] =
                "Please enter a valid email.";
            }

            if (inputField.entityControlPersonPhoneNumber === "") {
              errors = true;
              values[index]["entityControlPersonPhoneNumberError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonIsUSBased === "") {
              errors = true;
              values[index]["entityControlPersonIsUSBasedError"] =
                "This field is required.";
            } else if (inputField.entityControlPersonIsUSBased === "No") {
              errors = true;
              values[index]["entityControlPersonIsUSBasedError"] =
                "We only support US-based individuals at this time.";
            }

            if (inputField.entityControlPersonIsUSCitizen === "") {
              errors = true;
              values[index]["entityControlPersonIsUSCitizenError"] =
                "This field is required.";
            } else if (inputField.entityControlPersonIsUSCitizen === "No") {
              errors = true;
              values[index]["entityControlPersonIsUSCitizenError"] =
                "We only support US citizens at this time.";
            }

            if (inputField.entityControlPersonPIIAvailable === "") {
              errors = true;
              values[index]["entityControlPersonPIIAvailableError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonPIIAvailable === "Yes") {
              if (inputField.entityControlPersonAddressLine1 === "") {
                errors = true;
                values[index]["entityControlPersonAddressLine1Error"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonCity === "") {
                errors = true;
                values[index]["entityControlPersonCityError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonState === "") {
                errors = true;
                values[index]["entityControlPersonStateError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonPostalCode === "") {
                errors = true;
                values[index]["entityControlPersonPostalCodeError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonDOB === "") {
                errors = true;
                values[index]["entityControlPersonDOBError"] =
                  "This field is required.";
              } else if (!isDobValid(inputField.entityControlPersonDOB)) {
                errors = true;
                values[index]["entityControlPersonDOBError"] =
                  "Please enter a valid dob.";
              }

              if (inputField.entityControlPersonTaxIDNumber === "") {
                errors = true;
                values[index]["entityControlPersonTaxIDNumberError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonPassportDocument === "") {
                errors = true;
                values[index]["entityControlPersonPassportDocumentError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonProofOfAddress === "") {
                errors = true;
                values[index]["entityControlPersonProofOfAddressError"] =
                  "This field is required.";
              }
            }

            if (inputField.entityCertificateOfFormation === "") {
              errors = true;
              values[index]["entityCertificateOfFormationError"] =
                "This field is required.";
            }

            if (inputField.entityProofOfAddress === "") {
              errors = true;
              values[index]["entityProofOfAddressError"] =
                "This field is required.";
            }

            return {
              rp_type: "Entity",
              role: "TST",
              entity_type: inputField.entityType,
              entity_name: inputField.entityName,
              entity_percent_owned: inputField.entityPercentOwned,
              entity_state_of_incorporation:
                inputField.entityStateOfIncorporation,
              ...(inputField.entityType !== "LT" &&
                inputField.entityType !== "SM_LLC" && {
                entity_tax_id_number: inputField.entityTaxIDNumber,
              }),
              entity_phone_number: stripOutDigits(inputField.entityPhoneNumber),
              entity_address_line_1: inputField.entityAddressLine1,
              entity_address_line_2: inputField.entityAddressLine2,
              entity_city: inputField.entityCity,
              entity_state: inputField.entityState,
              entity_postal_code: inputField.entityPostalCode,
              address_type:
                inputField.entityMailingAddressDifferent === "Yes" ? "R" : "RM",
              ...(inputField.entityMailingAddressDifferent === "Yes" && {
                entity_mailing_address_line_1:
                  inputField.entityMailingAddressLine1,
                entity_mailing_address_line_2:
                  inputField.entityMailingAddressLine2,
                entity_mailing_city: inputField.entityMailingCity,
                entity_mailing_state: inputField.entityMailingState,
                entity_mailing_postal_code: inputField.entityMailingPostalCode,
              }),
              entity_is_trust_management_company:
                inputField.entityIsTrustManagementCompany === "Yes",
              entity_control_person_first_name:
                inputField.entityControlPersonFirstName,
              entity_control_person_last_name:
                inputField.entityControlPersonLastName,
              entity_control_person_email: inputField.entityControlPersonEmail,
              entity_control_person_phone_number: stripOutDigits(
                inputField.entityControlPersonPhoneNumber
              ),
              entity_control_person_is_us_based:
                inputField.entityControlPersonIsUSBased === "Yes",
              entity_control_person_is_us_citizen:
                inputField.entityControlPersonIsUSCitizen === "Yes",
              ...(inputField.entityControlPersonPIIAvailable === "Yes"
                ? {
                  entity_control_person_address_line_1:
                    inputField.entityControlPersonAddressLine1,
                  entity_control_person_address_line_2:
                    inputField.entityControlPersonAddressLine2,
                  entity_control_person_city:
                    inputField.entityControlPersonCity,
                  entity_control_person_state:
                    inputField.entityControlPersonState,
                  entity_control_person_postal_code:
                    inputField.entityControlPersonPostalCode,
                  entity_control_person_dob:
                    inputField.entityControlPersonDOB,
                  entity_control_person_tax_id_number:
                    inputField.entityControlPersonTaxIDNumber,
                }
                : {}),
              documents: [
                {
                  category: "Entity Proof of Address",
                  b64_string: inputField.entityProofOfAddress.b64_string,
                  name: "Entity Proof of Address",
                },
                {
                  category: "Entity Certificate of Formation",
                  b64_string:
                    inputField.entityCertificateOfFormation.b64_string,
                  name: "Entity Certificate of Formation",
                },
                ...(inputField.entityControlPersonPIIAvailable === "Yes"
                  ? [
                    {
                      category: "Entity Control Person Passport Document",
                      b64_string:
                        inputField.entityControlPersonPassportDocument
                          .b64_string,
                      name: "Entity Control Person Passport Document",
                    },
                    {
                      category: "Entity Control Person Proof of Address",
                      b64_string:
                        inputField.entityControlPersonProofOfAddress
                          .b64_string,
                      name: "Entity Control Person Proof of Address",
                    },
                  ]
                  : []),
              ],
            };
          } else {
            if (inputField.firstName === "") {
              values[index]["firstNameError"] = "This field is required.";
              errors = true;
            }

            if (inputField.isUSCitizen === "") {
              values[index]["isUSCitizenError"] = "This field is required.";
            }

            if (inputField.lastName === "") {
              values[index]["lastNameError"] = "This field is required.";
              errors = true;
            }

            if (inputField.email === "") {
              values[index]["emailError"] = "This field is required.";
              errors = true;
            } else if (!isEmailValid(inputField.email)) {
              values[index]["emailError"] = "Please enter a valid email.";
              errors = true;
            }

            if (inputField.phoneNumber === "") {
              values[index]["phoneNumberError"] = "This field is required.";
              errors = true;
            } else if (stripOutDigits(inputField.phoneNumber).length < 11) {
              values[index]["phoneNumberError"] =
                "Please enter a valid phone number.";
              errors = true;
            }

            if (inputField.isUSCitizen === "") {
              errors = true;
              values[index]["isUSCitizenError"] = "This field is required.";
            }

            if (inputField.addressLine1 === "") {
              values[index]["addressLine1Error"] = "This field is required.";
              errors = true;
            }

            if (inputField.city === "") {
              values[index]["cityError"] = "This field is required.";
              errors = true;
            }

            if (
              inputField.state === "" &&
              inputField.country === "United States"
            ) {
              values[index]["stateError"] = "This field is required.";
              errors = true;
            }

            if (inputField.postalCode === "") {
              values[index]["postalCodeError"] = "This field is required.";
              errors = true;
            }

            if (inputField.dateOfBirth === "") {
              values[index]["dateOfBirthError"] = "This field is required.";
              errors = true;
            } else if (!isDobValid(inputField.dateOfBirth)) {
              values[index]["dateOfBirthError"] =
                "Please enter a valid date of birth.";
              errors = true;
            }

            if (inputField.idNum === "") {
              values[index]["idNumError"] = "This field is required.";
              errors = true;
            } else if (
              !isValidSSN(inputField.idNum) &&
              inputField.country === "United States"
            ) {
              values[index]["idNumError"] = "Please enter a valid ssn.";
              errors = true;
            }

            if (inputField.percentOwnership === "") {
              values[index]["percentOwnershipError"] =
                "This field is required.";
              errors = true;
            }

            return {
              rp_type: "Person",
              first_name: inputField.firstName,
              last_name: inputField.lastName,
              email: inputField.email,
              phone_number: stripOutDigits(inputField.phoneNumber),
              address_line_1: inputField.addressLine1,
              address_line_2: inputField.addressLine2,
              city: inputField.city,
              state: inputField.state,
              postal_code: inputField.postalCode,
              address_type: "RM",
              date_of_birth: inputField.dateOfBirth,
              id_type: inputField.country === "United States" ? "SSN" : "PASS",
              id_number: inputField.idNum,
              domicile_country: inputField.country,
              role: "TST",
              is_us_citizen: inputField.isUSCitizen,
              percent_owned: inputField.percentOwnership,
              country: inputField.country,
              ...(inputField.isUSCitizen !== "Yes"
                ? { citizenship: inputField.citizenship }
                : { citizenship: "United States" }),
            };
          }
        });

        setRelatedPartyInfo(values);
      } else if (hasTrustees === "") {
        errors = true;
      }

      if (accountType === "TRUST") {
        if (entityTaxIDNumber === "") {
          errors = true;
          setEntityTaxIDNumberError("This field is required.");
        }
        if (entityTaxIDNumber.replace(/\D/g, "").length !== 9 && entityCountry === "United States") {
          errors = true;
          setEntityTaxIDNumberError("EIN must have 9 digits.");
        }

        if (hasAlternateSignatory === "") {
          errors = true;
          setHasAlternateSignatoryError("This field is required.");
        } else if (hasAlternateSignatory === "Yes") {
          if (alternateSignatoryFullName === "") {
            errors = true;
            setAlternateSignatoryFullNameError("This field is required.");
          }

          if (alternateSignatoryEmail === "") {
            errors = true;
            setAlternateSignatoryEmailError("This field is required.");
          } else if (!isEmailValid(alternateSignatoryEmail)) {
            errors = true;
            setAlternateSignatoryEmailError("Please enter a valid email.");
          }

          if (alternateSignatoryTitle === "") {
            errors = true;
            setAlternateSignatoryTitleError("This field is required.");
          }
        }
      }
    } else if (accountType === "SM_LLC") {
      if (entityName === "") {
        errors = true;
        setEntityNameError("This field is required.");
      }

      if (entityAddressLine1 === "") {
        errors = true;
        setEntityAddressLine1Error("This field is required.");
      }

      if (entityCity === "") {
        errors = true;
        setEntityCityError("This field is required.");
      }

      if (entityState === "") {
        errors = true;
        setEntityStateError("This field is required.");
      }

      if (entityPostalCode === "") {
        errors = true;
        setEntityPostalCodeError("This field is required.");
      }

      if (entityPhoneNumber === "") {
        errors = true;
        setEntityPhoneNumberError("This field is required.");
      } else if (stripOutDigits(entityPhoneNumber).length < 11) {
        errors = true;
        setEntityPhoneNumberError("Please enter a valid phone number.");
      }

      if (entityStateOfIncorporation === "" && entityCountry === "United States") {
        errors = true;
        setEntityStateOfIncorporationError("This field is required.");
      }
    } else if (["LLC", "S-CORP", "C-CORP", "GP", "LP"].includes(accountType)) {
      if (entityName === "") {
        errors = true;
        setEntityNameError("This field is required.");
      }

      if (entityAddressLine1 === "") {
        errors = true;
        setEntityAddressLine1Error("This field is required.");
      }

      if (entityCity === "") {
        errors = true;
        setEntityCityError("This field is required.");
      }

      if (entityState === "") {
        errors = true;
        setEntityStateError("This field is required.");
      }

      if (entityPostalCode === "") {
        errors = true;
        setEntityPostalCodeError("This field is required.");
      }

      if (entityTaxIDNumber === "") {
        errors = true;
        setEntityTaxIDNumberError("This field is required.");
      }
  
      if (entityCountry === "") {
        errors = true;
        setEntityCountryError("This field is required.");
      }

      if (entityTaxIDNumber.replace(/\D/g, "").length !== 9 && entityCountry === "United States") {
        errors = true;
        setEntityTaxIDNumberError("EIN must have 9 digits.");
      }

      if (entityPhoneNumber === "") {
        errors = true;
        setEntityPhoneNumberError("This field is required.");
      } else if (stripOutDigits(entityPhoneNumber).length < 11) {
        errors = true;
        setEntityPhoneNumberError("Please enter a valid phone number.");
      }

      if (entityStateOfIncorporation === "" && entityCountry === "United States") {
        errors = true;
        setEntityStateOfIncorporationError("This field is required.");
      }
    }

    if (
      (["LLC", "S-CORP", "C-CORP", "GP", "LP"].includes(accountType) ||
        ((accountType === "LT" || accountType === "TRUST") &&
          hasTrustManagementCompany === false &&
          hasTrustManagementCompany !== "")) &&
      hasBeneficialOwners === ""
    ) {
      errors = true;
    } else if (hasBeneficialOwners === true) {
      let beneficialOwnerValues = [...beneficialOwnerInfo];
      beneficialOwnerRequestData = beneficialOwnerInfo.map(
        (inputField, index) => {
          if (inputField.relatedPartyType === "Entity") {
            if (inputField.entityType === "") {
              errors = true;
              beneficialOwnerValues[index]["entityTypeError"] =
                "This field is required.";
            }

            if (inputField.entityName === "") {
              errors = true;
              beneficialOwnerValues[index]["entityNameError"] =
                "This field is required.";
            }

            if (inputField.entityPercentOwned === "") {
              errors = true;
              beneficialOwnerValues[index]["entityPercentOwnedError"] =
                "This field is required.";
            } else if (parseInt(inputField.entityPercentOwned) === 0) {
              errors = true;
              beneficialOwnerValues[index]["entityPercentOwnedError"] =
                "Percent owned must be a value greater than 0.";
            }

            if (inputField.entityStateOfIncorporation === "" && inputField.entityCountry === "United States") {
              errors = true;
              beneficialOwnerValues[index]["entityStateOfIncorporationError"] =
                "This field is required.";
            }

            if (
              inputField.entityType !== "LT" &&
              inputField.entityType !== "SM_LLC" &&
              inputField.entityTaxIDNumber === ""
            ) {
              errors = true;
              beneficialOwnerValues[index]["entityTaxIDNumberError"] =
                "This field is required.";
            }

            if (inputField.entityPhoneNumber === "") {
              errors = true;
              beneficialOwnerValues[index]["entityPhoneNumberError"] =
                "This field is required.";
            }

            if (inputField.entityAddressLine1 === "") {
              errors = true;
              beneficialOwnerValues[index]["entityAddressLine1Error"] =
                "This field is required.";
            }

            if (inputField.entityCity === "") {
              errors = true;
              beneficialOwnerValues[index]["entityCityError"] =
                "This field is required.";
            }

            if (inputField.entityState === "") {
              errors = true;
              beneficialOwnerValues[index]["entityStateError"] =
                "This field is required.";
            }

            if (inputField.entityPostalCode === "") {
              errors = true;
              beneficialOwnerValues[index]["entityPostalCodeError"] =
                "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "") {
              errors = true;
              beneficialOwnerValues[index][
                "entityMailingAddressDifferentError"
              ] = "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "Yes") {
              if (inputField.entityMailingAddressLine1 === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingAddressLine1Error"] =
                  "This field is required.";
              }

              if (inputField.entityMailingCity === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingCityError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingState === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingStateError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingPostalCode === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingPostalCodeError"] =
                  "This field is required.";
              }
            }

            // if (inputField.entityIsTrustManagementCompany === "") {
            //   errors = true;
            //   beneficialOwnerValues[index]["entityIsTrustManagementCompanyError"] =
            //     "This field is required.";
            // }

            if (inputField.entityType === "TRUST") {
              if (inputField.entityNestedControlEntityTrusteeType === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityNestedControlEntityTrusteeTypeError"
                ] = "This field is required.";
              } else if (
                inputField.entityNestedControlEntityTrusteeType === "Person"
              ) {
                if (inputField.entityControlPersonFirstName === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonFirstNameError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonLastName === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonLastNameError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonEmail === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonEmailError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPhoneNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPhoneNumberError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonIsUSBased === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSBasedError"
                  ] = "This field is required.";
                } else if (inputField.entityControlPersonIsUSBased === "No") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSBasedError"
                  ] = "We only support US-based individuals at this time.";
                }

                if (inputField.entityControlPersonIsUSCitizen === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSCitizenError"
                  ] = "This field is required.";
                } else if (inputField.entityControlPersonIsUSCitizen === "No") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSCitizenError"
                  ] = "We only support US citizens at this time.";
                }

                if (inputField.entityControlPersonAddressLine1 === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonAddressLine1Error"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonCity === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonCityError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonState === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonStateError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPostalCode === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPostalCodeError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonDOB === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "This field is required.";
                } else if (!isDobValid(inputField.entityControlPersonDOB)) {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "Please enter a valid dob.";
                }

                if (inputField.entityControlPersonTaxIDNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonTaxIDNumberError"
                  ] = "This field is required.";
                } else if (
                  !isValidSSN(inputField.entityControlPersonTaxIDNumber)
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonTaxIDNumberError"
                  ] = "Please enter a valid ssn.";
                }

                if (inputField.entityControlPersonPassportDocument === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPassportDocumentError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonProofOfAddressError"
                  ] = "This field is required.";
                }

                if (inputField.entityCertificateOfFormation === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityCertificateOfFormationError"
                  ] = "This field is required.";
                }

                if (inputField.entityProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityProofOfAddressError"] =
                    "This field is required.";
                }
              } else {
                if (inputField.entityNestedControlEntityType === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityTypeError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityName === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityNameError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityPercentOwned === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityPercentOwnedError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityStateOfIncorporation ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityStateOfIncorporationError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityPhoneNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityPhoneNumberError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityTaxIDNumber === "" &&
                  inputField.entityNestedControlEntityType !== "SM_LLC"
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityTaxIDNumberError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityAddressLine1 === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityAddressLine1Error"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityCity === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityCityError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityState === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityStateError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityPostalCode === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityPostalCodeError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityIsTrustManagementCompany ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityIsTrustManagementCompanyError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonFirstName ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonFirstNameError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonLastName ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonLastNameError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonEmail === ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonEmailError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonPhoneNumber ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonPhoneNumberError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonIsUSBased ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSBasedError"
                  ] = "This field is required.";
                } /*else if (
                  inputField.entityNestedControlEntityControlPersonIsUSBased ===
                  "No"
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSBasedError"
                  ] = "We only support US-based individuals at this time.";
                }*/

                if (
                  inputField.entityNestedControlEntityControlPersonIsUSCitizen ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSCitizenError"
                  ] = "This field is required.";
                } /*else if (
                  inputField.entityNestedControlEntityControlPersonIsUSCitizen ===
                  "No"
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSCitizenError"
                  ] = "We only support US citizens at this time.";
                }*/

                if (
                  inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonPIIAvailableError"
                  ] = "This field is required.";
                } else if (
                  inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                  "Yes"
                ) {
                  if (
                    inputField.entityNestedControlEntityControlPersonTaxIDNumber ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonTaxIDNumberError"
                    ] = "This field is required.";
                  } else if (
                    !isValidSSN(
                      inputField.entityNestedControlEntityControlPersonTaxIDNumber
                    )
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonTaxIDNumberError"
                    ] = "Please enter a valid ssn.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonDOB === ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonDOBError"
                    ] = "This field is required.";
                  } else if (
                    !isDobValid(
                      inputField.entityNestedControlEntityControlPersonDOB
                    )
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonDOBError"
                    ] = "Please enter a valid dob.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonAddressLine1 ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonAddressLine1Error"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonCity === ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonCityError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonState ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonStateError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonPostalCode ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonPostalCodeError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonPassportDocument ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonPassportDocumentError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonProofOfAddress ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonProofOfAddressError"
                    ] = "This field is required.";
                  }
                }

                if (inputField.entityCertificateOfFormation === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityCertificateOfFormationError"
                  ] = "This field is required.";
                }

                if (inputField.entityProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityProofOfAddressError"] =
                    "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityCertificateOfFormation ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityCertificateOfFormationError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityProofOfAddressError"
                  ] = "This field is required.";
                }
              }
            } else {
              if (inputField.entityControlPersonFirstName === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonFirstNameError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonLastName === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonLastNameError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonEmail === "") {
                errors = true;
                beneficialOwnerValues[index]["entityControlPersonEmailError"] =
                  "This field is required.";
              } else if (!isEmailValid(inputField.entityControlPersonEmail)) {
                errors = true;
                beneficialOwnerValues[index]["entityControlPersonEmailError"] =
                  "Please enter a valid email.";
              }

              if (inputField.entityControlPersonPhoneNumber === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonPhoneNumberError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonIsUSBased === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSBasedError"
                ] = "This field is required.";
              } /*else if (inputField.entityControlPersonIsUSBased === "No") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSBasedError"
                ] = "We only support US-based individuals at this time.";
              }*/

              if (inputField.entityControlPersonIsUSCitizen === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSCitizenError"
                ] = "This field is required.";
              } /*else if (inputField.entityControlPersonIsUSCitizen === "No") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSCitizenError"
                ] = "We only support US citizens at this time.";
              }*/

              if (inputField.entityControlPersonPIIAvailable === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonPIIAvailableError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonPIIAvailable === "Yes") {
                if (inputField.entityControlPersonAddressLine1 === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonAddressLine1Error"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonCity === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonCityError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonState === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonStateError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPostalCode === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPostalCodeError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonDOB === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "This field is required.";
                } else if (!isDobValid(inputField.entityControlPersonDOB)) {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "Please enter a valid dob.";
                }

                if (inputField.entityControlPersonTaxIDNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonTaxIDNumberError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPassportDocument === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPassportDocumentError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonProofOfAddressError"
                  ] = "This field is required.";
                }
              }

              if (inputField.entityCertificateOfFormation === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityCertificateOfFormationError"
                ] = "This field is required.";
              }

              if (inputField.entityProofOfAddress === "") {
                errors = true;
                beneficialOwnerValues[index]["entityProofOfAddressError"] =
                  "This field is required.";
              }
            }

            return {
              rp_type: "Entity",
              role: "BO",
              entity_type: inputField.entityType,
              entity_name: inputField.entityName,
              entity_percent_owned: inputField.entityPercentOwned,
              entity_state_of_incorporation:
                inputField.entityStateOfIncorporation,
              ...(inputField.entityType !== "LT" &&
                inputField.entityType !== "SM_LLC" && {
                entity_tax_id_number: inputField.entityTaxIDNumber,
              }),
              entity_phone_number: stripOutDigits(inputField.entityPhoneNumber),
              entity_address_line_1: inputField.entityAddressLine1,
              entity_address_line_2: inputField.entityAddressLine2,
              entity_city: inputField.entityCity,
              entity_state: inputField.entityState,
              entity_postal_code: inputField.entityPostalCode,
              address_type:
                inputField.entityMailingAddressDifferent === "Yes" ? "R" : "RM",
              entity_mailing_address_line_1:
                inputField.entityMailingAddressLine1,
              entity_mailing_address_line_2:
                inputField.entityMailingAddressLine2,
              entity_mailing_city: inputField.entityMailingCity,
              entity_mailing_state: inputField.entityMailingState,
              entity_mailing_postal_code: inputField.entityMailingPostalCode,
              ...(inputField.entityType !== "TRUST" && {
                entity_control_person_first_name:
                  inputField.entityControlPersonFirstName,
                entity_control_person_last_name:
                  inputField.entityControlPersonLastName,
                entity_control_person_email:
                  inputField.entityControlPersonEmail,
                entity_control_person_phone_number: stripOutDigits(
                  inputField.entityControlPersonPhoneNumber
                ),
                entity_control_person_is_us_based:
                  inputField.entityControlPersonIsUSBased,
                entity_control_person_is_us_citizen:
                  inputField.entityControlPersonIsUSCitizen,
                ...(inputField.entityControlPersonPIIAvailable === "Yes"
                  ? {
                    entity_control_person_address_line_1:
                      inputField.entityControlPersonAddressLine1,
                    entity_control_person_address_line_2:
                      inputField.entityControlPersonAddressLine2,
                    entity_control_person_city:
                      inputField.entityControlPersonCity,
                    entity_control_person_state:
                      inputField.entityControlPersonState,
                    entity_control_person_postal_code:
                      inputField.entityControlPersonPostalCode,
                    entity_control_person_dob:
                      inputField.entityControlPersonDOB,
                    entity_control_person_tax_id_number:
                      inputField.entityControlPersonTaxIDNumber,
                  }
                  : {}),
                documents: [
                  {
                    category: "Entity Proof of Address",
                    b64_string: inputField.entityProofOfAddress.b64_string,
                    name: "Entity Proof of Address",
                  },
                  {
                    category: "Entity Certificate of Formation",
                    b64_string:
                      inputField.entityCertificateOfFormation.b64_string,
                    name: "Entity Certificate of Formation",
                  },
                  ...(inputField.entityControlPersonPIIAvailable === "Yes"
                    ? [
                      {
                        category: "Entity Control Person Passport Document",
                        b64_string:
                          inputField.entityControlPersonPassportDocument
                            .b64_string,
                        name: "Entity Control Person Passport Document",
                      },
                      {
                        category: "Entity Control Person Proof of Address",
                        b64_string:
                          inputField.entityControlPersonProofOfAddress
                            .b64_string,
                        name: "Entity Control Person Proof of Address",
                      },
                    ]
                    : []),
                ],
              }),
              ...(inputField.entityType === "TRUST" && {
                ...(inputField.entityNestedControlEntityTrusteeType ===
                  "Person" && {
                  entity_control_person_first_name:
                    inputField.entityControlPersonFirstName,
                  entity_control_person_last_name:
                    inputField.entityControlPersonLastName,
                  entity_control_person_email:
                    inputField.entityControlPersonEmail,
                  entity_control_person_phone_number: stripOutDigits(
                    inputField.entityControlPersonPhoneNumber
                  ),
                  entity_control_person_is_us_based:
                    inputField.entityControlPersonIsUSBased,
                  entity_control_person_is_us_citizen:
                    inputField.entityControlPersonIsUSCitizen,
                  entity_control_person_address_line_1:
                    inputField.entityControlPersonAddressLine1,
                  entity_control_person_address_line_2:
                    inputField.entityControlPersonAddressLine2,
                  entity_control_person_city:
                    inputField.entityControlPersonCity,
                  entity_control_person_state:
                    inputField.entityControlPersonState,
                  entity_control_person_postal_code:
                    inputField.entityControlPersonPostalCode,
                  entity_control_person_dob: inputField.entityControlPersonDOB,
                  entity_control_person_tax_id_number:
                    inputField.entityControlPersonTaxIDNumber,
                  documents: [
                    {
                      category: "Entity Proof of Address",
                      b64_string: inputField.entityProofOfAddress.b64_string,
                      name: "Entity Proof of Address",
                    },
                    {
                      category: "Entity Certificate of Formation",
                      b64_string:
                        inputField.entityCertificateOfFormation.b64_string,
                      name: "Entity Certificate of Formation",
                    },

                    {
                      category: "Entity Control Person Passport Document",
                      b64_string:
                        inputField.entityControlPersonPassportDocument
                          .b64_string,
                      name: "Entity Control Person Passport Document",
                    },
                    {
                      category: "Entity Control Person Proof of Address",
                      b64_string:
                        inputField.entityControlPersonProofOfAddress.b64_string,
                      name: "Entity Control Person Proof of Address",
                    },
                  ],
                }),
                ...(inputField.entityNestedControlEntityTrusteeType ===
                  "Entity" && {
                  nested_rp_type: "Entity",
                  nested_role: "TST",
                  nested_entity_type: inputField.entityNestedControlEntityType,
                  nested_entity_name: inputField.entityNestedControlEntityName,
                  nested_entity_state_of_incorporation:
                    inputField.entityNestedControlEntityStateOfIncorporation,
                  nested_entity_percent_owned:
                    inputField.entityNestedControlEntityPercentOwned,
                  nested_entity_phone_number: stripOutDigits(
                    inputField.entityNestedControlEntityPhoneNumber
                  ),
                  nested_entity_tax_id_number:
                    inputField.entityNestedControlEntityTaxIDNumber,
                  nested_entity_address_type: "RM",
                  nested_entity_address_line_1:
                    inputField.entityNestedControlEntityAddressLine1,
                  nested_entity_address_line_2:
                    inputField.entityNestedControlEntityAddressLine2,
                  nested_entity_city: inputField.entityNestedControlEntityCity,
                  nested_entity_state:
                    inputField.entityNestedControlEntityState,
                  nested_entity_postal_code:
                    inputField.entityNestedControlEntityPostalCode,
                  nested_entity_is_trust_management_company:
                    inputField.entityNestedControlEntityIsTrustManagementCompany,
                  nested_entity_mailing_address_line_1: "",
                  nested_entity_mailing_address_line_2: "",
                  nested_entity_mailing_city: "",
                  nested_entity_mailing_state: "",
                  nested_entity_mailing_postal_code: "",
                  nested_entity_control_person_first_name:
                    inputField.entityNestedControlEntityControlPersonFirstName,
                  nested_entity_control_person_last_name:
                    inputField.entityNestedControlEntityControlPersonLastName,
                  nested_entity_control_person_email:
                    inputField.entityNestedControlEntityControlPersonEmail,
                  nested_entity_control_person_phone_number: stripOutDigits(
                    inputField.entityNestedControlEntityControlPersonPhoneNumber
                  ),
                  nested_entity_control_person_is_us_based:
                    inputField.entityNestedControlEntityControlPersonIsUSBased,
                  nested_entity_control_person_is_us_citizen:
                    inputField.entityNestedControlEntityControlPersonIsUSCitizen,
                  nested_entity_control_person_address_line_1:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? inputField.entityNestedControlEntityControlPersonAddressLine1
                      : "",
                  nested_entity_control_person_address_line_2:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? inputField.entityNestedControlEntityAddressLine2
                      : "",
                  nested_entity_control_person_city:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? inputField.entityNestedControlEntityControlPersonCity
                      : "",
                  nested_entity_control_person_state:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? inputField.entityNestedControlEntityControlPersonState
                      : "",
                  nested_entity_control_person_postal_code:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? inputField.entityNestedControlEntityControlPersonPostalCode
                      : "",
                  nested_entity_control_person_dob:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? inputField.entityNestedControlEntityControlPersonDOB
                      : "",
                  nested_entity_control_person_tax_id_number:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? inputField.entityNestedControlEntityControlPersonTaxIDNumber
                      : "",
                  documents: [
                    {
                      category: "Trust Proof of Address",
                      b64_string: inputField.entityProofOfAddress.b64_string,
                      name: "Trust Proof of Address",
                    },
                    {
                      category: "Trust Certificate of Formation",
                      b64_string:
                        inputField.entityCertificateOfFormation.b64_string,
                      name: "Trust Certificate of Formation",
                    },
                  ],
                  nested_documents: [
                    {
                      category: "Trustee Certificate of Formation",
                      b64_string:
                        inputField
                          .entityNestedControlEntityCertificateOfFormation
                          .b64_string,
                      name: "Trust Certificate of Formation",
                    },
                    {
                      category: "Trustee Proof of Address",
                      b64_string:
                        inputField.entityNestedControlEntityProofOfAddress
                          .b64_string,
                      name: "Trust Proof of Address",
                    },
                    ...(inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                      "Yes"
                      ? [
                        {
                          category:
                            "Trustee Entity Control Person Passport Document",
                          b64_string:
                            inputField
                              .entityNestedControlEntityControlPersonPassportDocument
                              .b64_string,
                          name: "Trustee Entity Control Person Passport Document",
                        },
                        {
                          category:
                            "Trustee Entity Control Person Proof of Address",
                          b64_string:
                            inputField
                              .entityNestedControlEntityControlPersonProofOfAddress
                              .b64_string,
                          name: "Trustee Entity Control Person Proof of Address",
                        },
                      ]
                      : []),
                  ],
                }),
              }),
            };
          } else {
            if (inputField.firstName === "") {
              beneficialOwnerValues[index]["firstNameError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.lastName === "") {
              beneficialOwnerValues[index]["lastNameError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.email === "") {
              beneficialOwnerValues[index]["emailError"] =
                "This field is required.";
              errors = true;
            } else if (!isEmailValid(inputField.email)) {
              beneficialOwnerValues[index]["emailError"] =
                "Please enter a valid email.";
              errors = true;
            }

            if (inputField.phoneNumber === "") {
              beneficialOwnerValues[index]["phoneNumberError"] =
                "This field is required.";
              errors = true;
            } else if (stripOutDigits(inputField.phoneNumber).length < 11) {
              beneficialOwnerValues[index]["phoneNumberError"] =
                "Please enter a valid phone number.";
              errors = true;
            }

            if (inputField.isUSCitizen === "") {
              beneficialOwnerValues[index]["isUSCitizenError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.addressLine1 === "") {
              beneficialOwnerValues[index]["addressLine1Error"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.city === "") {
              beneficialOwnerValues[index]["cityError"] =
                "This field is required.";
              errors = true;
            }

            if (
              inputField.state === "" &&
              inputField.country === "United States"
            ) {
              beneficialOwnerValues[index]["stateError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.postalCode === "") {
              beneficialOwnerValues[index]["postalCodeError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.dateOfBirth === "") {
              beneficialOwnerValues[index]["dateOfBirthError"] =
                "This field is required.";
              errors = true;
            } else if (!isDobValid(inputField.dateOfBirth)) {
              beneficialOwnerValues[index]["dateOfBirthError"] =
                "Please enter a valid date of birth.";
              errors = true;
            }

            if (inputField.idNum === "") {
              beneficialOwnerValues[index]["idNumError"] =
                "This field is requred.";
              errors = true;
            } else if (
              !isValidSSN(inputField.idNum) &&
              inputField.country === "United States"
            ) {
              beneficialOwnerValues[index]["idNumError"] =
                "Please enter a valid ssn.";
              errors = true;
            }

            if (inputField.percentOwnership === "") {
              beneficialOwnerValues[index]["percentOwnershipError"] =
                "This field is required.";
              errors = true;
            }

            return {
              rp_type: "Person",
              first_name: inputField.firstName,
              last_name: inputField.lastName,
              email: inputField.email,
              phone_number: stripOutDigits(inputField.phoneNumber),
              address_line_1: inputField.addressLine1,
              address_line_2: inputField.addressLine2,
              city: inputField.city,
              state: inputField.state,
              postal_code: inputField.postalCode,
              address_type: "RM",
              date_of_birth: inputField.dateOfBirth,
              id_type: inputField.country === "United States" ? "SSN" : "PASS",
              id_number: inputField.idNum,
              domicile_country: inputField.country,
              role: "BO",
              is_us_citizen: inputField.isUSCitizen,
              percent_owned: inputField.percentOwnership,
              country: inputField.country,
              ...(inputField.isUSCitizen !== "Yes"
                ? { citizenship: inputField.citizenship }
                : { citizenship: "United States" }),
            };
          }
        }
      );

      setBeneficialOwnerInfo(beneficialOwnerValues);
    }

    console.log(errors);
    if (true) {
      let postData = {
        ...(user &&
          user.userRole !== "Advisor" &&
          !shareClients && {
          advisor_id: advisorId,
        }),
        ...(accountType === "TRUST" &&
          hasAlternateSignatory === "Yes" && {
          signer_information: {
            name: alternateSignatoryFullName,
            email: alternateSignatoryEmail,
            title: alternateSignatoryTitle,
          },
        }),

        client: {
          first_name: clientFirstName,
          last_name: clientLastName,
          email: clientEmail,
          phone_number: stripOutDigits(clientPhoneNumber),
          address_line_1: clientAddressLine1,
          address_line_2: clientAddressLine2,
          city: clientCity,
          state: clientState,
          postal_code: clientPostalCode,
          id_type: country === "United States" ? "SSN" : "PASS",
          id_num: clientTaxIdNumber,
          dob: clientDOB,
          is_us_citizen: clientIsUSCitizen,
          ...(clientIsUSCitizen !== "Yes"
            ? { citizenship: citizenship }
            : { citizenship: "United States" }),
          country: country,
        },
        account: {
          account_type: accountType,
          account_custodian_institution: accountCustodianInstitution,
          account_custodial_number: accountCustodialNumber,
          account_qualification: accountQualification,
          ...(accountType === "JOINT" && {
            joint_account_type: jointAccountType,
            joint_holder_first_name: jointHolderFirstName,
            joint_holder_last_name: jointHolderLastName,
            joint_holder_email: jointHolderEmail,
            joint_holder_phone_number: stripOutDigits(jointHolderPhoneNumber),
            joint_holder_address_line_1: jointHolderAddressLine1,
            joint_holder_address_line_2: jointHolderAddressLine2,
            joint_holder_city: jointHolderCity,
            joint_holder_state: jointHolderState,
            joint_holder_postal_code: jointHolderPostalCode,
            joint_holder_id_type: "SSN",
            joint_holder_id_num: jointHolderTaxIDNumber,
            joint_holder_dob: jointHolderDOB,
            joint_holder_is_us_citizen: jointHolderIsUSCitizen,
          }),
          ...(accountType === "SM_LLC" && {
            entity_name: entityName,
            entity_address_line_1: entityAddressLine1,
            entity_address_line_2: entityAddressLine2,
            entity_country: entityCountry, 
            entity_city: entityCity,
            entity_state: entityState,
            entity_postal_code: entityPostalCode,
            entity_phone_number: entityPhoneNumber,
            entity_state_of_incorporation: entityStateOfIncorporation,
          }),
          ...((accountType === "LT" || accountType === "TRUST") && {
            percent_owned: percentOwned,
            entity_name: entityName,
            entity_address_line_1: entityAddressLine1,
            entity_address_line_2: entityAddressLine2,
            entity_city: entityCity,
            entity_state: entityState,
            entity_country: entityCountry, 
            entity_postal_code: entityPostalCode,
            entity_phone_number: entityPhoneNumber,
            entity_state_of_incorporation: entityStateOfIncorporation,
            ...(accountType === "TRUST" && {
              entity_tax_id_type: (entityCountry==="United States") ?  "EIN" : "FTID" ,
              entity_tax_id_number: entityTaxIDNumber,
            }),
            has_related_party: hasTrustees || hasBeneficialOwners,
            related_parties: requestData.concat(beneficialOwnerRequestData),
          }),
          ...(["LLC", "S-CORP", "C-CORP", "GP", "LP"].includes(accountType) && {
            entity_name: entityName,
            entity_address_line_1: entityAddressLine1,
            entity_address_line_2: entityAddressLine2,
            entity_city: entityCity,
            entity_state: entityState,
            entity_country: entityCountry, 
            entity_postal_code: entityPostalCode,
            entity_phone_number: entityPhoneNumber,
            entity_state_of_incorporation: entityStateOfIncorporation,
            entity_tax_id_type: (entityCountry==="United States") ?  "EIN" : "FTID" ,
            entity_tax_id_number: entityTaxIDNumber,
            has_related_party: hasBeneficialOwners,
            ...(hasBeneficialOwners === true && {
              related_parties: beneficialOwnerRequestData,
            }),
          }),
        },
      };

      questions.map(function (q) {
        console.log(q);
        console.log(q.name, q.answer);
        postData = { ...postData, [q.name]: q.answer };

      });

      try {
        //await call goes here
        if (addAnother === true) {
          // console.log("Post data is: " + JSON.stringify(postData));
          // ToDo: Uncomment this when the endpoint is ready
          if (!errors) {
          await createFirmClient(postData); 
          window.location.reload(); }
        } else {
          // console.log("Post data is: " + JSON.stringify(postData));
          // ToDo: Uncomment this when the endpoint is ready
          if (!errors) {
            await createFirmClient(postData);
            user.userRole === 'Self-Serve' ? navigate("/") : navigate("/clients"); }
            else {
              console.log(postData);
            }
        }

      } catch (err) {
        {
        }
      }
    } else {
      return;
    }
  };


  return (
    <React.Fragment>
      <div className="row g-0 w-100 mt-2 pt-2 justify-content-center">
        <div className="container-xxl mt-5 mb-5" style={{ maxWidth: "628px" }}>
          <div
            className="row g-0 w-100 align-items-center mb-3"
            style={{ marginBottom: "28px" }}
          >
            <div className="d-flex align-items-center">
             {user.userRole !== 'Self-Serve' &&  <img
                src="https://d1swyfveypj49y.cloudfront.net/NewBackArrow.svg"
                style={{
                  width: "16px",
                  height: "14px",
                  cursor: "pointer",
                  marginRight: "12px",
                }}
                onClick={() => navigate("/clients")}
              /> }
              <h5 className="align-self-center mt-auto mb-auto">Add {user.userRole === 'Self-Serve' ? 'Account' : 'Client'}</h5>
            </div>
          </div>
          <div className="row g-0 w-100">
            <div className="col-12 add-client-container p-3">
              {user && !["Advisor", "Self-Serve"].includes(user.userRole) && !shareClients && (
                <div className="row g-0 w-100 mb-3">
                  <DynamicDropdown
                    id="dynamic-select"
                    value={advisorId}
                    setValue={setAdvisorId}
                    label={"Advisor"}
                    retrievalFunction={async () => {
                      const resp = await getAdvisorList();
                      return {
                        ...resp,
                        data: resp.data,
                      };
                    }}
                  />
                </div>
              )}
              <ClientInformation
                countryError={countryError}
                accountType={accountType}
                percentOwned={percentOwned}
                setPercentOwnedError={setPercentOwnedError}
                setPercentOwned={setPercentOwned}
                percentOwnedError={percentOwnedError}
                clientFirstName={clientFirstName}
                setClientFirstName={setClientFirstName}
                clientFirstNameError={clientFirstNameError}
                setClientFirstNameError={setClientFirstNameError}
                clientLastName={clientLastName}
                setClientLastName={setClientLastName}
                clientLastNameError={clientLastNameError}
                setClientLastNameError={setClientLastNameError}
                clientEmail={clientEmail}
                setClientEmail={setClientEmail}
                clientEmailError={clientEmailError}
                setClientEmailError={setClientEmailError}
                clientPhoneNumber={clientPhoneNumber}
                setClientPhoneNumber={setClientPhoneNumber}
                clientPhoneNumberError={clientPhoneNumberError}
                setClientPhoneNumberError={setClientPhoneNumberError}
                clientAddressLine1={clientAddressLine1}
                setClientAddressLine1={setClientAddressLine1}
                clientAddressLine1Error={clientAddressLine1Error}
                setClientAddressLine1Error={setClientAddressLine1Error}
                clientAddressLine2={clientAddressLine2}
                setClientAddressLine2={setClientAddressLine2}
                clientAddressLine2Error={clientAddressLine2Error}
                setClientAddressLine2Error={setClientAddressLine2Error}
                clientCity={clientCity}
                setClientCity={setClientCity}
                clientCityError={clientCityError}
                setClientCityError={setClientCityError}
                clientState={clientState}
                setClientState={setClientState}
                clientStateError={clientStateError}
                setClientStateError={setClientStateError}
                clientPostalCode={clientPostalCode}
                setClientPostalCode={setClientPostalCode}
                clientPostalCodeError={clientPostalCodeError}
                setClientPostalCodeError={setClientPostalCodeError}
                clientTaxIdNumber={clientTaxIdNumber}
                setClientTaxIdNumber={setClientTaxIdNumber}
                clientTaxIdNumberError={clientTaxIdNumberError}
                setClientTaxIdNumberError={setClientTaxIdNumberError}
                clientDOB={clientDOB}
                setClientDOB={setClientDOB}
                clientDOBError={clientDOBError}
                setClientDOBError={setClientDOBError}
                clientIsUSCitizen={clientIsUSCitizen}
                setClientIsUSCitizen={setClientIsUSCitizen}
                clientIsUSCitizenError={clientIsUSCitizenError}
                setClientIsUSCitizenError={setClientIsUSCitizenError}
                country={country}
                setCountry={setCountry}
                setCountryError={setCountryError}
                citizenship={citizenship}
                setCitizenship={setCitizenship}
                citizenshipError={citizenshipError}
                setCitizenshipError={setCitizenshipError}
              />
              <AccountInformation
                country={country}
                userRole = {user && user.userRole}
                accountTypeOptions={accountTypeOptions}
                accountType={accountType}
                setAccountType={setAccountType}
                accountTypeError={accountTypeError}
                setAccountTypeError={setAccountTypeError}
                accountCustodianInstitution={accountCustodianInstitution}
                setAccountCustodianInstitution={setAccountCustodianInstitution}
                accountCustodianInstitutionError={
                  accountCustodianInstitutionError
                }
                setAccountCustodianInstitutionError={
                  setAccountCustodianInstitutionError
                }
                accountCustodialNumber={accountCustodialNumber}
                setAccountCustodialNumber={setAccountCustodialNumber}
                accountCustodialNumberError={accountCustodialNumberError}
                setAccountCustodialNumberError={setAccountCustodialNumberError}
                jointAccountType={jointAccountType}
                setJointAccountType={setJointAccountType}
                jointAccountTypeError={jointAccountTypeError}
                setJointAccountTypeError={setJointAccountTypeError}
                jointAccountTypeOptions={jointAccountTypeOptions}
                entityCountry={entityCountry}
                setEntityCountry={setEntityCountry}
                entityName={entityName}
                setEntityName={setEntityName}
                entityNameError={entityNameError}
                setEntityNameError={setEntityNameError}
                entityAddressLine1={entityAddressLine1}
                setEntityAddressLine1={setEntityAddressLine1}
                entityAddressLine1Error={entityAddressLine1Error}
                setEntityAddressLine1Error={setEntityAddressLine1Error}
                entityAddressLine2={entityAddressLine2}
                setEntityAddressLine2={setEntityAddressLine2}
                entityAddressLine2Error={entityAddressLine2Error}
                setEntityAddressLine2Error={setEntityAddressLine2Error}
                entityCity={entityCity}
                setEntityCity={setEntityCity}
                entityCityError={entityCityError}
                setEntityCityError={setEntityCityError}
                entityState={entityState}
                setEntityState={setEntityState}
                entityStateError={entityStateError}
                setEntityStateError={setEntityStateError}
                entityPostalCode={entityPostalCode}
                setEntityPostalCode={setEntityPostalCode}
                entityPostalCodeError={entityPostalCodeError}
                setEntityPostalCodeError={setEntityPostalCodeError}
                entityTaxIDNumber={entityTaxIDNumber}
                setEntityTaxIDNumber={setEntityTaxIDNumber}
                entityTaxIDNumberError={entityTaxIDNumberError}
                setEntityTaxIDNumberError={setEntityTaxIDNumberError}
                entityPhoneNumber={entityPhoneNumber}
                setEntityPhoneNumber={setEntityPhoneNumber}
                entityPhoneNumberError={entityPhoneNumberError}
                setEntityPhoneNumberError={setEntityPhoneNumberError}
                entityStateOfIncorporation={entityStateOfIncorporation}
                setEntityStateOfIncorporation={setEntityStateOfIncorporation}
                entityStateOfIncorporationError={
                  entityStateOfIncorporationError
                }
                setEntityStateOfIncorporationError={
                  setEntityStateOfIncorporationError
                }
                jointHolderFirstName={jointHolderFirstName}
                setJointHolderFirstName={setJointHolderFirstName}
                jointHolderFirstNameError={jointHolderFirstNameError}
                setJointHolderFirstNameError={setJointHolderFirstNameError}
                jointHolderLastName={jointHolderLastName}
                setJointHolderLastName={setJointHolderLastName}
                jointHolderLastNameError={jointHolderLastNameError}
                setJointHolderLastNameError={setJointHolderLastNameError}
                jointHolderEmail={jointHolderEmail}
                setJointHolderEmail={setJointHolderEmail}
                jointHolderEmailError={jointHolderEmailError}
                setJointHolderEmailError={setJointHolderEmailError}
                jointHolderPhoneNumber={jointHolderPhoneNumber}
                setJointHolderPhoneNumber={setJointHolderPhoneNumber}
                jointHolderPhoneNumberError={jointHolderPhoneNumberError}
                setJointHolderPhoneNumberError={setJointHolderPhoneNumberError}
                jointHolderTaxIDNumber={jointHolderTaxIDNumber}
                setJointHolderTaxIDNumber={setJointHolderTaxIDNumber}
                jointHolderTaxIDNumberError={jointHolderTaxIDNumberError}
                setJointHolderTaxIDNumberError={setJointHolderTaxIDNumberError}
                jointHolderDOB={jointHolderDOB}
                setJointHolderDOB={setJointHolderDOB}
                jointHolderDOBError={jointHolderDOBError}
                setJointHolderDOBError={setJointHolderDOBError}
                jointHolderIsUSCitizen={jointHolderIsUSCitizen}
                setJointHolderIsUSCitizen={setJointHolderIsUSCitizen}
                jointHolderIsUSCitizenError={jointHolderIsUSCitizenError}
                setJointHolderIsUSCitizenError={setJointHolderIsUSCitizenError}
                jointHolderAddressLine1={jointHolderAddressLine1}
                setJointHolderAddressLine1={setJointHolderAddressLine1}
                jointHolderAddressLine1Error={jointHolderAddressLine1Error}
                setJointHolderAddressLine1Error={
                  setJointHolderAddressLine1Error
                }
                jointHolderAddressLine2={jointHolderAddressLine2}
                setJointHolderAddressLine2={setJointHolderAddressLine2}
                jointHolderAddressLine2Error={jointHolderAddressLine2Error}
                setJointHolderAddressLine2Error={
                  setJointHolderAddressLine2Error
                }
                jointHolderCity={jointHolderCity}
                setJointHolderCity={setJointHolderCity}
                jointHolderCityError={jointHolderCityError}
                setJointHolderCityError={setJointHolderCityError}
                jointHolderState={jointHolderState}
                setJointHolderState={setJointHolderState}
                jointHolderStateError={jointHolderStateError}
                setJointHolderStateError={setJointHolderStateError}
                jointHolderPostalCode={jointHolderPostalCode}
                setJointHolderPostalCode={setJointHolderPostalCode}
                jointHolderPostalCodeError={jointHolderPostalCodeError}
                setJointHolderPostalCodeError={setJointHolderPostalCodeError}
                hasTrustees={hasTrustees}
                setHasTrustees={setHasTrustees}
                relatedPartyInfo={relatedPartyInfo}
                setRelatedPartyInfo={setRelatedPartyInfo}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setHideButton={setHideButton}
                hideAlterButtons={hideAlterButtons}
                addNewTab={addNewTab}
                handleRemoveFields={handleRemoveFields}
                disabled={false}
                hasBeneficialOwners={hasBeneficialOwners}
                setHasBeneficialOwners={setHasBeneficialOwners}
                beneficialOwnerInfo={beneficialOwnerInfo}
                setBeneficialOwnerInfo={setBeneficialOwnerInfo}
                beneficialOwnerActiveTab={beneficialOwnerActiveTab}
                setBeneficialOwnerActiveTab={setBeneficialOwnerActiveTab}
                handleBeneficialOwnerRemoveField={
                  handleBeneficialOwnerRemoveField
                }
                setHideBeneficialOwnerButton={setHideBeneficialOwnerButton}
                hideAlterBeneficialOwnerButtons={
                  hideAlterBeneficialOwnerButtons
                }
                addNewBeneficialOwnerTab={addNewBeneficialOwnerTab}
                accountQualification={accountQualification}
                setAccountQualification={setAccountQualification}
                accountQualificationError={accountQualificationError}
                setAccountQualificationError={setAccountQualificationError}
                accountNotQualifiedError={accountNotQualifiedError}
                setAccountNotQualifiedError={setAccountNotQualifiedError}
                hasTrustManagementCompany={hasTrustManagementCompany}
                hasAlternateSignatory={hasAlternateSignatory}
                setHasAlternateSignatory={setHasAlternateSignatory}
                hasAlternateSignatoryError={hasAlternateSignatoryError}
                setHasAlternateSignatoryError={setHasAlternateSignatoryError}
                alternateSignatoryFullName={alternateSignatoryFullName}
                setAlternateSignatoryFullName={setAlternateSignatoryFullName}
                alternateSignatoryFullNameError={
                  alternateSignatoryFullNameError
                }
                setAlternateSignatoryFullNameError={
                  setAlternateSignatoryFullNameError
                }
                alternateSignatoryEmail={alternateSignatoryEmail}
                setAlternateSignatoryEmail={setAlternateSignatoryEmail}
                alternateSignatoryEmailError={alternateSignatoryEmailError}
                setAlternateSignatoryEmailError={
                  setAlternateSignatoryEmailError
                }
                alternateSignatoryTitle={alternateSignatoryTitle}
                setAlternateSignatoryTitle={setAlternateSignatoryTitle}
                alternateSignatoryTitleError={alternateSignatoryTitleError}
                setAlternateSignatoryTitleError={
                  setAlternateSignatoryTitleError
                }
                entityCountryError={entityCountryError}
                setEntityCountryError={setEntityCountryError}
              />
              <AccreditedInvestorQualification questions={questions} setQuestions={setQuestions} />
              <div className="row g-0 w-100 mt-3">
                <div className="d-flex">
                  <div className="me-3">
                    <MuiButton
                      buttonText={"Submit"}
                      onClick={() => submit(false)}
                      size={"large"}
                    />
                  </div>
                  <div>
                    { user.userRole !== 'Self-Serve' && <MuiButton
                      buttonText={"Submit and Add Another"}
                      onClick={() => submit(true)}
                      size={"large"}
                    /> }
                  </div> 
                </div>
              </div>
              <SnackBar
                snackbarState={advisorIdError}
                setSnackbarState={setAdvisorIdError}
                vertical={"top"}
                horizontal={"right"}
                duration={5000}
                sxProps={{ paddingLeft: "3px" }}
                severity={"error"}
                message={
                  "You must select an advisor to associate with the client."
                }
              />
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        snackbarState={notAccreditedError}
        setSnackbarState={setNotAccreditedError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "Sorry, your account does not qualify as an accredited investor, and we only allow accredited investors on our platform at this time."
        }
      />
      <SnackBar
        snackbarState={notAllQuestionsAnsweredError}
        setSnackbarState={setNotAllQuestionsAnsweredError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must answer all of the questions below."}
      />

    </React.Fragment>
  );
}
export default AddFirmClient;
