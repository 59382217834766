import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions";
import { HOST } from "../utilities/host-config/host-config";

export const getUninvestedClients = async (fundId) => {
  const config = await getRequestHeaders();

  return axios.get(
    HOST + "/wm-get-uninvested-clients?fund_id=" + fundId,
    config
  );
};

export const createBulkInvestments = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(
    HOST + "/wm-bulk-create-client-investments",
    payload,
    config
  );
};

export const getFundInvestmentInformation = async (fundId) => {
  const config = await getRequestHeaders();

  return axios.get(
    HOST + "/wm-get-fund-investment-information/" + fundId + "/",
    config
  );
};

export const getFundName = async (fundId) => {
  const config = await getRequestHeaders();

  return axios.get(HOST + "/wm-get-fund-name/" + fundId + "/", config);
};

export const getFirmUserFundAllocationRequest = async (fundId) => {
  const config = await getRequestHeaders();

  return axios.get(
    HOST + "/wm-get-firm-user-fund-allocation-request?fund_link=" + fundId,
    config
  );
};

export const createFirmUserFundAllocationRequest = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(
    HOST + "/wm-create-firm-user-fund-allocation-request",
    payload,
    config
  );
};
