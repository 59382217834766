import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles/Styles.scss";
import SearchBarNoShadow from "../../../../Components/SearchBar/SearchBarNoShadow/SearchBarNoShadow";
import ChooseClientTable from "../../../../Components/ChooseClientTable/ChooseClientTable";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton";
import FundInvestmentInformation from "./FundInvestmentInformation/FundInvestmentInformation";
import GetFundInvestmentInformation from "../../../../Adapters/ConfirmInvestment";
import Spinner1 from "../../../../Components/Spinners/Spinner1";
import ParentComponent from "../../../MultiSelectTable/ParentComponent";

const SpecifyClientInvestments = ({
  id,
  setSelectedClient,
  setSelectedTab,
}) => {
  const navigate = useNavigate();
  const [clientSearchQuery, setClientSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const { fundInvestmentInformation } = GetFundInvestmentInformation(
    refresh,
    id,
    setLoading
  );

  const [successfulInvestmentSubmission, setSuccessfulInvestmentSubmission] =
    useState(false);

  const [data, setData] = useState([
    {
      id: "ebf53baf-1ebc-430e-b970-0be238a6159a",
      firstName: "John",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "5f4e8bd9-c3bf-4d9b-8e77-ea536fa9857d",
      firstName: "Michael",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "82ae7437-f1a2-457c-8188-c7ab9bc0287f",
      firstName: "Frank",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "3be22d94-6c47-402c-9af0-1d602910d4b6",
      firstName: "Jim",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "fcfc7b2a-6f47-4130-95a2-852f007d6e7c",
      firstName: "Carter",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "3e339eba-e945-41d7-bb5c-cb48b77776d8",
      firstName: "Martin",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1fd54269-81ef-4058-ba08-71c47493d931",
      firstName: "Lawrence",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "b917ed20-5482-42ba-9e59-b1483291238b",
      firstName: "Dan",
      lastName: "Friedlinghaus",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "91f9d13d-d274-40ab-afd6-d62058f1f827",
      firstName: "Max",
      lastName: "Johnson",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "19917030-4b23-475c-a852-63c4fd754b07",
      firstName: "Tim",
      lastName: "White",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "ca132209-dc47-4015-9804-7947015a2811",
      firstName: "Patrick",
      lastName: "Ewing",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "84c01bdc-80ab-42e4-b611-00326fcf3b5e",
      firstName: "Dustin",
      lastName: "Black",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1309fa67-2979-4c57-8f2f-d57f1fdb4de8",
      firstName: "George",
      lastName: "Russel",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "8c4416c0-d555-4beb-9305-afdb24882ccf",
      firstName: "Daniel",
      lastName: "Ricciardo",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "47eba459-6a4c-4290-b578-bf956cdac2ec",
      firstName: "Michael",
      lastName: "Charleston",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "4c40fe7b-a053-46ba-939b-ec718586e34e",
      firstName: "Samuel",
      lastName: "Jackson",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "13c5dd1f-4be5-47a4-87aa-5aed6cc4ea71",
      firstName: "Robert",
      lastName: "Ewing",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1bd95676-12b0-4c8c-b67e-3ecc3b6f67a6",
      firstName: "Marshall",
      lastName: "White",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1191a994-35c6-4780-8bd7-b2a34d533e2f",
      firstName: "Phil",
      lastName: "Mikaelson",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "dde8a0d5-c96f-415e-a605-626e75533445",
      firstName: "Rory",
      lastName: "Johnson",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "b9da49f5-d600-46bc-973f-f9f5b219fd46",
      firstName: "Ryan",
      lastName: "Reynolds",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "1e8a5352-fe2c-4cbb-a856-bcc3192e351b",
      firstName: "Ashton",
      lastName: "Kutcher",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "6d4f98b2-a8af-4a5c-be85-5a07de4b145b",
      firstName: "Nina",
      lastName: "Doberman",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "f1d6812f-e693-4adb-8ebc-b7c1351cb79a",
      firstName: "Lisa",
      lastName: "Kudrow",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
    {
      id: "fdc14666-44ad-4413-95c3-b2d4d02a3662",
      firstName: "Michelle",
      lastName: "Smith",
      status: "Profile created",
      email: "john@aqua.com",
      authLevel: "Make investments",
      selected: false,
    },
  ]);

  const [clientData, setClientData] = useState(data);

  useEffect(() => {
    let tempData = data;
    let newData = tempData.filter((elem) => {
      var fullName = elem.firstName + " " + elem.lastName;
      if (
        fullName.toLowerCase().includes(clientSearchQuery.toLowerCase()) ||
        elem.email.toLowerCase().includes(clientSearchQuery.toLowerCase())
      ) {
        return elem;
      }
    });
    setClientData(newData);
  }, [clientSearchQuery]);

  const columns = () => {
    return [
      {
        dataField: "firstName",
        text: "First Name",
        classes: "body-text-md-regular align-middle",
        headerClasses: "start-padding body-text-md-bold",
      },
      {
        dataField: "lastName",
        text: "Last Name",
        classes: "body-text-md-regular align-middle",
        headerClasses: "start-padding body-text-md-bold",
      },
      {
        dataField: "status",
        text: "Status",
        classes: "body-text-md-regular align-middle",
        headerClasses: "start-padding body-text-md-bold",
      },
      {
        dataField: "email",
        text: "Email",
        classes: "body-text-md-regular align-middle",
        headerClasses: "start-padding body-text-md-bold",
      },
      {
        dataField: "authLevel",
        text: "Permissions",
        classes: "body-text-md-regular align-middle",
        headerClasses: "start-padding body-text-md-bold",
      },
      {
        dataField: "dummy",
        text: "Select a Client",
        classes: "body-text-md-regular align-items-center",
        headerClasses: "start-padding body-text-md-bold",
        formatter: (cellContent, row, rowIndex, extraData) => {
          return (
            <div className="row g-0 w-100 h-100 align-items-center">
              <MuiButton
                buttonText={"Select this client"}
                onClick={() => {
                  setSelectedClient({
                    name: row.firstName + " " + row.lastName,
                    email: row.email,
                  });
                  setSelectedTab(1);
                }}
                size={"small"}
              />
            </div>
          );
        },
      },
    ];
  };

  return successfulInvestmentSubmission === true ? (
    <React.Fragment>
      <div className="row g-0 w-100 justify-content-center mt-4">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/green-check.svg"
          style={{ maxWidth: "75px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-4 justify-content-center text-center">
        <h4>
          Your investment requests for this fund opportunity have been
          successfully submitted.
        </h4>
      </div>
      <div className="row g-0 w-100 mb-3 text-center justify-content-center">
        <span className="body-text-lg-medium">
          Head back to the invest page to continue browsing through available
          opportunities.
        </span>
      </div>
      <div className="row g-0 w-100 mb-3 justify-content-center">
        <div className="d-flex justify-content-center">
          <MuiButton
            buttonText={"Back to invest page"}
            onClick={() => navigate("/invest")}
            size={"medium"}
          />
        </div>
      </div>
    </React.Fragment>
  ) : (
    <div className="container-fluid ps-4 pe-4 pb-3">
      <div className="row g-0 w-100">
        <h4>Please specify the investment requests to submit</h4>
      </div>
      <div className="row g-0 w-100 mb-4">
        <span className="body-text-sm-regular">
          *Each proposed client investment must be less than the maximum
          allocation amount and greater than the minimum allocation amount.
        </span>
      </div>
      <FundInvestmentInformation
        fundInvestmentInformation={fundInvestmentInformation}
        loading={loading}
      />
      {loading === true ? (
        <div className="row g-0 w-100">
          <Spinner1 />
        </div>
      ) : (
        <ParentComponent
          fundInvestmentInformation={fundInvestmentInformation}
          setSuccessfulInvestmentSubmission={setSuccessfulInvestmentSubmission}
        />
      )}
    </div>
  );
};
export default SpecifyClientInvestments;
