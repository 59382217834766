import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import Tabs from "../../../../Components/Tabs/Tabs";
import Tab from "../../../../Components/Tabs/Tab/Tab";
import Overview from "./Overview/Overview";
import Structure from "./Structure/Structure";
import Deck from "./Deck/Deck";
import Docs from "./Docs/Docs";

const MainContent = ({ fund }) => {
  const [overviewSelected, setOverviewSelected] = useState(true);
  const [deckSelected, setDeckSelected] = useState(false);
  const [structureSelected, setStructureSelected] = useState(false);
  const [docsSelected, setDocsSelected] = useState(false);
  const [learnMoreSelected, setLearnMoreSelected] = useState(false);

  useEffect(() => {
    if (overviewSelected) {
      setDeckSelected(false);
      setStructureSelected(false);
      setDocsSelected(false);
      setLearnMoreSelected(false);
    }
  }, [overviewSelected]);

  useEffect(() => {
    if (deckSelected) {
      setOverviewSelected(false);
      setStructureSelected(false);
      setDocsSelected(false);
      setLearnMoreSelected(false);
    }
  }, [deckSelected]);

  useEffect(() => {
    if (structureSelected) {
      setDeckSelected(false);
      setOverviewSelected(false);
      setDocsSelected(false);
      setLearnMoreSelected(false);
    }
  }, [structureSelected]);

  useEffect(() => {
    if (docsSelected) {
      setDeckSelected(false);
      setStructureSelected(false);
      setOverviewSelected(false);
      setLearnMoreSelected(false);
    }
  }, [docsSelected]);

  useEffect(() => {
    if (learnMoreSelected) {
      setDeckSelected(false);
      setStructureSelected(false);
      setDocsSelected(false);
      setOverviewSelected(false);
    }
  }, [learnMoreSelected]);

  return (
    <React.Fragment>
      <Tabs>
        <Tab
          key={0}
          title="Overview"
          selected={overviewSelected}
          setSelected={setOverviewSelected}
        >
          <Overview fund={fund} />
        </Tab>
        <Tab
          key={1}
          title="Deck"
          selected={deckSelected}
          setSelected={setDeckSelected}
        >
          <Deck fund={fund} />
        </Tab>
        {/* <Tab
          key={2}
          title="Structure"
          selected={structureSelected}
          setSelected={setStructureSelected}
        >
          <Structure fund={fund} />
        </Tab> */}
        <Tab
          key={2}
          title="Docs"
          selected={docsSelected}
          setSelected={setDocsSelected}
        >
          <Docs fund={fund} />
        </Tab>
        {/* <Tab
          title="Learn More"
          selected={learnMoreSelected}
          setSelected={setLearnMoreSelected}
        >
         
        </Tab> */}
      </Tabs>
    </React.Fragment>
  );
};

export default MainContent;
