import React from "react";
import "./Styles/Styles.scss";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MuiButton from "../../../Components/Buttons/MuiButton/MuiButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.calories}</TableCell>
        <TableCell align="left">{row.fat}</TableCell>
        <TableCell align="left">{row.carbs}</TableCell>
        <TableCell align="left">{row.protein}</TableCell>
        <TableCell align="left">{""}</TableCell>
        <TableCell align="left">{""}</TableCell>
        <TableCell align="right">
          <MuiButton
            buttonText={
              !open ? "Reveal Wire Instructions" : "Hide Wire Instructions"
            }
            onClick={() => setOpen(!open)}
            size={"small"}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="container mt-5 mb-5 justify-content-center justify-self-center">
              <div className="row g-0 w-100 justify-content-center">
                <Card sx={{ maxWidth: 500 }}>
                  <div className="container p-3">
                    <div className="row g-0 w-100">
                      <div className="col-12">
                        <div className="row g-0 w-100">
                          <h5>Wire Instructions</h5>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="bottom-divider-border" />
                        </div>
                        <div className="row g-0 w-100 mb-1">
                          <div className="col-12 p-3">
                            <div className="border-container">
                              <div className="row g-0 w-100 mb-3">
                                <div className="col-12">
                                  <div className="row g-0 w-100 align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                      <div className="body-text-sm-bold me-3">
                                        Account Number:
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <Chip
                                            label="XXXXXXXXXX"
                                            variant="outlined"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-0 w-100 mb-4">
                                <div className="bottom-divider-border" />
                              </div>
                              <div className="row g-0 w-100 mb-3">
                                <div className="col-12">
                                  <div className="row g-0 w-100 align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                      <div className="body-text-sm-bold me-3">
                                        Routing Number:
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <Chip
                                            label="XXXXXXXXXX"
                                            variant="outlined"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-0 w-100 mb-4">
                                <div className="bottom-divider-border" />
                              </div>
                              <div className="row g-0 w-100 mb-3">
                                <div className="col-12">
                                  <div className="row g-0 w-100 align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                      <div className="body-text-sm-bold me-3">
                                        Bank Name:
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <Chip
                                            label="Bank of America"
                                            variant="outlined"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-0 w-100 mb-4">
                                <div className="bottom-divider-border" />
                              </div>
                              <div className="row g-0 w-100">
                                <div className="col-12">
                                  <div className="row g-0 w-100 align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                      <div className="body-text-sm-bold me-3">
                                        Bank Address:
                                      </div>
                                      <div>
                                        <div className="d-flex">
                                          <Chip
                                            label="200 Water Street, New York, NY 10038"
                                            variant="outlined"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable() {
  return (
    <div className="container-xxl pt-5 mt-5">
      <div className="row g-0 w-100 mt-5 mb-3">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell flex={1}>Opportunity Name</TableCell>
                <TableCell flex={1}>Account</TableCell>
                <TableCell flex={1}>Amount</TableCell>
                <TableCell flex={1}>Fulfill By</TableCell>
                <TableCell flex={1}>Paid In</TableCell>
                <TableCell flex={1}>Status</TableCell>
                <TableCell flex={1}>Fulfilled On</TableCell>
                <TableCell flex={1}>Created At</TableCell>
                <TableCell flex={1} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
