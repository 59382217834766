import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "../../../utilities/scss/_variables.scss";

function PasswordInputField({
  value,
  setValue,
  valueError,
  setValueError,
  label,
  required,
  fullWidth,
  size,
  id,
  disabled,
  shrink,
}) {
  //Styles variable assignment from exported sass variable values
  const primaryFontFamily = styles.primaryFontFamily;
  const primaryBlack2 = styles.primaryBlack2;
  const primaryBlue = styles.primaryBlue;
  const secondaryOffWhite = styles.secondaryOffWhite;
  const secondaryRed = styles.secondaryRed;
  const fontSize3 = styles.fontSize3;

  useEffect(() => {
    setValueError("");
  }, [value]);

  const [isMasked, setIsMasked] = useState(true);

  return (
    <Box sx={{ minWidth: 120 }}>
      <TextField
        error={valueError !== ""}
        type={isMasked ? "password" : "text"}
        id={id}
        label={label}
        helperText={valueError}
        value={value}
        size={size}
        disabled={disabled}
        onChange={(event) => setValue(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsMasked(true)}
                onMouseDown={(e) => {
                  setIsMasked(false);
                  e.preventDefault();
                }}
                edge="end"
              >
                {!isMasked ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: primaryFontFamily,
            color: primaryBlack2,
            fontSize: fontSize3,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: primaryBlue,
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: primaryBlue,
              color: primaryBlue,
            },
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: secondaryOffWhite,
            },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            "& > fieldset": {
              borderColor: secondaryOffWhite,
            },
          },
          "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
            "& > fieldset": {
              borderColor: valueError === "" ? secondaryOffWhite : secondaryRed,
            },
          },
        }}
        required={required}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: shrink }}
      />
    </Box>
  );
}
export default PasswordInputField;
