import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import { UserContext } from "../Contexts/UserContext";

export default function UnauthenticatedRoute({ children, ...rest }) {
  const { user } = useContext(UserContext);
  const redirect = querystring("redirect");

  return user !== null ? (
    !user.isAuthenticated ? (
      children
    ) : (
      <Navigate
        to={redirect === "" || redirect === null ? (user.userRole === "Vendor" ? "/clients" : "/invest") : redirect}
      />
    )
  ) : (
    children
  );
}

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
