import React, { useState, useEffect } from "react";
import QualificationQuestion from "./QualificationQuestion.js";
import QPModal from "./../Qualification/QPModal.js";
//import TrustAccountBasicInformationQualificationError from "./TrustAccountBasicInformationQualificationError/TrustAccountBasicInformationQualificationError";
import "./../AddClientWizard/AccountBasicInformation/BasicInfo.scss";
import MuiButton from "./../Buttons/MuiButton/MuiButton";
import { flatten } from "underscore";

function EntityQualificationSection({
  showQualSection,
  mountedStyle,
  unmountedStyle,
  setActiveSlide,
  qualForm, setQualForm
}) {
  const [error, setError] = useState("");

  const validate = () => {
    if (
      qualForm.trustQualificationQ1 !== "" &&
      qualForm.trustQualificationQ2 !== "" &&
      qualForm.trustQualificationQ3 !== "" &&
      qualForm.trustQualificationQ4 !== "" &&
      qualForm.trustQualificationQ5 !== ""
    ) {
      if (qualForm.trustQualificationQ1 === false) {
        if (
          qualForm.trustQualificationQ2 === true &&
          qualForm.trustQualificationQ3 === true
        ) {
          setActiveSlide((prevState) => prevState + 1);
        } else if (
          qualForm.trustQualificationQ2 === true &&
          qualForm.trustQualificationQ4 === true
        ) {
          setActiveSlide((prevState) => prevState + 1);
        } else if (
          qualForm.trustQualificationQ3 === false &&
          qualForm.trustQualificationQ4 === false &&
          qualForm.trustQualificationQ5 == true
        ) {
          setActiveSlide((prevState) => prevState + 1);
        } else if (
          qualForm.trustQualificationQ3 === true &&
          qualForm.trustQualificationQ4 === false &&
          qualForm.trustQualificationQ5 == true
        ) {
          setActiveSlide((prevState) => prevState + 1);
        } } else {
          setError(
            "Sorry, your entity doesn't qualify as a Qualified Purchaser. Unfortunately, our opportunities are only open to qualified purchasers at this time, but we'll have investment opportunities for you very soon."
          );
        }
    } else {
      setError("Please answer all qualification questions.");
    }
  };

  useEffect(() => {
    setError(false);
  }, [qualForm]);

  return (
    <React.Fragment>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={showQualSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-step-subheader">
          Qualification Questions
        </span>
      </div>
      <div
        className="row g-0 w-100 mb-4"
        style={showQualSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-qualification-subheader pe-1">
          Please confirm the account's{" "}
          <span style={{ fontWeight: 600 }}>Qualified Purchaser</span> status
        </span>
      </div>
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={1}
        questionText={
          "Was the entity formed for the specific purpose of investing in a particular fund?"
        }
        answer={qualForm.trustQualificationQ1}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ1: answer,
          }))
        }
      />
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={2}
        questionText={"Does the entity have at least $5M in investments?"}
        answer={qualForm.trustQualificationQ2}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ2: answer,
          }))
        }
      />
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={3}
        questionText={`Is the entity owned directly or indirectly by 2 or more\n 
          natural persons who are related as siblings or spouse
          (including former spouses), or direct lineal descendants
          by birth or adoption, or spouses of such persons, the
          estates of such persons, or foundations, charitable
          organizations, or trusts established by or for the
          benefit of such persons?`}
        answer={qualForm.trustQualificationQ3}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ3: answer,
          }))
        }
      />
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={4}
        questionText={`Does the entity have more than $25M in investments that
        are invested on its own account or on behalf of other
        individuals?`}
        answer={qualForm.trustQualificationQ4}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ4: answer,
          }))
        }
      />
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={5}
        questionText={`Are all beneficial owners of the entity qualified
        purchasers (*Definition below)?`}
        answer={qualForm.trustQualificationQ5}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ5: answer,
          }))
        }
      />

      <QPModal
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
      {error ? (
        <div className="row g-0 w-100 mb-4">
          <div className="col-10">
            <div className="row g-0 w-100">
              <div className="col">
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                  alt="error"
                  width="15"
                  height="15"
                />
                <span className="ms-3 trust-qualification-error-text">
                  {error}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <div className="d-flex">
        <MuiButton
          buttonText={"Next Step"}
          onClick={() => validate()}
          size={"large"}
        />
      </div> */ } 
      {/* <TrustAccountBasicInformationQualificationError
        QualificationQuestionError={
          QualificationQuestionError
        }
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />  */}
    </React.Fragment>
  );
}
export default EntityQualificationSection;
