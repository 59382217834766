import React from "react";
import "./Styles/Styles.scss";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

function InvestmentCard({ investment }) {
  const card = (
    <React.Fragment>
      <CardContent>
        <div className="row g-0 w-100 align-items-center">
          <h6>{investment.full_name}</h6>
        </div>
        <Divider light={true} sx={{ height: "2px" }} />

        <div className="row g-0 w-100 mt-3">
          <div className="col p-2">
            <div
              className="p-2"
              style={{ backgroundColor: "#F3F5F7", borderRadius: "4px" }}
            >
              <div className="row g-0 w-100 align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <span className="body-text-sm-semibold me-2">
                      Client Email:
                    </span>
                    <span className="body-text-xs-medium mt-auto mb-auto">
                      {investment.client_email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mt-3 mb-2">
                <div className="col">
                  <div style={{ border: "0.5px solid #E2E6ED" }} />
                </div>
              </div>
              <div className="row g-0 w-100 align-items-center">
                <div className="d-flex align-items-center">
                  <span className="body-text-sm-semibold mt-auto mb-auto me-2">
                    Account Type:
                  </span>
                  <span className="body-text-xs-medium mt-auto mb-auto">
                    {investment.account_type}
                  </span>
                </div>
              </div>
              <div className="row g-0 w-100 mt-2 mb-2">
                <div className="col">
                  <div style={{ border: "0.5px solid #E2E6ED" }} />
                </div>
              </div>
              <div className="row g-0 w-100 align-items-center">
                <div className="d-flex align-items-center">
                  <span className="body-text-sm-semibold mt-auto mb-auto me-2">
                    Commitment Amount:
                  </span>
                  <span className="body-text-xs-medium mt-auto mb-auto">
                    $
                    {parseInt(
                      investment.proposed_investment_amount
                    ).toLocaleString("en-US")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </React.Fragment>
  );

  return (
    <div className="col-4">
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">{card}</Card>
      </Box>
    </div>
  );
}
export default InvestmentCard;
