import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import { HOST } from "../utilities/host-config/host-config.js";

export const getWMFirmInfo = async () => {
  const config = await getRequestHeaders();

  //   return axios.get(HOST + "/get-wm-firm-info", config);
  return axios.get(HOST + "/get-wm-firm-info", config);
};
