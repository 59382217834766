import { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import MuiButton from "../../Components/Buttons/MuiButton/MuiButton";
import MultiSelectFilter from "../../Components/MultiSelectFilter/MultiSelectFilter";
import ValueMultiselectFilter from "../../Components/ValueMultiselectFilter/ValueMultiselectFilter";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  useGridSelector,
  useGridApiRef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import Spinner1 from "../../Components/Spinners/Spinner1";
import SnackBar from "../../Components/Alerts/SnackBar/SnackBar";
import { UserContext } from "../../Contexts/UserContext.js";
import EnhancedKYC from "../../Components/EnhancedKYC/EnhancedKYC";
import { getFirmUser } from "../../APIs/WealthManagementAPI";

function Tasks({ }) {

  const { user, setUser } = useContext(UserContext);
  const [selfServe, setSelfServe] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");

  useEffect(
    () => {
      (async () => {
        const firmUser = await getFirmUser();
        if (user) {
          setSelfServe(user.userRole === "Self-Serve");
          setClient(firmUser.data.clients.length > 0 && firmUser.data.clients[0].client_id)
          setClients(firmUser.data.clients.filter(client => client.kyc_outstanding === true));
        }
      })();
    }, [user]
  )

  return (
    <>

      <h2 className="tw-text-2xl tw-font-bold tw-mb-6">Tasks</h2>

      {
        selfServe ?
          (client ? <EnhancedKYC clientId={client} /> : <>No outstanding tasks.</>) :
          <>
            {(clients.length === 0) ?
              <>No clients with outstanding tasks.</> :
              <>
                <ul>
                  {clients.map((client) =>
                    <>
                      These clients have outstanding tasks:
                      <li>
                        <a href={`/clients/kyc/${client.client_id}`}>{client.email}</a>
                      </li>

                    </>)
                  }
                </ul>
              </>} </>} </>
  );
}
export default Tasks;
