import { useState, useEffect } from "react";
import { getSignedURL } from "../utilities/functions/functions";

export default function GetDocs(fund, deckOnly) {
  const [fundDocs, setFundDocs] = useState([]);
  const [tempLink, setTempLink] = useState("");

  useEffect(() => {
    if (fund !== null && fund !== undefined) {
      async function getFundDocs() {
        if (
          fund.fundDocs !== [] &&
          fund.fundDocs !== null &&
          fund.fundDocs !== undefined
        ) {
          const deckDoc = {
            id: "deck",
            name: "Fund Deck",
            s3_document_link: fund.deckLink,
            display_document: true,
          };
          const unprocessedDocs = deckOnly ? [deckDoc] : fund.fundDocs;
          let processedFunds = unprocessedDocs.map((doc) => {
            async function getLink() {
              var temporaryLink = await getSignedURL(
                "public",
                doc.s3_document_link
              );

              setTempLink(temporaryLink);
              // setFundDocs((prevState) => [
              //   ...prevState,
              //   {
              //     id: doc.id,
              //     documentName: doc.name,
              //     documentLink: tempLink,
              //     display: doc.display_document,
              //   },
              // ]);
              // return tempLink;
            }
            getLink();

            return {
              id: doc.id,
              documentName: doc.name,
              signedLink: tempLink,
              documentLink: tempLink,
              displayDocument: doc.display_document,
            };
          });

          setFundDocs(processedFunds);
        }
      }
      getFundDocs();
    }
  }, [fund, tempLink]);

  return { fundDocs };
}
