const mockSpecialists = [
  {
    id: "spec-1",
    name: "Sarah Operations",
    email: "sarah.ops@investwithaqua.com",
    role: "Co-Advisor",
  },
  {
    id: "spec-2",
    name: "Mike Support",
    email: "mike.support@investwithaqua.com",
    role: "Co-Advisor",
  },
  {
    id: "spec-3",
    name: "Lisa Tech",
    email: "lisa.tech@investwithaqua.com",
    role: "Co-Advisor",
  },
];

const mockAdvisors = [
  {
    id: "adv-1",
    name: "John Advisor",
    email: "john.advisor@investwithaqua.com",
    role: "Advisor",
  },
  {
    id: "adv-2",
    name: "Emma Wealth",
    email: "emma.wealth@investwithaqua.com",
    role: "Advisor",
  },
];

export const fetchAvailableUsers = async (role) => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 500));

  return role === "Advisor" ? mockSpecialists : mockAdvisors;
};

export const saveAssociations = async (userId, associationIds) => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 1000));

  // Simulate different error scenarios
  const simulateError = Math.random();

  if (simulateError < 0.5) {
    throw {
      response: {
        data: {
          message:
            "Network error: Failed to connect to server. Please try again.",
        },
      },
    };
  }

  if (simulateError < 0.8) {
    throw {
      response: {
        data: {
          message:
            "Permission denied: You don't have access to perform this action.",
        },
      },
    };
  }

  // Simulate API response
  return {
    success: true,
    message: "Associations saved successfully",
  };
};

// Add new function to fetch associations
export const fetchAssociationsForUser = async (userId) => {
  try {
    const response = await fetch(
      `/get-associations-for-firm-user?id=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Add any auth headers needed
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || "Failed to fetch associations");
    }

    const data = await response.json();
    return data.associations;
  } catch (error) {
    console.error("Error fetching associations:", error);
    throw error;
  }
};
