import React from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../../../Components/InputFields/TextInputField/TextInputField";
import PhoneInputField from "../../../../../../../Components/InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "../../../../../../../Components/InputFields/SelectInputField/SelectInputField";
import { formatSSN } from "../../../../../../../utilities/functions/functions";
import StyledDateInputField from "../../../../../../../Components/StyledDateInput/StyledDateInput";
import { stateList } from "../../../../../../../Components/stateList";

function JointAccountHolderInformation({
  jointHolderFirstName,
  setJointHolderFirstName,
  jointHolderFirstNameError,
  setJointHolderFirstNameError,
  jointHolderLastName,
  setJointHolderLastName,
  jointHolderLastNameError,
  setJointHolderLastNameError,
  jointHolderEmail,
  setJointHolderEmail,
  jointHolderEmailError,
  setJointHolderEmailError,
  jointHolderPhoneNumber,
  setJointHolderPhoneNumber,
  jointHolderPhoneNumberError,
  setJointHolderPhoneNumberError,
  jointHolderTaxIDNumber,
  setJointHolderTaxIDNumber,
  jointHolderTaxIDNumberError,
  setJointHolderTaxIDNumberError,
  jointHolderDOB,
  setJointHolderDOB,
  jointHolderDOBError,
  setJointHolderDOBError,
  jointHolderIsUSCitizen,
  setJointHolderIsUSCitizen,
  jointHolderIsUSCitizenError,
  setJointHolderIsUSCitizenError,
  jointHolderAddressLine1,
  setJointHolderAddressLine1,
  jointHolderAddressLine1Error,
  setJointHolderAddressLine1Error,
  jointHolderAddressLine2,
  setJointHolderAddressLine2,
  jointHolderAddressLine2Error,
  setJointHolderAddressLine2Error,
  jointHolderCity,
  setJointHolderCity,
  jointHolderCityError,
  setJointHolderCityError,
  jointHolderState,
  setJointHolderState,
  jointHolderStateError,
  setJointHolderStateError,
  jointHolderPostalCode,
  setJointHolderPostalCode,
  jointHolderPostalCodeError,
  setJointHolderPostalCodeError,
}) {
  const booleanList = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 align-items-center mb-4">
        <h6 className="mt-auto mb-auto">Joint Account Holder Information</h6>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={jointHolderFirstName}
            setValue={setJointHolderFirstName}
            valueError={jointHolderFirstNameError}
            setValueError={setJointHolderFirstNameError}
            label={"First Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-first-name-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={jointHolderLastName}
            setValue={setJointHolderLastName}
            valueError={jointHolderLastNameError}
            setValueError={setJointHolderLastNameError}
            label={"Last Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-last-name-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={jointHolderEmail}
            setValue={setJointHolderEmail}
            valueError={jointHolderEmailError}
            setValueError={setJointHolderEmailError}
            label={"Email"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-email-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <PhoneInputField
            shrink={true}
            label={"Phone Number"}
            size={"small"}
            fullWidth={true}
            required={true}
            value={jointHolderPhoneNumber}
            setValue={setJointHolderPhoneNumber}
            valueError={jointHolderPhoneNumberError}
            setValueError={setJointHolderPhoneNumberError}
            disabled={false}
            id={"client-joint-holder-phone-number-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={jointHolderAddressLine1}
            setValue={setJointHolderAddressLine1}
            valueError={jointHolderAddressLine1Error}
            setValueError={setJointHolderAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-address-line-1-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={jointHolderAddressLine2}
            setValue={setJointHolderAddressLine2}
            valueError={jointHolderAddressLine2Error}
            setValueError={setJointHolderAddressLine2Error}
            label={"Address Line 2"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={jointHolderCity}
            setValue={setJointHolderCity}
            valueError={jointHolderCityError}
            setValueError={setJointHolderCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-city-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <SelectInputField
            value={jointHolderState}
            setValue={setJointHolderState}
            valueError={jointHolderStateError}
            setValueError={setJointHolderStateError}
            selectOptions={stateList}
            label={"State"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-joint-holder-state-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-0">
          <TextInputField
            value={jointHolderPostalCode}
            setValue={setJointHolderPostalCode}
            valueError={jointHolderPostalCodeError}
            setValueError={setJointHolderPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-postal-code-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={jointHolderTaxIDNumber}
            setValue={setJointHolderTaxIDNumber}
            valueError={jointHolderTaxIDNumberError}
            setValueError={setJointHolderTaxIDNumberError}
            label={"SSN"}
            required={true}
            fullWidth={true}
            size={"small"}
            mask={{
              maskFormatter: formatSSN,
              maskPattern: "999-99-9999",
            }}
            id={"client-joint-holder-ssn-input"}
          />
        </div>
        <div className="col pe-0">
          <StyledDateInputField
            value={jointHolderDOB}
            setValue={setJointHolderDOB}
            valueError={jointHolderDOBError}
            setValueError={setJointHolderDOBError}
            label={"Date Of Birth"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-joint-holder-dob-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100">
        <div className="col pe-0">
          <SelectInputField
            value={jointHolderIsUSCitizen}
            setValue={setJointHolderIsUSCitizen}
            valueError={jointHolderIsUSCitizenError}
            setValueError={setJointHolderIsUSCitizenError}
            selectOptions={booleanList}
            label={"Is U.S. Citizen"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-joint-holder-citizenship-input"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default JointAccountHolderInformation;
