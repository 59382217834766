import React, { useState, useEffect } from "react";
import QualificationQuestion from "./QualificationQuestion.js";
import QPModal from "./../Qualification/QPModal.js";
//import TrustAccountBasicInformationQualificationError from "./TrustAccountBasicInformationQualificationError/TrustAccountBasicInformationQualificationError";
import "./../AddClientWizard/AccountBasicInformation/BasicInfo.scss";
import MuiButton from "./../Buttons/MuiButton/MuiButton";

function TrustQualificationSection({
  qualForm,
  setQualForm,
  showQualSection,
  mountedStyle,
  unmountedStyle,
  setActiveSlide,
  qualifies,
  setQualifies,
}) {
  const [error, setError] = useState("");



  useEffect(() => {
    setError(false);
  }, [qualifies]);

  return (
    <React.Fragment>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={showQualSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-step-subheader">
          Qualification Questions
        </span>
      </div>
      <div
        className="row g-0 w-100 mb-4"
        style={showQualSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-qualification-subheader pe-1">
          Please confirm the account's{" "}
          <span style={{ fontWeight: 600 }}>Qualified Purchaser</span> status
        </span>
      </div>
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={1}
        questionText={"Does the trust have at least $5M in investments?"}
        answer={qualForm.trustQualificationQ1}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ1: answer,
          }))
        }
      />
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={2}
        questionText={
          "Is the trust owned by two or more close family members (spouses, siblings, descendants, and/or their respective spouses)?"
        }
        answer={qualForm.trustQualificationQ2}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ2: answer,
          }))
        }
      />
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={3}
        questionText={
          "Was the trust formed for the specific purpose of investing in a particular fund?"
        }
        answer={qualForm.trustQualificationQ3}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ3: answer,
          }))
        }
      />
      <QualificationQuestion
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={4}
        questionText={
          "Are the trustee(s) and persons granting assets to the trust qualified purchasers (*Definition below)?"
        }
        answer={qualForm.trustQualificationQ4}
        setAnswer={(answer) =>
          setQualForm((prevState) => ({
            ...prevState,
            trustQualificationQ4: answer,
          }))
        }
      />
      <QPModal
        showQualSection={showQualSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
      {error ? (
        <div className="row g-0 w-100 mb-4">
          <div className="col-10">
            <div className="row g-0 w-100">
              <div className="col">
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                  alt="error"
                  width="15"
                  height="15"
                />
                <span className="ms-3 trust-qualification-error-text">
                  {error}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <div className="d-flex">
                <MuiButton
                  buttonText={"Next Step"}
                  onClick={() => validateTrustQPStatus()}
                  size={"large"}
                />
                  </div> */}
      {/* <TrustAccountBasicInformationQualificationError
        QualificationQuestionError={
          QualificationQuestionError
        }
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />  */}
    </React.Fragment>
  );
}
export default TrustQualificationSection;
