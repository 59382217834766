import React, { useEffect, useState, useContext } from "react";
import { aggViews, advisorAggViews } from "./../../APIs/WealthManagementAPI";
import BootstrapTable from "react-bootstrap-table-next";
import { UserContext } from "../../Contexts/UserContext.js";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { DataGridPro } from "@mui/x-data-grid-pro";

const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  // ....
  return page === "<" ? (
    <img class="arrow" src="/Left-Arrow.svg" onClick={handleClick} />
  ) : page === ">" ? (
    <img class="arrow" src="/Right-Arrow.svg" onClick={handleClick} />
  ) : (
    <li className="page-item" onClick={handleClick}>
      <span href="#" class={active ? "active-page" : "inactive-page"}>
        {" "}
        {page}{" "}
      </span>
    </li>
  );
};

function moneyFormatter(cell) {
  if (cell.value === undefined || cell.value === null) {
    return "$ 0";
  }
  var integer = (Math.round(parseFloat(cell.value) * 100) / 100).toFixed(2);
  return "$ " + integer.toLocaleString("en-US");
}

const options = {
  pageButtonRenderer,
  sizePerPage: 10,
};

const FirmByFirm = ({ isAdvisor }) => {
  const { user } = useContext(UserContext);
  const isMasterOpsOrRM =
    user.userRole === "Master" ||
    user.userRole === "Ops" ||
    user.userRole === "Relationship Manager";
  const [rows, setRows] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(true);
  const query = "";
  const columns = () => [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "fund",
      text: "Fund Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "investment_amount",
      text: "Invested",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: moneyFormatter,
    },
    {
      dataField: "distributions",
      text: "Distributed",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: moneyFormatter,
    },
    {
      dataField: "capital_calls",
      text: "Capital Called",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: moneyFormatter,
    },
    {
      dataField: "marked_value",
      text: "Marked value",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: moneyFormatter,
    },
    // {
    //   dataField: "dummy",
    //   text: "Download",
    //   classes: "body-text-md-regular align-middle",
    //   headerClasses: "start-padding body-text-md-bold",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span className="body-text-md-medium">Available</span>
    //         <img
    //           src="https://d1swyfveypj49y.cloudfront.net/arrow-down-circle.svg"
    //           className="ms-2"
    //           onClick={() => console.log("Clicked")}
    //           style={{ height: "25px", width: "25px" }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const newCols = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "fund",
      headerName: "Fund Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "investment_amount",
      headerName: "Invested",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
    },
    {
      field: "distributions",
      headerName: "Distributed",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
    },
    {
      field: "capital_calls",
      headerName: "Capital Called",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
    },
    {
      field: "marked_value",
      headerName: "Marked value",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
    },
  ];
  const noResultsMessage = "None";
  const showButton = false;

  const [data, setData] = useState(null);

  const fetchData = async () => {
    setResultsLoading(true);
    const resp = isMasterOpsOrRM
      ? await aggViews("fund", user.firmInfo.id)
      : await advisorAggViews("fund");

    if (resp.data.msg === "No investments") {
      setData(null);
      setRows([]);
      setResultsLoading(false);
    } else {
      setData(resp.data.fundData);
      let uniqueRows = [];

      if (
        resp.data !== null &&
        resp.data !== undefined &&
        resp.data.fundData !== null &&
        resp.data.fundData !== undefined
      ) {
        uniqueRows = resp.data.fundData.map((item, idx) => {
          return {
            id: idx,
            ...item,
          };
        });
      }
      setRows(uniqueRows);
      setResultsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <div className="w-100"> */}
      {/* <BootstrapTable
          pagination={paginationFactory(options)}
          keyField="id"
          data={rows}
          columns={columns(selectRow)}
          bordered={false}
          rowClasses={"table-row-style"}
        /> */}

      <DataGridPro
        sx={{
          height: "400px",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            borderRadius: "6px",
          },
        }}
        rows={rows}
        columns={newCols}
        loading={resultsLoading}
        rowHeight={54}
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
      />
      {/* </div> */}
    </>
  );
};
export default FirmByFirm;
