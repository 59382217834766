import React, { useState, useEffect, useMemo } from "react";
import "./App.scss";
import NavRoutes from "./Routes/NavRoutes";
import { UserContext } from "./Contexts/UserContext";
import {
  getInvestorProfile,
  getInvestorSummary,
  getAccountList,
} from "./APIs/ProfileAPI";
import { getFirmUser } from "./APIs/WealthManagementAPI";
import { Auth } from "aws-amplify";
import { LicenseInfo } from "@mui/x-license-pro";
import { getSignedURL } from "./utilities/functions/functions.js";
import { H } from "highlight.run";
import FS from "@fullstory/browser";
import { FullStoryAPI } from "react-fullstory";

LicenseInfo.setLicenseKey(
  "90ea1ebaedc04fc526d174349ec958baTz05MjYxMixFPTE3NTAyMjIxMjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const [activePage, setActivePage] = useState("");
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      setIsLoading(true);
      let sessionInfo = await Auth.currentSession();

      try {
        const userID = sessionInfo.idToken.payload.email;

        try {
          let getProfileStartTime = new Date().getTime();
          let getProfileEndTime = new Date().getTime();
          let getProfileExecutionTime = getProfileEndTime - getProfileStartTime;

          const wmFirmUserInfo = await getFirmUser();
          const isAuthenticated = true;

          async function getSignedLogoLink(
            id,
            authenticated,
            firmUser,
            setFirmUser,
            setLoadingVal
          ) {
            try {
              // var logoLink = "";
              var logoLink = await getSignedURL(
                "public",
                firmUser.data.firm.logo
              );

              setFirmUser({
                id: id,
                userProfileData: {
                  id: firmUser.data.id,
                  firstName: firmUser.data.first_name,
                  lastName: firmUser.data.last_name,
                  email: firmUser.data.email,
                  phoneNumber: firmUser.data.phone_number,
                  country: firmUser.data.country,
                },
                userRole: firmUser.data.role,
                clients: firmUser.data.clients,
                firmInfo: {
                  deals: firmUser.data.deals,
                  id: firmUser.data.firm.id,
                  firmType: firmUser.data.firm.firm_type,
                  name: firmUser.data.firm.name,
                  logoLink: firmUser ? (logoLink ? logoLink : "") : "",
                  shareClients: firmUser
                    ? firmUser.data.firm.share_clients
                    : false,
                },
                isAuthenticated: authenticated,
                clientId: firmUser.data.account
                  ? firmUser.data.account.client_id
                  : null,
              });
              // FS.identify(firmUser.data.email, {
              //   displayName:
              //     firmUser.data.first_name + " " + firmUser.data.last_name,
              //   email: firmUser.data.email,
              //   // Add your own custom user variables here, details at
              //   // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
              // });
              FullStoryAPI("identify", firmUser.data.email, {
                displayName:
                  firmUser.data.first_name + " " + firmUser.data.last_name,
                email: firmUser.data.email,
              });
              setLoadingVal(false);
            } catch (err) {
              setLoadingVal(false);
            }
          }

          getSignedLogoLink(
            userID,
            isAuthenticated,
            wmFirmUserInfo,
            setUser,
            setIsLoading
          );

          // setUser({
          //   id: userID,
          //   userProfileData: {
          //     id: wmFirmUserInfo.data.id,
          //     firstName: wmFirmUserInfo.data.first_name,
          //     lastName: wmFirmUserInfo.data.last_name,
          //     email: wmFirmUserInfo.data.email,
          //     phoneNumber: wmFirmUserInfo.data.phone_number,
          //     country: wmFirmUserInfo.data.country,
          //   },
          //   userRole: wmFirmUserInfo.data.role,
          //   firmInfo: {
          //     id: wmFirmUserInfo.data.firm.id,
          //     name: wmFirmUserInfo.data.firm.name,
          //   },
          //   isAuthenticated: isAuthenticated,
          // });
          // setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          if (process.env.DEBUG)
            console.error("Error getting current user profile.");
        }
      } catch (err) {
        setIsLoading(false);
        console.error("Error getting cognitoUser.");
      }
    } catch (e) {
      setIsLoading(false);
      if (e !== "No current user") {
        if (process.env.DEBUG) console.error("Error getting current session.");
      }
    }
  }

  const value = useMemo(() => {
    return {
      user: user,
      setUser: setUser,
    };
  }, [user]);

  // H.init("jgoqyzel", {
  //   serviceName: "frontend-app",
  //   tracingOrigins: false,
  //   networkRecording: {
  //     enabled: false,
  //     recordHeadersAndBody: false,
  //     urlBlocklist: [
  //       // insert full or partial urls that you don't want to record here
  //       // Out of the box, Highlight will not record these URLs (they can be safely removed):
  //       "https://www.googleapis.com/identitytoolkit",
  //       "https://securetoken.googleapis.com",
  //     ],
  //   },
  // });

  return (
    <React.Fragment>
      <UserContext.Provider value={value}>
        <NavRoutes
          isLoading={isLoading}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </UserContext.Provider>
    </React.Fragment>
  );
}

export default App;
