import React, {useState, useEffect } from "react";
import QualificationQuestion from "./../../Qualification/QualificationQuestion.js";
import QPModal from "./../../Qualification/QPModal.js"
import "./BasicInfo.scss";
import SnackBar from "./../../Alerts/SnackBar/SnackBar";

function AccountBasicInformation({formValues, setActiveSlide, qualifies, setQualifies, setIndComplete, setComplete}) {

  const [individualQualificationQ1, setIndividualQualificationQ1] = useState("");
  const [loading, setLoading] = useState(false); 
  const showIndividualQualificationSection = true;
  const mountedStyle = {
    opacity: 1,
    transition: "opacity 1.5s ease-out, transform 400ms ease-out",
  };
  const unmountedStyle = { opacity: 0, transition: "opacity 400ms ease-in" };
  const individualAccountBasicInformationError = false; 


  useEffect( () => {
    individualQualificationQ1 !== false ? setQualifies(true) : setQualifies(false); 
  }, [individualQualificationQ1]);

  useEffect( () => {
    if (individualQualificationQ1 !== "") { setIndComplete(true); setComplete(true); } 
  }, [individualQualificationQ1]);

  return (
    <React.Fragment>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={
          showIndividualQualificationSection ? mountedStyle : unmountedStyle
        }
      >
        <span className="add-account-step-subheader">
          Qualification Question
        </span>
      </div>
      <div
        className="row g-0 w-100 mb-4 fade-in-test"
        style={
          showIndividualQualificationSection ? mountedStyle : unmountedStyle
        }
      >
        <span className="add-account-qualification-subheader pe-1">
          Please confirm your{" "}
          <span style={{ fontWeight: 600 }}>Qualified Purchaser</span> status
        </span>
      </div>
      <QualificationQuestion
        showQualSection={showIndividualQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={1}
        questionText={
          "Are you a natural person who alone (or together with his/her spouse, if investing jointly) owns not less than $5,000,000 in investments."
        }
        answer={individualQualificationQ1}
        setAnswer={setIndividualQualificationQ1}
      />
      <QPModal
        showQualSection={showIndividualQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />

      { individualAccountBasicInformationError && <SnackBar
        snackbarState={true}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Sorry, there was an error signing up."}
      /> } 
    </React.Fragment>
  );
}
export default AccountBasicInformation;
