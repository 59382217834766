import React, { useState, useEffect } from "react";
import { getFundInvestmentInformation } from "../APIs/ConfirmInvestmentAPI";

export default function GetFundInvestmentInformation(
  time,
  fundLink,
  setLoading
) {
  const [fundInvestmentInformation, setFundInvestmentInformation] =
    useState(null);

  useEffect(() => {
    async function getFundInvestmentInformationObject() {
      setLoading(true);
      try {
        const response = await getFundInvestmentInformation(fundLink);
        const fundInvestmentInformationData = response.data;
        // const investorConfidentialityAgreementData = response.data[0];
        setFundInvestmentInformation(fundInvestmentInformationData);
        // setInvestorConfidentialityAgreement({
        //   id: investorConfidentialityAgreementData.id,
        //   version: investorConfidentialityAgreementData.version,
        //   agreement: investorConfidentialityAgreementData.agreement,
        //   status: investorConfidentialityAgreementData.status,
        //   startDate: investorConfidentialityAgreementData.start_date,
        // });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err);
        }
      }
      setLoading(false);
    }
    getFundInvestmentInformationObject();
  }, [time, fundLink]);
  return { fundInvestmentInformation };
}
