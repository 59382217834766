import React, { useRef, useState, useContext, useEffect, createRef } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./Styles/Styles.scss";
import { UserContext } from "../../../../../Contexts/UserContext";
import { useMediaQuery } from "react-responsive";
import Docs from "../Docs/Docs";
import { getSignedURL } from "../../../../../utilities/functions/functions";

const PDFDocument = ({ fund }) => {
  const [numPages, setNumPages] = useState(null);
  const [deckLink, setDeckLink] = useState("");

  const getDeckURL = async () => {
    const signedURL = await getSignedURL("public", fund.deckLink);
    setDeckLink(signedURL);
  };
  useEffect(() => {
    getDeckURL();
  }, [fund]);

  return (
    <Document
      onLoadSuccess={(PDFDocumentProxy) => {
        setNumPages(PDFDocumentProxy._pdfInfo.numPages);
      }}
      file={{ url: deckLink }}
    >
      {" "}
      <PDFPage numPages={numPages} />{" "}
    </Document>
  );
};

// We can move this to a folder containing common components
const ArrowButton = ({ direction, onClick }) => {
  const DIRECTIONS = { left: "Left-Arrow.svg", right: "Right-Arrow.svg" };

  return (
    <img
      src={`/${DIRECTIONS[direction]}`}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    />
  );
};

const PDFPage = ({ numPages }) => {
  const [page, setPage] = useState(1);
  const canvas = useRef();
  const { user } = useContext(UserContext);

  const onRenderSuccess = () => {
    if (canvas.current) {
      const context = canvas.current.getContext("2d");
      const { height } = canvas.current;
      context.globalAlpha = 0.5;
      context.textAlign = "left";
      context.font = height / 35 + "px sans-serif";
      // Watermark displaying full name and email
      context.fillStyle = "rgba(255, 255, 255, 0.5)";
      context.translate(0, -15);
      context.fillText(
        `${user.userProfileData.firstName} ${user.userProfileData.lastName} / ${user.id}`,
        0,
        height / 16
      );
      // context.translate(-2, -2);
      context.fillStyle = "rgba(0,0,0,0.5)";
      context.translate(0, 0);
      context.fillText(
        `${user.userProfileData.firstName} ${user.userProfileData.lastName} / ${user.id}`,
        0,
        height / 16
      );
      context.save();
      // Page numbering
      context.font = height / 40 + "px sans-serif";
      context.translate(0, (height * 9) / 10);
      context.fillText(`Page ${page} of ${numPages}`, 0, height / 16);
      context.fillStyle = "white";
      context.translate(-2, -2);
      context.fillText(`Page ${page} of ${numPages}`, 0, height / 16);
    }
  };

  return (
    <div className="row g-0 ms-4 me-4 mt-4">
      <div className="col text-center arrow-buttons">
        {page > 1 && (
          <ArrowButton
            direction="left"
            onClick={() => {
              setPage(page - 1);
            }}
          />
        )}
      </div>
      <div className="col d-flex align-items-center">
        <Page
          canvasRef={canvas}
          onRenderSuccess={onRenderSuccess}
          height={500}
          pageNumber={page}
        />
      </div>
      <div className="col text-center arrow-buttons">
        {page < numPages && (
          <ArrowButton
            direction="right"
            onClick={() => {
              setPage(page + 1);
            }}
          />
        )}
      </div>
    </div>
  );
};

const Deck = ({ fund }) => {
  // Screens exceeding this width will be provided a signed URL to the deck pdf
  const isMobile = useMediaQuery({ query: `(max-width: 1280px)` });

  return (
    <div className="row g-0 ms-4 me-4 mt-4" style={{ marginBottom: "7%" }}>
      <div className="col-12 heading-4">Deck Preview</div>
      <div className="container">
        <div className="row mt-2 gx-3 gy-2">
          {
            !isMobile ? <PDFDocument fund={fund} /> : null
            // <Docs fund={fund} deckOnly={true} />
          }
        </div>
      </div>
    </div>
  );
};
export default Deck;
