import React, { useEffect, useState } from "react";
import "./Styles/Styles.scss";
import WidgetRow from "../../../../../../Components/WidgetRow/WidgetRow";

const Reference = ({ referenceTerms }) => {
  const [displayTerms, setDisplayTerms] = useState([]);

  useEffect(() => {
    let terms = referenceTerms.filter((term) => term.display_term === true);
    setDisplayTerms(terms);
  }, []);

  return <WidgetRow cardItems={displayTerms} />;
};
export default Reference;
