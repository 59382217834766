import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import { useStep } from "react-hooks-helper";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import ConfidentialityAgreement from "./ConfidentialityAgreement/ConfidentialityAgreement";
import SignupForm from "./SignupForm/SignupForm";
import CodeVerification from "./CodeVerification/CodeVerification";
import MagicCode from "./CodeVerification/MagicCode";
import ProvideEmail from "./SignupForm/ProvideEmail";

import LeftArrow from "../../../../Components/Buttons/LeftArrow/LeftArrow";

const SignupSteps = ({ feeder }) => {
  // Definitions for steps of the signup process
  const steps = [
    { id: "termsAgreement" },
    { id: "confidentialityAgreement" },
    { id: "signupForm" },
    { id: "confirmCode" },
  ];

  const feederSteps = [{ id: "magicCode" }, { id: "provideEmail" }];

  const { step, navigation } = useStep({
    steps: feeder === true ? feederSteps.concat(steps) : steps,
    initialStep: 0,
  });

  // States needed for signup
  const [code, setCode] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsOfUseId, setTermsOfUseId] = useState("");
  const [termsOfUseDateSigned, setTermsOfUseDateSigned] = useState("");
  const [
    confidentialityAgreementSignature,
    setConfidentialityAgreementSignature,
  ] = useState("");
  const [confidentialityAgreementAgreed, setConfidentialityAgreementAgreed] =
    useState(false);
  const [confidentialityAgreementId, setConfidentialityAgreementId] =
    useState("");
  const [
    confidentialityAgreementDateSigned,
    setConfidentialityAgreementDateSigned,
  ] = useState("");

  const [firmName, setFirmName] = useState("");
  const [advisorFirstName, setAdvisorFirstName] = useState("");
  const [advisorLastName, setAdvisorLastName] = useState("");
  const [domicileCountry, setDomicileCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <div className="container-fluid g-0 ps-5 pe-5 h-100">
      <div className="row g-0 w-100 h-100">
        <div className="col-12">
          <div className="row g-0 w-100 mt-5 mb-5">
            <div className="col-12 height-md">
              {step.id !== "termsAgreement" ||
                (step.id !== "magicCode" && feeder !== true && (
                  <LeftArrow onClick={() => navigation.previous()} />
                ))}
            </div>
          </div>
          <div className="row g-0 w-100 align-self-top mb-4">
            <h2>Sign Up</h2>
          </div>
          <React.Fragment>
            {(() => {
              switch (step.id) {
                case "magicCode":
                  return (
                    <MagicCode
                      code={code}
                      setCode={setCode}
                      navigation={navigation}
                    />
                  );
              }
              switch (step.id) {
                case "provideEmail":
                  return (
                    <ProvideEmail
                      code={code}
                      email={email}
                      setEmail={setEmail}
                      navigation={navigation}
                    />
                  );
              }
              switch (step.id) {
                case "termsAgreement":
                  return (
                    <TermsOfUse
                      navigation={navigation}
                      checked={termsAgreed}
                      setChecked={setTermsAgreed}
                      setTermsOfUseId={setTermsOfUseId}
                      setTermsOfUseDateSigned={setTermsOfUseDateSigned}
                    />
                  );
                case "confidentialityAgreement":
                  return (
                    <ConfidentialityAgreement
                      navigation={navigation}
                      checked={confidentialityAgreementAgreed}
                      setChecked={setConfidentialityAgreementAgreed}
                      name={confidentialityAgreementSignature}
                      setName={setConfidentialityAgreementSignature}
                      setConfidentialityAgreementId={
                        setConfidentialityAgreementId
                      }
                      setConfidentialityAgreementDateSigned={
                        setConfidentialityAgreementDateSigned
                      }
                    />
                  );
                case "signupForm":
                  return (
                    <SignupForm
                      navigation={navigation}
                      confidentialitySignatureName={
                        confidentialityAgreementSignature
                      }
                      firmName={firmName}
                      setFirmName={setFirmName}
                      advisorFirstName={advisorFirstName}
                      setAdvisorFirstName={setAdvisorFirstName}
                      advisorLastName={advisorLastName}
                      setAdvisorLastName={setAdvisorLastName}
                      domicileCountry={domicileCountry}
                      setDomicileCountry={setDomicileCountry}
                      email={email}
                      setEmail={setEmail}
                      phoneNumber={phoneNumber}
                      setPhoneNumber={setPhoneNumber}
                      password={password}
                      setPassword={setPassword}
                      confirmPassword={confirmPassword}
                      setConfirmPassword={setConfirmPassword}
                      confidentialityAgreementId={confidentialityAgreementId}
                      confidentialityAgreementDateSigned={
                        confidentialityAgreementDateSigned
                      }
                      termsOfUseId={termsOfUseId}
                      termsOfUseDateSigned={termsOfUseDateSigned}
                      feeder={feeder}
                      code={code}
                    />
                  );
                case "confirmCode":
                  return <CodeVerification email={email} />;
                default:
                  return (
                    <TermsOfUse
                      navigation={navigation}
                      termsAgreed={termsAgreed}
                      setTermsAgreed={setTermsAgreed}
                      setTermsOfUseId={setTermsOfUseId}
                      setTermsOfUseDateSigned={setTermsOfUseDateSigned}
                    />
                  );
              }
            })()}
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};
export default SignupSteps;
