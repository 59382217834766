import React, { useEffect, useState } from "react";
import "./Styles/Styles.scss";
import Checkbox from "../../../../../Components/InputFields/Checkbox/Checkbox";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import GetTermsOfUseAgreement from "../../../../../Adapters/TermsOfUseAgreement";
import { isBottom } from "../../../../../utilities/functions/functions";

const TermsOfUse = ({
  navigation,
  checked,
  setChecked,
  setTermsOfUseId,
  setTermsOfUseDateSigned,
}) => {
  const timeStamp = Date.now();
  const [time, setTime] = useState(timeStamp);
  const { investorTermsOfUse } = GetTermsOfUseAgreement(time);

  const [documentHasBeenRead, setDocumentHasBeenRead] = useState(false);
  const [documentReadError, setDocumentReadError] = useState(false);

  const [checkboxError, setCheckboxError] = useState(false);

  useEffect(() => {
    if (
      investorTermsOfUse !== null &&
      investorTermsOfUse !== undefined &&
      investorTermsOfUse.id !== null &&
      investorTermsOfUse.id !== undefined
    ) {
      setTermsOfUseId(investorTermsOfUse.id);
    }
  }, [investorTermsOfUse]);

  var handleScroll = (e) => {
    if (process.env.DEBUG) console.log("document read: ", documentHasBeenRead);
    if (isBottom(e) && !documentHasBeenRead) {
      setDocumentHasBeenRead(true);
    }
  };

  const validateCheckboxSelected = () => {
    var errors = false;
    if (!checked) {
      errors = true;
      setCheckboxError(true);
    }

    return !errors;
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-2">
        <h6>Please read through our terms of use agreement:</h6>
      </div>
      <div className="row g-0 w-100 mb-1">
        <div className="col-12">
          <div
            className="document-container"
            onScroll={(event) => handleScroll(event)}
          >
            <div className="container-fluid">
              <span
                className="body-text-xs-regular"
                dangerouslySetInnerHTML={{
                  __html: investorTermsOfUse
                    ? investorTermsOfUse.agreement
                    : "Loading ...",
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col-12">
          <div className="row g-0 w-100">
            <div
              className="col-1 checkBox-container"
              onClick={() => {
                if (documentHasBeenRead) {
                  if (!checked) {
                    var today = new Date().toISOString();
                    setTermsOfUseDateSigned(today);
                  }
                  setChecked(!checked);
                } else if (checked) {
                  setChecked(false);
                } else if (!documentHasBeenRead) {
                  setDocumentReadError(true);
                }
              }}
            >
              <Checkbox selected={checked} />
            </div>
            <div className="col-11">
              <span className="body-text-xs-regular ps-2">
                I have read, understood, and agree to all the terms noted in the{" "}
                <strong>Terms Of Use Agreement</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100">
        <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
          <div className="row g-0 w-100">
            <div className="col ms-0 me-auto">
              <div className="d-flex">
                <MuiButton
                  buttonText={"Next Step"}
                  onClick={() => {
                    if (validateCheckboxSelected()) {
                      navigation.next();
                    }
                  }}
                  size={"large"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        snackbarState={documentReadError}
        setSnackbarState={setDocumentReadError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"warning"}
        message={
          "Please read the terms of use agreement before selecting the checkbox."
        }
      />
      <SnackBar
        snackbarState={checkboxError}
        setSnackbarState={setCheckboxError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"warning"}
        message={
          "Please make sure you have selected the checkbox to agree to our terms."
        }
      />
    </React.Fragment>
  );
};
export default TermsOfUse;
