import React, { useEffect, useState } from "react";
import Widget from "./Widget/Widget.js";
import useWindowDimensions from "../../utilities/functions/useWindowDimensions.js";

/* Display cards/widgets with border spacing in rows of 3
-- Input: Array of objects
    objects must include:
    -id
    -definition
    -term
-- output: row with sets of 3 or less cards
*/
const WidgetRow = ({ cardItems }) => {
  const [rowClass, setRowClass] = useState("row content row-cols-3");
  let { width } = useWindowDimensions();

  useEffect(() => {
    //let viewWidth = {width}
    if (cardItems.length == 1 || width <= 1030) {
      setRowClass("row content row-cols-1");
    }

    if (cardItems.length % 3 == 0) {
      setRowClass("row content row-cols-3");
    }
  }, []);

  let mobileClass = "row content row-cols-1 ";

  let itemsRow = (
    <React.Fragment>
      {cardItems.length > 0 ? (
        <React.Fragment>
          {width <= 1030 ? (
            <div className={mobileClass}>
              {cardItems.map((item) => (
                <Widget cardItem={item} key={item.id} />
              ))}
            </div>
          ) : (
            <div className={rowClass}>
              {cardItems.map((item) => (
                <Widget cardItem={item} key={item.id} />
              ))}
            </div>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="col-12">{itemsRow}</div>
    </React.Fragment>
  );
};

export default WidgetRow;
