import React, { useState, useEffect, useRef } from "react";
import "./Styles/Styles.scss";
import PropTypes from "prop-types";

function ProgressBar(props) {
  const { size, progress, strokeWidth, circleOneStroke, circleTwoStroke } =
    props;

  const [offset, setOffset] = useState(0);
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  //   const circle1Ref = useRef(null);
  const circle2Ref = useRef(null);
  const textRef = useRef(null);

  //   useEffect(() => {
  //     circle1Ref.current.style = "transform: rotate(-90deg);";
  //     circle2Ref.current.style = "transform: rotate(-90deg);";
  //   }, []);

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
    circle2Ref.current.style =
      "transition: stroke-dashoffset 850ms ease-in-out;";
    if (progress >= 75) {
      textRef.current.className.baseVal = "svg-circle-text red-text";
    }
  }, [setOffset, circumference, progress, offset]);

  return (
    <React.Fragment>
      <svg className="svg" width={size} height={size}>
        <circle
          className="svg-circle-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-circle"
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          ref={circle2Ref}
        />
        <text className="svg-circle-text" x={center} y={center} ref={textRef}>
          {progress}%
        </text>
      </svg>
    </React.Fragment>
  );
}
ProgressBar.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  circleOneStroke: PropTypes.string.isRequired,
  circleTwoStroke: PropTypes.string.isRequired,
};
export default ProgressBar;
