import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import GlobalCarousel from "../Carousel/Carousel.js";
import IndividualQualificationSection from "./AccountBasicInformation/AccountBasicInformation";
import TrustQualificationSection from "./../Qualification/TrustQualificationSection";
import EntityQualificationSection from "./../Qualification/EntityQualificationSection";
import LegalInformationTaxSection from "./../LegalInformationTaxSection/LegalInformationTaxSection.js";
import GrantorInformation from "./GrantorInformation.js";
import ClientBasicInfo from "./ClientBasicInfo.js";
import AccountInfo from "./AccountInfo.js";
import MuiButton from "./../Buttons/MuiButton/MuiButton.js";
import BeneficialOwners from "./BeneficialOwners.js";

import AccountContactInfo from "./AccountContactInfo.js";
import { useParams } from "react-router";
import { UserContext } from "../../Contexts/UserContext";

function AddClientWizard() {
  const [activeSlide, setActiveSlide] = useState(0);
  /*
  let history = useNavigate();

  const { id } = useParams();

  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);


  const [alertError, setAlertError] = useState("");
  const [accountID, setAccountID] = useState(null);

  const mountedStyle = {
    opacity: 1,
    transition: "opacity 1.5s ease-out, transform 400ms ease-out",
  };
  const unmountedStyle = { opacity: 0, transition: "opacity 400ms ease-in" };

  const [investorProfileComplete, setInvestorProfileComplete] = useState(false);

  Client Basic Info
  Account Type
  Qual Section - [ Ind, Trust, Ent]
  Tax and Legal - [ Trust, Ent ]
  Related Parties / Grantor - [ Trust, Ent ]

*/
  const [formValues, setFormValues] = useState({});

  const [
    showIndividualQualificationSection,
    setShowIndividualQualificationSection,
  ] = useState(false);

  const [isEnt, setIsEnt] = useState(false);

  return (
    <GlobalCarousel
      title="Add Client"
      returnRoute="/clients"
      slides={[
        <ClientBasicInfo />,
        <AccountInfo setIsEnt={setIsEnt} />,
        <>IND successfully added.</>,
        <LegalInformationTaxSection isEnt={isEnt} />,
        <GrantorInformation
          formValues={formValues}
          setFormValues={setFormValues}
          validate={() => {}}
        />,
        <>Trust successfully added.</>,
        <BeneficialOwners />,
        <>Ent succesfully added</>,
      ]}
    />
  );
}
export default AddClientWizard;
