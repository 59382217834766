import React, {useEffect, useState} from "react";
import StyledDateInputField from "../StyledDateInput/StyledDateInput.js";
import TextInputField from "../InputFields/TextInputField/TextInputField";
import PhoneInputField from "../InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "../InputFields/SelectInputField/SelectInputField";
import InfiniteInputComponent from "../InfiniteInputComponent/InfiniteInputComponent.js";
import InfiniteTabInput from "../InfiniteInputComponent/InfiniteTabInput.js";
import TrusteeQuestion from "./TrusteeQuestion.js"
import { countryList } from "../../utilities/lists/countryList";
import MuiButton from "./../Buttons/MuiButton/MuiButton";
import { isEmailValid } from "./../../utilities/functions/functions.js";
import { useNavigate } from "react-router-dom";



function BeneficialOwners({setActiveSlide}) {
  const navigate = useNavigate();
    const stateList = [{}];
    const [answer, setAnswer] = useState("");
    const [hasTrusteesError, setHasTrusteesError] = useState("");

    const [relatedAccounts, setRelatedAccounts] = useState([{
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
      },
]);
    const [activeTab, setActiveTab] = useState("Beneficial Owner #1");
  
    const handleRemoveFields = (index) => {
      const nextIdx = index === 0 ? 0 : index - 1;
      setActiveTab("Beneficial Owner #" + (nextIdx + 1).toString());
      setRelatedAccounts((relatedAccounts) =>
        relatedAccounts.filter((elem, idx) => idx !== index)
      );
    };
    const addNewTab = () => {
      setRelatedAccounts([
        ...relatedAccounts,
        {
          firstName: "",
          firstNameError: "",
          lastName: "",
          lastNameError: "",
          email: "",
          emailError: "",
          phoneNumber: "",
          phoneNumberError: "",
          addressLine1: "",
          addressLine1Error: "",
          addressLine2: "",
          addressLine2Error: "",
          city: "",
          cityError: "",
          state: "",
          stateError: "",
          country: "",
          countryError: "",
          postalCode: "",
          postalCodeError: "",
          addressType: "",
          addressTypeError: "",
          deliverableAddressLine1: "",
          deliverableAddressLine2: "",
          deliverableCity: "",
          deliverableState: "",
          deliverableCountry: "",
          deliverablePostalCode: "",
          deliverableAddressType: "",
          dateOfBirth: "",
          dateOfBirthError: "",
          idType: "",
          idNum: "",
          idNumError: "",
          domicileCountry: "",
          domicileCountryError: "",
        },
      ]);
      setActiveTab("Beneficial Owner #" + (relatedAccounts.length + 1).toString());
    };


    const submit =  () => {
      let values = [...relatedAccounts];
      let requestData = [];
      var errors = false;
  
      if (answer === "") {
        setHasTrusteesError("Please make a selection.");
        errors = true;
      }
  
      if (answer === true) {
        requestData = relatedAccounts.map((inputField, index) => {
          if (inputField.firstName === "") {
            values[index]["firstNameError"] = "This field is required.";
            errors = true;
          }
          if (inputField.lastName === "") {
            values[index]["lastNameError"] = "This field is required.";
            errors = true;
          }
          if (inputField.email === "") {
            values[index]["emailError"] = "This field is required.";
            errors = true;
          } else if (!isEmailValid(inputField.email)) {
            values[index]["emailError"] = "Please enter a valid email.";
            errors = true;
          }
          //Add email validation
          if (inputField.phoneNumber === "") {
            values[index]["phoneNumberError"] =
              "Please enter a valid phone number.";
          } else if (inputField.phoneNumber.length < 7) {
            values[index][inputField.phoneNumberError] =
              "Please enter a valid phone number";
            errors = true;
          }
          if (inputField.addressLine1 === "") {
            values[index]["addressLine1Error"] = "This field is required.";
            errors = true;
          }
          if (inputField.city === "") {
            values[index]["cityError"] = "This field is required.";
            errors = true;
          }
          if (inputField.country === "United States") {
            if (inputField.state === "") {
              values[index]["stateError"] = "Please enter a state from the list.";
              errors = true;
            }
          }
          if (inputField.country === "") {
            values[index]["countryError"] =
              "Please enter a country from the list.";
            errors = true;
          } 
          if (inputField.postalCode === "") {
            values[index]["postalCodeError"] = "This field is required.";
            errors = true;
          }
  
          if (inputField.domicileCountry === "") {
            values[index]["domicileCountryError"] =
              "Please enter a country from the list.";
            errors = true;
          }
  
          if (inputField.dateOfBirth === "") {
            values[index]["dateOfBirthError"] = "This field is required.";
            errors = true;
          } else {
            const date = inputField.dateOfBirth;
          }
          if (inputField.idNum === "") {
            values[index]["idNumError"] = "This field is required.";
            errors = true;
          } else if (
            inputField.domicileCountry === "United States" && false
          ) {
            errors = true;
            values[index]["idNumError"] = "Please enter a valid ssn.";
          }
          return {
            first_name: inputField.firstName,
            last_name: inputField.lastName,
            email: inputField.email,
            phone_number: inputField.phoneNumber,
            address_line_1: inputField.addressLine1,
            address_line_2: inputField.addressLine2,
            city: inputField.city,
            state:
              inputField.country === "United States"
                ? inputField.state.length !== 0
                  ? inputField.state
                  : ""
                : "",
            country: inputField.country,
            postal_code: inputField.postalCode,
            address_type: "RM",
            date_of_birth: inputField.dateOfBirth,
            id_type:
              inputField.domicileCountry === "United States" ? "SSN" : "PASS",
            id_number: inputField.idNum,
            domicile_country: inputField.domicileCountry,
            role: "TST",
            address2_line_1: "",
            address2_line_2: "",
            address2_city: "",
            address2_state: "",
            address2_country: "",
            address2_postal_code: "",
            address2_type: "",
          };
        });
      }
  
  
      // if (relatedAccounts.length >= 1) {
      if (errors) {
        setRelatedAccounts(values);
        return;
      } else {
          navigate("/clients");
          // Make sure that the active slide is progressed to the next page on success. Don't want the user to be able to adjust the information that they supplied.
          setActiveSlide((prevState) => prevState + 1);
        //}
      }
    };

    useEffect(() => setHasTrusteesError(""), [answer]);
  

    return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-1">
          <span className="add-account-step-subheader">
            Related Party Information
          </span>
        </div>
        <TrusteeQuestion
        questionNumber={10}
        questionText={"Excluding yourself, does this entity have any beneficial owners that own 25% or more?"}
        answer={answer}
        setAnswer={setAnswer}
        hasTrusteesError={hasTrusteesError}
      />
        { answer === true && <div
        className={"row g-0 w-100 fade-in-test"}
      >
        <InfiniteTabInput
          tabs={relatedAccounts.map(
            (elem, idx) => "Beneficial Owner #" + (idx + 1).toString()
          )}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addNewTab={addNewTab}
          addMore
        />
        <InfiniteInputComponent
          accountID={""}
          activeTab={activeTab}
          processedCountryList={countryList}
          processedStateList={stateList}
          inputFields={relatedAccounts}
          setInputFields={setRelatedAccounts}
          handleRemoveFields={handleRemoveFields}
          index={activeTab.match(/\d+/) - 1}
          setActiveTab={setActiveTab}
          setActiveSlide={setActiveSlide}
          role={"Beneficial Owner"}
          hideButton={"hideButton"}
          setHideButton={()=>{}}
          hideAlterButtons={""}
          setHideAlterButtons={()=>{}}
        />
      </div> }
      <div className="d-flex">
        <MuiButton
          buttonText={"Next Step"}
          onClick={submit}
          size={"large"}
        />  
      </div>
    </React.Fragment>
  );
}
export default BeneficialOwners;
