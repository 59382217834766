import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../Contexts/UserContext";
import PageForbidden from "../Layouts/403/PageForbidden";

interface AdvisorCoAdvisorAuthenticatedRouteProps {
  children: React.ReactNode;
}

interface User {
  userRole: string;
  isAuthenticated: boolean;
}

interface UserContextType {
  user: User | null;
}

export default function AdvisorCoAdvisorAuthenticatedRoute({
  children,
  ...rest
}: AdvisorCoAdvisorAuthenticatedRouteProps) {
  const { pathname, search } = useLocation();
  const { user } = useContext(UserContext) as UserContextType;

  return user !== null && user.isAuthenticated ? (
    user.userRole === "Advisor" || user.userRole === "Co-Advisor" ? (
      children
    ) : (
      <PageForbidden />
    )
  ) : (
    <Navigate to={`/login?redirect=${pathname}${search}`} />
  );
}
