import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import { HOST } from "../utilities/host-config/host-config.js";
import { v4 as uuidv4 } from "uuid";

//To-Do: Adapt the endpoint being hit to allow advisors to retrieve fund information without supplying an account id
export const getFund = async (fundLink) => {
  const link = fundLink.toString();
  const config = await getRequestHeaders();

  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/wm-get-fund/" + link + "/", requestParams);

  // return {
  //   data: {
  //     id: uuidv4(),
  //     link: "vintage_iv",
  //     name: "Vintage IV",
  //     tagline: "Strong Risk Adjusted Returns",
  //     short_description: `<div class="row g-0 w-100 mt-4 ps-4">
  //           <span class="heading-4 mb-2" style="font-size: 22px;">June 2022 Update:</span>
  //           <ul class="ms-3">
  //             <li class="ms-4 mb-1">
  //           <span class="body-text-sm-regular">Sigma 6 is <b>~66% committed to 30 investments to date</b>; current portfolio generating a 21% annualized cash yield</span>
  //             </li>
  //             <li class="ms-4">
  //           <span class="body-text-sm-regular">Closed commitments of €84m to date; target fund size of €150m, with <b>significant GP commitment of ~20% of the fund</b></span>
  //             </li>
  //             </ul>
  //           </div>
  //           <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //           <span class="heading-4" style="font-size: 22px;">Overview</span>
  //           </div>
  //           <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //           <p class="body-text-sm-regular">The <a href="https://www.permira.com" target="_blank" style="cursor: pointer;">Permira</a> Credit funds have been investing in European private credit for more than a decade.</p>
  //           <p class="body-text-sm-regular">Collectively, the Permira Credit funds have invested approximately €13bn since inception in a range of European debt products, making them one of the leading specialist private credit investors in Europe.</p>
  //           <p class="body-text-sm-regular">The Sigma funds began investing in European CLOs in 2010, and have developed a distinctive investment strategy over the last decade which focuses on making long-term investments in the European CLO market.</p>
  //                     </div>
  //           <div class="row g-0 w-100 ps-4 pe-4 mb-1">
  //           <span class="heading-4" style="font-size: 22px;">Aqua Thesis</span>
  //           </div>
  //                     <div class="row g-0 ps-4 pe-4 w-100">
  //                       <div class="col-12">
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Compelling Opportunity:
  //                           </span>
  //           <div class="d-flex ps-3 pe-3 justify-content-center"><img src="https://d1swyfveypj49y.cloudfront.net/permirariskreward.png" /></div>
  //                         </div>
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Proven Track Record:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 Fully realised track record; net multiple of 1.8x and net
  //                                 IRR of 19% for 2010-2012 vintage funds
  //                               </span>
  //                             </li>
  //                             <li class="ms-4">
  //                               <span class="body-text-sm-regular">
  //                                 €1.5bn invested in structured credit since 2010 across
  //                                 five Sigma funds and several managed accounts
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Flexible Investment Strategy:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 Ability to invest across the CLO capital structure as the
  //                                 opportunity set shifts (equity and sub-IG rated notes)
  //                               </span>
  //                             </li>
  //                             <li class="ms-4">
  //                               <span class="body-text-sm-regular">
  //                                 Undertaking bottom-up credit analysis and applying
  //                                 informed judgement on individual loans within portfolios
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Leading Credit Platform:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 One of the largest specialist credit platform in Europe,
  //                                 with a Team of ~60 credit professionals across three core
  //                                 strategies (direct lending, structured credit, CLO
  //                                 management)
  //                               </span>
  //                             </li>
  //                             <li class="ms-4">
  //                               <span class="body-text-sm-regular">
  //                                 Three structured credit specialists with access to ~30
  //                                 investment professionals with credit analysis expertise
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                         <div class="row g-0 w-100">
  //                           <span class="body-text-lg-medium mb-1">
  //                             About Permira:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 British global investment firm established in 1985 with
  //                                 ~€58B AUM, 360 employees in 16 offices around Europe,
  //                                 North America and Asia.
  //                               </span>
  //                             </li>
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 Permira focuses on four sectors: Technology, Consumer,
  //                                 Healthcare and Services
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                       </div>
  //                     </div>`,
  //     long_description: `<div class="row g-0 w-100 mt-4 ps-4">
  //           <span class="heading-4 mb-2" style="font-size: 22px;">June 2022 Update:</span>
  //           <ul class="ms-3">
  //             <li class="ms-4 mb-1">
  //           <span class="body-text-sm-regular">Sigma 6 is <b>~66% committed to 30 investments to date</b>; current portfolio generating a 21% annualized cash yield</span>
  //             </li>
  //             <li class="ms-4">
  //           <span class="body-text-sm-regular">Closed commitments of €84m to date; target fund size of €150m, with <b>significant GP commitment of ~20% of the fund</b></span>
  //             </li>
  //             </ul>
  //           </div>
  //           <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //           <span class="heading-4" style="font-size: 22px;">Overview</span>
  //           </div>
  //           <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //           <p class="body-text-sm-regular">The <a href="https://www.permira.com" target="_blank" style="cursor: pointer;">Permira</a> Credit funds have been investing in European private credit for more than a decade.</p>
  //           <p class="body-text-sm-regular">Collectively, the Permira Credit funds have invested approximately €13bn since inception in a range of European debt products, making them one of the leading specialist private credit investors in Europe.</p>
  //           <p class="body-text-sm-regular">The Sigma funds began investing in European CLOs in 2010, and have developed a distinctive investment strategy over the last decade which focuses on making long-term investments in the European CLO market.</p>
  //                     </div>
  //           <div class="row g-0 w-100 ps-4 pe-4 mb-1">
  //           <span class="heading-4" style="font-size: 22px;">Aqua Thesis</span>
  //           </div>
  //                     <div class="row g-0 ps-4 pe-4 w-100">
  //                       <div class="col-12">
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Compelling Opportunity:
  //                           </span>
  //           <div class="d-flex ps-3 pe-3 justify-content-center"><img src="https://d1swyfveypj49y.cloudfront.net/permirariskreward.png" /></div>
  //                         </div>
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Proven Track Record:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 Fully realised track record; net multiple of 1.8x and net
  //                                 IRR of 19% for 2010-2012 vintage funds
  //                               </span>
  //                             </li>
  //                             <li class="ms-4">
  //                               <span class="body-text-sm-regular">
  //                                 €1.5bn invested in structured credit since 2010 across
  //                                 five Sigma funds and several managed accounts
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Flexible Investment Strategy:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 Ability to invest across the CLO capital structure as the
  //                                 opportunity set shifts (equity and sub-IG rated notes)
  //                               </span>
  //                             </li>
  //                             <li class="ms-4">
  //                               <span class="body-text-sm-regular">
  //                                 Undertaking bottom-up credit analysis and applying
  //                                 informed judgement on individual loans within portfolios
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                         <div class="row g-0 w-100 mb-2">
  //                           <span class="body-text-lg-medium mb-1">
  //                             Leading Credit Platform:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 One of the largest specialist credit platform in Europe,
  //                                 with a Team of ~60 credit professionals across three core
  //                                 strategies (direct lending, structured credit, CLO
  //                                 management)
  //                               </span>
  //                             </li>
  //                             <li class="ms-4">
  //                               <span class="body-text-sm-regular">
  //                                 Three structured credit specialists with access to ~30
  //                                 investment professionals with credit analysis expertise
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                         <div class="row g-0 w-100">
  //                           <span class="body-text-lg-medium mb-1">
  //                             About Permira:
  //                           </span>
  //                           <ul class="ms-3">
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 British global investment firm established in 1985 with
  //                                 ~€58B AUM, 360 employees in 16 offices around Europe,
  //                                 North America and Asia.
  //                               </span>
  //                             </li>
  //                             <li class="ms-4 mb-1">
  //                               <span class="body-text-sm-regular">
  //                                 Permira focuses on four sectors: Technology, Consumer,
  //                                 Healthcare and Services
  //                               </span>
  //                             </li>
  //                           </ul>
  //                         </div>
  //                       </div>
  //                     </div>`,
  //     incorporation_country: "United States",
  //     registration_type: {
  //       id: uuidv4(),
  //       type: "3C7",
  //     },
  //     strategy_type: {
  //       id: uuidv4(),
  //       type: "PRIVATE_EQUITY",
  //     },
  //     deck_link:
  //       "public/fund_documents/fa05b9bd-80ef-46d7-b6fe-211d8258aa91/documents/HarbourVest2022GlobalFundPi.pdf",
  //     investment_status: "Fundraising",
  //     display_flag: true,
  //     originator_firm: {
  //       id: uuidv4(),
  //       name: "Thamesville Capital",
  //       logo_link:
  //         "public/firm_documents/03c5c002-e7b0-44d8-b94a-94a37aad5b56/static_firm_logos/PermiraGeneratedLog.svg",
  //       firm_aum: "1000000000",
  //     },
  //     originating_fund: {
  //       id: uuidv4(),
  //       name: "Vintage IV",
  //       short_description: `<div class="row g-0 w-100 mt-4 ps-4">
  //                   <span class="heading-4 mb-2" style="font-size: 22px;">June 2022 Update:</span>
  //                   <ul class="ms-3">
  //                     <li class="ms-4 mb-1">
  //                   <span class="body-text-sm-regular">Sigma 6 is <b>~66% committed to 30 investments to date</b>; current portfolio generating a 21% annualized cash yield</span>
  //                     </li>
  //                     <li class="ms-4">
  //                   <span class="body-text-sm-regular">Closed commitments of €84m to date; target fund size of €150m, with <b>significant GP commitment of ~20% of the fund</b></span>
  //                     </li>
  //                     </ul>
  //                   </div>
  //                   <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //                   <span class="heading-4" style="font-size: 22px;">Overview</span>
  //                   </div>
  //                   <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //                   <p class="body-text-sm-regular">The <a href="https://www.permira.com" target="_blank" style="cursor: pointer;">Permira</a> Credit funds have been investing in European private credit for more than a decade.</p>
  //                   <p class="body-text-sm-regular">Collectively, the Permira Credit funds have invested approximately €13bn since inception in a range of European debt products, making them one of the leading specialist private credit investors in Europe.</p>
  //                   <p class="body-text-sm-regular">The Sigma funds began investing in European CLOs in 2010, and have developed a distinctive investment strategy over the last decade which focuses on making long-term investments in the European CLO market.</p>
  //                             </div>
  //                   <div class="row g-0 w-100 ps-4 pe-4 mb-1">
  //                   <span class="heading-4" style="font-size: 22px;">Aqua Thesis</span>
  //                   </div>
  //                             <div class="row g-0 ps-4 pe-4 w-100">
  //                               <div class="col-12">
  //                                 <div class="row g-0 w-100 mb-2">
  //                                   <span class="body-text-lg-medium mb-1">
  //                                     Compelling Opportunity:
  //                                   </span>
  //                   <div class="d-flex ps-3 pe-3 justify-content-center"><img src="https://d1swyfveypj49y.cloudfront.net/permirariskreward.png" /></div>
  //                                 </div>
  //                                 <div class="row g-0 w-100 mb-2">
  //                                   <span class="body-text-lg-medium mb-1">
  //                                     Proven Track Record:
  //                                   </span>
  //                                   <ul class="ms-3">
  //                                     <li class="ms-4 mb-1">
  //                                       <span class="body-text-sm-regular">
  //                                         Fully realised track record; net multiple of 1.8x and net
  //                                         IRR of 19% for 2010-2012 vintage funds
  //                                       </span>
  //                                     </li>
  //                                     <li class="ms-4">
  //                                       <span class="body-text-sm-regular">
  //                                         €1.5bn invested in structured credit since 2010 across
  //                                         five Sigma funds and several managed accounts
  //                                       </span>
  //                                     </li>
  //                                   </ul>
  //                                 </div>
  //                                 <div class="row g-0 w-100 mb-2">
  //                                   <span class="body-text-lg-medium mb-1">
  //                                     Flexible Investment Strategy:
  //                                   </span>
  //                                   <ul class="ms-3">
  //                                     <li class="ms-4 mb-1">
  //                                       <span class="body-text-sm-regular">
  //                                         Ability to invest across the CLO capital structure as the
  //                                         opportunity set shifts (equity and sub-IG rated notes)
  //                                       </span>
  //                                     </li>
  //                                     <li class="ms-4">
  //                                       <span class="body-text-sm-regular">
  //                                         Undertaking bottom-up credit analysis and applying
  //                                         informed judgement on individual loans within portfolios
  //                                       </span>
  //                                     </li>
  //                                   </ul>
  //                                 </div>
  //                                 <div class="row g-0 w-100 mb-2">
  //                                   <span class="body-text-lg-medium mb-1">
  //                                     Leading Credit Platform:
  //                                   </span>
  //                                   <ul class="ms-3">
  //                                     <li class="ms-4 mb-1">
  //                                       <span class="body-text-sm-regular">
  //                                         One of the largest specialist credit platform in Europe,
  //                                         with a Team of ~60 credit professionals across three core
  //                                         strategies (direct lending, structured credit, CLO
  //                                         management)
  //                                       </span>
  //                                     </li>
  //                                     <li class="ms-4">
  //                                       <span class="body-text-sm-regular">
  //                                         Three structured credit specialists with access to ~30
  //                                         investment professionals with credit analysis expertise
  //                                       </span>
  //                                     </li>
  //                                   </ul>
  //                                 </div>
  //                                 <div class="row g-0 w-100">
  //                                   <span class="body-text-lg-medium mb-1">
  //                                     About Permira:
  //                                   </span>
  //                                   <ul class="ms-3">
  //                                     <li class="ms-4 mb-1">
  //                                       <span class="body-text-sm-regular">
  //                                         British global investment firm established in 1985 with
  //                                         ~€58B AUM, 360 employees in 16 offices around Europe,
  //                                         North America and Asia.
  //                                       </span>
  //                                     </li>
  //                                     <li class="ms-4 mb-1">
  //                                       <span class="body-text-sm-regular">
  //                                         Permira focuses on four sectors: Technology, Consumer,
  //                                         Healthcare and Services
  //                                       </span>
  //                                     </li>
  //                                   </ul>
  //                                 </div>
  //                               </div>
  //                             </div>`,
  //       long_description: `<div class="row g-0 w-100 mt-4 ps-4">
  //                             <span class="heading-4 mb-2" style="font-size: 22px;">June 2022 Update:</span>
  //                             <ul class="ms-3">
  //                               <li class="ms-4 mb-1">
  //                             <span class="body-text-sm-regular">Sigma 6 is <b>~66% committed to 30 investments to date</b>; current portfolio generating a 21% annualized cash yield</span>
  //                               </li>
  //                               <li class="ms-4">
  //                             <span class="body-text-sm-regular">Closed commitments of €84m to date; target fund size of €150m, with <b>significant GP commitment of ~20% of the fund</b></span>
  //                               </li>
  //                               </ul>
  //                             </div>
  //                             <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //                             <span class="heading-4" style="font-size: 22px;">Overview</span>
  //                             </div>
  //                             <div class="row g-0 w-100 ps-4 pe-4 mb-2">
  //                             <p class="body-text-sm-regular">The <a href="https://www.permira.com" target="_blank" style="cursor: pointer;">Permira</a> Credit funds have been investing in European private credit for more than a decade.</p>
  //                             <p class="body-text-sm-regular">Collectively, the Permira Credit funds have invested approximately €13bn since inception in a range of European debt products, making them one of the leading specialist private credit investors in Europe.</p>
  //                             <p class="body-text-sm-regular">The Sigma funds began investing in European CLOs in 2010, and have developed a distinctive investment strategy over the last decade which focuses on making long-term investments in the European CLO market.</p>
  //                                       </div>
  //                             <div class="row g-0 w-100 ps-4 pe-4 mb-1">
  //                             <span class="heading-4" style="font-size: 22px;">Aqua Thesis</span>
  //                             </div>
  //                                       <div class="row g-0 ps-4 pe-4 w-100">
  //                                         <div class="col-12">
  //                                           <div class="row g-0 w-100 mb-2">
  //                                             <span class="body-text-lg-medium mb-1">
  //                                               Compelling Opportunity:
  //                                             </span>
  //                             <div class="d-flex ps-3 pe-3 justify-content-center"><img src="https://d1swyfveypj49y.cloudfront.net/permirariskreward.png" /></div>
  //                                           </div>
  //                                           <div class="row g-0 w-100 mb-2">
  //                                             <span class="body-text-lg-medium mb-1">
  //                                               Proven Track Record:
  //                                             </span>
  //                                             <ul class="ms-3">
  //                                               <li class="ms-4 mb-1">
  //                                                 <span class="body-text-sm-regular">
  //                                                   Fully realised track record; net multiple of 1.8x and net
  //                                                   IRR of 19% for 2010-2012 vintage funds
  //                                                 </span>
  //                                               </li>
  //                                               <li class="ms-4">
  //                                                 <span class="body-text-sm-regular">
  //                                                   €1.5bn invested in structured credit since 2010 across
  //                                                   five Sigma funds and several managed accounts
  //                                                 </span>
  //                                               </li>
  //                                             </ul>
  //                                           </div>
  //                                           <div class="row g-0 w-100 mb-2">
  //                                             <span class="body-text-lg-medium mb-1">
  //                                               Flexible Investment Strategy:
  //                                             </span>
  //                                             <ul class="ms-3">
  //                                               <li class="ms-4 mb-1">
  //                                                 <span class="body-text-sm-regular">
  //                                                   Ability to invest across the CLO capital structure as the
  //                                                   opportunity set shifts (equity and sub-IG rated notes)
  //                                                 </span>
  //                                               </li>
  //                                               <li class="ms-4">
  //                                                 <span class="body-text-sm-regular">
  //                                                   Undertaking bottom-up credit analysis and applying
  //                                                   informed judgement on individual loans within portfolios
  //                                                 </span>
  //                                               </li>
  //                                             </ul>
  //                                           </div>
  //                                           <div class="row g-0 w-100 mb-2">
  //                                             <span class="body-text-lg-medium mb-1">
  //                                               Leading Credit Platform:
  //                                             </span>
  //                                             <ul class="ms-3">
  //                                               <li class="ms-4 mb-1">
  //                                                 <span class="body-text-sm-regular">
  //                                                   One of the largest specialist credit platform in Europe,
  //                                                   with a Team of ~60 credit professionals across three core
  //                                                   strategies (direct lending, structured credit, CLO
  //                                                   management)
  //                                                 </span>
  //                                               </li>
  //                                               <li class="ms-4">
  //                                                 <span class="body-text-sm-regular">
  //                                                   Three structured credit specialists with access to ~30
  //                                                   investment professionals with credit analysis expertise
  //                                                 </span>
  //                                               </li>
  //                                             </ul>
  //                                           </div>
  //                                           <div class="row g-0 w-100">
  //                                             <span class="body-text-lg-medium mb-1">
  //                                               About Permira:
  //                                             </span>
  //                                             <ul class="ms-3">
  //                                               <li class="ms-4 mb-1">
  //                                                 <span class="body-text-sm-regular">
  //                                                   British global investment firm established in 1985 with
  //                                                   ~€58B AUM, 360 employees in 16 offices around Europe,
  //                                                   North America and Asia.
  //                                                 </span>
  //                                               </li>
  //                                               <li class="ms-4 mb-1">
  //                                                 <span class="body-text-sm-regular">
  //                                                   Permira focuses on four sectors: Technology, Consumer,
  //                                                   Healthcare and Services
  //                                                 </span>
  //                                               </li>
  //                                             </ul>
  //                                           </div>
  //                                         </div>
  //                                       </div>`,
  //       firm: uuidv4(),
  //       registration_type: {
  //         id: uuidv4(),
  //         type: "3C7",
  //       },
  //       strategy_type: {
  //         id: uuidv4(),
  //         type: "PRIVATE_EQUITY",
  //       },
  //     },
  //     fund_terms: [
  //       {
  //         fund: uuidv4(),
  //         fund_size: "1000000.00",
  //         target_length_months: "120",
  //         fund_currency: "USD",
  //         minimum_allocation_amount: "100000.00",
  //         maximum_allocation_amount: "100000.00",
  //         capital_calls: false,
  //         initial_funding_percent: "0",
  //         target_return_lower_percent: "24",
  //         target_return_upper_percent: "24",
  //         target_return_multiple: "2.5",
  //         upfront_management_fee_percent: "0.5",
  //         ongoing_distribution_fee_percent: "1.0",
  //         legacy_pricing_structure: false,
  //       },
  //     ],
  //     fund_restrictions: [],
  //     fund_icons: [],
  //     fund_search_tags: [],
  //     fund_subscription_stats: [
  //       {
  //         fund: uuidv4(),
  //         fund_size: "1000000.00",
  //         committed_allocation_amount: "0.00",
  //         remaining_allocation_amount: "1000000.00",
  //       },
  //     ],
  //     fund_management_team: [],
  //     fund_faq: [
  //       {
  //         id: uuidv4(),
  //         fund: uuidv4(),
  //         question: "What is the target investment sector?",
  //         answer: "Industrial Companies",
  //         display_question: true,
  //         display_order: "1",
  //       },
  //     ],
  //     fund_documents: [
  //       {
  //         id: uuidv4(),
  //         fund: uuidv4(),
  //         category: "Subscription Documents",
  //         name: "Thamesville Capital Vintage IV Sample Subscription Documents",
  //         upload_date: "2022-09-30T23:05:00Z",
  //         s3_document_link:
  //           "public/fund_documents/fa05b9bd-80ef-46d7-b6fe-211d8258aa91/documents/HarbourVest2022GlobalFundPi.pdf",
  //         format: "PDF",
  //         description: "This is a sample subscription document",
  //         display_document: true,
  //       },
  //     ],
  //     extension_categories: [],
  //     fund_due_diligence_questions: [
  //       {
  //         id: uuidv4(),
  //         fund: uuidv4(),
  //         question: "What are the key features of a CLO?",
  //         answer: `<p class="body-text-sm-regular">A CLO is a structured credit vehicle that invests principally in corporate debt. The vehicle is financed with a capital structure that consists of rated notes (AAA to B) and subordinated notes (referred to as equity or equity notes), with investors selecting the part of the capital structure which matches their risk appetite.</p><p class="body-text-sm-regular">The capital is used by the CLO manager to invest in a portfolio of corporate credits in large companies. The CLO manager is responsible for selecting and actively managing this portfolio.</p><p class="body-text-sm-regular">A CLO portfolio typically contains 80 to 100 credits, each on average €3m to €5m in size, most of which are structured with floating rate coupons. The yield generated by the credits is used to settle the cost of the CLO’s capital structure.</p>`,
  //         display: true,
  //         display_order: "1",
  //       },
  //     ],
  //     fund_reference_terms: [],
  //   },
  // };
};
