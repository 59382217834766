import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./Styles/Styles.scss";
import AddFirmUser from "./AddFirmUser/AddFirmUser";
import ViewUsers from "./ViewUsers/ViewUsers";

function ManageUsers() {
  const [showAddFirmUser, setShowAddFirmUser] = useState(false);
  return (
    <div className="container-xxl p-4">
      {showAddFirmUser === false ? (
        <ViewUsers setShowAddFirmUser={setShowAddFirmUser} />
      ) : (
        <AddFirmUser setShowAddFirmUser={setShowAddFirmUser} />
      )}
    </div>
  );
}
export default ManageUsers;
