import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import Tabs from "../../../Components/Tabs/Tabs";
import Tab from "../../../Components/Tabs/Tab/Tab";
import { useNavigate, useParams } from "react-router-dom";
import { GetFundSubscriptionInfo } from "../../../Adapters/FundActions";
import GetFundInvestmentInformation from "../../../Adapters/ConfirmInvestment";
import GetFundName from "../../../Adapters/RequestAllocationForm";
import Spinner1 from "../../../Components/Spinners/Spinner1";
import InvestmentAmountField from "../../../Components/InputFields/InvestmentAmountInputField/InvestmentAmountInputField";
import MuiButton from "../../../Components/Buttons/MuiButton/MuiButton";
import SnackBar from "../../../Components/Alerts/SnackBar/SnackBar";
import GetFirmUserFundAllocationRequest from "../../../Adapters/GetFirmUserFundAllocationRequest";
import { createFirmUserFundAllocationRequest } from "../../../APIs/ConfirmInvestmentAPI";

function RequestAllocationForm() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [
    firmUserFundAllocationRequestLoading,
    setFirmUserFundAllocationRequestLoading,
  ] = useState(true);
  const [loading, setLoading] = useState(true);
  const [requestAllocation, setRequestAllocation] = useState(true);
  const [investmentConfirmation, setInvestmentConfirmation] = useState(false);
  const { id } = useParams();
  const [allocationAmount, setAllocationAmount] = useState("");
  const [allocationAmountError, setAllocationAmountError] = useState("");
  const [emptyAllocationAmountError, setEmptyAllocationAmountError] =
    useState(false);
  const [
    firmUserFundAllocationRequestCreationSuccess,
    setFirmUserFundAllocationRequestCreationSuccess,
  ] = useState(false);
  const [
    firmUserFundAllocationRequestCreationFailure,
    setFirmUserFundAllocationRequestCreationFailure,
  ] = useState(false);

  const { fundName } = GetFundName(refresh, id, setLoading);

  const { firmUserFundAllocationRequest } = GetFirmUserFundAllocationRequest(
    refresh,
    id,
    setFirmUserFundAllocationRequestLoading
  );

  const submitAllocationRequest = async () => {
    var errors = false;

    if (allocationAmount === "") {
      errors = true;
      setEmptyAllocationAmountError(true);
    } else if (allocationAmount.replace(/[^\d]/g, "") <= 0) {
      errors = true;
      setEmptyAllocationAmountError(true);
    }

    if (!errors) {
      //make request to backend
      var requestAmount = allocationAmount.replace(/[^\d]/g, "");

      try {
        const payload = {
          fund_link: id,
          allocation_amount: requestAmount,
        };

        await createFirmUserFundAllocationRequest(payload);
        setFirmUserFundAllocationRequestCreationSuccess(true);
        setRefresh((prevState) => prevState + 1);
      } catch (err) {
        setFirmUserFundAllocationRequestCreationFailure(true);
      }
    }
  };

  return (
    <div className="container-xxl mt-5 pt-5 pb-3">
      <div className="row g-0 w-100 mb-3">
        <Tabs selectedTab={selectedTab}>
          <Tab
            key={0}
            title="Request Allocation"
            selected={requestAllocation}
            setSelected={setRequestAllocation}
            disableClick={true}
            fullWidth
          >
            <React.Fragment>
              {firmUserFundAllocationRequestLoading === true ? (
                <div className="container-xxl p-4">
                  <div className="row g-0 w-100 align-items-center">
                    <Spinner1 />
                  </div>
                </div>
              ) : firmUserFundAllocationRequest !== null &&
                firmUserFundAllocationRequest !== undefined &&
                firmUserFundAllocationRequest.length !== 0 ? (
                <div className="container-xxl p-4">
                  <div className="row g-0 w-100 mb-4">
                    <h5>
                      {"You have already submitted an allocation request of $"}
                      {parseFloat(
                        firmUserFundAllocationRequest[0].allocation_amount
                      ).toLocaleString("en-US")}
                      {" for this fund opportunity."}
                    </h5>
                  </div>
                  <div className="row g-0 w-100">
                    <div className="d-flex">
                      <MuiButton
                        buttonText={"Back to Fund Details"}
                        onClick={() => navigate("/fund/" + id)}
                        size={"large"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container-xxl p-4">
                  {loading === false ? (
                    <React.Fragment>
                      <div className="row g-0 w-100 mb-3">
                        <h5>
                          {
                            "How much of an allocation would you like to request in "
                          }
                          {fundName}
                          {" on behalf of your clients in aggregate?"}
                        </h5>
                      </div>
                      <div className="row g-0 w-100 mb-4">
                        <div className="col-3">
                          <InvestmentAmountField
                            placeholder={"Enter Allocation Amount"}
                            error={allocationAmountError}
                            fieldValueChange={setAllocationAmount}
                            initialValue={allocationAmount}
                            disabled={false}
                            maxInput={1000000000}
                          />
                        </div>
                      </div>
                      <div className="row g-0 w-100">
                        <div className="d-flex">
                          <div className="me-4">
                            <MuiButton
                              buttonText={"Submit"}
                              onClick={() => submitAllocationRequest()}
                              size={"large"}
                            />
                          </div>
                          <div>
                            <MuiButton
                              buttonText={"Back to Fund Details"}
                              onClick={() => navigate("/fund/" + id)}
                              size={"large"}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="row g-0 w-100 justify-content-center">
                      <Spinner1 />
                    </div>
                  )}
                </div>
              )}
              <SnackBar
                snackbarState={emptyAllocationAmountError}
                setSnackbarState={setEmptyAllocationAmountError}
                vertical={"top"}
                horizontal={"right"}
                duration={5000}
                sxProps={{ paddingLeft: "3px" }}
                severity={"error"}
                message={"You must specify an allocation amount."}
              />
              <SnackBar
                snackbarState={firmUserFundAllocationRequestCreationSuccess}
                setSnackbarState={
                  setFirmUserFundAllocationRequestCreationSuccess
                }
                vertical={"top"}
                horizontal={"right"}
                duration={5000}
                sxProps={{ paddingLeft: "3px" }}
                severity={"success"}
                message={
                  "Your allocation request has been submitted successfully."
                }
              />
              <SnackBar
                snackbarState={firmUserFundAllocationRequestCreationFailure}
                setSnackbarState={
                  setFirmUserFundAllocationRequestCreationFailure
                }
                vertical={"top"}
                horizontal={"right"}
                duration={5000}
                sxProps={{ paddingLeft: "3px" }}
                severity={"error"}
                message={"There was an error submitting your request."}
              />
            </React.Fragment>
          </Tab>
          <Tab
            key={2}
            title=""
            selected={investmentConfirmation}
            setSelected={setInvestmentConfirmation}
            disableClick={true}
          ></Tab>
        </Tabs>
      </div>
    </div>
  );
}
export default RequestAllocationForm;
