import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import * as dayjs from "dayjs";
import {
  // AreaChart,
  // Area,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // Legend,
} from "recharts";
import { thousandsSeparator } from "../../../../../../utilities/functions/functions";
import { numFormatter } from "../../../../../../utilities/functions/functions";

const Graph = ({
  fundName,
  investments,
  graph,
  distributions,
  fromDate,
  toDate,
  accountCreationDate,
}) => {
  const [filterInvestments, setFilterInvestments] = useState([]);
  const [filterDistributions, setFilterDistributions] = useState([]);
  const [cumulativePortfolio, setCumulativePortfolio] = useState([]);
  const [createdAccountDate, setCreatedAccountDate] = useState(
    "2022-10-21T17:20:30Z"
  );


  useEffect(() => {
    var dated = dayjs(createdAccountDate).format("MMM D, YYYY");
  }, [createdAccountDate]);

  useEffect(() => {
    if (investments !== undefined && investments !== null) {
      let filteredArray = [];
      investments.forEach((investment) => {
        if (investment.status === "F") {
          filteredArray.push(investment);
        }
      });
      setFilterInvestments(filteredArray);
    }
  }, [investments]);

  useEffect(() => {
    if (distributions !== undefined && distributions !== null) {
      let filteredArray = [];
      distributions.forEach((distribution) => {
        if (distribution.status === "F") {
          filteredArray.push(distribution);
        }
      });
      setFilterDistributions(filteredArray);
    }
  }, [distributions]);

  useEffect(() => {
    const hashedPortfolio = {};
    if (
      filterInvestments.length === 0 &&
      filterDistributions.length === 0 &&
      accountCreationDate !== ""
    ) {
      var defaultPortfolio = [];
      var inputAccountCreationDate = accountCreationDate.substring(0, 10);
      var formattedAccountCreationDate = dayjs(
        dayjs(inputAccountCreationDate).format("MMM D, YYYY")
      );

      var newDate = new Date(createdAccountDate);

      var unixAccountCreationDate = dayjs(createdAccountDate).unix();

      var currentDate = new Date();
      var currentDateNoTime = dayjs(dayjs(currentDate).format("MMM D, YYYY"));
      var unixCurrentDate = dayjs(currentDateNoTime).unix();

      if (unixCurrentDate === unixAccountCreationDate) {
        let days = 1;

        let priorDate = new Date(
          currentDate.getTime() - days * 24 * 60 * 60 * 1000
        );

        defaultPortfolio.push({
          investmentAmount: 0,
          distributionAmount: 0,
          investmentDate: dayjs(priorDate).unix(),
        });
        defaultPortfolio.push({
          investmentAmount: 0,
          distributionAmount: 0,
          investmentDate: dayjs(new Date()).unix(),
        });
      } else {
        defaultPortfolio.push({
          investmentAmount: 0,
          distributionAmount: 0,
          investmentDate: unixAccountCreationDate,
        });

        defaultPortfolio.push({
          investmentAmount: 0,
          distributionAmount: 0,
          investmentDate: dayjs(new Date()).unix(),
        });
      }
      setCumulativePortfolio(defaultPortfolio);
    } else if (
      filterInvestments !== null &&
      filterInvestments !== undefined &&
      filterInvestments.length === 1
    ) {
      let filteredInvestments = [];

      let currFund = filterInvestments[0].fund;
      let currInvestmentAmount = filterInvestments[0].investment_amount;
      let investDate = new Date(filterInvestments[0].investment_date);
      let todayDate = new Date();
      let days = 1;
      let prevDate = new Date(
        investDate.getTime() - days * 24 * 60 * 60 * 1000
      );

      filteredInvestments.push({
        investment_date: prevDate.toISOString(),
        investment_amount: 0,
        fund: currFund,
      });

      if (todayDate > investDate) {
        filteredInvestments.push({
          investment_date: todayDate.toISOString(),
          investment_amount: 0,
          fund: currFund,
        });
      }

      filterInvestments.forEach(
        ({ investment_date, fund, investment_amount, status }) => {
          if (
            (fund.name === fundName || fundName === "All") &&
            status === "F"
          ) {
            filteredInvestments.push({
              investment_date: investment_date,
              investment_amount: investment_amount,
            });
          }
        }
      );
      filteredInvestments.forEach(({ investment_date, investment_amount }) => {
        var unixDateNoTime = dayjs(
          dayjs(investment_date).format("MMM D, YYYY")
        );
        var unixDate = dayjs(unixDateNoTime).unix();
        var unixDateISO = dayjs.unix(unixDate).toISOString();

        if (
          hashedPortfolio[unixDate] === null ||
          hashedPortfolio[unixDate] === undefined
        ) {
          hashedPortfolio[unixDate] = {
            investmentAmount: 0,
            distributionAmount: 0,
            investmentDate: unixDateISO,
          };
        }
        hashedPortfolio[unixDate].investmentAmount += investment_amount;
      });

      if (
        filterDistributions !== undefined &&
        filterDistributions !== null &&
        filterDistributions.length !== 0
      ) {
        let filteredDistributions = [];
        filterDistributions.forEach(
          ({ distribution_date, distribution_amount, fund, status }) => {
            if (
              (fund.name === fundName || fundName === "All") &&
              status === "F"
            ) {
              filteredDistributions.push({
                distribution_amount: distribution_amount,
                distribution_date: distribution_date,
              });
            }
          }
        );
        filteredDistributions.forEach(
          ({ distribution_date, distribution_amount }) => {
            var unixDistributionDateNoTime = dayjs(
              dayjs(distribution_date).format("MMM D, YYYY")
            );
            var unixDistributionDate = dayjs(unixDistributionDateNoTime).unix();
            var unixDistributionDateISO = dayjs
              .unix(unixDistributionDate)
              .toISOString();

            if (
              hashedPortfolio[unixDistributionDate] === null ||
              hashedPortfolio[unixDistributionDate] === undefined
            ) {
              hashedPortfolio[unixDistributionDate] = {
                investmentAmount: 0,
                distributionAmount: 0,
                investmentDate: unixDistributionDateISO,
              };
            }
            hashedPortfolio[unixDistributionDate].distributionAmount +=
              distribution_amount;
          }
        );
      }

      const orderedPortfolio = Object.entries(hashedPortfolio)
        .map(
          ([key, { investmentAmount, investmentDate, distributionAmount }]) => {
            return {
              unixDate: key,
              investmentAmount: investmentAmount,
              distributionAmount: distributionAmount,
              investmentDate: investmentDate,
            };
          }
        )
        .sort((a, b) => a.investmentDate.localeCompare(b.investmentDate));

      var processedPortfolio = [];

      if (fromDate !== null) {
        let constructedDate = new Date(
          fromDate.year,
          fromDate.month - 1,
          fromDate.day
        );
        let constructedUnixDateNoTime = dayjs(
          dayjs(constructedDate).format("MMM D, YYYY")
        );
        let constructedUnixDate = dayjs(constructedUnixDateNoTime).unix();
        var startDate = constructedUnixDate;
      }
      if (toDate !== null) {
        let constructedDate = new Date(
          toDate.year,
          toDate.month - 1,
          toDate.day
        );
        let constructedUnixDateNoTime = dayjs(
          dayjs(constructedDate).format("MMM D, YYYY")
        );
        let constructedUnixDate = dayjs(constructedUnixDateNoTime).unix();
        var endDate = constructedUnixDate;
      }
      var startIndex = 0;
      var endIndex = orderedPortfolio.length - 1;
      var previousInvestmentTotal = 0;
      var previousDistributionTotal = 0;
      var pushEnd = false;

      for (let f = 0; f < orderedPortfolio.length; f++) {
        if (
          startDate !== undefined &&
          startDate !== null &&
          startDate >= orderedPortfolio[f].unixDate
        ) {
          startIndex = f;
          previousInvestmentTotal += orderedPortfolio[f].investmentAmount;
          previousDistributionTotal += orderedPortfolio[f].distributionAmount;
        }
        if (
          endDate !== undefined &&
          endDate !== null &&
          endDate >= orderedPortfolio[f].unixDate
        ) {
          endIndex = f;
        }
      }

      if (
        endDate !== undefined &&
        endDate !== null &&
        endDate > orderedPortfolio[endIndex].unixDate
      ) {
        pushEnd = true;
      }

      if (startDate !== undefined && startDate !== null) {
        processedPortfolio.push({
          investmentDate: startDate,
          investmentAmount: previousInvestmentTotal,
          distributionAmount: previousDistributionTotal,
        });
      }

      var investmentRunSum = previousInvestmentTotal;
      var distributionRunSum = previousDistributionTotal;

      for (
        let i =
          startDate === undefined || startDate === null ? 0 : startIndex + 1;
        i <= endIndex;
        i++
      ) {
        investmentRunSum += orderedPortfolio[i].investmentAmount;
        distributionRunSum += orderedPortfolio[i].distributionAmount;
        processedPortfolio.push({
          investmentDate: orderedPortfolio[i].unixDate,
          investmentAmount: investmentRunSum,
          distributionAmount: distributionRunSum,
        });
      }
      if (pushEnd) {
        var lastEntry =
          processedPortfolio[processedPortfolio.length - 1].investmentAmount;
        processedPortfolio.push({
          investmentDate: endDate,
          investmentAmount:
            processedPortfolio[processedPortfolio.length - 1].investmentAmount,
          distributionAmount:
            processedPortfolio[processedPortfolio.length - 1]
              .distributionAmount,
        });
      }
      setCumulativePortfolio(processedPortfolio);
    } else if (
      filterInvestments !== null &&
      filterInvestments !== undefined &&
      filterInvestments.length !== 0
    ) {
      let filteredInvestments = [];

      var formattedAccountCreationDate = dayjs(
        dayjs(accountCreationDate).format("MMM D, YYYY")
      );
      var unixAccountCreationDate = dayjs(createdAccountDate).unix();
      var investDate = dayjs(
        dayjs(filterInvestments[0].investment_date).format("MMM D, YYYY")
      );
      var unixInvestDate = dayjs(investDate).unix();

      var previousDate = "";

      if (unixInvestDate === unixAccountCreationDate) {
        let days = 1;
        let firstInvestmentDate = new Date(
          filterInvestments[0].investment_date
        );
        previousDate = new Date(
          firstInvestmentDate.getTime() - days * 24 * 60 * 60 * 1000
        );
      } else {
        previousDate = new Date(createdAccountDate);
      }

      filterInvestments.forEach(
        ({ investment_date, fund, investment_amount, status }) => {
          if (
            (fund.name === fundName || fundName === "All") &&
            status === "F"
          ) {
            filteredInvestments.push({
              investment_date: investment_date,
              investment_amount: investment_amount,
            });
          }
        }
      );

      filteredInvestments.push({
        investment_date: previousDate,
        investment_amount: 0,
      });

      var lastInvestDate = dayjs(
        filterInvestments[filterInvestments.length - 1].investment_date
      ).unix();
      var todayDate = dayjs(new Date().toISOString()).format("MMM D, YYYY");
      var unixTodayDate = dayjs(todayDate).unix();

      if (unixTodayDate > lastInvestDate) {
        filteredInvestments.push({
          investment_date: new Date(),
          investment_amount: 0,
        });
      }

      filteredInvestments.forEach(({ investment_date, investment_amount }) => {
        var unixDateNoTime = dayjs(
          dayjs(investment_date).format("MMM D, YYYY")
        );
        var unixDate = dayjs(unixDateNoTime).unix();
        var unixDateISO = dayjs.unix(unixDate).toISOString();

        if (
          hashedPortfolio[unixDate] === null ||
          hashedPortfolio[unixDate] === undefined
        ) {
          hashedPortfolio[unixDate] = {
            investmentAmount: 0,
            distributionAmount: 0,
            investmentDate: unixDateISO,
          };
        }
        hashedPortfolio[unixDate].investmentAmount += investment_amount;
      });

      if (
        filterDistributions !== undefined &&
        filterDistributions !== null &&
        filterDistributions.length !== 0
      ) {
        let filteredDistributions = [];
        filterDistributions.forEach(
          ({ distribution_date, distribution_amount, fund, status }) => {
            if (
              (fund.name === fundName || fundName === "All") &&
              status === "F"
            ) {
              filteredDistributions.push({
                distribution_amount: distribution_amount,
                distribution_date: distribution_date,
              });
            }
          }
        );
        filteredDistributions.forEach(
          ({ distribution_date, distribution_amount }) => {
            var unixDistributionDateNoTime = dayjs(
              dayjs(distribution_date).format("MMM D, YYYY")
            );
            var unixDistributionDate = dayjs(unixDistributionDateNoTime).unix();
            var unixDistributionDateISO = dayjs
              .unix(unixDistributionDate)
              .toISOString();

            if (
              hashedPortfolio[unixDistributionDate] === null ||
              hashedPortfolio[unixDistributionDate] === undefined
            ) {
              hashedPortfolio[unixDistributionDate] = {
                investmentAmount: 0,
                distributionAmount: 0,
                investmentDate: unixDistributionDateISO,
              };
            }
            hashedPortfolio[unixDistributionDate].distributionAmount +=
              distribution_amount;
          }
        );
      }

      const orderedPortfolio = Object.entries(hashedPortfolio)
        .map(
          ([key, { investmentAmount, investmentDate, distributionAmount }]) => {
            return {
              unixDate: key,
              investmentAmount: investmentAmount,
              distributionAmount: distributionAmount,
              investmentDate: investmentDate,
            };
          }
        )
        .sort((a, b) => a.investmentDate.localeCompare(b.investmentDate));

      var processedPortfolio = [];

      if (fromDate !== null) {
        let constructedDate = new Date(
          fromDate.year,
          fromDate.month - 1,
          fromDate.day
        );
        let constructedUnixDateNoTime = dayjs(
          dayjs(constructedDate).format("MMM D, YYYY")
        );
        let constructedUnixDate = dayjs(constructedUnixDateNoTime).unix();
        var startDate = constructedUnixDate;
      }
      if (toDate !== null) {
        let constructedDate = new Date(
          toDate.year,
          toDate.month - 1,
          toDate.day
        );
        let constructedUnixDateNoTime = dayjs(
          dayjs(constructedDate).format("MMM D, YYYY")
        );
        let constructedUnixDate = dayjs(constructedUnixDateNoTime).unix();
        var endDate = constructedUnixDate;
      }
      var startIndex = 0;
      var endIndex = orderedPortfolio.length - 1;
      var previousInvestmentTotal = 0;
      var previousDistributionTotal = 0;
      var pushEnd = false;

      for (let f = 0; f < orderedPortfolio.length; f++) {
        if (
          startDate !== undefined &&
          startDate !== null &&
          startDate >= orderedPortfolio[f].unixDate
        ) {
          startIndex = f;
          previousInvestmentTotal += orderedPortfolio[f].investmentAmount;
          previousDistributionTotal += orderedPortfolio[f].distributionAmount;
        }
        if (
          endDate !== undefined &&
          endDate !== null &&
          endDate >= orderedPortfolio[f].unixDate
        ) {
          endIndex = f;
        }
      }

      if (
        endDate !== undefined &&
        endDate !== null &&
        endDate > orderedPortfolio[endIndex].unixDate
      ) {
        pushEnd = true;
      }

      if (startDate !== undefined && startDate !== null) {
        processedPortfolio.push({
          investmentDate: startDate,
          investmentAmount: previousInvestmentTotal,
          distributionAmount: previousDistributionTotal,
        });
      }

      var investmentRunSum = previousInvestmentTotal;
      var distributionRunSum = previousDistributionTotal;

      for (
        let i =
          startDate === undefined || startDate === null ? 0 : startIndex + 1;
        i <= endIndex;
        i++
      ) {
        investmentRunSum += orderedPortfolio[i].investmentAmount;
        distributionRunSum += orderedPortfolio[i].distributionAmount;
        processedPortfolio.push({
          investmentDate: orderedPortfolio[i].unixDate,
          investmentAmount: investmentRunSum,
          distributionAmount: distributionRunSum,
        });
      }
      if (pushEnd) {
        var lastEntry =
          processedPortfolio[processedPortfolio.length - 1].investmentAmount;
        processedPortfolio.push({
          investmentDate: endDate,
          investmentAmount:
            processedPortfolio[processedPortfolio.length - 1].investmentAmount,
          distributionAmount:
            processedPortfolio[processedPortfolio.length - 1]
              .distributionAmount,
        });
      }

      setCumulativePortfolio(processedPortfolio);
    }
  }, [
    createdAccountDate,
    filterInvestments,
    filterDistributions,
    fromDate,
    fundName,
    toDate,
    accountCreationDate,
  ]);

  const AxisFormatter = (number) => {
    return "$ " + numFormatter(number);
  };

  const TooltipAxisFormatter = (number) => {
    return "$ " + thousandsSeparator(number);
  };

  const data = useState([]);

  /*
  const fetchGraph = async () => {
    const resp = await aggViewsGraph(
      formatDate(startDate),
      formatDate(endDate)
    );
    setGraphData(resp.data.data);
  }; */

  return (
    <div className="row g-0 w-100">
      <div className="col-12 d-flex justify-content-center">
        <div className="row gx-4 w-100">
          <div className="col-12 pe-4 ps-0">
            <div className="w-100 graph-container">
              {/* <div className="row g-0 w-100 mb-3 bottom-line">
                <div className="col-md-6 col-12 mt-3 mb-3">
                  <span className="ms-4 me-2 portfolio-activity-text">
                    Performance •
                  </span>
                </div>
              </div> */}
              <div className="row g-0 w-100 pb-3 mb-4 justify-content-center">
                <ResponsiveContainer width="95%" height={250}>
                  <LineChart
                    data={
                      graph !== null && graph !== undefined
                        ? graph.map((e) => ({
                            ...e,
                            date: dayjs(e.index).unix(),
                          }))
                        : [
                            {
                              date: dayjs().unix(),
                              investment_amount: 0.0,
                              distribution_amount: 0.0,
                              capital_calls: 0.0,
                              marked_value: 0.0,
                            },
                            {
                              date: dayjs().subtract(5, "day").unix(),
                              investment_amount: 0.0,
                              distribution_amount: 0.0,
                              capital_calls: 0.0,
                              marked_value: 0.0,
                            },
                          ]
                    }
                    height={250}
                    margin={{
                      top: 10,
                      right: 10,
                      left: 10,
                      bottom: 11,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="0"
                      stroke="#E2E6ED"
                      strokeWidth={1}
                      hidden={true}
                    />
                    <XAxis
                      type={"number"}
                      domain={["dataMin", "dataMax"]}
                      dataKey="date"
                      interval="preserveEnd"
                      tickLine={false}
                      hide={true}
                      allowDataOverflow={false}
                    />
                    <YAxis
                      type={"number"}
                      width={65}
                      tickFormatter={AxisFormatter}
                      domain={["auto", "auto"]}
                      style={{
                        color: "#262A3D",
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontSize: "14px",
                      }}
                    />
                    <Tooltip
                      labelFormatter={(date) =>
                        dayjs.unix(date).format("MMM D, YYYY")
                      }
                      labelStyle={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontSize: "13px",
                      }}
                      contentStyle={{
                        fontWeight: 500,
                        fontStyle: "normal",
                        fontSize: "13px",
                      }}
                      formatter={TooltipAxisFormatter}
                      wrapperStyle={{
                        border: "none !important",
                        borderRadius: "20px",
                        background: "#0adddf",
                      }}
                    />
                    <Line
                      type="stepAfter"
                      name="Investments"
                      dataKey="investment_amount"
                      stroke="#0091EA"
                      activeDot={{ r: 6 }}
                      strokeWidth={3}
                      dot={false}
                    />
                    <Line
                      type="stepAfter"
                      name="Distributions"
                      dataKey="distribution_amount"
                      stroke="#0D47A1"
                      activeDot={{ r: 6 }}
                      strokeWidth={3}
                      dot={false}
                    />
                    <Line
                      type="stepAfter"
                      name="Capital Calls"
                      dataKey="capital_calls"
                      stroke="#0D47A1"
                      activeDot={{ r: 6 }}
                      strokeWidth={3}
                      dot={false}
                    />
                    <Line
                      type="stepAfter"
                      name="Marked Value"
                      dataKey="marked_value"
                      stroke="#0D47A1"
                      activeDot={{ r: 6 }}
                      strokeWidth={3}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Graph;
