import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import useDebounce from "../Hooks/useDebounce.js";
import { HOST } from "../utilities/host-config/host-config.js";
import { UserContext } from "../Contexts/UserContext.js";

const CancelToken = axios.CancelToken;

//Dev environment function
export default function useMasterOpsFirmUserSearch(searchKeywords, setLoading) {
  const [firmUsers, setFirmUsers] = useState([]);
  const cancelSource = useRef(null);
  const debouncedSearchTerm = useDebounce(searchKeywords, 200);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setFirmUsers([]);
  }, []);

  useEffect(() => {
    setFirmUsers([]);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    cancelSource.current = CancelToken.source();
    setFirmUsers([]);
    setLoading(true);

    var queryParam = "";
    queryParam = debouncedSearchTerm;

    async function getFirmUsers(endpoint, queryString, config) {
      setLoading(true);

      return axios
        .get(endpoint + "?query=" + queryString.replace("+", "%2B"), config)
        .then((response) => {
          const tempObject = response.data;

          if (
            response.data === null ||
            response.data === undefined ||
            response.data.length === 0
          ) {
            setFirmUsers([]);
            setLoading(false);
          } else {
            setFirmUsers([]);
            tempObject.map((firmUser) => {
              async function mapFirmUserInfo() {
                const firmUserInfo = {
                  id: firmUser.id,
                  name: firmUser.name,
                  email: firmUser.email,
                  phoneNumber: firmUser.phone_number,
                  role: firmUser.role,
                  associations: {
                    // Initialize empty arrays for all roles
                    advisors: [],
                    specialists: [],
                    // For Advisors, map their co-advisors
                    ...(firmUser.role === "Advisor" && {
                      specialists:
                        firmUser.associations?.map((associate) => ({
                          id: associate.id,
                          name: associate.name,
                          email: associate.email,
                          role: "Co-Advisor",
                        })) || [],
                    }),
                    // For Co-Advisors (IOS), map their advisors
                    ...(firmUser.role === "Co-Advisor" && {
                      advisors:
                        firmUser.associations?.map((associate) => ({
                          id: associate.id,
                          name: associate.name,
                          email: associate.email,
                          role: "Advisor",
                        })) || [],
                    }),
                  },
                };

                setFirmUsers((prevFirmUsers) => [
                  ...prevFirmUsers,
                  firmUserInfo,
                ]);
                setLoading(false);
              }
              mapFirmUserInfo();
            });
          }
        })
        .catch((error) => {
          setFirmUsers([]);
          setLoading(false);
          if (process.env.REACT_APP_DEBUG === "1") console.log(error);
        });
    }

    getRequestHeaders().then((config) => {
      config.cancelToken = cancelSource.current.token;
      getFirmUsers(HOST + "/search-firm-users", queryParam, config).then();
    });

    return () => {
      cancelSource.current.cancel();
    };
  }, [debouncedSearchTerm]);

  return { firmUsers };
}
