import React from "react";
import "./Styles.scss";

function LoadingAnimation(props) {
  return (
    <React.Fragment>
      {props.loading === true ? (
        <div className="container align-items-center justify-content-center mt-5 pt-5 pb-5">
          <div class="loader mt-5">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
}
export default LoadingAnimation;
