import React, { useEffect, useState } from "react";
import "./Styles/Styles.scss";
import ChooseClientTable from "../../../../../Components/ChooseClientTable/ChooseClientTable";
import { DataGridPro } from "@mui/x-data-grid-pro";

function FirmRestrictionsTable({
  firmFundRestrictions,
  firmFundRestrictionsLoading,
}) {
  const [fundRestrictions, setFundRestrictions] = useState([]);

  useEffect(() => {
    if (
      firmFundRestrictions !== null &&
      firmFundRestrictions !== undefined &&
      firmFundRestrictions.length !== 0
    ) {
      setFundRestrictions(firmFundRestrictions);
    }
  }, [firmFundRestrictions]);

  const columns = [
    {
      field: "firmName",
      headerName: "Firm Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "fundName",
      headerName: "Fund Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "strategy",
      headerName: "Strategy",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "restrictionType",
      headerName: "Restriction Type",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
  ];

  return (
    <div className="container">
      <div className="row g-0 w-100">
        <h6>Firm Fund Restrictions</h6>
      </div>
      <div className="row g-0 w-100">
        <DataGridPro
          sx={{
            height: "400px",
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              borderRadius: "6px",
            },
          }}
          rows={fundRestrictions}
          columns={columns}
          loading={firmFundRestrictionsLoading}
          rowHeight={54}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
        />
      </div>
    </div>
  );
}
export default FirmRestrictionsTable;
