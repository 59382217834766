import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import { HOST } from "../utilities/host-config/host-config.js";

export const getFundList = async () => {
  const config = await getRequestHeaders();

  //   return axios.get(HOST + "/get-wm-firm-info", config);
  return axios.get(HOST + "/get-fund-list", config);
};

export const getFirmFundRestrictionAction = async () => {
  const config = await getRequestHeaders();

  return axios.get(HOST + "/get-firm-restriction-action", config);
};

export const updateFirmRestrictionAction = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(HOST + "/update-firm-restriction-action", payload, config);
};

export const getFirmFundRestrictions = async (firmId) => {
  const config = await getRequestHeaders();

  return axios.get(
    HOST + "/get-firm-fund-restrictions?firm_id=" + firmId,
    config
  );
};

export const createFirmRestrictionAction = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(HOST + "/create-firm-fund-restriction", payload, config);
};
