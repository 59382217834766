import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Carousel from "react-bootstrap/Carousel";
import "./Carousel.scss";
import { useParams } from "react-router";
import { UserContext } from "../../Contexts/UserContext";

function GlobalCarousel({ slides, title, returnRoute }) {
  let navigate = useNavigate();

  const { id } = useParams();

  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);

  const mountedStyle = {
    opacity: 1,
    transition: "opacity 1.5s ease-out, transform 400ms ease-out",
  };
  const unmountedStyle = { opacity: 0, transition: "opacity 400ms ease-in" };

  return (
    <React.Fragment>
      <div className="container-xxl pt-5 mb-5" style={{ maxWidth: "628px" }}>
        <div className="row g-0 w-100 mt-5"></div>
        <div className="row g-0 w-100 mt-5" style={{ marginBottom: "28px" }}>
          <div className="d-flex align-items-center">
            <img
              src="https://d1swyfveypj49y.cloudfront.net/NewBackArrow.svg"
              style={{
                width: "16px",
                height: "14px",
                cursor: "pointer",
                marginRight: "12px",
              }}
              onClick={() => navigate(returnRoute)}
            />
            <span class="title-text">{title}</span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-4">
          <Carousel
            indicators={false}
            controls={false}
            interval={null}
            activeIndex={activeSlide}
          >
            {slides.map((slide) => (
              <Carousel.Item>
                <div class="row g-0 w-100 add-account-wizard-container">
                  <div class="col-12 add-account-outer-column-padded">
                    {React.cloneElement(slide, {
                      setActiveSlide: setActiveSlide,
                    })}
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </React.Fragment>
  );
}
export default GlobalCarousel;
