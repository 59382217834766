import React, { useEffect, useState } from "react";
import "./Styles/Styles.scss";
import Checkbox from "../../../../../Components/InputFields/Checkbox/Checkbox";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import AgreementFooter from "./AgreementFooter/AgreementFooter";
import GetInvestorConfidentialityAgreement from "../../../../../Adapters/ConfidentialityAgreement";
import { isBottom } from "../../../../../utilities/functions/functions";

const ConfidentialityAgreement = ({
  navigation,
  name,
  setName,
  checked,
  setChecked,
  setConfidentialityAgreementId,
  setConfidentialityAgreementDateSigned,
}) => {
  // Pull in Investor Confidentiality Agreement using current time as document refresh mechanism
  const timeStamp = Date.now();
  const [time, setTime] = useState(timeStamp);
  const { investorConfidentialityAgreement } =
    GetInvestorConfidentialityAgreement(time);

  const [documentHasBeenRead, setDocumentHasBeenRead] = useState(false);
  const [documentReadError, setDocumentReadError] = useState(false);
  const [nameError, setNameError] = useState("");

  // Checkbox States
  const [checkboxError, setCheckboxError] = useState(false);

  useEffect(() => {
    if (
      investorConfidentialityAgreement !== null &&
      investorConfidentialityAgreement !== undefined &&
      investorConfidentialityAgreement.id !== null &&
      investorConfidentialityAgreement.id !== undefined
    ) {
      setConfidentialityAgreementId(investorConfidentialityAgreement.id);
    }
  }, [investorConfidentialityAgreement]);

  var handleScroll = (e) => {
    if (process.env.DEBUG) console.log("document read: ", documentHasBeenRead);
    if (isBottom(e) && !documentHasBeenRead) {
      setDocumentHasBeenRead(true);
    }
  };

  const validateConfidentialityAgreementSigned = () => {
    var errors = false;
    if (name === "") {
      errors = true;
      setDocumentReadError(true);
    } else if (!checked) {
      errors = true;
      setCheckboxError(true);
    }

    return !errors;
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-2">
        <h6>Please read through our confidentiality agreement:</h6>
      </div>
      <div className="row g-0 w-100 mb-1">
        <div className="col-12">
          <div
            className="document-container"
            onScroll={(event) => handleScroll(event)}
          >
            <div className="container-fluid">
              <span
                className="body-text-xs-regular"
                dangerouslySetInnerHTML={{
                  __html: investorConfidentialityAgreement
                    ? investorConfidentialityAgreement.agreement
                    : "Loading ...",
                }}
              ></span>
            </div>
            <AgreementFooter
              investorConfidentialityAgreement={
                investorConfidentialityAgreement
              }
              name={name}
              setName={setName}
              nameError={nameError}
              setNameError={setNameError}
            />
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col-12">
          <div className="row g-0 w-100">
            <div
              className="col-1 checkBox-container"
              onClick={() => {
                if (documentHasBeenRead && name !== "") {
                  if (!checked) {
                    const today = new Date().toISOString();
                    setConfidentialityAgreementDateSigned(today);
                  }
                  setChecked(!checked);
                } else if (checked) {
                  setChecked(false);
                } else if (!documentHasBeenRead || name === "") {
                  setDocumentReadError(true);
                }
              }}
            >
              <Checkbox selected={checked} />
            </div>
            <div className="col-11">
              <span className="body-text-xs-regular ps-2">
                I have read, understood, and agree to all the terms noted in the{" "}
                <strong>Confidentiality Agreement</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100">
        <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
          <div className="row g-0 w-100">
            <div className="col ms-0 me-auto">
              <div className="d-flex">
                <MuiButton
                  buttonText={"Next Step"}
                  onClick={() => {
                    if (validateConfidentialityAgreementSigned()) {
                      navigation.next();
                    }
                  }}
                  size={"large"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        snackbarState={documentReadError}
        setSnackbarState={setDocumentReadError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"warning"}
        message={
          "Please make sure you have read the confidentiality agreement and signed your full name at the bottom of the document."
        }
      />
      <SnackBar
        snackbarState={checkboxError}
        setSnackbarState={setCheckboxError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"warning"}
        message={
          "Please make sure you have selected the checkbox to agree to our agreement."
        }
      />
    </React.Fragment>
  );
};
export default ConfidentialityAgreement;
