import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles.scss";
import StageSection from "./StageSection/StageSection.js";
import FundNameSection from "./FundNameSection/FundNameSection.js";
import TargetPerformanceSection from "./TargetPerformanceSection/TargetPerformanceSection.js";
import AllocationGraphSection from "./AllocationGraphSection/AllocationGraphSection.js";
import RequestAllocation from "./RequestAllocation/RequestAllocation";
//import InactiveFundCard from "./InactiveFundCard/InactiveFundCard";

function FundCard({ fund }) {
  let navigate = useNavigate();

  const stageMap = {
    STRUCTURED_CREDIT: "Private Credit",
    CLO_MANAGEMENT: "CLO Management",
    DIRECT_LANDING: "Direct Landing",
    PRIVATE_EQUITY: "Private Equity",
    CO_INVESTMENT: "Co-Investment",
    FUND_OF_FUNDS: "Fund of Funds",
    VENTURE_CAPITAL: "Venture Capital",
    BUYOUT: "Buyout",
    GROWTH: "Growth",
    CREDIT: "Credit",
  };
  const fundRef = useRef(null);

  return (
    <div className="col-xl-4 col-md-6 col-12">
      <div className="row g-0 ps-3 pe-3 h-100">
        <div
          ref={fundRef}
          className="fund-card active-fund-card"
          onClick={() => {
            if (
              (!["Closed"].includes(fund.fundInvestmentStatus)) ||
              fund.enableRequestAllocation === true
            ) {
              navigate("/fund/" + fund.link);
            }
          }}
        >
          <div className="col-12">
            <FundNameSection
              fundName={fund.fundName}
              firmName={fund.firm}
              logoLink={fund.logoLink}
            />
            <TargetPerformanceSection
              stage={fund.fundInvestmentStatus}
              targetIRR={fund.targetIRR}
              strategy={stageMap[fund.strategy]}
              minimum={fund.minimumInvestment}
              targetReturnMultiple={fund.fundTerms.target_return_multiple}
            />
            <AllocationGraphSection
              stage={fund.fundInvestmentStatus}
              allocationLeft={fund.amountInvested / fund.fundSize}
              requestAllocation={fund.enableRequestAllocation}
            />
            {/*<RequestAllocation}
              stage={fund.fundInvestmentStatus}
              requestAllocation={fund.enableRequestAllocation}
        />*/}
            <StageSection stage={fund.fundInvestmentStatus} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default FundCard;
