import { useState, useEffect } from "react";
import { getUninvestedClients } from "../APIs/ConfirmInvestmentAPI";

export default function GetUninvestedClients(
  fundInvestmentInformation,
  setLoading
) {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    async function getClients() {
      if (
        fundInvestmentInformation !== null &&
        fundInvestmentInformation !== undefined
      ) {
        setLoading(true);
        try {
          var response = await getUninvestedClients(
            fundInvestmentInformation.id
          );

          var uninvestedClientData = response.data;
          setClients(uninvestedClientData);
        } catch (err) {
          {}; 
        }
        setLoading(false);
      }
    }
    getClients();
  }, [fundInvestmentInformation]);

  return { clients };
}
