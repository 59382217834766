import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import VerticalTab from "../../../Components/VerticalTab/VerticalTab";
import VerticalTabs from "../../../Components/VerticalTab/VerticalTabs";
import FirmInformation from "./FirmInformation/FirmInformation";
import FirmRestrictions from "./FirmRestrictons/FirmRestrictions";
import ManageUsers from "./ManageUsers/ManageUsers";

function ManageFirm() {
  const [initialState, setInitialState] = useState(0);
  const [firmInfoSelected, setFirmInfoSelected] = useState(true);
  const [firmRestrictionsSelected, setFirmRestrictionsSelected] =
    useState(false);
  const [manageUsersSelected, setManageUsersSelected] = useState(false);

  useEffect(() => {
    if (firmInfoSelected === true) {
      setFirmRestrictionsSelected(false);
      setManageUsersSelected(false);
    } else if (firmRestrictionsSelected === true) {
      setFirmInfoSelected(false);
      setManageUsersSelected(false);
    } else if (manageUsersSelected === true) {
      setFirmRestrictionsSelected(false);
      setFirmInfoSelected(false);
    }
  }, [firmInfoSelected, firmRestrictionsSelected, manageUsersSelected]);

  return (
    <div className="container-xxl container-fluid w-sm-75 w-100 mt-3 pt-5 justify-content-center">
      <div className="row g-0 w-100 mt-5 mb-2">
        <h2>Manage Firm</h2>
      </div>
      <div className="row g-0 w-100">
        <VerticalTabs fullWidth={true} initial={initialState}>
          <VerticalTab
            title={"Firm Information"}
            selected={firmInfoSelected}
            setSelected={setFirmInfoSelected}
          >
            <FirmInformation />
          </VerticalTab>
          <VerticalTab
            title={"Firm Restrictions"}
            selected={firmRestrictionsSelected}
            setSelected={setFirmRestrictionsSelected}
          >
            <FirmRestrictions />
          </VerticalTab>
          <VerticalTab
            title={"Manage Users"}
            selected={manageUsersSelected}
            setSelected={setManageUsersSelected}
          >
            <ManageUsers />
          </VerticalTab>
        </VerticalTabs>
      </div>
    </div>
  );
}
export default ManageFirm;
