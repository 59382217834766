import * as dayjs from "dayjs";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import MultiSelectFilter from "../../../../../Components/MultiSelectFilter/MultiSelectFilter.js";
import ValueMultiselectFilter from "../../../../../Components/ValueMultiselectFilter/ValueMultiselectFilter.js";
import { getCapitalCall } from "../../../../../APIs/CapitalCallAPI";
import IconButton from "@mui/material/IconButton";
import Modal from "react-bootstrap/Modal";
import QPModal from "../../../../../Components/Qualification/QPModal.js";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  useGridSelector,
  useGridApiRef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import { UserContext } from "../../../../../Contexts/UserContext.js";

function WireCard({ props }) {
  return (
    <div className="row g-0 w-100 mb-1">
      <div className="col-12 p-3">
        <div className="border-container">
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Account Number:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.accNum} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Routing Number:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.routNum} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Bank Name:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.bankName} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Bank Address:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.bankAddress} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">
                    Beneficiary Name:
                  </div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.beneficiaryName} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">
                    Beneficiary Address:
                  </div>
                  <div>
                    <div className="d-flex">
                      <Chip
                        label={props.beneficiaryAddress}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b className="ps-3">Wire instructions are unique for each client’s investment.</b>
    </div>
  );
}

function arrayContainsValue(id, searchArray) {
  return searchArray.find((elem) => elem.key === id);
}

function CapitalCallsTable({
  showClient,
  setSelected,
  setModalOpen,
  clientToShow,
}) {
  function moneyFormatter(amount) {
    if (amount === undefined || amount === null || amount === "0") {
      return "$ 0";
    }
    var integer = parseInt(amount.toString(), 10);
    return "$ " + integer.toLocaleString("en-US");
  }
  const { user, setUser } = useContext(UserContext);

  const { client, fund } = useParams();

  const [clients, setClients] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [funds, setFunds] = useState([]);

  const [fundsSelected, setFundsSelected] = useState(
    fund ? [decodeURI(fund)] : []
  );

  const [clientsSelected, setClientsSelected] = useState(
    showClient ? (client ? [client.substring(0, 36)] : []) : [clientToShow]
  );

  const [statusSelected, setStatusSelected] = useState([]);

  const [resp, setResp] = useState();

  async function getCapitalCallWrapper(payload) {
    const capResp = await getCapitalCall(payload);
    setResp(capResp);
  }

  const unformatStatus = (ret) => {
    if (ret === "Late Fulfillment") {
      return "L";
    } else if (ret === "Partial Fulfillment") {
      return "X";
    } else if (ret === "Pending Fulfillment") {
      return "P";
    } else if (ret === "Fulfilled") {
      return "F";
    }
    else if (ret === "Overfunded") {
      return "O";
    }
    else if (ret === "Partially Fulfilled") {
      return "X";
    }
  };

  /*
          PENDING = "P", _("Pending Fulfillment"),
        PARTIAL = "X", _("Partially Fulfilled"),
        OVERFUNDED = "O", _("Overfunded")
        LATE = "L", _("Fulfilled Late")
        FULFILLED = "F", _("Fulfilled")
        DELINQUENT = "D", _("Delinquent")*/

  const statusFormatter = (ret) => {
    if (ret === "L") {
      return "Late Fulfillment";
    } else if (ret === "X") {
      return "Partial Fulfillment";
    } else if (ret === "P") {
      return "Pending Fulfillment";
    } else if (ret === "F") {
      return "Fulfilled";
    }
    else if (ret === "O") {
      return "Overfunded";
    }
    else if (ret === "X") {
      return "Partially Fulfilled";
    }
  };

  useEffect(() => {
    getCapitalCallWrapper({
      fund: fundsSelected,
      status: statusSelected.map((status) => unformatStatus(status)),
      client: clientsSelected,
    });
  }, [fundsSelected, statusSelected, clientsSelected]);

  useEffect(() => {
    if (resp && fundsSelected && (clientsSelected) && statusSelected) {
      if (
        (fundsSelected.length === 0 &&
          (clientsSelected.length === 0 || !showClient) &&
          statusSelected.length === 0) ||
        (client && fund)
      ) {
        var funds = [];
        var statuses = [];
        let clientsArray = [];

        Object.values(resp.data.data).forEach((elem) => {
          if (!funds.includes(elem.fundName)) {
            funds.push(elem.fundName);
          }
          if (!statuses.includes(elem.status)) {
            statuses.push(elem.status);
          }
          if (!arrayContainsValue(elem.clientId, clientsArray)) {
            clientsArray.push({
              key: elem.clientId,
              value: elem.clientName,
            });
          }
        });

        setFunds(funds);
        setStatuses(statuses);
        setClients(clientsArray);
      }
    }
  }, [resp]);

  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();
  useEffect(() => {
    if (resp) {
      setLoading(true);
      const capitalCalls = Object.values(resp.data.data);
      var formattedRowData = capitalCalls.map((item, idx) => {
        return {
          id: idx,
          ...item,
          amount: moneyFormatter(item.amount),
          status: statusFormatter(item.status),
          fulfilledBy: item.fulfilledBy ? item.fulfilledBy.slice(0, 10) : "",
          fulfillOn: item.fulfillOn ? item.fulfillOn.slice(0, 10) : "",
        };
      });
      setFormattedData(formattedRowData);
      setLoading(false);
    }
  }, [resp]);

  var columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "fundName",
      headerName: "Opportunity Name",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    ...(showClient
      ? [
        {
          field: "clientName",
          headerName: "Client Name",
          headerClassName: "stylish-header",
          flex: 1,
          filterable: false,
          hideSortIcons: true,
          sortable: false,
          hideable: false,
          pinnable: false,
        },
      ]
      : []),
    {
      field: "account",
      headerName: "Account",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "amount",
      headerClassName: "stylish-header",
      headerName: "Amount",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "fulfillBy",
      headerClassName: "stylish-header",
      headerName: "Fulfill By",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "status",
      headerClassName: "stylish-header",
      headerName: "Status",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "fulfilledOn",
      headerClassName: "stylish-header",
      headerName: "Fulfilled On",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerClassName: "stylish-header",
      headerName: "Wire Instructions",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      field: "showWire",
      renderCell: (params) => {
        return (
          <MuiButton
            size="large"
            buttonText={"Show"}
            onClick={() => {
              setSelected(params.row);
              setModalOpen(true);
            }}
          />
        );
      },
    },
    {
      headerClassName: "stylish-header",
      headerName: "Statement",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      field: "download",
      renderCell: (params) => {
        return (
          params.row.s3_document_link != null ? <MuiButton
            size="large"
            buttonText={"Download"}
            onClick={() => {
              fetch(params.row.s3_document_link, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/pdf',
                },
              })
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(
                    new Blob([blob]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `${params.row.account}${params.row.fundName}.pdf`,
                  );
                  document.body.appendChild(link);

                  link.click();
                  link.parentNode.removeChild(link);
                });

            }}
          /> : <> N/A</>
        );
      },
    },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
    },
  ];


  return resp &&
    resp.data &&
    resp.data.data &&
    Object.values(resp.data.data).length > 0 ? (
    <Box sx={{ minHeight: 475, width: "100%" }}>
      <div className="row g-0 w-100 mb-3">
        {user.userRole !== 'Self-Serve' && showClient && clients.length !== 0 && (
          <ValueMultiselectFilter
            label={"Client Name"}
            options={clients}
            selectedValues={clientsSelected}
            setSelectedValues={setClientsSelected}
            loading={loading}
          />
        )}
        <MultiSelectFilter
          label={"Status"}
          options={statuses.map((e) => statusFormatter(e))}
          selectedValues={statusSelected}
          setSelectedValues={setStatusSelected}
          loading={loading}
        />
        <MultiSelectFilter
          label={"Opportunity Name"}
          options={funds}
          selectedValues={fundsSelected}
          setSelectedValues={setFundsSelected}
          loading={loading}
        />
      </div>
      <DataGridPro
        apiRef={apiRef}
        sx={{
          height: "400px",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            borderRadius: "6px",
          },
        }}
        columns={columns}
        rows={formattedData}
        loading={loading}
        rowHeight={54}
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
        getDetailPanelContent={(params) => <WireCard props={params.row} />}
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
              id: false,
            },
          },
        }}
      />
    </Box>
  ) : (
    <>No capital calls found.</>
  );
}

const CapitalCalls = ({
  data,
  graph,
  setCapitalCallsSelected,
  setInvestment,
  setInvestmentsSelected,
  showClient,
  clientToShow,
}) => {
  const [capitalCalls, setCapitalCalls] = useState([]);

  const navigate = useNavigate();
  function moneyFormatter(cell) {
    if (cell === undefined || cell === null || cell === "0") {
      return "$ 0";
    }
    var integer = parseInt(cell.toString(), 10);
    return "$ " + integer.toLocaleString("en-US");
  }
  useEffect(() => {
    if (data !== null && data !== undefined && data.length !== 0) {
      setCapitalCalls(
        data.map((e) => ({
          fundName: e.fund,
          investmentAmount: e.amount,
          totalDistributed: e.distributions,
          opportunityStatus: e.fund_status,
          investmentStage: e.status,
          accountName: e.account_name,
          investmentId: e.id,
        }))
      );
    }
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState({});

  return (
    <>
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h5>Wire Instructions</h5>
        </Modal.Header>
        <Modal.Body>
          <WireCard props={selected} />
        </Modal.Body>
        <Modal.Footer>
          <MuiButton
            size="large"
            buttonText={"Close"}
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </Modal.Footer>
      </Modal>
      <div className="container ps-3 pe-3">
        <div className="row g-0 w-100 mb-2">
          <h5>Capital Calls</h5>
        </div>
        <div className="row g-0 w-100 mb-2">
          <CapitalCallsTable
            showClient={showClient}
            clientToShow={clientToShow}
            rowData={capitalCalls}
            setCapitalCallsSelected={setCapitalCallsSelected}
            setInvestment={setInvestment}
            setInvestmentsSelected={setInvestmentsSelected}
            setSelected={setSelected}
            setModalOpen={setModalOpen}
          />
        </div>
      </div>
    </>
  );
};
export default CapitalCalls;
