import React, { useContext, useEffect } from "react";
import "./Styles/Styles.scss";
import { useNavigate } from "react-router";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton";
import ChooseClientTable from "../../../../Components/ChooseClientTable/ChooseClientTable";
import useMasterOpsClientSearch from "../../../../APIs/useMasterOpsClientSearch";
import { UserContext } from "../../../../Contexts/UserContext";

function MasterOpsClientsTable({
  clientSearchQuery,
  setClientsLoading,
  clientsLoading,
  refreshClients,
}) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const shareClients = user.firmInfo.shareClients;

  const { clients } = useMasterOpsClientSearch(
    clientSearchQuery,
    setClientsLoading,
    refreshClients
  );

  const columns = shareClients
    ? [
        {
          dataField: "id",
          hidden: true,
        },
        {
          dataField: "firstName",
          text: "First Name",
          classes: "body-text-md-regular align-middle",
          headerClasses: "start-padding body-text-md-bold",
        },
        {
          dataField: "lastName",
          text: "Last Name",
          classes: "body-text-md-regular align-middle",
          headerClasses: "start-padding body-text-md-bold",
        },
        {
          dataField: "email",
          text: "Email",
          classes: "body-text-md-regular align-middle",
          headerClasses: "start-padding body-text-md-bold",
        },
        {
          dataField: "dummy",
          text: "",
          classes: "body-text-md-regular align-end justify-content-end",
          headerClasses: "start-padding body-text-md-bold text-end",
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex justify-content-end">
                <MuiButton
                  buttonText={"View Client Details"}
                  onClick={() => navigate("/clients/" + row.id)}
                  size={"small"}
                />
              </div>
            );
          },
        },
      ]
    : [
        {
          dataField: "id",
          hidden: true,
        },
        {
          dataField: "firstName",
          text: "First Name",
          classes: "body-text-md-regular align-middle",
          headerClasses: "start-padding body-text-md-bold",
        },
        {
          dataField: "lastName",
          text: "Last Name",
          classes: "body-text-md-regular align-middle",
          headerClasses: "start-padding body-text-md-bold",
        },
        {
          dataField: "email",
          text: "Email",
          classes: "body-text-md-regular align-middle",
          headerClasses: "start-padding body-text-md-bold",
        },
        {
          dataField: "advisorEmail",
          text: "Advisor Email",
          classes: "body-text-md-regular align-middle",
          headerClasses: "start-padding body-text-md-bold",
        },
        {
          dataField: "dummy",
          text: "",
          classes: "body-text-md-regular align-end justify-content-end",
          headerClasses: "start-padding body-text-md-bold text-end",
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex justify-content-end">
                <MuiButton
                  buttonText={"View Client Details"}
                  onClick={() => navigate("/clients/" + row.id)}
                  size={"small"}
                />
              </div>
            );
          },
        },
      ];

  // const shareClientColumns = [
  //   {
  //     dataField: "id",
  //     hidden: true,
  //   },
  //   {
  //     dataField: "firstName",
  //     text: "First Name",
  //     classes: "body-text-md-regular align-middle",
  //     headerClasses: "start-padding body-text-md-bold",
  //   },
  //   {
  //     dataField: "lastName",
  //     text: "Last Name",
  //     classes: "body-text-md-regular align-middle",
  //     headerClasses: "start-padding body-text-md-bold",
  //   },
  //   {
  //     dataField: "email",
  //     text: "Email",
  //     classes: "body-text-md-regular align-middle",
  //     headerClasses: "start-padding body-text-md-bold",
  //   },
  //   {
  //     dataField: "dummy",
  //     text: "",
  //     classes: "body-text-md-regular align-end justify-content-end",
  //     headerClasses: "start-padding body-text-md-bold text-end",
  //     formatter: (cellContent, row) => {
  //       return (
  //         <div className="d-flex justify-content-end">
  //           <MuiButton
  //             buttonText={"View Client Details"}
  //             onClick={() => navigate("/clients/" + row.id)}
  //             size={"small"}
  //           />
  //         </div>
  //       );
  //     },
  //   },
  // ];

  // useEffect(() => {
  //   console.log("Clients are: " + JSON.stringify(clients));
  // }, [clients]);
  return (
    <div
      className="row g-0 w-100"
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "6px",
      }}
    >
      <ChooseClientTable
        data={clients}
        columns={columns}
        loading={clientsLoading}
      />
    </div>
  );
}
export default MasterOpsClientsTable;
