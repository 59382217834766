import React from "react";
import TextInputField from "../../../../../../Components/InputFields/TextInputField/TextInputField";
import "./Styles/Styles.scss";

const AgreementFooter = ({
  investorConfidentialityAgreement,
  name,
  setName,
  nameError,
  setNameError,
}) => {
  return investorConfidentialityAgreement ? (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="col-12">
          <div className="row g-0 w-100 align-items-center">
            <div className="col-6">
              <span className="signature-font">
                <b>Rohan Marwaha</b>
              </span>
            </div>
            <div className="col-6">
              <span>
                <b>Aqua Platform Inc., CEO</b>
              </span>
            </div>
          </div>
          <div className="row g-0 w-100 align-items-center">
            <div className="col-6">
              <span>Disclosing Party Signature</span>
            </div>
            <div className="col-6">
              <span>Disclosing Party Full Name & Title</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col-12">
          <div className="row g-0 w-100 mt-2">
            <span className="mb-3">
              <b>
                Entering your name in the box below will be legally considered
                as a proxy to your signature of acceptance to the terms
                described above in the Non-Dislosure and Confidentiality
                Agreement.
              </b>
            </span>
          </div>
          <div className="row g-0 w-100 mb-1">
            <div className="col-6">
              <TextInputField
                value={name}
                setValue={setName}
                valueError={nameError}
                setValueError={setNameError}
                label={"Recipient Party Full Name"}
                required={true}
                fullWidth={true}
                shrink={true}
                size={"small"}
                id={"recipient-party-full-name-input"}
              />
            </div>
          </div>
          <div className="row g-0 w-100">
            <span>Recipient Party Full Name</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};
export default AgreementFooter;
