import { useState, useEffect, useContext } from "react";
import { UserContext } from "../Contexts/UserContext";
import {
  getClientDocuments,
  getTransactions,
  getAccountVerificationDocuments,
} from "../APIs/ClientDetailsAPI";
import { Storage } from "aws-amplify";
import { getSignedURL } from "../utilities/functions/functions.js";
import * as dayjs from "dayjs";

export const GetDocuments = (clientId, docType, setLoading) => {
  const [documents, setDocuments] = useState([]);

  const { user } = useContext(UserContext);

  useEffect(() => {
    async function getDocuments() {
      try {
        const response = await getClientDocuments(clientId, docType);

        if (process.env.REACT_APP_DEBUG === "1") {
          console.log(response.data);
        }
        const docData = response.data;

        const sortableDocumentsData = docData.map((item) => {
          let date = new Date(item.year, item.month - 1);
          let unixDate = dayjs(date.toString()).unix();
          let formattedDate = dayjs.unix(unixDate).format("MMM, YYYY");

          return {
            id: item.id,
            docName: item.document_name,
            docType: item.document_type,
            year: item.year,
            month: item.month,
            unixDate: unixDate,
            documentDate: formattedDate,
            docLink: item.document_link,
            display: true,
          };
        });

        sortableDocumentsData.sort((a, b) => {
          if (a.unixDate > b.unixDate) {
            return -1;
          }
          if (a.unixDate > b.unixDate) {
            return 1;
          }
          return 0;
        });

        sortableDocumentsData.forEach((item) => {
          let formattedDate = dayjs.unix(item.unixDate).format("MMM, YYYY");
          let X = item.docLink;
          let Y = "/private";
          let Z = X.slice(item.docLink.indexOf(Y) + Y.length);
          let Q = "/";
          let A = Z;

          let intermediaryUrl = Z.slice(A.indexOf(Q) + Q.length);

          async function getSignedURL() {
            var tempURL = await Storage.get(intermediaryUrl, {
              level: "private",
            });

            setDocuments((prevState) => [
              ...prevState,
              {
                id: item.id,
                docName: item.docName,
                docType: item.docType,
                year: item.year,
                month: item.month,
                docDate: formattedDate,
                docLink: item.docLink,
                display: true,
              },
            ]);
          }
          getSignedURL();
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.error(err);
      }
      setLoading(false);
    }
    getDocuments();
  }, [clientId, setDocuments]);

  return { documents };
};

export const GetTransactions = ({ clientId, type }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    async function getTransactionInfo() {
      try {
        const response = await getTransactions(clientId);

        const transactionResponse = response.data;

        let transactionData = transactionResponse.map((item) => {
          let transactionTypes = {
            DEPOSIT: "Deposit",
            DISBURSEMENT: "Disbursement",
          };

          let transactionStatuses = {
            PENDING: "Pending",
            IN_PROCESS: "Processing",
            COMPLETED: "Completed",
            FAILED: "Failed",
          };

          let transactionMethods = {
            ACH: "ACH",
            WIRE: "Wire",
            ELECTRONIC_CHEQUE: "Electronic Check",
            MAIL_CHEQUE: "Mail Check",
          };

          let dateNoTime = dayjs(dayjs(item.date).format("MMM D, YYYY"));

          let unixDate = dayjs(dateNoTime).unix();

          let formattedDate = dayjs.unix(unixDate).format("MMM, YYYY");

          return {
            id: item.id,
            firmName: item.firm_name,
            fundName: item.fund_name,
            type: transactionTypes[item.type],
            amount: item.amount,
            status: transactionStatuses[item.status],
            method: transactionMethods[item.method],
            date: formattedDate,
            unixDate: unixDate,
          };
        });

        transactionData.sort((a, b) => {
          if (a.unixDate > b.unixDate) {
            return -1;
          }
          if (a.unixDate < b.unixDate) {
            return 1;
          }
          return 0;
        });

        setTransactions(transactionData);
      } catch (err) {
        //console.log(err);
        {
        }
      }
    }
    getTransactionInfo();
  }, [clientId, type]);

  return { transactions };
};

export const GetAccountVerificationDocuments = (
  clientId,
  setLoading,
  refresh
) => {
  const [accountVerificationDocuments, setAccountVerificationDocuments] =
    useState([]);
  const [message, setMessage] = useState("");
  const [docList, setDocList] = useState([]);
  const [
    accountVerificationDocumentRequestStatus,
    setAccountVerificationDocumentRequestStatus,
  ] = useState("");

  let statusMapping = {
    PA: "Pending Approval",
    A: "Approved",
  };

  useEffect(() => {
    async function getVerificationDocs() {
      try {
        setLoading(true);
        const response = await getAccountVerificationDocuments(clientId);

        const accountVerificationDocumentResponse = response.data;

        if (
          accountVerificationDocumentResponse !== null &&
          accountVerificationDocumentResponse !== undefined &&
          Object.keys(accountVerificationDocumentResponse).length !== 0
        ) {
          if (
            accountVerificationDocumentResponse.account_verification_document_request !==
              null &&
            accountVerificationDocumentResponse.account_verification_document_request !==
              undefined
          ) {
            setMessage(
              accountVerificationDocumentResponse
                .account_verification_document_request.message
            );
            setDocList(
              accountVerificationDocumentResponse
                .account_verification_document_request.doc_list
            );
            setAccountVerificationDocumentRequestStatus(
              accountVerificationDocumentResponse
                .account_verification_document_request.status
            );
          }

          if (
            accountVerificationDocumentResponse.documents !== null &&
            accountVerificationDocumentResponse.documents !== undefined &&
            accountVerificationDocumentResponse.documents.length !== 0
          ) {
            let docData = accountVerificationDocumentResponse.documents.map(
              (item) => {
                return {
                  id: item.id,
                  name: item.name,
                  category: item.category,
                  uploadDate: item.upload_date,
                  status: statusMapping[item.status],
                  docLink: item.s3_document_link,
                };
              }
            );
            setAccountVerificationDocuments(docData);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        //console.log(err);
        console.log(err);
      }
    }
    getVerificationDocs();
  }, [clientId, refresh]);

  return {
    accountVerificationDocuments,
    message,
    docList,
    accountVerificationDocumentRequestStatus,
  };
};
