import React from "react";
import "./Styles/Styles.scss";
import InfiniteTabInput from "../../../../../../../Components/InfiniteInputComponent/InfiniteTabInput";
import InfiniteInputComponent from "../../../../../../../Components/InfiniteInputComponent/InfiniteInputComponent";

function BeneficialOwnerInformation({
  accountType,
  hasBeneficialOwners,
  setHasBeneficialOwners,
  beneficialOwnerInfo,
  setBeneficialOwnerInfo,
  beneficialOwnerActiveTab,
  setBeneficialOwnerActiveTab,
  handleRemoveFields,
  disabled,
  setHideButton,
  hideAlterButtons,
  addNewTab,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <div className="row g-0 w-100 mb-2">
          <span className="body-text-sm-semibold mb-1 mt-2">
            {
              "Does this entity have any beneficial owners that own 25%+ of the entity other than the primary account holder?"
            }
          </span>
        </div>
        <div className="row g-0 w-100 mb-2 fade-in-test">
          <div className="col-6">
            <label for={"hasBeneficialOwnersRadioId"} className={"radio"}>
              <input
                type={"radio"}
                name={"hasBeneficialOwnersRadio"}
                id={"hasBeneficialOwnersRadioId"}
                className={"radio__input"}
                checked={hasBeneficialOwners}
                onClick={() => {
                  setHasBeneficialOwners(true);
                }}
              />
              <div className="radio__radio"></div>
              <span className="qualification-question-text ms-2">Yes</span>
            </label>
          </div>
          <div className="col-6">
            <label for={"notHasBeneficialOwnersRadioId"} className={"radio"}>
              <input
                type={"radio"}
                name={"notHasBeneficialOwnersRadio"}
                id={"notHasBeneficialOwnersRadioId"}
                className={"radio__input"}
                checked={
                  hasBeneficialOwners !== "" && hasBeneficialOwners === false
                }
                onClick={() => {
                  setHasBeneficialOwners(false);
                }}
              />
              <div className="radio__radio"></div>
              <span className="qualification-question-text ms-2">No</span>
            </label>
          </div>
        </div>
      </div>
      {hasBeneficialOwners === true && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <h6>Related Party Information</h6>
          </div>
          <div className="row g-0 w-100">
            <InfiniteTabInput
              tabs={beneficialOwnerInfo.map(
                (elem, idx) => "Beneficial Owner #" + (idx + 1).toString()
              )}
              activeTab={beneficialOwnerActiveTab}
              setActiveTab={setBeneficialOwnerActiveTab}
              addNewTab={addNewTab}
            />
            <InfiniteInputComponent
              inputFields={beneficialOwnerInfo}
              setInputFields={setBeneficialOwnerInfo}
              index={beneficialOwnerActiveTab.match(/\d+/) - 1}
              handleRemoveFields={handleRemoveFields}
              disabled={disabled}
              setActiveTab={setBeneficialOwnerActiveTab}
              setHideButton={setHideButton}
              hideAlterButtons={hideAlterButtons}
              accountType={accountType}
              relatedPartyType={"BO"}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default BeneficialOwnerInformation;
