import React, { useState, useEffect, useContext } from "react";
import "./Profile.css";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
/*import DeliverableAddressSection from "./DeliverableAddressSection/DeliverableAddressSection";
import { isDobValid } from "../../../Functions/functions.js";
import { isValidSSN } from "../../../Functions/functions.js";
import { isValidCountry } from "../../../GlobalFunctions/globalFunctions";
import { UserContext } from "../../../GlobalContexts/UserContext.js";
import { useLocation, useHistory } from "react-router-dom";
import {
  updateInvestorAddress,
  updateInvestorPII,
} from "../../../Api/ProfileApi.js";*/
import StyledDateInputField from "../StyledDateInput/StyledDateInput.js";
import TextInputField from "../InputFields/TextInputField/TextInputField";

import { stateList } from "../stateList.js";
import { countryList } from "../countryList.js"; 
import MuiButton from "../Buttons/MuiButton/MuiButton"
import {default as SelectField}  from "../InputFields/SelectInputField/SelectInputField.js";
import { formatSSN } from "./../../utilities/functions/functions.js";

function InvestorInformation({ accountSummaryData, setAccountSummaryData }) {
  const [piiLoading, setPIILoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [deliverableSelectionError, setDeliverableSelectionError] =
    useState("");
  const [primaryAddressDeliverable, setPrimaryAddressDeliverable] =
    useState("Yes");
  const [addressLine2Error, setAddressLine2Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryStateError, setCountryStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [nationalIdError, setNationalIdError] = useState("");
  const [dobError, setDobError] = useState("");
  const [address, setAddress] = useState("200 Water St");
  const [addressLine2, setAddressLine2] = useState("Apt 1");
  const [city, setCity] = useState("New York");
  const [countryState, setCountryState] = useState([]);
  const [country, setCountry] = useState("United States");
  const [postalCode, setPostalCode] = useState("11101");
  const [addressType, setAddressType] = useState("");
  const [hideDeliverableQuestion, setHideDeliverableQuestion] = useState(false);
  const [deliverableAddressLine1, setDeliverableAddressLine1] = useState("");
  const [deliverableAddressLine2, setDeliverableAddressLine2] = useState("");
  const [deliverableCity, setDeliverableCity] = useState("");
  const [deliverableState, setDeliverableState] = useState("");
  const [deliverableCountry, setDeliverableCountry] = useState("");
  const [deliverablePostalCode, setDeliverablePostalCode] = useState("");
  const [deliverableAddressLine1Error, setDeliverableAddressLine1Error] =
    useState("");
  const [deliverableAddressLine2Error, setDeliverableAddressLine2Error] =
    useState("");
  const [deliverableCityError, setDeliverableCityError] = useState("");
  const [deliverableStateError, setDeliverableStateError] = useState("");
  const [deliverableCountryError, setDeliverableCountryError] = useState("");
  const [deliverablePostalCodeError, setDeliverablePostalCodeError] =
    useState("");
  const [nationalId, setNationalId] = useState("");
  const [passportNumber, setPassportNumber] = useState("1234567890");
  const [passportNumberError, setPassportNumberError] = useState("");
  const [idType, setIDType] = useState("");
  const [dob, setDob] = useState("");
  //const location = useLocation();
  //const from = location ? (location.state ? location.state.from : "") : "";
  //let history = useHistory();
  const user  = { investorPrimaryAddressLine1: "", investor_pii: "", userProfileData: { investor_pii: ""}, investorSummaryData: { country: ""} } 


  useEffect(() => {
  }, [user]);

  useEffect(() => {
    setCountryStateError("");
  }, [countryState]);

  useEffect(() => {
    setAddressError("");
  }, [address]);

  useEffect(() => {
    setAddressLine2Error("");
  }, [addressLine2]);

  useEffect(() => {
    setPostalCodeError("");
  }, [postalCode]);

  useEffect(() => {
    setDobError("");
  }, [dob]);

  useEffect(() => {
    setNationalIdError("");
    setPassportNumberError("");
    setIDType("SSN");
  }, [nationalId]);

  useEffect(() => {
    setPassportNumberError("");
    setNationalIdError("");
    setIDType("PASS");
  }, [passportNumber]);

  useEffect(() => {
    setCityError("");
  }, [city]);

  useEffect(() => {
    setCountryError("");
  }, [country]);

  useEffect(() => {
    setDeliverableAddressLine1Error("");
  }, [deliverableAddressLine1]);

  useEffect(() => {
    setDeliverableAddressLine2Error("");
  }, [deliverableAddressLine2]);

  useEffect(() => {
    setDeliverableCityError("");
  }, [deliverableCity]);

  useEffect(() => {
    setDeliverableStateError("");
  }, [deliverableState]);

  useEffect(() => {
    setDeliverableCountryError("");
  }, [deliverableCountry]);

  useEffect(() => {
    setDeliverablePostalCodeError("");
  }, [deliverablePostalCode]);

  useEffect(() => {
    setDeliverableSelectionError("");
  }, [primaryAddressDeliverable]);



  return (
    <React.Fragment>
      <div className="container-fluid w-100 ps-sm-5 ps-3 pe-sm-3 pe-2">
        <div className="row g-0 w-100">
          <div className="col-lg-11 col-12">
            <div className="row g-0 w-100 mt-3 mb-3">
              <div className="col-12">
                <h4>
                  Advisor Information
                </h4>
              </div>
            </div>
            <div className="row g-0 w-100 mt-4 mb-4">
              <div className="col ps-0 pe-2">
                <TextInputField
                  value={address}
                  setValue={setAddress}
                  setValueError={setAddressError}
                  valueError={addressError}
                  label={"Address Line 1"}
                  shrink={true}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={true}
                  id={"investor-profile-address-line-1-input"}
                />
              </div>
              <div className="col ps-2 pe-0">
                <TextInputField
                  value={addressLine2}
                  setValue={setAddressLine2}
                  setValueError={setAddressLine2Error}
                  valueError={addressLine2Error}
                  label={"Address Line 2"}
                  shrink={true}

                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={true}
                  id={"investor-profile-address-line-2-input"}
                />
              </div>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div
                className={
                  country === "United States"
                    ? "col ps-0 pe-2"
                    : "col ps-0 pe-0"
                }
              >
                <TextInputField
                  value={city}
                  setValue={setCity}
                  setValueError={setCityError}
                  valueError={cityError}
                  label={"City"}
                  required={true}
                  shrink={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={true}
                  id={"investor-profile-city-input"}
                />
              </div>
              {country === "United States" && (
                <div className="col ps-2 pe-0">
                  <SelectField
                    value={countryState}
                    setValue={setCountryState}
                    valueError={countryStateError}
                    setValueError={setCountryStateError}
                    selectOptions={stateList}
                    label={"State"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"investor-profile-address-state-input"}
                  />
                </div>
              )}
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="col ps-0 pe-2">
                <SelectField
                  value={country}
                  setValue={setCountry}
                  valueError={countryError}
                  setValueError={setCountryError}
                  selectOptions={countryList}
                  label={"Country"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={true}
                  shrink={true}
                  id={"investor-profile-address-country-input"}
                />
              </div>
              <div className="col ps-2 pe-0">
                <TextInputField
                  value={postalCode}
                  setValue={setPostalCode}
                  setValueError={setPostalCodeError}
                  valueError={postalCodeError}
                  label={"Postal Code"}
                  required={true}
                  fullWidth={true}
                  shrink={true}
                  size={"small"}
                  disabled={true}
                  id={"investor-profile-postal-code-input"}
                />
              </div>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="col pe-2">
                <StyledDateInputField
                  value={"01-01-2001"}
                  setValue={setDob}
                  valueError={dobError}
                  setValueError={setDobError}
                  label={"Date Of Birth"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={true
                  }
                  shrink={true}
                  id={"investor-profile-dob-input"}
                />
              </div>
              <div className="col ps-2 pe-0">
                {user.investorSummaryData.country === "United States" && (
                  <TextInputField
                    value={nationalId}
                    setValue={setNationalId}
                    valueError={nationalIdError}
                    setValueError={setNationalIdError}
                    label={"SSN"}
                    required={true}
                    fullWidth={true}
                    shrink={true}

                    disabled={
                      user
                        ? user.userProfileData.investor_pii.length !== 0
                          ? user.userProfileData.investor_pii[0].id_num !== ""
                            ? true
                            : false
                          : false
                        : false
                    }
                    size={"small"}
                    mask={{
                      maskFormatter: formatSSN,
                      maskPattern: "999-99-9999",
                    }}
                    id={"investor-profile-ssn-input"}
                  />
                )}
                {user.investorSummaryData.country !== "United States" && (
                  <TextInputField
                    value={passportNumber}
                    setValue={setPassportNumber}
                    valueError={passportNumberError}
                    setValueError={setPassportNumberError}
                    label={"Passport Number"}
                    required={true}
                    fullWidth={true}
                    disabled={true
                    }
                    shrink={true}
                    size={"small"}
                    id={"investor-profile-passport-input"}
                  />
                )}
              </div>
            </div>

            {user &&
            (user.userProfileData.investor_pii.length === 0 ||
              user.userProfileData.investor_pii[0].date_of_birth === "" ||
              user.userProfileData.investor_pii[0].id_num === "") ? (
              <div className="row g-0 w-100 mb-4">
                <div className="d-flex">
                {/* <MuiButton 
                            buttonText={"Save Personal Information"}
                            onClick={() => {}}
                            size={"large"}
                            disabled={true}
                            loading={false}
              /> */}

                </div>
              </div>
            ) : (
              <div className="row g-0 w-100 mt-4 mb-4 contact-aqua-admin-container">
                <div className="d-flex align-items-center">
                  <img
                    src="https://d1swyfveypj49y.cloudfront.net/newInfo.svg"
                    style={{ marginRight: "14px" }}
                  />
                  <span className="contact-aqua-admin-text">
                    If any of the above information is incorrect, please contact
                    admin@investwithaqua.com to update your account information.
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InvestorInformation;
