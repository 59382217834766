import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../../../../Contexts/UserContext";
import { useNavigate } from "react-router-dom";
import "./Styles/Styles.scss";
import NavLinks from "../../../NavLinks/NavLinks";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton.js";
import { numFormatter } from "../../../../utilities/functions/functions.js";
import styles from "../../../../utilities/scss/_variables.scss";
import ProgressBar from "../../../../Components/Progress/ProgressBar/ProgressBar";

const Header = ({ fund, stage }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [firmLogoLoading, setFirmLogoLoading] = useState(true);
  const firmLogoPicOnLoad = () => {
    setFirmLogoLoading(false);
  };

  const [stageBoxDiv, setStageBoxDiv] = useState("");
  const [stageTextDiv, setStageTextDiv] = useState("");

  const colRef = useRef(null);
  const rowRef = useRef(null);
  const targetPerformanceRowRef = useState(null);

  const primaryBlue = styles.primaryBlue;
  const secondaryRed = styles.secondaryRed;

  useEffect(() => {
    if (
      fund !== null &&
      fund !== undefined &&
      fund.amountInvested !== null &&
      fund.amountInvested !== undefined &&
      fund.allocationSize !== null &&
      fund.allocationSize !== undefined
    ) {
      let allocationFilled =
        Math.round(
          ((fund.amountInvested / fund.allocationSize) * 100 + Number.EPSILON) *
            100
        ) / 100;
      if (allocationFilled >= 25) {
        colRef.current.className =
          "col-xxl col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 pe-sm-0";
        rowRef.current.className = "row bottom-border bottom-divider g-0";
        targetPerformanceRowRef.current.className =
          "row right-border g-0 d-flex justify-content-center";
      }
    }
  }, [fund]);

  useEffect(() => {
    if (stage === "Upcoming") {
      setStageBoxDiv("upcoming-stage-box ms-3");
      setStageTextDiv("upcoming-stage-text");
    } else if (stage === "Fundraising") {
      setStageBoxDiv("currently-fundraising-stage-box ms-3");
      setStageTextDiv("currently-fundraising-stage-text");
    } else if (stage === "Pending Closing") {
      setStageBoxDiv("newest-fundraising-complete-stage-box ms-3");
      setStageTextDiv("fundraising-complete-stage-text");
    } else if (stage === "Closed") {
      setStageBoxDiv("newest-closed-stage-box ms-3");
      setStageTextDiv("closed-stage-text");
    } else if (stage === "Past") {
      setStageBoxDiv("newest-past-stage-box ms-3");
      setStageTextDiv("past-stage-text");
    }
  }, [stage]);

  return (
    <React.Fragment>
      <NavLinks />
      <div className="row g-0 w-100 mb-3 mt-2">
        <div className="row g-0 w-100 fund-detail-header-section">
          <div
            className="col-xxl col-xl col-lg col-md-10 col-sm-12 col-xs-12"
            ref={colRef}
          >
            <div className="row bottom-divider g-0" ref={rowRef}>
              <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-3 col-12">
                <div
                  className="rounded responsive center-content"
                  style={{
                    paddingTop: "24px",
                    paddingLeft: "24px",
                    paddingBottom: "24px",
                  }}
                >
                  <img
                    src={fund ? fund.logoLink : ""}
                    alt="thamesville"
                    style={{
                      display: firmLogoLoading ? "none" : "inline-block",
                      height: "80px",
                      width: "80px",
                    }}
                    onLoad={firmLogoPicOnLoad}
                  />
                  <div
                    className="animated-slow"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "10px",
                      display: firmLogoLoading ? "inline-block" : "none",
                    }}
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12 mt-auto ms-xxl-4 ms-xl-5 ms-lg-1 ms-md-1 ms-sm-1 ms-xs-1 mb-auto">
                <div className="row">
                  <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-5 col-sm-6 col-12">
                    <span>
                      <div className="mt-xxl-0 mt-xl-2 mt-lg-1 mt-md-2 mt-sm-4 mt-2 d-flex justify-content-start restriction-text">
                        {fund
                          ? fund.registrationType === "3C7"
                            ? "Qualified Purchasers Only"
                            : "Accredited Investors"
                          : ""}
                      </div>
                    </span>
                    <span className="heading-4">
                      <div className="mt-xxl-0 mt-xl-2 mt-lg-1 mt-md-2 mt-sm-4 mt-2 d-flex justify-content-start">
                        {fund ? fund.firmName : ""}
                      </div>
                    </span>
                    <span>
                      <div className="mt-xxl-0 mt-xl-2 mt-lg-1 mt-md-2 mt-sm-4 mt-2 d-flex justify-content-start heading-6">
                        {fund ? fund.fundName : ""}
                      </div>
                    </span>
                  </div>
                  <div className="col-xxl-5 col-xl-4 col-lg-2 col-md-2 col-sm-3 col-12 mt-xxl-1 mt-xl-1 mt-lg-1 mt-md-3 mt-sm-4 mt-3 ms-md-5 ms-sm-2 d-flex justify-content-center">
                    <div className="row g-0 w-100 mt-3 mb-3 align-items-center">
                      <div className="col-12 d-flex">
                        <div className={stageBoxDiv}>
                          <div className={stageTextDiv}>{stage}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-4 col-10 mt-xxl-auto mt-xl-auto mt-lg-auto mt-3 mb-xxl-auto mb-xl-auto mb-lg-auto mt-md-5 mb-sm-1 mb-1 ms-lg-3 ms-sm-auto me-sm-auto ms-auto me-auto">
                <div className="d-flex justify-content-end">
                  {stage === "Fundraising" &&
                  user &&
                  ["Advisor", "Master", "Relationship Manager", "Self-Serve", "Co-Advisor"].includes(
                    user.userRole
                  ) ? (
                    <React.Fragment>
                      <div>
                        <MuiButton
                          buttonText={"Invest"}
                          onClick={() =>
                            navigate(
                              "/fund/" + fund.link + "/confirm-investment"
                            )
                          }
                          size={"large"}
                        />
                      </div>
                    </React.Fragment>
                  ) : null}
                  {stage === "Upcoming" &&
                  user &&
                  user.userRole === "Advisor" &&
                  fund.enableRequestAllocation === true ? (
                    <React.Fragment>
                      <div>
                        <MuiButton
                          buttonText={"Request Allocation"}
                          onClick={() =>
                            navigate(
                              "/fund/" + fund.link + "/request-allocation"
                            )
                          }
                          size={"large"}
                        />
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className="row g-0 d-flex justify-content-center"
              ref={targetPerformanceRowRef}
            >
              <div className="col ms-1 pt-2 right-border pb-2 d-flex">
                <div className="row g-0">
                  <div className="col-12 ms-3 align-self-start detail-section-title-text pt-2 pb-3">
                    Target IRR
                  </div>
                  <div className="col-12 ms-3 align-self-end body-text-lg-semibold pb-2">
                    {fund ? fund.targetIRR : "~"}%
                  </div>
                </div>
              </div>
              <div className="col ms-1 pt-2 right-border pb-2 d-flex">
                <div className="row g-0">
                  <div className="col-12 ms-3 align-self-start detail-section-title-text pt-2 pb-3">
                    Target Net Multiple
                  </div>
                  <div className="col-12 ms-3 align-self-end body-text-lg-semibold pb-2">
                    {fund ? fund.targetReturnMultiple : "-"}x
                  </div>
                </div>
              </div>
              <div className="col ms-1 pt-2 right-border pb-2 d-flex">
                <div className="row g-0">
                  <div className="col-12 ms-3 align-self-start detail-section-title-text pt-2 pb-3">
                    Min. Investment
                  </div>
                  <div className="col-12 ms-3 align-self-end body-text-lg-semibold pb-2">
                    {fund
                      ? "$ " +
                        parseInt(fund.minimumInvestment).toLocaleString("en-US")
                      : "~"}
                  </div>
                </div>
              </div>
              <div className="col ms-1 pt-2 right-border pb-2 d-flex">
                <div className="row g-0">
                  <div className="col-12 ms-3 align-self-start detail-section-title-text pt-2 pb-3">
                    Fund Size
                  </div>
                  <div className="col-12 ms-3 align-self-end body-text-lg-semibold pb-2">
                    $ {fund ? numFormatter(fund.fundSize) : "-"}
                  </div>
                </div>
              </div>
              <div className="col ms-1 pt-2 pb-2 d-flex">
                <div className="row g-0">
                  <div className="col-12 ms-3 align-self-start detail-section-title-text pt-2 pb-3">
                    Strategy
                  </div>
                  <div className="col-12 ms-3 align-self-end body-text-lg-semibold pb-2">
                    {fund
                      ? fund.fundStrategy.type === "STRUCTURED_CREDIT"
                        ? "Private Credit"
                        : fund.fundStrategy.type === "CLO_MANAGEMENT"
                        ? "CLO Management"
                        : fund.fundStrategy.type === "DIRECT_LANDING"
                        ? "Direct Landing"
                        : fund.fundStrategy.type === "PRIVATE_EQUITY"
                        ? "Private Equity"
                        : fund.fundStrategy.type === "CO_INVESTMENT"
                        ? "Co-Investment"
                        : fund.fundStrategy.type === "FUND_OF_FUNDS"
                        ? "Fund of Funds"
                        : fund.fundStrategy.type === "BUYOUT"
                        ? "Buyout"
                        : fund.fundStrategy.type === "GROWTH"
                        ? "Growth"
                        : fund.fundStrategy.type === "CREDIT"
                        ? "Credit"
                        : fund.fundStrategy.type === "VENTURE_CAPITAL"
                        ? "Venture Capital"
                        : ""
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {fund &&
            Math.round(
              ((fund.amountInvested / fund.allocationSize) * 100 +
                Number.EPSILON) *
                100
            ) /
              100 >=
              25 && (
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-5 ms-sm-auto me-sm-auto d-flex ps-sm-0 align-items-center justify-content-center">
                <div className="row g-0 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-3 mb-xxl-0 mb-xl-0 mb-lg-2 mb-md-0 mb-2 justify-content-center">
                  <div className="col-11 align-items-center">
                    <div className="row g-0 w-100">
                      <div className="align-self-start justify-self-center pb-0 mb-0">
                        <ProgressBar
                          progress={
                            fund
                              ? Math.round(
                                  ((fund.amountInvested / fund.allocationSize) *
                                    100 +
                                    Number.EPSILON) *
                                    100
                                ) / 100
                              : 0
                          }
                          size={115}
                          strokeWidth={5}
                          circleOneStroke={
                            fund
                              ? Math.round(
                                  (((fund.amountInvested /
                                    fund.allocationSize) *
                                    100 +
                                    Number.EPSILON) *
                                    100) /
                                    100
                                ) >= 75
                                ? "rgb(221, 36, 71, 0.1)"
                                : "rgba(13, 71, 161, 0.1)"
                              : "rgba(13, 71, 161, 0.1)"
                          }
                          circleTwoStroke={
                            fund
                              ? Math.round(
                                  (((fund.amountInvested /
                                    fund.allocationSize) *
                                    100 +
                                    Number.EPSILON) *
                                    100) /
                                    100
                                ) >= 75
                                ? secondaryRed
                                : primaryBlue
                              : primaryBlue
                          }
                        />
                      </div>
                      <div className="align-self-start text-center">
                        <span className="heading-6">Committed</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Header;
