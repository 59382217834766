import React from "react";

const LeftArrow = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
export default LeftArrow;
