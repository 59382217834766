import React, { useContext } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../Contexts/UserContext";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { user } = useContext(UserContext);

  return user !== null && user.isAuthenticated && user.userRole !== "Vendor"  ? (
    children
  ) : (
    <Navigate to={`/login?redirect=${pathname}${search}`} />
  );
}
