import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../Contexts/UserContext";
import "./Styles/Styles.scss";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton";
import { GetFirmFundRestrictionAction } from "../../../../Adapters/FirmFundRestriction";
import { GetFirmFundRestrictions } from "../../../../Adapters/FirmFundRestriction";
import Spinner1 from "../../../../Components/Spinners/Spinner1";
import { updateFirmRestrictionAction } from "../../../../APIs/FirmFundRestrictionsAPI";
import FirmRestrictionsTable from "./FirmRestrictionsTable/FirmRestrictionsTable";
import DynamicDropdown from "../../../../Components/DynamicDropdown/DynamicDropdown";
import { createFirmRestrictionAction } from "../../../../APIs/FirmFundRestrictionsAPI";
import { getFundList } from "../../../../APIs/FirmFundRestrictionsAPI";
import SnackBar from "../../../../Components/Alerts/SnackBar/SnackBar";

function FirmRestrictions() {
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [
    firmFundRestrictionActionLoading,
    setFirmFundRestrictionActionLoading,
  ] = useState(true);
  const [
    firmFundRestrictionActionRefresh,
    setFirmFundRestrictionActionRefresh,
  ] = useState(1);
  const { firmFundRestrictionAction } = GetFirmFundRestrictionAction(
    setFirmFundRestrictionActionLoading,
    firmFundRestrictionActionRefresh
  );
  const [restrictionAction, setRestrictionAction] = useState("Exclude");

  const [firmFundRestrictionsLoading, setFirmFundRestrictionsLoading] =
    useState(true);
  const [firmFundRestrictionsRefresh, setFirmFundRestrictionsRefresh] =
    useState(0);
  const { firmFundRestrictions } = GetFirmFundRestrictions(
    setFirmFundRestrictionsLoading,
    firmFundRestrictionsRefresh
  );

  const [editRestrictionAction, setEditRestrictionAction] = useState(false);
  const [restrictionActionNotShown, setRestrictionActionNotShown] =
    useState(true);
  const [restrictionActionShown, setRestrictionActionShown] = useState(false);

  const [
    submitFirmFundRestrictionLoading,
    setSubmitFirmFundRestrictionLoading,
  ] = useState(false);

  const [showAddFirmRestriction, setShowAddFirmRestriction] = useState(false);

  const [
    createFirmFundRestrictionLoading,
    setCreateFirmFundRestrictionLoading,
  ] = useState(false);

  const [restrictionExistsError, setRestrictionExistsError] = useState(false);
  const [createRestrictionError, setCreateRestrictionError] = useState(false);
  const [fundIdError, setFundIdError] = useState(false);

  const [fundId, setFundId] = useState("");

  useEffect(() => {
    if (firmFundRestrictionAction === "Exclude") {
      setRestrictionActionNotShown(true);
    } else if (firmFundRestrictionAction === "Include") {
      setRestrictionActionShown(true);
    }
  }, [firmFundRestrictionAction]);

  useEffect(() => {
    if (restrictionActionShown === true) {
      setRestrictionActionNotShown(false);
    }
  }, [restrictionActionShown]);

  useEffect(() => {
    if (restrictionActionNotShown === true) {
      setRestrictionActionShown(false);
    }
  }, [restrictionActionNotShown]);

  const submitFirmRestrictionActionUpdate = async () => {
    setSubmitFirmFundRestrictionLoading(true);
    var updatedRestrictionAction = "";

    if (restrictionActionNotShown === true) {
      updatedRestrictionAction = "Exclude";
    } else if (restrictionActionShown === true) {
      updatedRestrictionAction = "Include";
    }

    let payloadObject = {
      firm_id: user.firmInfo.id,
      restriction_action: updatedRestrictionAction,
    };

    try {
      await updateFirmRestrictionAction(payloadObject);
      setEditRestrictionAction(false);
      setFirmFundRestrictionActionRefresh((curState) => curState + 1);
    } catch (err) {
      {}; 
    }
    setSubmitFirmFundRestrictionLoading(false);
  };

  const createFirmFundRestriction = async () => {
    setCreateFirmFundRestrictionLoading(true);

    let payloadObject = {
      firm_id: user.firmInfo.id,
      fund_id: fundId,
    };

    if (fundId === "") {
      setFundIdError(true);
    } else {
      try {
        let requestResponse = await createFirmRestrictionAction(payloadObject);
        if (
          requestResponse.data !== null &&
          requestResponse.data !== undefined &&
          requestResponse.data.details !== null &&
          requestResponse.data.details !== undefined &&
          requestResponse.data.details === "OK"
        ) {
          setShowAddFirmRestriction(false);
          setFirmFundRestrictionsRefresh((prevState) => prevState + 1);
        } else if (
          requestResponse.data !== null &&
          requestResponse.data !== undefined &&
          requestResponse.data.details !== null &&
          requestResponse.data.details !== undefined &&
          requestResponse.data.details ===
            "This firm fund restriction already exists."
        ) {
          setRestrictionExistsError(true);
        } else {
          setCreateRestrictionError(true);
        }
      } catch (err) {
        setCreateRestrictionError(true);
      }
    }

    setCreateFirmFundRestrictionLoading(false);
  };

  return (
    <div className="container-xxl p-4">
      <div className="row g-0 w-100 mb-2">
        <h5>Restrictions</h5>
      </div>
      {editRestrictionAction === false && (
        <React.Fragment>
          {firmFundRestrictionActionLoading === false ? (
            <React.Fragment>
              <div className="row g-0 w-100 mb-2">
                <div className="d-flex">
                  <span className="body-text-sm-semibold me-2">
                    Restriction Action:
                  </span>
                  <span className="body-text-sm-regular">
                    {firmFundRestrictionAction}
                  </span>
                </div>
              </div>
              {firmFundRestrictionsLoading === false &&
                firmFundRestrictions.length === 0 && (
                  <div className="row g-0 w-100 mb-2">
                    <div className="col-6">
                      <MuiButton
                        buttonText={"Edit"}
                        onClick={() => {
                          setEditRestrictionAction(true);
                        }}
                        size={"small"}
                      />
                    </div>
                  </div>
                )}
            </React.Fragment>
          ) : (
            <div className="row g-0 w-100 mb-2 justify-content-center">
              <Spinner1 />
            </div>
          )}
        </React.Fragment>
      )}
      {editRestrictionAction === true && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-2">
            <span className="body-text-sm-semibold mb-1">
              {
                "Would you like to specify restrictions that indicate funds that should be shown to users or funds that should not be shown to users?"
              }
            </span>
            <span className="body-text-xs-regular">
              {
                "*Default is to specify restrictions that exclude funds from viewing"
              }
            </span>
          </div>
          <div className="row g-0 w-100 mb-2 fade-in-test">
            <div className="col-6">
              <label
                for={"restrictionActionNotShownRadioId"}
                className={"radio"}
              >
                <input
                  type={"radio"}
                  name={"restrictionActionNotShownRadio"}
                  id={"restrictionActionNotShownRadioId"}
                  className={"radio__input"}
                  checked={restrictionActionNotShown}
                  onClick={() => {
                    setRestrictionActionNotShown(true);
                  }}
                />
                <div className="radio__radio"></div>
                <span className="qualification-question-text ms-2">
                  Not Shown
                </span>
              </label>
            </div>
            <div className="col-6">
              <label for={"restrictionActionShownRadioId"} className={"radio"}>
                <input
                  type={"radio"}
                  name={"restrictionActionShownRadio"}
                  id={"restrictionActionShownRadioId"}
                  className={"radio__input"}
                  checked={restrictionActionShown}
                  onClick={() => {
                    setRestrictionActionShown(true);
                  }}
                />
                <div className="radio__radio"></div>
                <span className="qualification-question-text ms-2">Shown</span>
              </label>
            </div>
          </div>
          <div className="row g-0 w-100 mb-2">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <MuiButton
                  buttonText={"Submit"}
                  onClick={() => {
                    submitFirmRestrictionActionUpdate();
                  }}
                  size={"small"}
                  loading={submitFirmFundRestrictionLoading}
                />
              </div>
              <span
                className="cancel-text"
                onClick={() => {
                  setEditRestrictionAction(false);
                  setRestrictionActionNotShown(
                    firmFundRestrictionAction === "Exclude"
                  );
                  setRestrictionActionShown(
                    firmFundRestrictionAction === "Include"
                  );
                }}
              >
                <u>Cancel</u>
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
      <div className="row g-0 w-100 mt-5 mb-2">
        <FirmRestrictionsTable
          firmFundRestrictions={firmFundRestrictions}
          firmFundRestrictionsLoading={firmFundRestrictionsLoading}
        />
      </div>
      {showAddFirmRestriction === false && (
        <div className="row g-0 w-100 mb-2">
          <div className="d-flex">
            <MuiButton
              buttonText={"Add Firm Restriction"}
              onClick={() => {
                setShowAddFirmRestriction(true);
              }}
              size={"small"}
            />
          </div>
        </div>
      )}
      {showAddFirmRestriction === true && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-2">
            <span className="body-text-sm-semibold mb-1">
              {
                "Please select a fund from the dropdown that you would like to add a restriction for."
              }
            </span>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-6">
              <DynamicDropdown
                id="dynamic-select"
                value={fundId}
                setValue={setFundId}
                label={"Fund"}
                retrievalFunction={getFundList}
              />
            </div>
          </div>
          <div className="row g-0 w-100">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <MuiButton
                  buttonText={"Submit"}
                  onClick={() => {
                    createFirmFundRestriction();
                  }}
                  size={"small"}
                  loading={createFirmFundRestrictionLoading}
                />
              </div>
              <span
                className="cancel-text"
                onClick={() => {
                  setShowAddFirmRestriction(false);
                  setFundId("");
                }}
              >
                <u>Cancel</u>
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
      <SnackBar
        snackbarState={restrictionExistsError}
        setSnackbarState={setRestrictionExistsError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"warning"}
        message={"This restriction already exists."}
      />
      <SnackBar
        snackbarState={createRestrictionError}
        setSnackbarState={setCreateRestrictionError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Error creating restriction."}
      />
      <SnackBar
        snackbarState={fundIdError}
        setSnackbarState={setFundIdError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Fund needs to be selected to create a restriction."}
      />
    </div>
  );
}
export default FirmRestrictions;
