import React, { useCallback } from "react";
import "./Styles/Styles.scss";

const TabTitle = ({
  title,
  setSelectedTab,
  index,
  setSelected,
  selected,
  disableClick,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <div
      className="row gx-0 w-100 h-100 align-items-center justify-content-center"
      onClick={() => setSelected(true)}
    >
      <button
        className={
          "body-text-md-medium align-self-center align-text-center pb-md-3 pb-2 mt-md-0 mt-2"
        }
        onClick={onClick}
        disabled={disableClick}
      >
        {title}
      </button>
      <div
        className={
          selected
            ? "active-indicator align-self-end w-75"
            : "inactive-indicator align-self-end w-75"
        }
      ></div>
    </div>
  );
};
export default TabTitle;
