import React, { useState, useEffect } from "react";
import StyledDateInputField from "../StyledDateInput/StyledDateInput.js";
import TextInputField from "../InputFields/TextInputField/TextInputField";
import PhoneInputField from "../InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "../InputFields/SelectInputField/SelectInputField";
import InfiniteInputComponent from "../InfiniteInputComponent/InfiniteInputComponent.js";
import InfiniteTabInput from "../InfiniteInputComponent/InfiniteTabInput.js";
import TrusteeQuestion from "./TrusteeQuestion.js";
import { countryList } from "../../utilities/lists/countryList";
import MuiButton from "./../Buttons/MuiButton/MuiButton";
import { isEmailValid } from "./../../utilities/functions/functions.js";

import { formatSSN } from "./../../utilities/functions/functions.js";
import { stateList } from "./../stateList";
import { useNavigate } from "react-router-dom";

function GrantorInformation({ setActiveSlide }) {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");
  const [hasTrusteesError, setHasTrusteesError] = useState("");


  const [relatedAccounts, setRelatedAccounts] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      addressType: "",
      deliverableAddressLine1: "",
      deliverableAddressLine2: "",
      deliverableCity: "",
      deliverableState: "",
      deliverableCountry: "",
      deliverablePostalCode: "",
      deliverableAddressType: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      idType: "",
      idNum: "",
      idNumError: "",
      domicileCountry: "",
    },
  ]);
  const [activeTab, setActiveTab] = useState("Trustee #1");

  const handleRemoveFields = (index) => {
    const nextIdx = index === 0 ? 0 : index - 1;
    setActiveTab("Trustee #" + (nextIdx + 1).toString());
    setRelatedAccounts((relatedAccounts) =>
      relatedAccounts.filter((elem, idx) => idx !== index)
    );
  };

  const submit =  () => {
    let values = [...relatedAccounts];
    let requestData = [];
    var errors = false;

    if (answer === "") {
      setHasTrusteesError("Please make a selection.");
      errors = true;
    }

    if (answer === true) {
      requestData = relatedAccounts.map((inputField, index) => {
        if (inputField.firstName === "") {
          values[index]["firstNameError"] = "This field is required.";
          errors = true;
        }
        if (inputField.lastName === "") {
          values[index]["lastNameError"] = "This field is required.";
          errors = true;
        }
        if (inputField.email === "") {
          values[index]["emailError"] = "This field is required.";
          errors = true;
        } else if (!isEmailValid(inputField.email)) {
          values[index]["emailError"] = "Please enter a valid email.";
          errors = true;
        }
        //Add email validation
        if (inputField.phoneNumber === "") {
          values[index]["phoneNumberError"] =
            "Please enter a valid phone number.";
        } else if (inputField.phoneNumber.length < 7) {
          values[index][inputField.phoneNumberError] =
            "Please enter a valid phone number";
          errors = true;
        }
        if (inputField.addressLine1 === "") {
          values[index]["addressLine1Error"] = "This field is required.";
          errors = true;
        }
        if (inputField.city === "") {
          values[index]["cityError"] = "This field is required.";
          errors = true;
        }
        if (inputField.country === "United States") {
          if (inputField.state === "") {
            values[index]["stateError"] = "Please enter a state from the list.";
            errors = true;
          }
        }
        if (inputField.country === "") {
          values[index]["countryError"] =
            "Please enter a country from the list.";
          errors = true;
        } 
        if (inputField.postalCode === "") {
          values[index]["postalCodeError"] = "This field is required.";
          errors = true;
        }

        if (inputField.domicileCountry === "") {
          values[index]["domicileCountryError"] =
            "Please enter a country from the list.";
          errors = true;
        }

        if (inputField.dateOfBirth === "") {
          values[index]["dateOfBirthError"] = "This field is required.";
          errors = true;
        } else {
          const date = inputField.dateOfBirth;
        }
        if (inputField.idNum === "") {
          values[index]["idNumError"] = "This field is required.";
          errors = true;
        } else if (
          inputField.domicileCountry === "United States" && false
        ) {
          errors = true;
          values[index]["idNumError"] = "Please enter a valid ssn.";
        }
        return {
          first_name: inputField.firstName,
          last_name: inputField.lastName,
          email: inputField.email,
          phone_number: inputField.phoneNumber,
          address_line_1: inputField.addressLine1,
          address_line_2: inputField.addressLine2,
          city: inputField.city,
          state:
            inputField.country === "United States"
              ? inputField.state.length !== 0
                ? inputField.state
                : ""
              : "",
          country: inputField.country,
          postal_code: inputField.postalCode,
          address_type: "RM",
          date_of_birth: inputField.dateOfBirth,
          id_type:
            inputField.domicileCountry === "United States" ? "SSN" : "PASS",
          id_number: inputField.idNum,
          domicile_country: inputField.domicileCountry,
          role: "TST",
          address2_line_1: "",
          address2_line_2: "",
          address2_city: "",
          address2_state: "",
          address2_country: "",
          address2_postal_code: "",
          address2_type: "",
        };
      });
    }

    if (grantorFirstName === "") {
      setGrantorFirstNameError("This field is required.");
      errors = true;
    }

    if (grantorLastName === "") {
      setGrantorLastNameError("This field is required.");
      errors = true;
    }

    if (grantorEmail === "") {
      setGrantorEmailError("This field is required.");
      errors = true;
    } else if (!isEmailValid(grantorEmail)) {
      setGrantorEmailError("Please enter a valid email.");
      errors = true;
    }

    if (grantorPhoneNumber === "") {
      setGrantorPhoneNumberError("Please enter a phone number.");
      errors = true;
    } else if (grantorPhoneNumber.length < 7) {
      setGrantorPhoneNumberError("Please enter a valid phone number");
      errors = true;
    }

    if (grantorDomicileCountry === "") {
      setGrantorDomicileCountryError(
        "Please enter a domicile country from the dropdown."
      );
      errors = true;
    }

    if (grantorAddressLine1 === "") {
      setGrantorAddressLine1Error("This field is required.");
      errors = true;
    }

    if (grantorCity === "") {
      setGrantorCityError("This field is required.");
      errors = true;
    }

    if (grantorCountry === "United States" && grantorState === "") {
      setGrantorStateError("Please enter a state from the dropdown.");
      errors = true;
    }

    if (grantorCountry === "") {
      setGrantorCountryError("Please enter a country from the dropdown.");
      errors = true;
    }

    if (grantorPostalCode === "") {
      setGrantorPostalCodeError("This field is required.");
      errors = true;
    }

    if (grantorDOB === "") {
      setGrantorDOBError("This field is required.");
      errors = true;
    } 

    if (grantorIDNumber === "") {
      setGrantorIDNumberError("This field is required.");
      errors = true;
    }

    if (
      grantorDomicileCountryError === "United States" 
    ) {
      setGrantorIDNumberError("Please enter a valid ssn.");
      errors = true;
    }

    // if (relatedAccounts.length >= 1) {
    if (errors) {
      setRelatedAccounts(values);
      return;
    } else {
        navigate("/clients");
        // Make sure that the active slide is progressed to the next page on success. Don't want the user to be able to adjust the information that they supplied.
        setActiveSlide((prevState) => prevState + 1);
      //}
    }
  };

  const addNewTab = () => {
    setRelatedAccounts([
      ...relatedAccounts,
      {
        firstName: "",
        firstNameError: "",
        lastName: "",
        lastNameError: "",
        email: "",
        emailError: "",
        phoneNumber: "",
        phoneNumberError: "",
        addressLine1: "",
        addressLine1Error: "",
        addressLine2: "",
        addressLine2Error: "",
        city: "",
        cityError: "",
        state: "",
        stateError: "",
        country: "",
        countryError: "",
        postalCode: "",
        postalCodeError: "",
        addressType: "",
        addressTypeError: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        domicileCountryError: "",
      },
    ]);
    setActiveTab("Trustee #" + (relatedAccounts.length + 1).toString());
  };

  const [grantorFirstName, setGrantorFirstName] = useState("");
  const [grantorFirstNameError, setGrantorFirstNameError] = useState("");
  const [grantorLastName, setGrantorLastName] = useState("");
  const [grantorLastNameError, setGrantorLastNameError] = useState("");
  const [grantorEmail, setGrantorEmail] = useState("");
  const [grantorEmailError, setGrantorEmailError] = useState("");
  const [grantorPhoneNumber, setGrantorPhoneNumber] = useState("");
  const [grantorPhoneNumberError, setGrantorPhoneNumberError] = useState("");
  const [grantorDomicileCountry, setGrantorDomicileCountry] = useState("");
  const [grantorDomicileCountryError, setGrantorDomicileCountryError] =
    useState("");
  const [grantorAddressLine1, setGrantorAddressLine1] = useState("");
  const [grantorAddressLine1Error, setGrantorAddressLine1Error] = useState("");
  const [grantorAddressLine2, setGrantorAddressLine2] = useState("");
  const [grantorAddressLine2Error, setGrantorAddressLine2Error] = useState("");
  const [grantorState, setGrantorState] = useState("");
  const [grantorStateError, setGrantorStateError] = useState("");
  const [grantorCity, setGrantorCity] = useState("");
  const [grantorCityError, setGrantorCityError] = useState("");
  const [grantorCountry, setGrantorCountry] = useState("");
  const [grantorCountryError, setGrantorCountryError] = useState("");
  const [grantorPostalCode, setGrantorPostalCode] = useState("");
  const [grantorPostalCodeError, setGrantorPostalCodeError] = useState("");
  const [grantorDOB, setGrantorDOB] = useState("");
  const [grantorDOBError, setGrantorDOBError] = useState("");
  const [grantorIDNumber, setGrantorIDNumber] = useState("");
  const [grantorIDNumberError, setGrantorIDNumberError] = useState("");



  useEffect(() => {
    setGrantorFirstNameError("");
  }, [grantorFirstName]);

  useEffect(() => {
    setGrantorLastNameError("");
  }, [grantorLastName]);

  useEffect(() => {
    setGrantorEmailError("");
  }, [grantorEmail]);

  useEffect(() => {
    setGrantorPhoneNumberError("");
  }, [grantorPhoneNumber]);

  useEffect(() => {
    setGrantorDomicileCountryError("");
  }, [grantorDomicileCountry]);

  useEffect(() => {
    setGrantorIDNumberError("");
  }, [grantorIDNumber]);

  useEffect(() => {
    setGrantorAddressLine1Error("");
  }, [grantorAddressLine1]);

  useEffect(() => {
    setGrantorAddressLine2Error("");
  }, [grantorAddressLine2]);

  useEffect(() => {
    setGrantorCityError("");
  }, [grantorCity]);

  useEffect(() => {
    setGrantorStateError("");
  }, [grantorState]);

  useEffect(() => {
    setGrantorCountryError("");
  }, [grantorCountry]);

  useEffect(() => {
    setGrantorPostalCodeError("");
  }, [grantorPostalCode]);

  useEffect(() => setHasTrusteesError(""), [answer]);

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">Grantor Information</span>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={grantorFirstName}
            setValue={setGrantorFirstName}
            valueError={grantorFirstNameError}
            setValueError={setGrantorFirstNameError}
            label={"First Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-first-name-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={grantorLastName}
            setValue={setGrantorLastName}
            valueError={grantorLastNameError}
            setValueError={setGrantorLastNameError}
            label={"Last Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-last-name-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={grantorEmail}
            setValue={setGrantorEmail}
            valueError={grantorEmailError}
            setValueError={setGrantorEmailError}
            label={"Email"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-email-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <PhoneInputField
            shrink={true}
            label={"Phone Number"}
            size={"small"}
            fullWidth={true}
            required={true}
            value={grantorPhoneNumber}
            setValue={setGrantorPhoneNumber}
            valueError={grantorPhoneNumberError}
            setValueError={setGrantorPhoneNumberError}
            disabled={false}
          />
          {grantorPhoneNumberError && (
            <span
              className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
            >
              {grantorPhoneNumberError}
            </span>
          )}
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-0">
          <SelectInputField
            value={grantorDomicileCountry}
            setValue={setGrantorDomicileCountry}
            valueError={grantorDomicileCountryError}
            setValueError={setGrantorDomicileCountryError}
            label={"Domicile Country"}
            selectOptions={countryList}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-domicile-country-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">Mailing Address</span>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={grantorAddressLine1}
            setValue={setGrantorAddressLine1}
            valueError={grantorAddressLine1Error}
            setValueError={setGrantorAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-address-line-1-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={grantorAddressLine2}
            setValue={setGrantorAddressLine2}
            valueError={grantorAddressLine2Error}
            setValueError={setGrantorAddressLine2Error}
            label={"Address Line 2"}
            required={false}
            fullWidth={true}
            size={"small"}
            id={"grantor-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div
          className={
            grantorCountry === "United States" ? "col pe-2" : "col pe-0"
          }
        >
          <TextInputField
            value={grantorCity}
            setValue={setGrantorCity}
            valueError={grantorCityError}
            setValueError={setGrantorCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-city-input"}
          />
        </div>
        {grantorCountry === "United States" && (
          <div className="col ps-2 pe-0">
            <SelectInputField
              value={grantorState}
              setValue={setGrantorState}
              valueError={grantorStateError}
              setValueError={setGrantorStateError}
              selectOptions={stateList}
              label={"State"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"grantor-state-input-2"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectInputField
            value={grantorCountry}
            setValue={setGrantorCountry}
            valueError={grantorCountryError}
            setValueError={setGrantorCountryError}
            label={"Country"}
            selectOptions={countryList}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-address-country-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={grantorPostalCode}
            setValue={setGrantorPostalCode}
            valueError={grantorPostalCodeError}
            setValueError={setGrantorPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-postal-code-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">
          Grantor Personal Information
        </span>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <StyledDateInputField
            value={grantorDOB}
            setValue={setGrantorDOB}
            valueError={grantorDOBError}
            setValueError={setGrantorDOBError}
            label={"Date Of Birth"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"grantor-dob-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          {grantorDomicileCountry === "United States" && (
            <TextInputField
              value={grantorIDNumber}
              setValue={setGrantorIDNumber}
              valueError={grantorIDNumberError}
              setValueError={setGrantorIDNumberError}
              label={"SSN"}
              required={true}
              fullWidth={true}
              size={"small"}
              mask={{
                maskFormatter: formatSSN,
                maskPattern: "999-99-9999",
              }}
              id={"grantor-ssn-input"}
            />
          )}
          {grantorDomicileCountry !== "United States" && (
            <TextInputField
              value={grantorIDNumber}
              setValue={setGrantorIDNumber}
              valueError={grantorIDNumberError}
              setValueError={setGrantorIDNumberError}
              label={"Passport Number"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"grantor-passport-input"}
            />
          )}
        </div>
      </div>
      <div className="row g-0 w-100 mb-1">
        <span className="add-account-step-subheader">Trustee Information</span>
      </div>
      <TrusteeQuestion
        questionNumber={1}
        questionText={"Excluding yourself, does this trust have any trustees?"}
        answer={answer}
        setAnswer={setAnswer}
        hasTrusteesError={hasTrusteesError}
      />
      {answer === true && (
        <div className={"row g-0 w-100 fade-in-test"}>
          <InfiniteTabInput
            tabs={relatedAccounts.map(
              (elem, idx) => "Trustee #" + (idx + 1).toString()
            )}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            addNewTab={addNewTab}
            addMore
          />
          <InfiniteInputComponent
            accountID={""}
            activeTab={activeTab}
            processedCountryList={countryList}
            processedStateList={stateList}
            inputFields={relatedAccounts}
            setInputFields={setRelatedAccounts}
            handleRemoveFields={handleRemoveFields}
            index={activeTab.match(/\d+/) - 1}
            setActiveTab={setActiveTab}
            setActiveSlide={setActiveSlide}
            role={"Trustee"}
            hideButton={"hideButton"}
            setHideButton={() => {}}
            hideAlterButtons={""}
            setHideAlterButtons={() => {}}
          />
        </div>
      )}
      <div className="d-flex">
        <MuiButton
          buttonText={"Next Step"}
          onClick={submit}
          size={"large"}
        />
      </div>
    </React.Fragment>
  );
}
export default GrantorInformation;
