import React, { useState, useEffect, useContext } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../Contexts/UserContext.js";
import {
  isEmailValid,
  isPasswordValid,
} from "../../../utilities/functions/functions.js";
import { Auth } from "aws-amplify";
// import {
//   getAccountList,
//   getInvestorSummary,
//   updateIdentityId,
//   getInvestorProfile,
// } from "../../../Api/ProfileApi.js";
import {
  getAccountList,
  getInvestorSummary,
  updateIdentityId,
  getInvestorProfile,
} from "../../../APIs/ProfileAPI.js";
import CodeVerification from "../Signup/SignupSteps/CodeVerification/CodeVerification";
import MuiButton from "../../../Components/Buttons/MuiButton/MuiButton.js";
import PasswordInputField from "../../../Components/InputFields/PasswordInputField/PasswordInputField.js";
import TextInputField from "../../../Components/InputFields/TextInputField/TextInputField.js";
import SnackBar from "../../../Components/Alerts/SnackBar/SnackBar.js";
import "./Styles/Styles.scss";
import { getFirmUser } from "../../../APIs/WealthManagementAPI.js";
import { getSignedURL } from "../../../utilities/functions/functions.js";

function ForgotPassword(props) {
  //   let history = useHistory();
  let navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  const [pwdReset, setPwdReset] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const [newPwd, setNewPwd] = useState("");
  const [confirmNewPwd, setConfirmNewPwd] = useState("");
  const [confirmNewPwdError, setConfirmNewPwdError] = useState("");
  const [newPwdError, setNewPwdError] = useState("");

  const [loading, setLoading] = useState(false);
  const [cogUser, setCogUser] = useState(null);
  const { user, setUser } = useContext(UserContext);

  const [accountCreationSuccessful, setAccountCreationSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.accountCreationSuccessful !== null &&
      location.state.accountCreationSuccessful !== undefined
      ? location.state.accountCreationSuccessful
      : false
  );

  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      (location.state !== null) & (location.state !== undefined) &&
      location.state.passwordResetSuccessful !== null &&
      location.state.passwordResetSuccessful !== undefined
      ? location.state.passwordResetSuccessful
      : false
  );

  const [emailConfirmationSuccessful, setEmailConfirmationSuccessful] =
    useState(
      location !== null &&
        location !== undefined &&
        location.state !== null &&
        location.state !== undefined &&
        location.state.emailConfirmationSuccessful !== null &&
        location.state.emailConfirmationSuccessful !== undefined
        ? location.state.emailConfirmationSuccessful
        : false
    );

  const [errorSnackBarState, setErrorSnackbarState] = useState(false);

  const redirect = "";

  useEffect(() => {
    setLoginError("");
  }, [email, password]);

  useEffect(() => {}, [redirect]);

  const nextStep = async () => {
    var resetPasswordErrorDetected = false;

    if (newPwd.length === 0) {
      setPasswordError("This field is required.");
      resetPasswordErrorDetected = true;
    } else if (!isPasswordValid(newPwd)) {
      setPasswordError(
        "Your password must be longer than 10 characters and contain at least one number, an uppercase, a lowercase, and a special character."
      );
      resetPasswordErrorDetected = true;
    }
    if (confirmNewPwd.length === 0) {
      setConfirmNewPwdError("This field is required.");
      resetPasswordErrorDetected = true;
    } else if (newPwd !== confirmNewPwd) {
      setConfirmNewPwdError("Your confirm password does not match.");
      resetPasswordErrorDetected = true;
    }

    if (resetPasswordErrorDetected) {
      return;
    } else {
      setPwdReset(false);
      setShowCode(true);
    }
  };

  const respondAuthChallenge = () => {
    Auth.completeNewPassword(cogUser, newPwd, []).then((user) => {
    });
  };

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  useEffect(() => {
    setConfirmNewPwdError("");
  }, [confirmNewPwd]);

  useEffect(() => {
    setNewPwdError("");
  }, [newPwd]);

  const sendCode = async () => {
    var errorsDetected = false;

    if (email.length === 0) {
      setEmailError("This field is required.");
      errorsDetected = true;
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email.");
      errorsDetected = true;
    }

    if (errorsDetected) {
      return;
    } else {
      try {
        await Auth.forgotPassword(email);
        setPwdReset(true);
      } catch (err) {
        //setSendCodeError("There was an error sending code. Please try again.");
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        sendCode();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  return (
    <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-hidden cover-background align-items-center justify-content-center d-flex">
      <div className="row g-0 w-100 align-items-center justify-content-center">
        <div className="col-6 h-100 d-none d-sm-none d-md-none d-lg-block cover">
          <img
            src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
            style={{
              height: "100vh",
              backgroundSize: "cover !important",
              zIndex: 2,
            }}
            alt="community"
          ></img>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-10 mt-auto mb-auto align-items-center foreground-content">
          <div className="container-fluid g-0 ms-5 pe-3 h-100 align-items-center mt-auto mb-auto pt-auto pb-auto">
            <div className="row g-0 w-100 align-items-center h-100 mt-auto mb-auto pt-auto pb-auto">
              <div className="col-12 align-items-center mt-auto mb-auto pt-auto pb-auto">
                {!pwdReset && !showCode &&  (
                  <>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <h3>Forgot password?</h3>
                      </div>
                    </div>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <TextInputField
                          value={email}
                          setValue={setEmail}
                          valueError={emailError}
                          setValueError={setEmailError}
                          label={"Email"}
                          required={true}
                          fullWidth={true}
                          shrink={true}
                          size={"small"}
                          id={"email-input"}
                        />
                      </div>
                    </div>
                    <div className="row g-0 w-100">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <div className="row g-0 w-100">
                          <div className="col ms-0 me-auto">
                            <div className="d-flex">
                              <MuiButton
                                buttonText={"Send Code"}
                                onClick={sendCode}
                                size={"large"}
                                loading={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {pwdReset && (
                  <>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <span className="sized-down-enter-text">
                          Reset password here
                        </span>
                      </div>
                    </div>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <PasswordInputField
                          value={newPwd}
                          setValue={setNewPwd}
                          valueError={newPwdError}
                          setValueError={setNewPwdError}
                          label={"Password"}
                          required={true}
                          fullWidth={true}
                          shrink={true}
                          size={"small"}
                          id={"password-input"}
                        />
                      </div>
                    </div>
                    <div className="row g-0 w-100 mb-2">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <PasswordInputField
                          value={confirmNewPwd}
                          setValue={setConfirmNewPwd}
                          valueError={confirmNewPwdError}
                          setValueError={setConfirmNewPwdError}
                          label={"Confirm Password"}
                          required={true}
                          fullWidth={true}
                          shrink={true}
                          size={"small"}
                          id={"password-input"}
                        />
                      </div>
                    </div>{" "}
                    <div className="row g-0 w-100">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <div className="row g-0 w-100">
                          <div className="col ms-0 me-auto">
                            <div className="d-flex">
                              <MuiButton
                                buttonText={"Next step"}
                                onClick={nextStep}
                                size={"large"}
                                loading={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {showCode && (
                  <CodeVerification
                    reset={true}
                    password={newPwd}
                    email={email}
                  />
                )}

                <SnackBar
                  snackbarState={emailConfirmationSuccessful}
                  setSnackbarState={setEmailConfirmationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully confirmed. You may now log in."
                  }
                />
                <SnackBar
                  snackbarState={errorSnackBarState}
                  setSnackbarState={setErrorSnackbarState}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={10000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={
                    "Unfortunately, you will be unable to login because you have not confirmed your email. We tried resending a confirmation code to " +
                    email +
                    ", but we were unable to. Please confirm that you have signed up using a valid email that you still have access to."
                  }
                />
                <SnackBar
                  snackbarState={loginError}
                  setSnackbarState={setLoginError}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={"Sorry, there was an error logging in."}
                />
                <SnackBar
                  snackbarState={passwordResetSuccessful}
                  setSnackbarState={setPasswordResetSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={"Password reset successfully."}
                />
                <SnackBar
                  snackbarState={accountCreationSuccessful}
                  setSnackbarState={setAccountCreationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully created. You may now log in."
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
