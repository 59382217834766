import React from "react";
import "./Styles/Styles.scss";
import SignupSteps from "./SignupSteps/SignupSteps";
import { useParams } from "react-router-dom";

const Signup = ({ feeder }) => {
  const { code } = useParams();
  return (
    <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-hidden cover-background align-items-center justify-content-center d-flex">
      <div className="row g-0 w-100">
        <div className="col-6 h-100 d-none d-sm-none d-md-none d-lg-block cover">
          <img
            src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
            style={{
              height: "100vh",
              backgroundSize: "cover !important",
              zIndex: 2,
            }}
            alt="community"
          ></img>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-10 foreground-content">
          <SignupSteps feeder={feeder} code={code} />
          {/* <div className="container-fluid g-0 ms-5 pe-3 h-100 align-items-center mt-auto mb-auto pt-auto pb-auto">
            <div className="row g-0 w-100 align-items-center h-100 mt-auto mb-auto pt-auto pb-auto">
              <div className="col-12 align-items-center mt-auto mb-auto pt-auto pb-auto">
                <div className="row g-0 w-100 mb-2 align-self-center">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <h4>Welcome to Aqua</h4>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-4">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <span className="sized-down-enter-text">
                      Please sign in to use the platform
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-4">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <TextInputField
                      value={email}
                      setValue={setEmail}
                      valueError={emailError}
                      setValueError={setEmailError}
                      label={"Email"}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"email-input"}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100 mb-2">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <PasswordInputField
                      value={password}
                      setValue={setPassword}
                      valueError={passwordError}
                      setValueError={setPasswordError}
                      label={"Password"}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"password-input"}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100 mb-3">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <div className="row g-0 w-100">
                      <div className="align-self-end text-end">
                        <span
                          className="forgot-password"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/forgot-password");
                          }}
                        >
                          <u>Forgot Password?</u>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0 w-100">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <div className="row g-0 w-100">
                      <div className="col ms-0 me-auto">
                        <div className="d-flex">
                          <MuiButton
                            buttonText={"Sign In"}
                            onClick={() => signIn()}
                            size={"large"}
                            loading={loading}
                          />
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SnackBar
                  snackbarState={emailConfirmationSuccessful}
                  setSnackbarState={setEmailConfirmationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully confirmed. You may now log in."
                  }
                />
                <SnackBar
                  snackbarState={errorSnackBarState}
                  setSnackbarState={setErrorSnackbarState}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={10000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={
                    "Unfortunately, you will be unable to login because you have not confirmed your email. We tried resending a confirmation code to " +
                    email +
                    ", but we were unable to. Please confirm that you have signed up using a valid email that you still have access to."
                  }
                />
                <SnackBar
                  snackbarState={loginError}
                  setSnackbarState={setLoginError}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={"Sorry, there was an error logging in."}
                />
                <SnackBar
                  snackbarState={passwordResetSuccessful}
                  setSnackbarState={setPasswordResetSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={"Password reset successfully."}
                />
                <SnackBar
                  snackbarState={accountCreationSuccessful}
                  setSnackbarState={setAccountCreationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully created. You may now log in."
                  }
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Signup;
