import React, { useEffect, useState } from "react";
import "./Styles.scss";

function StageSection({ stage }) {
  const [stageBoxDiv, setStageBoxDiv] = useState("col-12 pt-0 pb-0 rounded-bottom");
  const [stageTextDiv, setStageTextDiv] = useState("col-12 stage-text");

  useEffect(() => {
    if (stage === "Upcoming") {
      setStageBoxDiv(stageBoxDiv + " new-upcoming-stage")
    } else if (stage === "Fundraising") {
      setStageBoxDiv(stageBoxDiv + " new-fundraising-stage")
    } else if (stage === "Pending Closing") {
      setStageBoxDiv(stageBoxDiv + " new-pending-closing-stage")
    } else if (stage === "Closed") {
      setStageBoxDiv(stageBoxDiv + " new-closed-stage")
    } else if (stage === "Past") {
      setStageBoxDiv(stageBoxDiv + " new-past-stage")
    }
  }, [stage]);

  return (
    <div style={{"bottom": "0px", "width": "100%", "position": "absolute" }}>
      <div class={stageBoxDiv}>
        <div class="row g-0 mt-3 ms-3 me-3" ><div class="col-12 stage-text">
          Stage: <b>{stage}</b>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StageSection;
