import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "../../../utilities/scss/_variables.scss";

function TextInputField({
  value,
  setValue,
  valueError,
  setValueError,
  label,
  required,
  fullWidth,
  size,
  id,
  mask,
  disabled,
  shrink,
}) {
  //Styles variable assignment from exported sass variable values
  const primaryFontFamily = styles.primaryFontFamily;
  const primaryBlack2 = styles.primaryBlack2;
  const primaryBlue = styles.primaryBlue;
  const secondaryOffWhite = styles.secondaryOffWhite;
  const secondaryRed = styles.secondaryRed;
  const fontSize3 = styles.fontSize3;

  useEffect(() => {
    setValueError("");
  }, [value]);

  const shouldMaskSSN =
    mask && mask.maskPattern && mask.maskPattern === "999-99-9999" && disabled;
  const [isMasked, setIsMasked] = useState(true);

  return (
    <Box sx={{ minWidth: 120 }}>
      {(shouldMaskSSN && !isMasked) || (mask && !shouldMaskSSN) ? (
        <InputMask
          mask={mask.maskPattern}
          value={value}
          maskChar=""
          disabled={disabled}
          onChange={(event) => setValue(mask.maskFormatter(event.target.value))}
          onBlur={() => {
            if (required && value === "") {
              setValueError("This field is required.");
            }
          }}
        >
          {() => (
            <TextField
              disabled={disabled}
              error={valueError}
              id={id}
              label={label}
              helperText={valueError}
              value={!shouldMaskSSN || !isMasked ? value : "XXX-XX-XXXX"}
              size={size}
              InputProps={
                shouldMaskSSN
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setIsMasked(true)}
                            onMouseDown={() => setIsMasked(false)}
                            edge="end"
                          >
                            {isMasked ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
              sx={{
                "& .MuiInputLabel-root": {
                  fontFamily: primaryFontFamily,
                  color: primaryBlack2,
                  fontSize: fontSize3,
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: primaryBlue,
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: primaryBlue,
                    color: primaryBlue,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: secondaryOffWhite,
                  },
                },
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  "& > fieldset": {
                    borderColor: secondaryOffWhite,
                  },
                },
                "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
                  "& > fieldset": {
                    borderColor:
                      valueError === "" ? secondaryOffWhite : secondaryRed,
                  },
                },
              }}
              required={required}
              fullWidth={fullWidth}
              InputLabelProps={{ shrink: shrink }}
            />
          )}
        </InputMask>
      ) : (
        <TextField
          error={valueError !== ""}
          id={id}
          label={label}
          helperText={valueError}
          value={
            shouldMaskSSN && isMasked
              ? "XXX-XX-".concat(value.substr(value.length - 4))
              : value
          }
          size={size}
          disabled={disabled}
          onChange={(event) => setValue(event.target.value)}
          InputProps={
            shouldMaskSSN
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsMasked(true)}
                        onMouseDown={() => setIsMasked(false)}
                        edge="end"
                      >
                        {isMasked ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
          sx={{
            "& .MuiInputLabel-root": {
              fontFamily: primaryFontFamily,
              color: primaryBlack2,
              fontSize: fontSize3,
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: primaryBlue,
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: primaryBlue,
                color: primaryBlue,
              },
            },
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: secondaryOffWhite,
              },
            },
            "& .MuiOutlinedInput-root.Mui-disabled": {
              "& > fieldset": {
                borderColor: secondaryOffWhite,
              },
            },
            "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
              "& > fieldset": {
                borderColor:
                  valueError === "" ? secondaryOffWhite : secondaryRed,
              },
            },
          }}
          required={required}
          fullWidth={fullWidth}
          InputLabelProps={{ shrink: shrink }}
        />
      )}
    </Box>
  );
}
export default TextInputField;
