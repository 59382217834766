import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import ReactInputVerificationCode from "react-input-verification-code";
import styles from "../../../../../utilities/scss/_variables.scss";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import { useNavigate } from "react-router-dom";
import {
  codeVerify,
  verifyFeederAccess,
} from "./../../../../../APIs/WealthManagementAPI";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";

const MagicCode = ({ code, setCode, navigation }) => {
  let navigate = useNavigate();
  const primaryBlue = styles.primaryBlue;

  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    async function utilityFunc() {
      const resp = await verifyFeederAccess();
      const hasAccess = resp.data.msg === "OK";
      !hasAccess && navigate("/not-found");
    }

    utilityFunc();
  }, []);

  const confirmCode = async () => {
    if (code.length === "") {
      setVerificationCodeError(true);
    } else {
      try {
        await codeVerify(code);
        // Import API wrapper here
        navigation.next();
        return;
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
        setVerificationCodeError(true);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-2">
            <h4>Enter Code</h4>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-11 col-sm-11 col-11 text-lg-start text-start pe-3">
              <h6>
                Enter the code provided to you by the firm with which you're
                investing
              </h6>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-11 text-lg-start text-center pe-3">
              <span className="body-text-md-bold text-center">
                Enter the code below
              </span>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3 justify-content-lg-start justify-content-center">
            <div className="col-10 d-flex justify-content-lg-start justify-content-center">
              <div className="custom-styles shift-left d-flex justify-content-center">
                {/* <ReactInputVerificationCode
                  placeholder=""
                  length={6}
                  onChange={(value) => setCode(value)}
                /> */}
                <TextInputField
                  value={code}
                  setValue={setCode}
                  valueError={codeError}
                  setValueError={setCodeError}
                  label={"Verification Code"}
                  required={true}
                  fullWidth={true}
                  shrink={true}
                  size={"small"}
                  id={"code-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-5 justify-content-lg-start justify-content-center">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
              <div className="d-flex">
                <MuiButton
                  buttonText={"Confirm Code"}
                  onClick={() => confirmCode()}
                  size={"large"}
                  loading={false}
                />
              </div>
            </div>
          </div>
          <SnackBar
            snackbarState={verificationCodeError}
            setSnackbarState={setVerificationCodeError}
            vertical={"top"}
            horizontal={"right"}
            duration={3000}
            sxProps={{ paddingLeft: "3px" }}
            severity={"error"}
            message={"Code Invalid. Please enter a valid verification code."}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default MagicCode;
