import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions";
import { HOST } from "../utilities/host-config/host-config.js";

export const getAdvisorList = async () => {
  const config = await getRequestHeaders();

  //   return axios.get(HOST + "/get-wm-firm-info", config);
  return axios.get(HOST + "/get-advisor-list", config);
};

export const createFirmClient = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(HOST + "/create-firm-client", payload, config);
};
