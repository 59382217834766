"use client";

import React from 'react';
import { KYCRequirement, Document } from "./interfaces"

interface ViewLastSubmissionModalProps {
  show: boolean;
  onClose: () => void;
  requirement: KYCRequirement | null;
}

const ViewLastSubmissionModal: React.FC<ViewLastSubmissionModalProps> = ({
  show,
  onClose,
  requirement,
}) => {
  if (!show) return null;

  const feedback = requirement?.feedback;
  const documents = requirement?.submission[0]?.user_files || [];

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center">
      <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-w-full tw-max-w-lg">
        {/* Modal Title */}
        <h2 className="tw-text-xl tw-font-bold tw-mb-4">View Last Submission</h2>

        {/* Feedback Section */}
        {feedback && feedback.length > 0 && (
          <div className="tw-mb-4">
            <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Feedback:</h3>
            <ul className="tw-list-disc tw-list-inside tw-text-sm tw-text-gray-600">
              {feedback.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Documents Table */}
        <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Submitted Documents</h3>
        <div className="tw-overflow-x-auto" style={{"height": "150px"}}>
          <table className="tw-min-w-full tw-bg-white tw-border">
            <thead>
              <tr className="tw-w-full tw-bg-gray-100 tw-border-b">
                <th className="tw-p-2 tw-text-left">Name</th>
                <th className="tw-p-2 tw-text-left">Upload Date</th>
                <th className="tw-p-2 tw-text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc) => (
                <tr key={doc.id} className="tw-border-b">
                  <td className="tw-p-2">{doc.name}</td>
                  <td className="tw-p-2">{doc.upload_date}</td>
                  <td className="tw-p-2">
                    <a href={doc.link} target="_blank" className="tw-text-blue-500 hover:tw-underline">View</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Close Button */}
        <div className="tw-mt-6 tw-flex tw-justify-end tw-space-x-2">
          <button
            className="tw-mt-4 tw-px-4 tw-py-2 tw-bg-gray-500 tw-text-white tw-font-medium tw-rounded-md"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewLastSubmissionModal;