import React, { useEffect } from "react";
import "./Styles/Styles.scss";
import { useNavigate } from "react-router";
import MuiButton from "../../../../Components/Buttons/MuiButton/MuiButton";
import ChooseClientTable from "../../../../Components/ChooseClientTable/ChooseClientTable";
import useAdvisorClientSearch from "../../../../APIs/useAdvisorClientSearch";

function AdvisorClientsTable({
  clientSearchQuery,
  setClientsLoading,
  clientsLoading,
  refreshClients,
}) {
  const navigate = useNavigate();
  const { clients } = useAdvisorClientSearch(
    clientSearchQuery,
    setClientsLoading,
    refreshClients
  );

  const columns = () => [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "clientName",
      text: "Client Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    // {
    //   dataField: "firstName",
    //   text: "First Name",
    //   classes: "body-text-md-regular align-middle",
    //   headerClasses: "start-padding body-text-md-bold",
    // },
    // {
    //   dataField: "lastName",
    //   text: "Last Name",
    //   classes: "body-text-md-regular align-middle",
    //   headerClasses: "start-padding body-text-md-bold",
    // },
    {
      dataField: "clientEmail",
      text: "Email",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "accountType",
      text: "Account Type",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "dummy",
      text: "",
      classes: "body-text-md-regular align-end justify-content-end",
      headerClasses: "start-padding body-text-md-bold text-end",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex justify-content-end">
            <MuiButton
              buttonText={"View Client Details"}
              onClick={() => navigate("/clients/" + row.id)}
              size={"small"}
            />
          </div>
        );
      },
    },
  ];

  // useEffect(() => {
  //   console.log("Clients are: " + JSON.stringify(clients));
  // }, [clients]);
  return (
    <div
      className="row g-0 w-100"
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "6px",
      }}
    >
      <ChooseClientTable
        advisor={true}
        data={clients}
        columns={columns}
        loading={clientsLoading}
      />
    </div>
  );
}
export default AdvisorClientsTable;
