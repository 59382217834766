import React from "react";
import { Button } from "@mui/material";
import "./Styles/Styles.scss";

const UploadButton = ({ text = "Upload", onChange, inputField, label }) => {
  return (
    <div>
      <input
        accept=".pdf, .doc"
        style={{ display: "none" }}
        id={"contained-button-file"+inputField+label}
        multiple
        type="file"
        onChange={(event) => onChange(event, inputField)}
      />
      <label htmlFor={"contained-button-file"+inputField+label}>
        <Button className="Button" variant="contained" component="span">
          {text}
        </Button>
      </label>
    </div>
  );
};
export default UploadButton;
