import React from "react";
import ProgressBar from "../../ProgressBar.js";
import "./Styles.scss";

function AllocationGraphSection({ stage, allocationLeft, requestAllocation }) {
  return stage !== "Upcoming" ? (
    <div className="row g-0" style={{ paddingBottom: "39px" }}>
      <div className="container me-3">
        <div className="row g-0">
          <div
            className="col-12 pt-0 pb-0 "
            style={{ display: allocationLeft >= 25 ? "block" : "none" }}
          >
            <React.Fragment>
              <div className="row g-0">
                <div className="col-12 ms-3">
                  <div className="allocation-left-text">
                    Allocation Filled:{" "}
                    <span
                      style={{
                        color:
                          allocationLeft >= 75
                            ? "variables.$secondary-red"
                            : "#000000",
                        fontWeight: allocationLeft >= 75 ? 700 : 500,
                      }}
                    >
                      {allocationLeft}%
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-0 mt-3 ms-3 me-3 mb-3">
                <div className="col-12">
                  <div className="progressBar-height">
                    <ProgressBar
                      bgColor={
                        allocationLeft < 75
                          ? "#0091EA"
                          : allocationLeft === 100
                          ? "#00AA26"
                          : "variables.$secondary-red"
                      }
                      completed={allocationLeft}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
          {/* <div
            className="col-12 pt-0 pb-0"
            style={{
              height: "62px",
              display: allocationLeft >= 25 ? "none" : "block",
            }}
          >
            <div className="container-fluid ps-1 pe-1">
              <div className="row g-0 w-100 h-100 align-items-center justify-content-center text-center">
                <div
                  className="d-flex align-items-center justify-content-center h-100 pt-auto pb-auto"
                  style={{ minHeight: "62px" }}
                >
                  <img
                    src="https://d1swyfveypj49y.cloudfront.net/confetti.svg"
                    style={{ height: "50px" }}
                  />
                  <div className="ps-4">
                    <div className="row g-0 w-100 text-start justify-content-start">
                      <span className="new-opportunity-text">
                        New Opportunity
                      </span>
                    </div>
                    <div className="row g-0 w-100 justify-content-start text-start">
                      <span className="check-it-out-text">
                        Get in before it's too late!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  ) : requestAllocation === true ? null : (
    <div
      className="row g-0 w-100 mt-2 mb-2"
      style={{ paddingBottom: "39px" }}
    ></div>
  );
}

export default AllocationGraphSection;
