import React from "react";

const Widget = ({ cardItem }) => {
  return (
    <React.Fragment>
      <div
        className="col mb-3 mt-3 d-flex align-content-stretch"
        key={cardItem.id}
      >
        <div className="card h-100 w-100">
          <div className="card-body">
            <h5 className="card-title default-section-text-muted-500">
              {cardItem.term}
            </h5>
            <p className="card-text default-section-text-500">
              {cardItem.definition}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Widget;
