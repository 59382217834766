import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles.scss";
import Stepper from "react-stepper-horizontal";
import AccountPicker from "./../../AccountPicker/AccountPicker.js";
//import GetAccountListAdapter from "../../../../Adapters/GetAccountListAdapter";
import { UserContext } from "../../../Contexts/UserContext";

function InvestPageHeader() {
  let history = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [accountID, setAccountID] = useState("");
  const [steps, setSteps] = useState([]);
  const [progressMessage, setProgressMessage] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [accountPickerLoading, setAccountPickerLoading] = useState(true);
  const { accountQualificationVerification } = {};

  const { accounts } = [];

  const [accountType, setAccountType] = useState("");
  const [investorProfileComplete, setInvestorProfileComplete] = useState("");
  const [entityComplete, setEntityComplete] = useState("");

  const { user } = useContext(UserContext);

  useEffect(() => {
    setShowProgressBar(true);
  }, []);

  return (
    <React.Fragment>
      {/* showProgressBar && (
        <div className="row g-0 w-100 mt-5 mb-3 pe-3 justify-content-end">
          {!loading && (
            <CompleteAccountComponent
              accountType={accountType}
              investorProfileComplete={investorProfileComplete}
              entityComplete={entityComplete}
              id={accountID}
            />
          )}
        </div>
          )*/}
      <div
        className={
          showProgressBar
            ? "row g-0 w-100 ps-3 pe-3 mb-4 align-items-center"
            : "row g-0 w-100 ps-3 pe-3 mt-5 mb-4 align-items-center"
        }
      >
        <div className="col-md-4 col-12">
          <div className="row g-0 w-100">
            {/* <span className="title-text">Invest</span> */}
            <h2>Invest</h2>
          </div>
          <div className="row g-0 w-100">
            <span className="subtitle-text">Browse Current Opportunities</span>
          </div>
        </div>
        <div className="col-md-8 col-12">
          {false && (
            <div className="row g-0 w-100 mt-md-0 mt-3 justify-content-end">
              <AccountPicker accounts={[]} width={350} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default InvestPageHeader;
