import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "../../utilities/scss/_variables.scss";
import Spinner1 from "../Spinners/Spinner1";
import { GetDropdownList } from "../../Adapters/DynamicDropdown";
import DynamicDropdownItem from "./DynamicDropdownItem/DynamicDropdownItem";

function DynamicDropdown(props) {
  const primaryFontFamily = styles.primaryFontFamily;
  const primaryBlack2 = styles.primaryBlack2;
  const primaryBlue = styles.primaryBlue;
  const secondaryOffWhite = styles.secondaryOffWhite;
  const secondaryRed = styles.secondaryRed;
  const fontSize3 = styles.fontSize3;

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const { items } = GetDropdownList(
    props.retrievalFunction,
    setLoading,
    refresh
  );

  const handleChange = (event) => {
    props.setValue(event.target.value);
  };

  return (
    <Box sx={{ midWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id={props.id + "-label"}
          size={"small"}
          sx={{
            "& .MuiInputLabel-root": {
              fontFamily: primaryFontFamily,
              color: primaryBlack2,
              fontSize: fontSize3,
            },
            "&.MuiInputLabel-root.Mui-focused": {
              color: primaryBlue,
            },
          }}
        >
          {props.label}
        </InputLabel>
        <Select
          labelId={props.id + "-label"}
          id={props.id}
          value={props.value}
          label={props.label}
          onChange={handleChange}
          size={"small"}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "lightgrey",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "lightgrey",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: primaryBlue,
            },
          }}
        >
          {items !== null &&
          items !== undefined &&
          items.length !== 0 &&
          loading === false ? (
            items.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))
          ) : (
            <Spinner1 />
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
export default DynamicDropdown;
