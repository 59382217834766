import React, { useState, useEffect } from "react";
import DynamicDropdown from "../../../Components/DynamicDropdown/DynamicDropdown";
import DynamicDropdownItem from "../../../Components/DynamicDropdown/DynamicDropdownItem/DynamicDropdownItem";
import { getFundList } from "../../../APIs/FirmFundRestrictionsAPI";
function TestDynamicSelectComponent() {
  const [fund, setFund] = useState("");

  const [items, setItems] = useState([
    {
      value: 10,
      label: "Ten",
    },
    {
      value: 20,
      label: "Twenty",
    },
    {
      value: 30,
      label: "Thirty",
    },
  ]);

  return (
    <div className="container-xxl mt-5 pt-5">
      <div className="row g-0 w-100 mt-5">Something to show here.</div>
      <div className="row g-0 w-100 mt-5">
        <div className="col-6">
          <DynamicDropdown
            id="dynamic-select"
            value={fund}
            setValue={setFund}
            label={"Fund"}
            retrievalFunction={getFundList}
          />
        </div>
      </div>
    </div>
  );
}
export default TestDynamicSelectComponent;
