import React from "react";
import "./Styles/Styles.scss";
import HorizontalTableContainer from "../../../../../Components/HorizontalTable/HorizontalTableContainer/HorizontalTableContainer";
import HorizontalTableRow from "../../../../../Components/HorizontalTable/HorizontalTableRow/HorizontalTableRow";
import LoadingAnimation from "../../../../../Components/LoadingAnimation/LoadingAnimation";
import Spinner1 from "../../../../../Components/Spinners/Spinner1";
import ParentComponent from "../../../../MultiSelectTable/ParentComponent";

function FundInvestmentInformation({ fundInvestmentInformation, loading }) {
  return (
    <div className="row g-0 w-100 mb-4">
      {loading === true ? (
        <div className="col-3">
          <Spinner1 />
        </div>
      ) : (
        <div className="col-xxl-3 col-xl-4 col-lg-5 col-6">
          <HorizontalTableContainer>
            <HorizontalTableRow
              rowName={"Fund Name"}
              rowValue={
                fundInvestmentInformation !== null &&
                  fundInvestmentInformation !== undefined
                  ? fundInvestmentInformation.name
                  : ""
              }
            />
            <HorizontalTableRow
              rowName={"Minimum Investment"}
              rowValue={
                fundInvestmentInformation !== null &&
                  fundInvestmentInformation !== undefined
                  ? "$" +
                  fundInvestmentInformation.minimum_allocation_amount.toLocaleString(
                    "en-US"
                  )
                  : ""
              }
            />
            {fundInvestmentInformation !== null && fundInvestmentInformation !== undefined && parseInt(fundInvestmentInformation.maximum_allocation_amount) !== 0 &&
              <HorizontalTableRow
                rowName={"Maximum Investment"}
                rowValue={
                  fundInvestmentInformation !== null &&
                    fundInvestmentInformation !== undefined
                    ? "$" +
                    fundInvestmentInformation.maximum_allocation_amount.toLocaleString(
                      "en-US"
                    )
                    : ""
                }
              />}
          </HorizontalTableContainer>
        </div>
      )}
    </div>
  );
}
export default FundInvestmentInformation;
