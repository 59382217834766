import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import ReactInputVerificationCode from "react-input-verification-code";
import styles from "../../../../../utilities/scss/_variables.scss";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const CodeVerification = ({ email, password, reset }) => {
  let navigate = useNavigate();
  const primaryBlue = styles.primaryBlue;

  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState(false);

  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [resendCodeSuccess, setResendCodeSuccess] = useState(false);
  const [resendCodeError, setResendCodeError] = useState(false);

  const [codeVerificationLoading, setCodeVerificationLoading] = useState(false);

  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    setUserEmail(email);
  }, [userEmail]);

  const resendConfirmationCode = async () => {
    setResendCodeLoading(true);
    try {
      await Auth.resendSignUp(userEmail);
      setResendCodeSuccess(true);
    } catch (err) {
      setResendCodeError(true);
    }
    setResendCodeLoading(false);
  };

  const confirmCode = async () => {
    setCodeVerificationLoading(true);
    if (verificationCode.length !== 6) {
      setVerificationCodeError(true);
    } else {
      try {
        if (reset === true) {
          await Auth.forgotPasswordSubmit(email, verificationCode, password);
          navigate("/login", { state: { passwordResetSuccessful: true } });
          return; 
        }
        var cognitoUser = await Auth.confirmSignUp(userEmail, verificationCode);
        if (process.env.REACT_APP_DEBUG === "1") console.log(cognitoUser);
        navigate("/login", { state: { accountCreationSuccessful: true } });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
        setVerificationCodeError(true);
      }
    }
    setCodeVerificationLoading(false);
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-2">
            <h4>Verify Email</h4>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-11 col-sm-11 col-11 text-lg-start text-start pe-3">
              <h6>We emailed you a six-digit code to: </h6>
              <span class="body-text-md-medium">{email.toLowerCase()}</span>
            </div>
          </div>
          <div className="row g-0 w-100 mb-0">
            <div className="col-11 text-lg-start text-center pe-3">
              <span className="body-text-md-bold text-center">
                Enter the code below to confirm your email address
              </span>
            </div>
          </div>
          <div className="row g-0 w-100 mb-2">
            <div className="col-11 text-lg-start text-center pe-3">
              <span className="body-text-sm-regular text-center">
                *Please check your spam mail if the code does not appear in your
                normal inbox.
              </span>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3 justify-content-lg-start justify-content-center">
            <div className="col-10 d-flex justify-content-lg-start justify-content-center">
              <div className="custom-styles shift-left d-flex justify-content-center">
                <ReactInputVerificationCode
                  placeholder=""
                  length={6}
                  onChange={(value) => setVerificationCode(value)}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-11 text-lg-start text-center pe-3">
              {resendCodeLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ color: primaryBlue }}
                ></span>
              ) : (
                <span className="body-text-sm-regular text-center">
                  Never received the confirmation code? Click to
                  <span
                    style={{ cursor: "pointer", color: primaryBlue }}
                    onClick={() => resendConfirmationCode()}
                  >
                    {" "}
                    resend code.
                  </span>
                </span>
              )}
            </div>
          </div>
          <div className="row g-0 w-100 mb-5 justify-content-lg-start justify-content-center">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
              <div className="d-flex">
                <MuiButton
                  buttonText={"Confirm Code"}
                  onClick={() => confirmCode()}
                  size={"large"}
                  loading={codeVerificationLoading}
                />
              </div>
            </div>
          </div>
          <SnackBar
            snackbarState={resendCodeSuccess}
            setSnackbarState={setResendCodeSuccess}
            vertical={"top"}
            horizontal={"right"}
            duration={10000}
            sxProps={{ paddingLeft: "3px" }}
            severity={"success"}
            message={"Confirmation code resent successfully."}
          />
          <SnackBar
            snackbarState={resendCodeError}
            setSnackbarState={setResendCodeError}
            vertical={"top"}
            horizontal={"right"}
            duration={10000}
            sxProps={{ paddingLeft: "3px" }}
            severity={"error"}
            message={
              "There was an error sending a confirmation code to " +
              userEmail +
              ". Please confirm that you have signed up using a valid email that you have access to."
            }
          />
          <SnackBar
            snackbarState={verificationCodeError}
            setSnackbarState={setVerificationCodeError}
            vertical={"top"}
            horizontal={"right"}
            duration={3000}
            sxProps={{ paddingLeft: "3px" }}
            severity={"error"}
            message={
              "Code Invalid. Please enter a valid, six-digit verification code."
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default CodeVerification;
