import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../Contexts/UserContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Styles/Styles.scss";
import Spinner1 from "../Spinners/Spinner1";
import {
  DataGridPro,
  useGridSelector,
  useGridApiRef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import MuiButton from "./../Buttons/MuiButton/MuiButton";
import { useNavigate } from "react-router-dom";

const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  // ....
  return page === "<" ? (
    <img class="arrow" src="/Left-Arrow.svg" onClick={handleClick} />
  ) : page === ">" ? (
    <img class="arrow" src="/Right-Arrow.svg" onClick={handleClick} />
  ) : (
    <li className="page-item" onClick={handleClick}>
      <span href="#" class={active ? "active-page" : "inactive-page"}>
        {" "}
        {page}{" "}
      </span>
    </li>
  );
};

const options = {
  pageButtonRenderer,
  sizePerPage: 10,
};

//

//

const ChooseClientTable = ({ advisor, ...props }) => {
  let history = useNavigate();

  const [query, setQuery] = useState("");
  const [selectRow, setSelectRow] = useState([]);
  const [clients, setClients] = useState(props.data);

  const { user } = useContext(UserContext);
  const shareClients = user.firmInfo.shareClients;

  useEffect(() => {
    setClients(props.data);
  }, [props]);

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "clientName",
      text: "Client Name",
      headerName: "Client Name",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "clientEmail",
      text: "Email",
      headerName: "Email",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "accountType",
      text: "Account Type",
      headerName: "Account Type",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "status",
      text: "Status",
      headerName: "Status",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    // {
    //   field: "firstName",
    //   headerName: "First Name",
    //   headerClassName: "stylish-header",
    //   flex: 1,
    //   filterable: false,
    //   hideSortIcons: true,
    //   sortable: false,
    //   hideable: false,
    //   pinnable: false,
    // },
    // {
    //   field: "lastName",
    //   headerName: "Last Name",
    //   headerClassName: "stylish-header",
    //   editable: false,
    //   flex: 1,
    //   filterable: false,
    //   hideSortIcons: true,
    //   sortable: false,
    //   hideable: false,
    //   pinnable: false,
    // },
    // {
    //   field: "email",
    //   headerClassName: "stylish-header",
    //   headerName: "Email",
    //   editable: false,
    //   flex: 1,
    //   filterable: false,
    //   hideSortIcons: true,
    //   sortable: false,
    //   hideable: false,
    //   pinnable: false,
    // },
    ...(!advisor && !shareClients
      ? [
          {
            field: "advisorEmail",
            headerClassName: "stylish-header",
            headerName: "Advisor",
            editable: false,
            flex: 1,
            filterable: false,
            hideSortIcons: true,
            sortable: false,
            hideable: false,
            pinnable: false,
          },
        ]
      : []),
    {
      headerClassName: "stylish-header",
      headerName: "Client Details",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      field: "showWire",
      renderCell: (params) => {
        return (
          <MuiButton
            size="large"
            buttonText={"View Client Details"}
            onClick={() => {
              history(`/clients/${params.row.id}`);
            }}
          />
        );
      },
    },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
    },
  ];

  const expandRow = props.expandRow;

  return (
    <>
      <div className="container-xxl container-fluid ">
        <div className="row g-0 w-100">
          <div className="col-12">
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row g-0 w-100 mb-3">
                  <div className="col-12">
                    <div className="row g-0 w-100 mb-0">
                      <div className="pt-3 justify-content-center">
                        <div className="w-100 table-style-2">
                          <DataGridPro
                            sx={{
                              height: "400px",
                              ".MuiDataGrid-columnSeparator": {
                                display: "none",
                              },
                              "&.MuiDataGrid-root": {
                                borderRadius: "6px",
                              },
                            }}
                            columns={columns}
                            rows={clients.filter((e) =>
                              [
                                "clientName",
                                "clientEmail",
                                "accountType",
                                "status",
                                "email",
                                "authLevel",
                              ]
                                .map((e) => e.includes(query))
                                .some((f) => f === true)
                            )}
                            loading={false}
                            rowHeight={54}
                            disableColumnMenu={true}
                            disableRowSelectionOnClick={true}
                          />
                          {/*<BootstrapTable
                            pagination={paginationFactory(options)}
                            keyField="id"
                            data={clients.filter((e) =>
                              [
                                "firstName",
                                "lastName",
                                "status",
                                "email",
                                "authLevel",
                              ]
                                .map((e) => e.includes(query))
                                .some((f) => f === true)
                            )}
                            columns={columns(selectRow)}
                            bordered={false}
                            rowClasses={"table-row-style"}
                            expandRow={expandRow}
                            /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChooseClientTable;
