import { useState, useEffect, useContext } from "react";
import {
  getFundSubscriptionStats,
  getInvestorFundInvestment,
} from "../APIs/FundActions";
import { UserContext } from "../Contexts/UserContext";

export const GetFundSubscriptionInfo = (fundLink) => {
  const [fundSubscriptionInfo, setFundSubscriptionInfo] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function getFundSubscriptionInfo() {
      try {
        let accountID = "";

        if (user && user.selectedAccountID !== "") {
          accountID = user.selectedAccountID;
        }

        const response = await getFundSubscriptionStats(fundLink);
        const fundSubscriptionData = response.data;

        setFundSubscriptionInfo({
          id: fundSubscriptionData.id,
          name: fundSubscriptionData.name,
          link: fundSubscriptionData.link,
          minimumInvestment:
            fundSubscriptionData.fund_terms[0].minimum_allocation_amount,
          maximumInvestment:
            fundSubscriptionData.fund_terms[0].maximum_allocation_amount,
          remainingAllocation:
            fundSubscriptionData.subscription_stats[0]
              .remaining_allocation_amount,
          status: fundSubscriptionData.investment_status,
          allowIraInvestments: fundSubscriptionData.allow_ira_investments,
          legacyPricingStructure:
            fundSubscriptionData.fund_terms[0].legacy_pricing_structure,
        });
      } catch (err) {
        setFundSubscriptionInfo({ error: err });
      }
    }
    getFundSubscriptionInfo();
  }, [fundLink, setFundSubscriptionInfo, user]);

  return { fundSubscriptionInfo };
};

export const GetInvestorFundInvestment = (fundId, refresh) => {
  const [investorFundInvestmentInfo, setInvestorFundInvestmentInfo] =
    useState(null);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    async function getInvestorFundInvestmentInfo() {
      try {
        const response = await getInvestorFundInvestment(
          fundId,
          user && user.selectedAccountID !== "" ? user.selectedAccountID : null
        );
        const investorFundInvestmentData = response.data;
        if (investorFundInvestmentData.length !== 0) {
          setInvestorFundInvestmentInfo({
            id: fundId,
            investmentDate: investorFundInvestmentData.investment_date,
            investmentAmount: investorFundInvestmentData.investment_amount,
            fundName: investorFundInvestmentData.fund.name,
            status: investorFundInvestmentData.status,
          });
        } else {
          setInvestorFundInvestmentInfo({});
        }
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.log(err);
      }
    }
    getInvestorFundInvestmentInfo();
  }, [fundId, setInvestorFundInvestmentInfo, refresh]);

  return { investorFundInvestmentInfo };
};
