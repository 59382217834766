import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import { HOST } from "../utilities/host-config/host-config.js";

export const getFirmUsers = async (firmId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(
    HOST + `/retool/get-wm-firm-users?firm_id=${firmId}`,
    config
  );
};

export const getFirmUser = async (args=null) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + `/firm-user${args?.firmUserFiles ? '?include=firmUserFiles' : ''}`, config);
};

export const getFirmClient = async (clientId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + `/firm-client?client_id=${clientId}`, config);
};

export const pollSpreadsheet = async () => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + `/poll-spreadsheet-status`, config);
};

export const aggViewsGraph = async (startDate, endDate, firmId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(
    HOST +
      `/agg-views-graph?start_date=${startDate}&end_date=${endDate}&firm_id=${firmId}&tz=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`,
    config
  );
};

export const aggViews = async (groupby, firmId) => {
  // groupby is either 'fund' or 'advisor'
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(
    HOST + `/agg-views?groupby=${groupby}&firm_id=${firmId}`,
    config
  );
};

export const codeVerify = async (code) => {
  // const config = await getRequestHeaders();
  const payload = { code: code };
  return axios.post(HOST + `/verify-magic-feeder-code?code=${code}`, payload);
};

export const clientDetails = async (client) => {
  // groupby is either 'fund' or 'advisor'
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + `/client-details?client_id=${client}&tz=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`, config);
};

export const verifyFeederAccess = async () => {
  // const config = await getRequestHeaders();
  // const requestParams = {
  //   headers: {
  //     Authorization: config.headers["Authorization"],
  //   },
  // };
  return axios.get(HOST + `/verify-feeder-signup-access`);
};

export const wmUserCheck = async (email) => {
  return axios.get(HOST + `/wm-user-check?email=${encodeURIComponent(email)}`);
};

export const clientData = async (client) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + `/client-data?client_id=${client}`, config);
};

export const getSubdocLink = async (code) => {
  const call = axios.get(HOST + `/enqueue-subdoc-link-client?code=${code}`);

  return call;
};

export const getSubdocLinkFromInv = async (accountId, fundId, sigType) => {
  const config = await getRequestHeaders();
  const call = axios.get(HOST + `/investment/subdoc-link?account_id=${accountId}&fund_id=${fundId}&sig_type=${sigType}`, config);

  return call;
};

export const getSubdomainLogoLink = async () => {
  // const config = await getRequestHeaders();
  // const requestParams = {
  //   headers: {
  //     Authorization: config.headers["Authorization"],
  //   },
  // };
  return axios.get(HOST + `/wm-get-subdomain-logo-link`);
};

export const resendMagicLink = async (code) => {
  const call = axios.get(HOST + `/resend-magic-link?code=${code}`);

  return call;
};

export const advisorAggViewsGraph = async (startDate, endDate) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(
    HOST +
      `/advisor/agg-views-graph?start_date=${startDate}&end_date=${endDate}&tz=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`,
    config
  );
};

export const advisorAggViews = async (groupby) => {
  // groupby is either 'fund' or 'advisor'
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + `/advisor/agg-views?groupby=${groupby}`, config);
};

export const submitBYO = async (payload) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.post(HOST + "/byo-inv", payload, config);
};

export const syonBankAccount = async (payload) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.post(HOST + "/syon/bank_account", payload, config);
};

export const authdFeederSignup = async (payload) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.post(HOST + "/wm-user-associate", payload, config);
};


export const wmUserSignup = async (payload) => {
  const config = await getRequestHeaders();
  return axios.post(HOST + "/wm-feeder-signup", payload);
};

export const isFeederAPI = async () => {
  //const config = await getRequestHeaders();
  return axios.get(HOST + "/is-feeder");
}


export const clickToSign = async (code, fullName) => {
  const requestParams = {
      code: code,
      signatory_name: fullName
  };
  const call = axios.post(HOST + `/sydecar-doc-sign`, requestParams);

  return call;
};
