import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import TabTitle from "./TabTitle/TabTitle";

const Tabs = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (props.selectedTab !== null && props.selectedTab !== undefined) {
      setSelectedTab(props.selectedTab);
    }
  }, [props.selectedTab]);


  useEffect(() => {
 
    props.children.map((item, index) => {
      if (item.props.selected) {
        setSelectedTab(index);
      }
    }); 
  }, [props.selected]); 


  useEffect( () => {
    props.children.map((item, index) => {
      if (item.props.selected) {
        setSelectedTab(index);
      }
    }); 

  }, [props]); 

  return (
    <div className="col-12 mt-4 pb-5 mb-4 justify-content-center d-flex">
      <div className="row g-0 w-100 justify-self-center">
        <div className="col-12 container-box pb-2">
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row gx-0 w-100 me-3 pt-3 mb-3 bottom">
                <div
                  className={
                    props.fullWidth
                      ? "col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ms-3 pe-3"
                      : "col-xxl-7 col-xl-8 col-lg-10 col-md-12 col-sm-12 col-12 ms-3 pe-3"
                  }
                >
                  <div className="row g-0 w-100">
                    {props.children.map((item, index) => {
                      return (
                        <div
                          className={
                            props.fullWidth
                              ? "col-lg-2 col-md-2 col-sm-4 col-6 h-100"
                              : "col-lg-3 col-md-3 col-sm-4 col-6 h-100"
                          }
                        >
                          <TabTitle
                            key={index}
                            index={index}
                            setSelectedTab={setSelectedTab}
                            title={item.props.title}
                            selected={item.props.selected}
                            setSelected={item.props.setSelected}
                            disableClick={item.props.disableClick}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-12">{props.children[selectedTab]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
