import { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MuiButton from "../../../Components/Buttons/MuiButton/MuiButton";
import MultiSelectFilter from "../../../Components/MultiSelectFilter/MultiSelectFilter";
import ValueMultiselectFilter from "../../../Components/ValueMultiselectFilter/ValueMultiselectFilter";
import {
  getGlobalDocs,
  firmUserDocumentApproval,
  resendDocumentAPI,
} from "../../../APIs/GlobalDocsAPI";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  useGridSelector,
  useGridApiRef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import { getSignedURL } from "../../../utilities/functions/functions";
import Spinner1 from "../../../Components/Spinners/Spinner1";
import SnackBar from "../../../Components/Alerts/SnackBar/SnackBar";
import { wrap } from "underscore";
import { UserContext } from "../../../Contexts/UserContext.js";

function Documents() {
  const { user, setUser } = useContext(UserContext);
  const { client, fund } = useParams();
  const [resp, setResp] = useState();
  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiRef = useGridApiRef();
  const [selected, setSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDocLink, setSelectedDocLink] = useState(null);
  const [magicLink, setMagicLink] = useState(null);
  const [approvalInProgress, setApprovalInProgress] = useState(false);
  const [clients, setClients] = useState([]);
  const [funds, setFunds] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [snackBar, setSnackBar] = useState(false);

  const [clientsSelected, setClientsSelected] = useState(
    client ? [decodeURI(client)] : []
  );
  const [fundsSelected, setFundsSelected] = useState(
    fund ? [decodeURI(fund)] : []
  );
  const [statusSelected, setStatusSelected] = useState([]);

  async function getGlobalDocumentsWrapper(payload) {
    const globalDocResp = await getGlobalDocs(payload);
    setResp(globalDocResp);
  }

  useEffect(() => {
    getGlobalDocumentsWrapper({
      fund: fundsSelected.length !== 0 ? fundsSelected : null,
      status: statusSelected.length !== 0 ? statusSelected : null,
      client_id: clientsSelected.length !== 0 ? clientsSelected : null,
    });
  }, [fundsSelected, clientsSelected, statusSelected]);

  const approveDocument = async (documentId) => {
    setApprovalInProgress(true);

    try {
      const response = await firmUserDocumentApproval({ id: documentId });
      //   console.log("Approval response is: " + JSON.stringify(response));
      setApprovalInProgress(false);
      setModalOpen(false);
      setSelectedDocLink(null);
      window.location.reload();
    } catch (err) {
      console.log(err);
      setApprovalInProgress(false);
    }
  };

  const resendDocument = async (documentId) => {
    setApprovalInProgress(true);

    try {
      const response = await resendDocumentAPI({ id: documentId });
      //   console.log("Approval response is: " + JSON.stringify(response));
      setApprovalInProgress(false);
      setModalOpen(false);
      setSelectedDocLink(null);
      setSnackBar(true);
      setTimeout(function () {
        setMagicLink(null);
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
      setApprovalInProgress(false);
    }
  };

  useEffect(() => {
    if (
      resp !== null &&
      resp !== undefined &&
      resp.data !== null &&
      resp.data !== undefined &&
      resp.data.data !== null &&
      resp.data.data !== undefined &&
      resp.data.data.length !== 0
    ) {
      let documentArray = resp.data.data.map((item) => {
        return {
          id: item.id,
          docType: item.doc_type,
          status: item.status,
          clientName: item.client.client_name,
          clientId: item.client.id,
          fundName: item.fund.name,
          fundId: item.fund.id,
          docLink: item.s3_document_link_url,
          magicCode: item.magic_code,
        };
      });

      setDocumentData(documentArray);
    } else {
      setDocumentData([]);
    }
  }, [resp]);

  useEffect(() => {
    if (
      resp !== null &&
      resp !== undefined &&
      resp.data !== null &&
      resp.data !== undefined &&
      resp.data.data !== null &&
      resp.data.data !== undefined &&
      resp.data.data.length !== 0 &&
      fundsSelected &&
      clientsSelected &&
      statusSelected
    ) {
      if (
        (fundsSelected.length === 0 &&
          clientsSelected.length === 0 &&
          statusSelected.length === 0) ||
        (client && fund)
      ) {
        let fundsArray = [];
        let clientsArray = [];
        let statusesArray = [];

        function arrayContainsValue(id, searchArray) {
          return searchArray.find((elem) => elem.key === id);
        }

        Object.values(resp.data.data).forEach((elem) => {
          if (!arrayContainsValue(elem.fund.id, fundsArray)) {
            fundsArray.push({ key: elem.fund.id, value: elem.fund.name });
          }
          if (!arrayContainsValue(elem.client.id, clientsArray)) {
            clientsArray.push({
              key: elem.client.id,
              value: elem.client.client_name,
            });
          }
          if (!statusesArray.includes(elem.status)) {
            statusesArray.push(elem.status);
          }
        });
        setFunds(fundsArray);
        setClients(clientsArray);
        setStatuses(statusesArray);
      }
    }
  }, [resp]);

  useEffect(() => {
    if (
      selected !== null &&
      selected !== undefined &&
      selected.docLink !== null &&
      selected.docLink !== undefined &&
      selected.docLink !== ""
    ) {
      setSelectedDocLink(selected.docLink);
      setMagicLink(selected.magicCode);
    }
  }, [selected]);

  var columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "clientName",
      headerName: "Client",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "fundName",
      headerName: "Fund",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "docType",
      headerClassName: "stylish-header",
      headerName: "Type",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "status",
      headerClassName: "stylish-header",
      headerName: "Status",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerClassName: "stylish-header",
      headerName: "View Document",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      field: "viewDocument",
      renderCell: (params) => {
        return (
          params.row.docLink !== null &&
          params.row.docLink !== undefined &&
          params.row.docLink !== "" && (
            <MuiButton
              size="small"
              buttonText={"View Document"}
              onClick={() => {
                setSelected(params.row);
                setModalOpen(true);
              }}
            />
          )
        );
      },
    },
  ];

  return (documentData !== null &&
    documentData !== undefined &&
    documentData.length !== 0) ||
    ((fundsSelected.length !== 0 ||
      clientsSelected.length !== 0 ||
      statusSelected.length !== 0) &&
      (fundsSelected.length === 0 ||
        (fundsSelected.length !== 0 && funds.length !== 0)) &&
      (statusSelected.length === 0 ||
        (statusSelected.length !== 0 && statuses.length !== 0)) &&
      (clientsSelected.length === 0 ||
        (clientsSelected.length !== 0 && clients.length !== 0))) ? (
    <div className="container ps-3 pe-3">
      <div className="row g-0 w-100">
        <h5>Documents</h5>
      </div>
      <div className="row g-0 w-100 mb-2">
        <Box sx={{ minHeight: 475, width: "100%" }}>
          <Modal
            show={modalOpen}
            onHide={() => setModalOpen(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {selectedDocLink === null ? (
              <Modal.Body>
                <Spinner1 />
              </Modal.Body>
            ) : (
              <Modal.Body>
                <div style={{ textAlign: "center" }}>
                  <iframe
                    height={"600px"}
                    width={"600px"}
                    src={selectedDocLink}
                  />
                </div>

                    <div className="row g-0 w-100 mb-3">
                      <div className="ps-3 pe-3">
                        <center>
                        Subscription documents will be sent from noreply@mail.hellosign.com. 
                        </center>
                      </div>
                    </div>
              </Modal.Body>
            )}
            <Modal.Footer>
              <div className="d-flex">
                {selected !== null &&
                selected !== undefined &&
                Object.keys(selected).length !== 0 &&
                selected.status !== null &&
                selected.status !== undefined &&
                selected.status === "Pending Approval" ? (
                  <div className="me-3">
                   { (user.userRole !== 'Self-Serve') && <MuiButton
                      size="small"
                      buttonText={"Approve"}
                      onClick={async () => {
                        await approveDocument(selected.id);
                      }}
                      disabled={approvalInProgress} /> }
                  </div> 
                ) : selected.status === "Pending Client Signature" ? (
                  <div className="me-3">
                    <MuiButton
                      size="small"
                      buttonText={"Re-send"}
                      onClick={async () => {
                        await resendDocument(selected.id);
                      }}
                      disabled={approvalInProgress}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <MuiButton
                    size="small"
                    buttonText={"Close"}
                    onClick={() => {
                      setModalOpen(false);
                      setSelectedDocLink(null);
                      setMagicLink(null);
                    }}
                  />
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <div className="row g-0 w-100 mb-3">
           { user.userRole !== 'Self-Serve' && <ValueMultiselectFilter
              label={"Client Name"}
              options={clients}
              selectedValues={clientsSelected}
              setSelectedValues={setClientsSelected}
              loading={false}
            /> }
            <MultiSelectFilter
              label={"Status"}
              options={statuses}
              selectedValues={statusSelected}
              setSelectedValues={setStatusSelected}
              loading={false}
            />
            <ValueMultiselectFilter
              label={"Fund"}
              options={funds}
              selectedValues={fundsSelected}
              setSelectedValues={setFundsSelected}
              loading={false}
            />
          </div>
          <DataGridPro
            apiRef={apiRef}
            sx={{
              height: "400px",
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                borderRadius: "6px",
              },
            }}
            columns={columns}
            rows={documentData}
            loading={loading}
            rowHeight={54}
            disableColumnMenu={true}
            disableRowSelectionOnClick={true}
            // getDetailPanelContent={(params) => <WireCard props={params.row} />}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
                  id: false,
                },
              },
            }}
          />
        </Box>
        <SnackBar
          snackbarState={snackBar}
          setSnackbarState={setSnackBar}
          vertical={"top"}
          horizontal={"right"}
          duration={3000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"success"}
          message={"Resend success"}
        />
      </div>
    </div>
  ) : (
    <div className="pt-5 mt-5">No documents found.</div>
  );
}
export default Documents;
