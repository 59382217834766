import React, {useContext} from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import PhoneInputField from "../../../../../Components/InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import { formatSSN } from "../../../../../utilities/functions/functions";
import StyledDateInputField from "../../../../../Components/StyledDateInput/StyledDateInput";
import { stateList } from "../../../../../Components/stateList";
import { UserContext } from "../../../../../Contexts/UserContext.js";
import GooglePlacesAutocomplete from "../../../../../Components/GooglePlacesAutocomplete";
import { CountryRegionData } from 'react-country-region-selector';

function ClientInformation({
  percentOwned,
  setPercentOwned,
  percentOwnedError,
  setPercentOwnedError,
  accountType,
  setAccountType,
  clientFirstName,
  setClientFirstName,
  clientFirstNameError,
  setClientFirstNameError,
  clientLastName,
  setClientLastName,
  clientLastNameError,
  setClientLastNameError,
  clientEmail,
  setClientEmail,
  clientEmailError,
  setClientEmailError,
  clientPhoneNumber,
  setClientPhoneNumber,
  clientPhoneNumberError,
  setClientPhoneNumberError,
  clientAddressLine1,
  setClientAddressLine1,
  clientAddressLine1Error,
  setClientAddressLine1Error,
  clientAddressLine2,
  setClientAddressLine2,
  clientAddressLine2Error,
  setClientAddressLine2Error,
  clientCity,
  setClientCity,
  clientCityError,
  setClientCityError,
  clientState,
  setClientState,
  clientStateError,
  setClientStateError,
  clientPostalCode,
  setClientPostalCode,
  clientPostalCodeError,
  setClientPostalCodeError,
  clientTaxIdNumber,
  setClientTaxIdNumber,
  clientTaxIdNumberError,
  setClientTaxIdNumberError,
  clientDOB,
  setClientDOB,
  clientDOBError,
  setClientDOBError,
  clientIsUSCitizen,
  setClientIsUSCitizen,
  clientIsUSCitizenError,
  setClientIsUSCitizenError,
  citizenship,
  setCitizenship,
  citizenshipError,
  setCitizenshipError,
  country,
  setCountry,
  countryError,
  setCountryError,
  addressError,
  setAddressError,
}) {
  const { user, setUser } = useContext(UserContext);
  const reducedCountryList = [
    { value: "United States", label: "United States" },
  ];

  const booleanList = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 align-items-center mb-2">
        <h6 className="mt-auto mb-auto"> {user.userRole !=='Self-Serve' ? 'Client' : 'Investor'} Information</h6>
      </div>
      <div className="row g-0 w-100 align-items-center mb-4">
        <span className="body-text-xs-medium">
          *Enter the Grantor Information if you're adding a trust account
        </span>
      </div>
     {user.userRole !== 'Self-Serve' &&  <div className="row g-0 w-100 mb-3 tw-flex tw-space-x-2">
        <div className="col">
          <TextInputField
            value={clientFirstName}
            setValue={setClientFirstName}
            valueError={clientFirstNameError}
            setValueError={setClientFirstNameError}
            label={"First Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-first-name-input"}
          />
        </div>
        <div className="col">
          <TextInputField
            value={clientLastName}
            setValue={setClientLastName}
            valueError={clientLastNameError}
            setValueError={setClientLastNameError}
            label={"Last Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-last-name-input"}
          />
        </div>
      </div> }
      <div className="row g-0 w-100 mb-3 tw-flex tw-space-x-2">
        <div className="col">
          <PhoneInputField
            shrink={true}
            label={"Phone Number"}
            size={"small"}
            fullWidth={true}
            required={true}
            value={clientPhoneNumber}
            setValue={setClientPhoneNumber}
            valueError={clientPhoneNumberError}
            setValueError={setClientPhoneNumberError}
            disabled={false}
            id={"client-phone-number-input"}
          />
        </div>
        <div className="col">
          {user.userRole !== 'Self-Serve' && <TextInputField
            value={clientEmail}
            setValue={setClientEmail}
            valueError={clientEmailError}
            setValueError={setClientEmailError}
            label={"Email"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-email-input"}
          /> }
        </div> 
      </div>
      <GooglePlacesAutocomplete 
      object={"investor"}
      values={{
        addressLine1: clientAddressLine1,
        addressLine2: clientAddressLine2,
        city: clientCity,
        state: clientState,
        postalCode: clientPostalCode, 
        country: country, 
      }}
      addressCallbacks={{
        setAddressLine1: setClientAddressLine1,
        setAddressLine2: setClientAddressLine2,
        setCity: setClientCity,
        setState: setClientState,
        setPostalCode: setClientPostalCode, 
        setCountry: setCountry, 
      }}
      errorList={[clientAddressLine1Error, clientCityError, clientStateError, clientPostalCodeError, countryError]}
      errorCallbacks={[setClientAddressLine1Error, setClientCityError, setClientStateError, setClientPostalCodeError, setCountryError]}
      />

      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          {country === "United States" ? (
            <TextInputField
              value={clientTaxIdNumber}
              setValue={setClientTaxIdNumber}
              valueError={clientTaxIdNumberError}
              setValueError={setClientTaxIdNumberError}
              label={"SSN"}
              required={true}
              fullWidth={true}
              size={"small"}
              mask={{
                maskFormatter: formatSSN,
                maskPattern: "999-99-9999",
              }}
              id={"client-ssn-input"}
            />
          ) : (
            <React.Fragment>
              <TextInputField
                value={clientTaxIdNumber}
                setValue={setClientTaxIdNumber}
                valueError={clientTaxIdNumberError}
                setValueError={setClientTaxIdNumberError}
                label={"Passport Number"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={false}
                shrink={true}
                id={"account-name-input"}
              />
              {clientTaxIdNumberError &&
                clientTaxIdNumberError !== "This field is required." && (
                  <div className="SignupForm-field-error-text mt-1">
                    <span className="">{clientTaxIdNumberError}</span>
                  </div>
                )}
            </React.Fragment>
          )}
        </div>
        <div className="col pe-0">
          <StyledDateInputField
            value={clientDOB}
            setValue={setClientDOB}
            valueError={clientDOBError}
            setValueError={setClientDOBError}
            label={"Date Of Birth"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-dob-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100">
        <div className="col pe-0">
          <SelectInputField
            value={clientIsUSCitizen}
            setValue={setClientIsUSCitizen}
            valueError={clientIsUSCitizenError}
            setValueError={setClientIsUSCitizenError}
            selectOptions={booleanList}
            label={"Is U.S. Citizen"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-citizenship-input"}
          />
        </div>

        {(accountType === "LT" || accountType === "TRUST") && (
          <div className="col ps-2 pe-0">
            <TextInputField
              value={percentOwned}
              setValue={setPercentOwned}
              valueError={percentOwnedError}
              setValueError={setPercentOwnedError}
              mask={{
                maskFormatter: (e) => (parseInt(e) <= 100 ? e : e.slice(0, 2)),
                maskPattern: "999",
              }}
              label={"Percent Ownership"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-entity-tax-id-number-input"}
            />
          </div>
        )}
      </div>
      {clientIsUSCitizen === "No" && (
        <div className="row g-0 w-100 mt-3">
          <div className="col pe-0">
            <SelectInputField
              value={citizenship}
              setValue={setCitizenship}
              valueError={citizenshipError}
              setValueError={setCitizenshipError}
              selectOptions={CountryRegionData.map((e) => ({"value": e[0], "label": e[0]}))}
              label={"Citizenship"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-citizenship-input"}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default ClientInformation;
