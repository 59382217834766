import { useState, useEffect } from "react";
import { getInvestorTermsOfAgreement } from "../APIs/SignupAPI";

export default function GetTermsOfUseAgreement(time) {
  const [investorTermsOfUse, setInvestorTermsOfUse] = useState(null);

  useEffect(() => {
    async function getInvestorTermsOfUseAgreementObject() {
      try {
        const response = await getInvestorTermsOfAgreement();
        const investorTermsOfUseData = response.data[0];
        setInvestorTermsOfUse({
          id: investorTermsOfUseData.id,
          version: investorTermsOfUseData.version,
          agreement: investorTermsOfUseData.agreement,
          status: investorTermsOfUseData.status,
          startDate: investorTermsOfUseData.start_date,
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err);
        }
      }
    }
    getInvestorTermsOfUseAgreementObject();
  }, [time]);
  return { investorTermsOfUse };
}
