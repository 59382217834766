import React from "react";
import "./Styles/Styles.scss";

function Spinner1() {
  return (
    <div className="lds-ring d-flex justify-content-center align-items-center">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
export default Spinner1;
