import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "../../utilities/scss/_variables.scss";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import Spinner1 from "../Spinners/Spinner1";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultiSelectFilter({
  options,
  selectedValues,
  setSelectedValues,
  loading,
  label,
  width
}) {
  const secondaryOffWhite = styles.secondaryOffWhite;

  // 

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  const handleClearClick = () => {
    setSelectedValues([]);
  };

  return (
    <FormControl sx={{ m: 1, width: width ? width : 300 }}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={
          <OutlinedInput
            label={label}
            sx={{
              "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
                "& > fieldset": {
                  borderColor: secondaryOffWhite,
                },
              },
            }}
          />
        }
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
        endAdornment={
          <IconButton
            sx={{
              visibility: selectedValues.length !== 0 ? "visible" : "hidden",
            }}
            onClick={handleClearClick}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        {loading === false ? (
          options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedValues.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <div className="row g-0 w-100 align-items-center justify-content-center">
              <Spinner1 />
            </div>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
export default MultiSelectFilter;
