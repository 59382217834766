import React, { useState } from "react";
import "./Styles/Styles.scss";
import InvestmentCard from "./InvestmentCard/InvestmentCard";
import MuiButton from "../../../Components/Buttons/MuiButton/MuiButton";
import { createBulkInvestments } from "../../../APIs/ConfirmInvestmentAPI";
import SnackBar from "../../../Components/Alerts/SnackBar/SnackBar";

function ConfirmInvestments({
  fundInvestmentInformation,
  investments,
  setShowConfirmInvestments,
  setSuccessfulInvestmentSubmission,
}) {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const submitInvestments = async () => {
    setLoading(true);
    var fundId = "";

    if (
      fundInvestmentInformation !== null &&
      fundInvestmentInformation !== undefined
    ) {
      fundId = fundInvestmentInformation.id;
    }
    const payloadObject = {
      fund_id: fundId,
      investments: investments,
    };

    try {
      let requestResponse = await createBulkInvestments(payloadObject);
      setSuccessfulInvestmentSubmission(true);
    } catch (err) {
      setSubmitError(true);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="container-xxl">
        <div className="row g-0 w-100 mb-1">
          <h4>Investments</h4>
        </div>
        <div className="row g-0 w-100 mb-3">
          <h6>Please review the proposed investments you specified below:</h6>
        </div>
        <div className="row g-4 mb-3">
          {investments.map((item) => (
            <InvestmentCard investment={item} />
          ))}
        </div>
        <div className="row w-100">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <div className="me-3 mt-auto mb-auto">
                <MuiButton
                  buttonText={"Submit Investments"}
                  onClick={() => submitInvestments()}
                  size={"medium"}
                  disabled={loading === true}
                />
              </div>
              <span
                className="body-text-md-medium mt-auto mb-auto"
                style={{ cursor: "pointer", opacity: 0.5 }}
                onClick={() => setShowConfirmInvestments(false)}
              >
                <u>Edit Investments</u>
              </span>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        snackbarState={submitError}
        setSnackbarState={setSubmitError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "Sorry, there was an error submitting these investment requests."
        }
      />
    </React.Fragment>
  );
}
export default ConfirmInvestments;
