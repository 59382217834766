import React from "react";
import "./Styles/Styles.scss";

function ErrorComponent({ errorDetails }) {
  return (
    <React.Fragment>
      {errorDetails !== "" && (
        <div className="container-xxl mt-4">
          <div className="col-xl-8 col-lg-10">
            <div className="row g-0 w-100">
              <div className="p-0">
                <div
                  className="ps-3 pt-3 pe-3"
                  style={{ backgroundColor: "#ffffff", borderRadius: "4px" }}
                >
                  <div className="row g-0 w-100">
                    <h6>
                      Please fix the following errors before trying to create
                      investments:
                    </h6>
                  </div>
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100">
                        <ul className="ps-3">
                          {errorDetails.map((item, idx) => {
                            if (item.hasOwnProperty("fullName")) {
                              return (
                                <li>{item.fullName + ": " + item.error}</li>
                              );
                            } else if (item.hasOwnProperty("error")) {
                              return <li>{"Overall: " + item.error}</li>;
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default ErrorComponent;
