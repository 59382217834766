import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import { HOST } from "../utilities/host-config/host-config.js";



export const getCapitalCall = async (payload) => {
    const config = await getRequestHeaders();
  
    return axios.post(
      HOST + "/get-capital-calls",
      payload,
      config
    );
  };
  