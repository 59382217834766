import React, { useEffect, useState } from "react";
import "./Styles/Styles.scss";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDemoData } from "@mui/x-data-grid-generator";
import MuiButton from "./../../../../../../Components/Buttons/MuiButton/MuiButton.js";
import MultiSelectFilter from "./../../../../../../Components/MultiSelectFilter/MultiSelectFilter";
import SingleSelectFilter from "./../../../../../../Components/SingleSelectFilter/SingleSelectFilter";



function InvestmentTable({ rowData, setCapitalCallsSelected, setInvestment, setInvestmentsSelected }) {
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100000,
    editable: false,
  });

  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(true);

  function moneyFormatter(amount) {
    if (amount === undefined || amount === null) {
      return "$ 0";
    }
    var integer = parseInt(amount.toString(), 10);
    return "$ " + integer.toLocaleString("en-US");
  }

  function investmentStatusFormatter(value) {
    if (value !== undefined && value !== null) {
      let investmentStatusMap = {
        P: "Pending Approval",
        PD: "Pending Sub Docs",
        PF: "Pending Funding",
        F: "Funded",
        A: "Approved",
        R: "Rejected",
      };
      return investmentStatusMap[value.toString()];
    }
    return "";
  }


  useEffect(() => {
    setLoading(true);
    var formattedRowData = rowData.map((item, idx) => {
      return {
        id: idx,
        ...item,
        investmentAmount: moneyFormatter(item.investmentAmount),
        totalDistributed: moneyFormatter(item.totalDistributed),
        investmentStage: investmentStatusFormatter(item.investmentStage),
      };
    });
    setFormattedData(formattedRowData);
    setLoading(false);
  }, [rowData]);

  var columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "fundName",
      headerName: "Opportunity Name",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "investmentAmount",
      headerName: "Investment Amount",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "totalDistributed",
      headerClassName: "stylish-header",
      headerName: "Total Distributed",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "opportunityStatus",
      headerClassName: "stylish-header",
      headerName: "Opportunity Status",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "investmentStage",
      headerClassName: "stylish-header",
      headerName: "Investment Stage",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "accountName",
      headerClassName: "stylish-header",
      headerName: "Account",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "capitalCalls",
      headerClassName: "stylish-header",
      headerName: "Capital Calls",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      renderCell: (params) => 
      <MuiButton
      size={"large"}
      buttonText={"View Capital Calls"}
      onClick={()=>{
        setInvestment(params.row.investmentId);
        setCapitalCallsSelected(true);
      }
    } />
    },
  ];

  var rows = [
    {
      id: 1,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 2,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 3,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 4,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 5,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 6,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 7,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 8,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 9,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 10,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
    {
      id: 11,
      fundName: "BET",
      investmentAmount: 30000,
      totalDistributed: 0,
      opportunityStatus: "Fundraising",
      investmentStage: "PF",
      accountName: "Alice Brown",
    },
  ];

  //   useEffect(() => {
  //     console.log("Data is: " + JSON.stringify(data.rows));
  //   }, [data]);

  return (
    <Box sx={{ minHeight: 475, width: "100%" }}>
      <DataGridPro
        sx={{
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            borderRadius: "6px",
          },
        }}
        columns={columns}
        rows={formattedData}
        loading={loading}
        rowHeight={54}
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
      />
    </Box>
  );
}
export default InvestmentTable;
