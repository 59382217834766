import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { UserContext } from "../../Contexts/UserContext";
import { Menu, Dropdown, Button } from "antd";
import "./Styles/Styles.scss";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function EllipseDropdown({ text, setText, defaultKey, setActivePage }) {
  const { user, setUser } = useContext(UserContext);
  let history = useNavigate();

  const [positionText, setPositionText] = useState("");

  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

  useEffect(() => {
    isMobile ? setPositionText("bottomCenter") : setPositionText("bottomRight");
  }, [isMobile]);

  const logoutUser = async () => {
    try {
      await Auth.signOut();
      if (process.env.REACT_APP_DEBUG === "1") {
        console.log("Successful logout.");
      }
      setUser(null);
      history("/login");
    } catch (error) {
      if (process.env.REACT_APP_DEBUG === "1")
        console.error("error signing out: ", error);
      return;
    }
  };

  const menu = (
    <Menu
      style={{ paddingTop: 0, paddingBottom: 0 }}
      selectable
      onClick={(e) => setText(e.key)}
      defaultSelectedKeys={defaultKey}
    >
      {/* <Menu.Item key={"Profile"} className="h-100">
        <Link
          to="/profile"
          onClick={() => {
            setActivePage("");
            history("/profile");
          }}
        >
          <div className="d-flex align-items-center">
            <img
              className="me-2"
              src="https://d1swyfveypj49y.cloudfront.net/user.svg"
              alt="user"
              loading="lazy"
            ></img>
            <span className="body-text-md-medium">Profile</span>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Divider></Menu.Divider> */}
      {/* <Menu.Item key={"Documents"} className="h-100">
        <Link to="/documents" onClick={() => setActivePage("")}>
          <div className="d-flex align-items-center">
            <img
              className="me-2"
              src="https://d1swyfveypj49y.cloudfront.net/File.svg"
              alt="File"
              loading="lazy"
            ></img>
            <span className="body-text-md-medium">Documents</span>
          </div>
        </Link>
      </Menu.Item> */}
      {/* <Menu.Item key={"Transactions"} className="h-100">
        <Link to="/transactions" onClick={() => setActivePage("")}>
          <div className="d-flex align-items-center">
            <img
              className="me-2"
              src="https://d1swyfveypj49y.cloudfront.net/Wallet.svg"
              alt="Wallet"
              loading="lazy"
            ></img>
            <span className="body-text-md-medium">Transactions</span>
          </div>
        </Link>
      </Menu.Item> */}
      {/* <Menu.Item key={"Referrals"} className="h-100">
        <Link to="/referrals">
          <div className="d-flex align-items-center">
            <img className="me-2" src="https://d1swyfveypj49y.cloudfront.net/users.svg" alt="users"></img>
            <span className="body-text-md-medium">Referrals</span>
          </div>
        </Link>
      </Menu.Item> */}
      <Menu.Item key={"Contact Us"} className="h-100">
        <Link to="/support" onClick={() => setActivePage("")}>
          <div className="d-flex align-items-center">
            <img
              className="me-2"
              src="https://d1swyfveypj49y.cloudfront.net/Line.svg"
              alt="message"
              loading="lazy"
            ></img>
            <span className="body-text-md-medium">Contact Us</span>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      {user !== null &&
        user !== undefined &&
        user.userRole !== null &&
        user.userRole !== undefined &&
        (user.userRole === "Master" || user.userRole === "Ops") && (
          <React.Fragment>
            <Menu.Item key={"Manage Firm"} className="h-100">
              <Link to="/manage-firm" onClick={() => setActivePage("")}>
                <div className="d-flex align-items-center">
                  <img
                    className="me-2"
                    src="https://d1swyfveypj49y.cloudfront.net/settings.svg"
                    alt="message"
                    loading="lazy"
                    height={"20px"}
                    width={"20px"}
                  ></img>
                  <span className="body-text-md-medium">Manage Firm</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Divider></Menu.Divider>
          </React.Fragment>
        )}
      {user !== null &&
        user !== undefined &&
        user.userRole !== null &&
        user.userRole !== undefined &&
        (user.userRole === "Self-Serve") && (
          <React.Fragment>
            <Menu.Item key={"Manage Firm"} className="h-100">
              <Link to="/reset-pwd" onClick={() => setActivePage("")}>
                <div className="d-flex align-items-center">
                  <span className="body-text-md-medium">Reset password</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Divider></Menu.Divider>
          </React.Fragment>
        )}
      <Menu.Item key={"Logout"} className="h-100">
        <div
          className="d-flex align-items-center"
          onClick={async () => {
            setActivePage("");
            logoutUser();
          }}
        >
          <img
            className="me-2"
            src="https://d1swyfveypj49y.cloudfront.net/Logout.svg"
            alt="Logout"
            loading="lazy"
          ></img>
          <span className="dropdown-logout-text">Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement={positionText}>
      <Button
        icon={
          <div className="d-flex h-100 w-100 align-items-start justify-content-center pt-0 pb-0 ps-0 pe-0 mt-0 mb-0 ms-0 me-0">
            <img
              className="align-self-center"
              src="https://d1swyfveypj49y.cloudfront.net/Ellipse.svg"
              alt="ellipse"
              loading="lazy"
              height="32px !important"
              width="32px !important"
            ></img>
          </div>
        }
        shape="circle"
        style={{
          backgroundColor: "#ffffff",
          outline: "none !important",
          border: "none !important",
          boxShadow: "none !important",
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        }}
      ></Button>
    </Dropdown>
  );
}
export default EllipseDropdown;
