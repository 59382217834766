import React, { useState } from "react";
import "./Styles/Styles.scss";
import ChooseClientTable from "../../../../../Components/ChooseClientTable/ChooseClientTable";
import { GetDocuments } from "../../../../../Adapters/ClientDetails";
import { DataGridPro } from "@mui/x-data-grid-pro";

const Docs = ({ docs }) => {
  const [loading, setLoading] = useState(true);
  const { documents } = GetDocuments("123349292929", "ALL", setLoading);

  const handleClick = (link) => {
    window.open(link);
  };

  const columns = () => [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Document Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "category",
      text: "Type",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "upload_date",
      text: "Date",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "dummy",
      text: "Download",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center">
            <span className="body-text-md-medium">Available</span>
            <img
              src="https://d1swyfveypj49y.cloudfront.net/arrow-down-circle.svg"
              className="ms-2"
              onClick={() => handleClick(row.s3_document_link)}
              style={{ height: "25px", width: "25px" }}
            />
          </div>
        );
      },
    },
  ];

  const newCols = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Document Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "category",
      headerName: "Type",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "upload_date",
      headerName: "Date",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "s3_document_link",
      headerName: "Download",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      renderCell: (cell) => {
        return (
          <div className="d-flex align-items-center">
            <span className="body-text-md-medium">Available</span>
            <img
              src="https://d1swyfveypj49y.cloudfront.net/arrow-down-circle.svg"
              className="ms-2"
              onClick={() => handleClick(cell.value)}
              style={{ height: "25px", width: "25px" }}
            />
          </div>
        );
      },
    },
  ];

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  return (
    <div className="container ps-3 pe-3">
      <div className="row g-0 w-100 mb-2">
        <h5>Documents</h5>
      </div>
      <div className="row g-0 w-100">
        {/* <ChooseClientTable data={} columns={columns} /> */}

        <DataGridPro
          sx={{
            height: "400px",
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              borderRadius: "6px",
            },
          }}
          rows={docs.map((e) => ({
            ...e,
            upload_date: formatDate(e.upload_date),
          }))}
          columns={newCols}
          loading={false}
          rowHeight={54}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
        />
      </div>
    </div>
  );
};
export default Docs;
