import React, {useState} from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../../Components/InputFields/TextInputField/TextInputField";
import SelectInputField from "../../../../../../Components/InputFields/SelectInputField/SelectInputField";
import { stateList } from "../../../../../../Components/stateList";
import PhoneInputField from "../../../../../../Components/InputFields/PhoneInputField/PhoneInputField";
import GooglePlacesAutocomplete  from "../../../../../../Components/GooglePlacesAutocomplete";

function EntityInformation({
  accountType,
  entityAddressLine1,
  setEntityAddressLine1,
  entityAddressLine1Error,
  setEntityAddressLine1Error,
  entityAddressLine2,
  setEntityAddressLine2,
  entityAddressLine2Error,
  setEntityAddressLine2Error,
  entityCity,
  setEntityCity,
  entityCityError,
  setEntityCityError,
  entityState,
  setEntityState,
  entityStateError,
  setEntityStateError,
  entityPostalCode,
  setEntityPostalCode,
  entityPostalCodeError,
  setEntityPostalCodeError,
  entityTaxIDNumber,
  setEntityTaxIDNumber,
  entityTaxIDNumberError,
  setEntityTaxIDNumberError,
  entityPhoneNumber,
  setEntityPhoneNumber,
  entityPhoneNumberError,
  setEntityPhoneNumberError,
  entityStateOfIncorporation,
  setEntityStateOfIncorporation,
  entityStateOfIncorporationError,
  setEntityStateOfIncorporationError,
  hasAlternateSignatory,
  setHasAlternateSignatory,
  hasAlternateSignatoryError,
  setHasAlternateSignatoryError,
  alternateSignatoryFullName,
  setAlternateSignatoryFullName,
  alternateSignatoryFullNameError,
  setAlternateSignatoryFullNameError,
  alternateSignatoryEmail,
  setAlternateSignatoryEmail,
  alternateSignatoryEmailError,
  setAlternateSignatoryEmailError,
  alternateSignatoryTitle,
  setAlternateSignatoryTitle,
  alternateSignatoryTitleError,
  setAlternateSignatoryTitleError,
  entityCountry,
  setEntityCountry,
  entityCountryError,
  setEntityCountryError
}) {
  return (
    <React.Fragment>
      <GooglePlacesAutocomplete 
      object={"entity"}
      values={{
        addressLine1: entityAddressLine1,
        addressLine2: entityAddressLine2,
        city: entityCity,
        state: entityState,
        postalCode: entityPostalCode, 
        country: entityCountry, 
      }}
      addressCallbacks={{
        setAddressLine1: setEntityAddressLine1,
        setAddressLine2: setEntityAddressLine2,
        setCity: setEntityCity,
        setState: setEntityState,
        setPostalCode: setEntityPostalCode, 
        setCountry: setEntityCountry, 
      }}
      errorList={[entityAddressLine1Error, entityCityError, entityStateError, entityPostalCodeError, entityCountryError]}
      errorCallbacks={[setEntityAddressLine1Error, setEntityCityError, setEntityStateError, setEntityPostalCodeError, setEntityCountryError]}
      />

      <div className="row g-0 w-100 mb-3">
        {accountType !== "SM_LLC" && accountType !== "LT" && (
          <div className="col ps-0 pe-0">
            <TextInputField
              value={entityTaxIDNumber}
              setValue={setEntityTaxIDNumber}
              valueError={entityTaxIDNumberError}
              setValueError={setEntityTaxIDNumberError}
              label={`Entity ${entityCountry === 'United States' ? `EIN` : 'Tax ID' }`}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-entity-tax-id-number-input"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 tw-flex tw-space-x-2">
      <div className="col pe-0">
          <PhoneInputField
            shrink={true}
            label={"Entity Phone Number"}
            size={"small"}
            fullWidth={true}
            required={true}
            value={entityPhoneNumber}
            setValue={setEntityPhoneNumber}
            valueError={entityPhoneNumberError}
            setValueError={setEntityPhoneNumberError}
            disabled={false}
            id={"client-entity-phone-number-input"}
          />
        </div>

        <div className="col pe-2">
         {["United States", ""].includes(entityCountry) && <SelectInputField
            value={entityStateOfIncorporation}
            setValue={setEntityStateOfIncorporation}
            valueError={entityStateOfIncorporationError}
            setValueError={setEntityStateOfIncorporationError}
            selectOptions={stateList}
            label={"State of Incorporation"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-entity-state-of-incorporation-input"}
          /> }
        </div>
      </div>
      {accountType === "TRUST" && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3 mt-3">
            <div className="col pe-0">
              <span className="body-text-sm-semibold">
                Will there be an alternate signatory for this trust? (If no,
                then the grantor of this trust will be the designated signatory)
              </span>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-6">
              <label for={"hasAlternateSignatoryId"} className={"radio"}>
                <input
                  type={"radio"}
                  name={"hasAlternateSignatoryRadio"}
                  id={"hasAlternateSignatoryId"}
                  className={"radio__input"}
                  checked={hasAlternateSignatory === "Yes"}
                  onClick={() => {
                    setHasAlternateSignatory("Yes");
                  }}
                />
                <div className="radio__radio"></div>
                <span className="qualification-question-text ms-2">Yes</span>
              </label>
            </div>
            <div className="col-6">
              <label for={"hasNoAlternateSignatoryId"} className={"radio"}>
                <input
                  type={"radio"}
                  name={"hasNoAlternateSignatoryRadio"}
                  id={"hasNoAlternateSignatoryId"}
                  className={"radio__input"}
                  checked={hasAlternateSignatory === "No"}
                  onClick={() => {
                    setHasAlternateSignatory("No");
                  }}
                />
                <div className="radio__radio"></div>
                <span className="qualification-question-text ms-2">No</span>
              </label>
            </div>
          </div>
          {hasAlternateSignatoryError !== "" && (
            <div className="row g-0 w-100 mb-3">
              <div className="SignupForm-field-error-text mt-1">
                <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                  {hasAlternateSignatoryError}
                </span>
              </div>
            </div>
          )}
          {hasAlternateSignatory === "Yes" && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-2">
                  <div className="align-items-center mt-1">
                    <TextInputField
                      value={alternateSignatoryFullName}
                      setValue={setAlternateSignatoryFullName}
                      setValueError={setAlternateSignatoryFullNameError}
                      valueError={alternateSignatoryFullNameError}
                      label={"Alternate Signatory Full Name"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-full-name-input"}
                    />
                  </div>
                </div>
                <div className="col ps-2 pe-0">
                  <div className="align-items-center mt-1">
                    <TextInputField
                      value={alternateSignatoryEmail}
                      setValue={setAlternateSignatoryEmail}
                      setValueError={setAlternateSignatoryEmailError}
                      valueError={alternateSignatoryEmailError}
                      label={"Alternate Signatory Email"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-email-input"}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col pe-0">
                  <div className="align-items-center mt-1">
                    <TextInputField
                      value={alternateSignatoryTitle}
                      setValue={setAlternateSignatoryTitle}
                      setValueError={setAlternateSignatoryTitleError}
                      valueError={alternateSignatoryTitleError}
                      label={"Alternate Signatory Title"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-title-input"}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default EntityInformation;
