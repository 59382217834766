import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MuiButton from "../Buttons/MuiButton/MuiButton.js";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import SearchBar from "../SearchBar/SearchBar.js";
import "./Styles.scss";

const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  // ....
  return page === "<" ? (
    <img class="arrow" src="/Left-Arrow.svg" onClick={handleClick} />
  ) : page === ">" ? (
    <img class="arrow" src="/Right-Arrow.svg" onClick={handleClick} />
  ) : (
    <li className="page-item" onClick={handleClick}>
      <span href="#" class={active ? "active-page" : "inactive-page"}>
        {" "}
        {page}{" "}
      </span>
    </li>
  );
};

const options = {
  pageButtonRenderer,
  sizePerPage: 10,
};

function ClientTable({
  columns,
  rows,
  noResultsMessage,
  showButton,
  ...props
}) {
  const [query, setQuery] = useState("");

  return (
    <div className="container-xxl container-fluid contain">
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 mb-3">
                <div className="col-12 pe-4">
                  <div className="row g-0 w-100 mb-4 align-items-center">
                    <div className=" col-xl-2 col-lg-2 col-12 me-md-auto mb-lg-0 mb-3">
                      <span className="heading-1">Clients</span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <SearchBar
                      setValue={setQuery}
                      placeholder="Search by name, status, email, etc. "
                    />
                  </div>

                  <div className="row g-0 w-100 mb-3">
                    <div className="table-style pt-3 pe-2 d-flex justify-content-center">
                      <div className="w-98">
                        <BootstrapTable
                          pagination={paginationFactory(options)}
                          keyField="id"
                          data={rows.filter((e) =>
                            Object.values(e)
                              .map((e) => e.includes(query))
                              .some((f) => f === true)
                          )}
                          columns={columns}
                          bordered={false}
                          rowClasses={"table-row-style"}
                        />
                        {rows.length === 0 && (
                          <div className=" w-100 pt-5 pb-5 text-center">
                            <span className="no-transactions-text">
                              {noResultsMessage}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClientTable;
