import React from "react";
import "./Styles/Styles.scss";

function HorizontalTableRow(props) {
  return (
    <div className="row g-0 w-100 p-3">
      <div className="d-flex">
        <div className="body-text-sm-semibold align-self-start ms-0 me-auto">
          {props.rowName}
        </div>
        <div className="body-text-sm-regular align-self-end ms-auto me-0">
          {props.rowValue}
        </div>
      </div>
    </div>
  );
}
export default HorizontalTableRow;
