import React, { useState, useEffect, useContext } from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import { Navigate } from "react-router-dom";
import {
  wmUserCheck,
  authdFeederSignup,
} from "../../../../../APIs/WealthManagementAPI";
import PasswordInputField from "../../../../../Components/InputFields/PasswordInputField/PasswordInputField.js";
import { Auth } from "aws-amplify";
import { isEmailValid } from "../../../../../utilities/functions/functions";
import { useNavigate } from "react-router-dom";
import { getFirmUser } from "../../../../../APIs/WealthManagementAPI";
import { getSignedURL } from "../../../../../utilities/functions/functions";
import { UserContext } from "../../../../../Contexts/UserContext";

const ProvideEmail = ({ navigation, email, setEmail, code }) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [emailError, setEmailError] = useState("");
  const [renderPwd, setRenderPwd] = useState(false);
  const [pwd, setPwd] = useState("");
  const [assocExists, setAssocExists] = useState(false);
  const [pwdError, setPwdError] = useState("");
  const [cognitoSignupError, setCognitoSignupError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [linkingError, setLinkingError] = useState(false);

  const [userId, setUserId] = useState("");
  const [directToLogin, setDirectToLogin] = useState(null);

  const logInJoinFeeder = async () => {
    var errors = false;

    if (pwd === "") {
      errors = true;
      setPwdError("This field is required.");
    }

    if (!errors) {
      try {
        const logUser = await Auth.signIn(email.toString().toLowerCase(), pwd);
        const feederSignupResp = await authdFeederSignup({ code: code });

        if (feederSignupResp.data.detail === "Association already exists") {
          setAssocExists(true);
          return;
        }

        const identityId = await Auth.currentCredentials();
        const userID = email.toString().toLowerCase();

        const wmFirmUserInfo = await getFirmUser();

        const isAuthenticated = true;

        async function getSignedLogoLink(
          id,
          authenticated,
          firmUser,
          setFirmUser,
          setLoadingVal
        ) {
          try {
            var logoLink = await getSignedURL(
              "public",
              firmUser.data.firm.logo
            );

            setFirmUser({
              id: id,
              userProfileData: {
                id: firmUser.data.id,
                firstName: firmUser.data.first_name,
                lastName: firmUser.data.last_name,
                email: firmUser.data.email,
                phoneNumber: firmUser.data.phone_number,
                country: firmUser.data.country,
              },
              userRole: firmUser.data.role,
              firmInfo: {
                id: firmUser.data.firm.id,
                name: firmUser.data.firm.name,
                logoLink: firmUser ? (logoLink ? logoLink : "") : "",
              },
              isAuthenticated: authenticated,
            });
            navigate({
              pathname: "/invest",
              state: { accountLinked: true },
            });
            // setLoadingVal(false);
            // navigate("/invest");
          } catch (err) {
            {}; 
            // setLoadingVal(false);
          }
        }

        getSignedLogoLink(
          userID,
          isAuthenticated,
          wmFirmUserInfo,
          setUser,
          setLoading
        );

      } catch (err) {
        setLinkingError(true);
      }
    }
  };

  const nextStep = async () => {
    if (email === "") {
      setEmailError("Required");
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email.");
    } else {
      const resp = await wmUserCheck(email);
      const userExists = resp.data.detail;

      if (userExists === true) {
        setRenderPwd(true);
        setUserAlreadyExists(true);
      } else navigation.next();
    }
  };
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="col-12">
          <div className="row g-0 w-100 mb-3">
            <h5>Enter your email</h5>
          </div>
          <div className="row g-0 w-100">
            <div className="col">
              <TextInputField
                value={email}
                setValue={setEmail}
                valueError={emailError}
                setValueError={setEmailError}
                label={"Email"}
                required={true}
                fullWidth={true}
                shrink={true}
                disabled={renderPwd}
                size={"small"}
                id={"first-name-input"}
              />
            </div>
            <div className="row g-0 w-100 mt-3">
              {renderPwd && (
                <div className="col">
                  <PasswordInputField
                    value={pwd}
                    setValue={setPwd}
                    valueError={pwdError}
                    setValueError={setPwdError}
                    label={"Password"}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"password-input"}
                  />
                </div>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100">
        <div className="col-12">
          <SnackBar
            snackbarState={cognitoSignupError}
            setSnackbarState={setCognitoSignupError}
            vertical={"top"}
            horizontal={"right"}
            duration={5000}
            sxProps={{ paddingLeft: "3px" }}
            severity={"error"}
            message={directToLogin || "Sorry, there was an error signing up."}
          />
        </div>

        {!renderPwd && (
          <div className="row g-0 w-100">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
              <div className="row g-0 w-100">
                <div className="col ms-0 me-auto">
                  <div className="d-flex">
                    <MuiButton
                      buttonText={"Sign Up"}
                      onClick={nextStep}
                      size={"large"}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {renderPwd && (
          <div className="row g-0 w-100">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
              <div className="row g-0 w-100">
                <div className="col ms-0 me-auto">
                  <div className="d-flex">
                    <MuiButton
                      buttonText={"Log in and Join Feeder"}
                      onClick={logInJoinFeeder}
                      size={"large"}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <SnackBar
          snackbarState={cognitoSignupError}
          setSnackbarState={setCognitoSignupError}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"error"}
          message={directToLogin || "Sorry, there was an error signing up."}
        />
        <SnackBar
          snackbarState={userAlreadyExists}
          setSnackbarState={setUserAlreadyExists}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"warning"}
          message={
            "You already have an Aqua account, please sign in to link your account and gain access to this platform."
          }
        />
        <SnackBar
          snackbarState={linkingError}
          setSnackbarState={setLinkingError}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"error"}
          message={
            "Sorry, there was an error authenticating or linking your account. Please check to make sure you have entered valid credentials."
          }
        />
        <SnackBar
          snackbarState={assocExists}
          setSnackbarState={setAssocExists}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"error"}
          message={"This account is already subscribed to this feeder."}
        />
      </div>
    </React.Fragment>
  );
};
export default ProvideEmail;
